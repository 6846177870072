import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import { Modal, Button } from "react-bootstrap";
import Uri from "../../Uri";
import axios from "axios";
import Helper from "../helpers/Helper";
import PaginationComponent from "../utils/PaginationComponent";
import { useAxios } from "../../App";
import { useMsal } from "@azure/msal-react";

export default function GeoPrecision(props) {
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();
    
    const authToken = localStorage.getItem("token");

    let [geoPrecision, setGeoPrecision] = useState("");
    let [geoPrecisionValue, setGeoPrecisionValue] = useState("");
    let [geoPrecisionUpdate, setGeoPrecisionUpdate] = useState("");
    let [geoPrecisionValueUpdate, setGeoPrecisionValueUpdate] = useState("");
    let [dataSource, setDataSource] = useState([]);
    let [loading, setLoading] = useState(true);
    let [idData, setIdData] = useState();
    let [isInvalid, setIsInvalid] = useState();
    let [isInvalidUpdate, setIsInvalidUpdate] = useState();
    let [currentPage, setCurrentPage] = useState();
    let [pageSize, setPageSize] = useState();
    let [totalData, setTotalData] = useState();


    const [showUpdate, setShowUpdate] = useState();
    const [showDelete, setShowDelete] = useState();

    function handleCloseDelete(e) {

        setShowDelete(false);

    };
    function handleShowDelete(e) {

        setShowDelete(true);

    }

    function handleCloseUpdate(e) {

        setShowUpdate(false);

    };

    function handleShowUpdate(e) {

        setShowUpdate(true);

    }

    function _onChangeGeoPrecision(e) {

        e.preventDefault();

        if (e.target.value) {

            setGeoPrecision(e.target.value);

        } else {

            setGeoPrecision("");

        }

    }

    function _onChangeGeoPrecisionUpdate(e) {

        e.preventDefault();

        if (e.target.value) {

            setGeoPrecisionUpdate(e.target.value);

        } else {

            setGeoPrecisionUpdate("");

        }

    }

    function _onChangeGeoPrecisionValue(e) {

        e.preventDefault();

        if (e.target.value) {

            setGeoPrecisionValue(e.target.value);

        } else {

            setGeoPrecisionValue("");

        }

    }

    function _onChangeGeoPrecisionValueUpdate(e) {

        e.preventDefault();

        if (e.target.value) {

            setGeoPrecisionValueUpdate(e.target.value);

        } else {

            setGeoPrecisionValueUpdate("");

        }

    }

    async function _saveData() {
        let data = {
            "code": geoPrecisionValue,
            "name": geoPrecision,
            "created_by": localStorage.getItem("usernameLogin"),
            "updated_by": localStorage.getItem("usernameLogin")
        }
        await axios.post(Uri.rootUri + `master/geoprec/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(async function (response) {
                // console.log(response);
                caches.delete("geoprec");//delete cache in browser for update
                let cacheName = {
                    "name": "geoprec",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")
                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .catch(function (error) {
                        console.log(error);
                    });
                _loadDataAll("geoprec");
                Helper.alert("Success", "success", "Success save data", 3000);
                setGeoPrecision("");
                setGeoPrecisionValue("");
                _loadData(currentPage, pageSize);
            })
            .catch(function (error) {
                let objError = error.toJSON();
                if (objError.status == 400) {
                    Helper.alert("Failed", "error", "Geo-precision value already exist, please choose another geo-precision value", 3000);
                }
            });

    }

    async function _handleValidation(geoPrecision, geoPrecisionValue, setInvalid, callback) {
        if (!geoPrecision || !geoPrecisionValue) {
            setInvalid(true);
            setTimeout(() => setInvalid(false), 3000);
        } else {

            const cacheStorage = await caches.open("geoprec");
            const cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/geoprec`);
            const responseData = await cacheResponse.json();

            // Are incident type and incident sub type the same?
            const isBothDataExist = responseData.find((item) => item.name == geoPrecision && item.code == geoPrecisionValue);

            if (isBothDataExist) {
                Helper.alert("Failed", "error", "This Geo-precision already exist", 3000);
            } else {
                await callback();
            }

        }

    }
    async function _updateData() {
        let data = {
            "code": geoPrecisionValueUpdate,
            "name": geoPrecisionUpdate,
            "updated_by": localStorage.getItem("usernameLogin")
        }
        await axios.put(Uri.rootUri + `master/geoprec/${idData}/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(async function (response) {
                caches.delete("geoprec");//delete cache in browser for update
                let cacheName = {
                    "name": "geoprec",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")
                }
                await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .catch(function (error) {
                        console.log(error);
                    });

                _loadDataAll("geoprec");
                Helper.alert("Success", "success", "Success update data", 3000);
                setGeoPrecisionUpdate("");
                setGeoPrecisionValueUpdate("");
                setShowUpdate(false);
                _loadData(currentPage, pageSize);
            })
            .catch(function (error) {
                setShowUpdate(false);
            })
    }
    async function _deleteGeoPrecision(e, id) {

        e.preventDefault();



        if (id) {

            // console.log(id);
            let data = {

                "deleted_by": localStorage.getItem("usernameLogin")

            }

            await axios.put(Uri.rootUri + `master/geoprec/${idData}/delete/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(async function (response) {

                    // console.log(response);
                    caches.delete("geoprec");//delete cache in browser for update

                    let cacheName = {

                        "name": "geoprec",
                        "created_by": localStorage.getItem("usernameLogin"),
                        "updated_by": localStorage.getItem("usernameLogin")

                    }

                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                        .then(function (response) {

                            // console.log(response);


                        })
                        .catch(function (error) {


                            console.log(error);

                        });

                    _loadDataAll("geoprec");

                    Helper.alert("Success", "success", "Success delete data", 3000);

                    setShowDelete(false);

                    _loadData(currentPage, pageSize);


                })
                .catch(function (error) {

                    // console.log(error);

                    setShowDelete(false);
                    let errorMessage = error.response?.data?.res;
                    if (error.response?.status == 400 && errorMessage == "Cannot delete, used in an incident") {
                        errorMessage = Helper.errorMsgDataValidation("Geo-precision");
                        Helper.alertHtml("Failed", "error", errorMessage, 3000);
                    }

                });



        } else {



            setShowDelete(false);

        }

    }

    const columns = [
        {
            title: 'Geo-Precision',
            dataIndex: 'code',
            sorter: (a, b) => a.code - b.code,
            width: '80%',
            defaultSortOrder: 'ascend',
            render: (text, record, index) => {

                return (
                    <p className="m-0">{`${record.code} - ${record.name}`}</p>
                )

            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record, index) => (
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                        handleShowUpdate(e);
                        setGeoPrecisionValueUpdate(record.code);
                        setGeoPrecisionUpdate(record.name);
                        setIdData(record.id);
                    }} id="update">
                        <i className="bi bi-pencil-fill"></i>
                    </button>

                    <button type="button" className="btn btn-primary btn-sm"
                        onClick={(e) => {
                            handleShowDelete(e);
                            setIdData(record.id);
                        }} id="delete"><i className="bi bi-trash-fill"></i>
                    </button>
                </div>
            )
        }
    ];

    async function _loadData(page, pageSize) {

        setCurrentPage(page);

        setPageSize(pageSize)

        await axios.get(Uri.rootUri + `master/geoprec/?page=${page}&page_size=${pageSize}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let dataResponse = response.data;

                setDataSource(dataResponse.results);

                setTotalData(dataResponse.count);

            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("geoprec")){

        //     await axios.get(Uri.rootUri + `master/geoprec/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         setDataSource(dataResponse);

        //         Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec` , dataResponse);

        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "geoprec"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/geoprec`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/geoprec/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 setDataSource(dataResponse);

        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });

        //       }else{ //if data in cache not empty


        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/geoprec/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 setDataSource(dataResponse);

        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });

        //         }else{


        //             setDataSource(dataResponse);


        //         }


        //       }


        //     });

        // }

    }

    async function _loadDataAll(cachenameparam) {

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("geoprec")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "geoprec");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let geoprecResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/geoprec');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !geoprecResponse || geoprecResponse == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseGeoprec = await geoprecResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseGeoprec || dataResponseGeoprec.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseGeoprec there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponseGeoprec);

                                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("geoprec")){

        //     await axios.get(Uri.rootUri + `master/geoprec/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec` , dataResponse);

        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultDataIncident = arrCache.filter( x => x == "geoprec"); //get where cache name

        //     resultDataIncident.map(async cachename => {

        //     let cacheStorage = await caches.open(cachename);

        //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/geoprec`);

        //     if(!cacheResponse || cacheResponse == undefined){

        //         await axios.get(Uri.rootUri + `master/geoprec/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec` , dataResponse);

        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //     }else{

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/geoprec/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec` , dataResponse);

        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });

        //         }

        //     }

        //     });

        // }
    }

    async function onChange(page, pageSize) {

        // console.log(page,pageSize,currentPage); //page = new previous  page and new next page

        setPageSize(pageSize);

        setCurrentPage(page);

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=20&';

        await axios.get(Uri.rootUri + `master/geoprec/?${currentPageOnChange}${currentPageSizeOnChange}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let dataResponse = response.data;

                setDataSource(dataResponse.results);

                setTotalData(dataResponse.count);

            })
            .catch(function (error) {

                console.log(error);

            });

    }

    useEffect(() => {
      const effect = async () => {

        //ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadData(1, 100);
        await _loadDataAll("geoprec");
        
        setLoading(false);
      }
      effect();
    }, []);
    return (
        <Spin size="large" spinning={loading}>
            <div className="geoprecision">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Add New Geo-Precision:</h4>
                        <div className="mb-3">
                            <label htmlFor="geoprecisionvalue">Geo-Precision Value</label>
                            {isInvalid && !geoPrecisionValue ?

                                <div id="geoprecisionvalue" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert Geo-Precision Value.
                                </div>
                                :
                                <></>
                            }
                            <input type="number" className="form-control" name="geoprecision" id="geoprecision" onChange={_onChangeGeoPrecisionValue} value={geoPrecisionValue && geoPrecisionValue} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="geoprecision">Geo-Precision</label>
                            {isInvalid && !geoPrecision ?

                                <div id="geoprecision" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert Geo-Precision.
                                </div>
                                :
                                <></>
                            }
                            <input type="text" className="form-control" name="geoprecision" id="geoprecision" onChange={_onChangeGeoPrecision} value={geoPrecision && geoPrecision} />
                        </div>
                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }} onClick={() => _handleValidation(geoPrecision, geoPrecisionValue, setIsInvalid, _saveData)}><i className="bi bi-plus-circle-fill"></i> Add to Master Data</button>
                    </div>
                    <div className="col-lg-8">
                        <h4>Geo-Precision Data</h4>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            scroll={{ x: 'max-content' }}
                        />
                        <div className="text-center mt-3">
                            <PaginationComponent
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                onChange={onChange}
                                showSizeChanger={false}
                                total={totalData ? totalData : 0}
                                defaultPageSize={20}
                            />
                        </div>
                    </div>

                    <>
                        <Modal show={showUpdate} onHide={handleCloseUpdate} id={`update`}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Data</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='mb-3'>
                                    <label htmlFor='type ' className='m-1 d-flex align-items-start'>Geo-Precision Value <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                    {isInvalidUpdate && !geoPrecisionValueUpdate ?

                                        <div id="type" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                            Please insert Geo-Precision Value.
                                        </div>
                                        :
                                        <></>
                                    }
                                    <input type="number" className="form-control" onChange={_onChangeGeoPrecisionValueUpdate} value={geoPrecisionValueUpdate ? geoPrecisionValueUpdate : ""} />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor='type ' className='m-1 d-flex align-items-start'>Geo-Precision <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                    {isInvalidUpdate && !geoPrecisionUpdate ?

                                        <div id="type" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                            Please insert Geo-Precision.
                                        </div>
                                        :
                                        <></>
                                    }
                                    <input type="text" className="form-control" onChange={_onChangeGeoPrecisionUpdate} value={geoPrecisionUpdate ? geoPrecisionUpdate : ""} />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseUpdate}>
                                    Close
                                </Button>
                                <Button type="submit" variant="primary" onClick={() => _handleValidation(geoPrecisionUpdate, geoPrecisionValueUpdate, setIsInvalidUpdate, _updateData)}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='m-0'>Delete this Geo - Precision ?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseDelete}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={(e) => _deleteGeoPrecision(e, idData)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>

                </div>
            </div>
        </Spin>
    )
}