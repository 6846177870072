import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table ,Tag, Spin} from "antd";
import axios from "axios";
import Uri from "../Uri";
import Helper from "./helpers/Helper";
import PaginationComponent from "./utils/PaginationComponent";
import { useAxios } from "../App";
import { useMsal } from "@azure/msal-react";

export default function UserPage(){
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();

    const authToken = localStorage.getItem("token");

    let history = useHistory();

    let [userData, setUserData] = useState([]);
    let [companyData, setCompanyData] = useState([]);
    let [userId, setUserId] = useState("");
    let [activeUsername, setActiveUsername] = useState(""); // State to track selected user item's username, used for deletion
    let [loading, setLoading] = useState("");
    let [permissionData, setPermissionData] = useState([]);
    let [search, setSearch] = useState("");

     //User Information

     let [username, setUsername] = useState("");
     let [emailAddress, setEmailAddress] = useState("");
     let [authId, setAuthId] = useState("");
     let [companyId, setCompanyId] = useState("");
    
     let [permissions, setPermissions] = useState([]);
     let [approveIncident, setApproveIncident] = useState("1");
     let [customerManager, setCustomerManager] = useState("2");
     let [editIncident, setEditIncident] = useState("3");
     let [poiSelfService, setPoiSelfService] = useState("4");
     let [readOnly, setReadOnly] = useState("5");
     let [superUser, setSuperUser] = useState("6");

     let [ currentPage, setCurrentPage] = useState();
     let [ pageSize, setPageSize ] = useState();
     let [ totalData, setTotalData] = useState();

      async function _deleteData(e,id,username){

        e.preventDefault();

        

        if(id){

          let userLogin = {
          
            "deleted_by" : localStorage.getItem("usernameLogin"),
            "deleted_username" : username // for backend to get which user to be deleted

          }

          // console.log(userLogin);

          await axios.put(Uri.rootUri + `user/${id}/delete/`,userLogin, { headers: {"Authorization" : `Bearer ${authToken}`} })
          .then(async function(response){

            // console.log(response);
            caches.delete("users");

            let cacheName = {

              "name" : "users",
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
            .then(function(response){

                console.log(response);

            })
            .catch(function(error){


                console.log(error);

            });

            _loadDataAll("users");

            Helper.alert("Success", "success", "Success delete data", 3000);

            _loadData(currentPage, pageSize);

          })
          .catch(function(error){

            console.log(error);

           

          })

        }

      }

      async function _loadPermissionData(){

        let arr = [];
        const dataPermission = [

            {
                "id":"1",
                "permissionname":"Approve Incident" 
            },
            {
                "id":"2",
                "permissionname":"Customer Manager" 
            },
            {
                "id":"3",
                "permissionname":"Edit Incident" 
            },
            {
                "id":"4",
                "permissionname":"POI Self Service" 
            },
            {
                "id":"5",
                "permissionname":"Read-Only" 
            },
            {
                "id":"6",
                "permissionname":"Super user" 
            },
        ]

        // dataPermission.forEach(x=>{

        //     arr.push(<Option key={x.id}>{x.permissionname}</Option>)

        // });


        setPermissionData(dataPermission);

        // setElPermission(arr);

    }
     
      const columns = [
        {
          title: 'User Name',
          dataIndex: 'username',
          key: 'username',
          sorter : (a,b)=> a.username.localeCompare(b.username) ,
          responsive: ['sm','md','lg','xs'],
        },
        {
            title: 'Permission Type',
            dataIndex: 'permissions',
            key: 'permissions',
            responsive: ['sm','md','lg','xs'],
            render : (text,record,index) => {
              // console.log(companyData,text);
                        return text.map((x)=>{

                            let permissionName = permissionData.find(item => item.id == x);

                            // console.log(permissionName);
                            
                                if(permissionName){
                                  return(
                                        <>
                                            <Tag color={`Grey`} className="text-white"><strong>{permissionName.permissionname}</strong></Tag>
                                        </>
                                  )
                                }else{
                                  return(
                                        <>

                                        </>
                                  )
                                }
                          })
                }
              },
        {
          title: 'Company',
          dataIndex: 'company_id',
          key: 'company_id',
          sorter : (a,b)=> a.company_id.localeCompare(b.company_id) ,
          responsive: ['sm','md','lg','xs'],
          render:(text,record,index)=>{
            
            return companyData.map(x => {

              if(Helper.hiddenChar(x.id) == Helper.hiddenChar(text)){

                  return x.companyname;

              }else{

                  return "";

              }

            })

          }
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          responsive: ['sm','md','lg','xs'],
          render : (text,record,index) => {
            return(
                    <>
                      <div className="btn-group" role="group" aria-label="Basic example">
                          <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>window.open(`${window.location.origin}/Index/EditNewUser/${record.id}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} >
                            {/* Production */}
                              <i className="bi bi-pencil-fill"></i>

                            {/* Development */}
                            {/* <Link onClick={(e)=>window.open(`http://localhost:3000/Index/EditNewUser/${record.id}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} className='text-white'>
                              <i className="bi bi-pencil-fill"></i>
                            </Link> */}
                          </button>
                          <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>{
                            setUserId(record.id);
                            setActiveUsername(record.username);
                          }} data-bs-toggle="modal" data-bs-target={`#exampleModal-delete`}><i className="bi bi-trash-fill"></i></button>
                      </div>
                    </>
                  )
            }
        }

      ];


      async function _loadData(page, pageSize){

        setCurrentPage(page);

        setPageSize(pageSize);

        await axios.get(Uri.rootUri + `user/?page=${page}&page_size=${pageSize}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(function(response){

            // console.log(response);

            let arr = [];

            let dataResponse = response.data;

            setTotalData(dataResponse.count);

            dataResponse.results.forEach(x=>{
              
              let strPermissions = Array.from(Helper.hiddenChar(x.permissions));
              
              // console.log(strPermissions);
              
              let data = {

                "id" : x.id,
                "username" : x.username,
                "company_id" : x.company_id,
                "permissions" : strPermissions,
                "auth_id" : x.auth_id,
                "emailaddress" : x.emailaddress,
                "updated_by" : x.updated_by

              }

              arr.push(data);

            })

            setUserData(arr);


        })
        .catch(function(error){

            console.log(error);

           

        })

      }
    async function _loadCompanyData(){

      let arr = [];

      let arrCache = await caches.keys();// get cache

      if(!arrCache.includes("company")){

        await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(function(response){

            // console.log(response);


            let dataResponse = response.data;

            dataResponse.forEach(x=>{

                let dataObj = {
                    "id" : Helper.hiddenChar(x.id),
                    "companyname" : x.companyname,
                    "subscriptiondate" : x.subscriptiondate,
                    "endofcontract" : x.endofcontract

                }

                arr.push(dataObj);

            });

            setCompanyData(arr);

            Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache

        })
        .catch(function(error){

            console.log(error);

        });

      }else{

          let resultData = arrCache.filter( x => x == "company"); 

          resultData.forEach(async cachename => {

              let cacheStorage = await caches.open(cachename);

              let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/company');

              if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

                  await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                  .then(function(response){
          
                      // console.log(response);
          
                      let arr = [];
          
                      let dataResponse = response.data;
          
                      dataResponse.forEach(x=>{
          
                          let dataObj = {
                              "id" : Helper.hiddenChar(x.id),
                              "companyname" : x.companyname,
                              "subscriptiondate" : x.subscriptiondate,
                              "endofcontract" : x.endofcontract
          
                          }
          
                          arr.push(dataObj);
          
                      });
          
                      setCompanyData(arr);
          
                      Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache
          
                  })
                  .catch(function(error){
          
                      console.log(error);
          
                  });

              }else{// if data in cache not empty

              let dataResponse = await cacheResponse.json();

              if(!dataResponse || dataResponse.length == 0){

                  await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                  .then(function(response){
          
                      // console.log(response);
          
                      let arr = [];
          
                      let dataResponse = response.data;
          
                      dataResponse.forEach(x=>{
          
                          let dataObj = {
                              "id" : Helper.hiddenChar(x.id),
                              "companyname" : x.companyname,
                              "subscriptiondate" : x.subscriptiondate,
                              "endofcontract" : x.endofcontract
          
                          }
          
                          arr.push(dataObj);
          
                      });
          
                      setCompanyData(arr);
          
                      Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache
          
                  })
                  .catch(function(error){
          
                      console.log(error);
          
                  });

                }else{

                  dataResponse.forEach(x=>{
          
                    let dataObj = {
                        "id" : Helper.hiddenChar(x.id),
                        "companyname" : x.companyname,
                        "subscriptiondate" : x.subscriptiondate,
                        "endofcontract" : x.endofcontract
    
                    }
    
                    arr.push(dataObj);
    
                  });
                  
                  setCompanyData(arr);

                }

              }

          });

      }


    }

    function _searchForm(e){

      if(e.target.value){

        setSearch(e.target.value);

      }else{

        setSearch("");

      }


    }

    async function _onSearch(e){

      e.preventDefault();

      let searchText = search ? `search=${search}&` : "";

      await axios.get(Uri.rootUri + `user/?page=1&${searchText}page_size=50`, { headers: {"Authorization" : `Bearer ${authToken}`} })
      .then(function(response){

        // console.log(response);

        let arr = [];

        let dataResponse = response.data;

        setTotalData(dataResponse.count);

        setCurrentPage(1);

        dataResponse.results.forEach(x=>{

          // console.log(x);

          // let strPermissionsPrefix = x.permissions.substr(1);
        
          // let strPermissionsSuffix = strPermissionsPrefix.substr(0, strPermissionsPrefix.length - 1);
          
          let strPermissions = Array.from(Helper.hiddenChar(x.permissions));
          
          // console.log(strPermissions);
          
          let data = {

            "id" : x.id,
            "username" : x.username,
            "company_id" : x.company_id,
            "permissions" : strPermissions,
            "auth_id" : x.auth_id,
            "emailaddress" : x.emailaddress,
            "updated_by" : x.updated_by

          }

          arr.push(data);

        })

        setUserData(arr);


      })
      .catch(function(error){

        console.log(error);

      });

    }

    async function onChange(page, pageSize){

      // console.log(page,pageSize,currentPage); //page = new previous  page and new next page

      setPageSize(pageSize);

      setCurrentPage(page);

      let searchText = search ? `search=${search}&` : "";

      let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

      let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=50&';        

      await axios.get(Uri.rootUri + `user/?${currentPageOnChange}${searchText}${currentPageSizeOnChange}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
      .then(function(response){

          let arr = [];

          let dataResponse = response.data;

          setTotalData(dataResponse.count);

          dataResponse.results.forEach(x=>{
              
            let strPermissions = Array.from(Helper.hiddenChar(x.permissions));
            
            let data = {

              "id" : x.id,
              "username" : x.username,
              "company_id" : x.company_id,
              "permissions" : strPermissions,
              "auth_id" : x.auth_id,
              "emailaddress" : x.emailaddress,
              "updated_by" : x.updated_by

            }

            arr.push(data);

          });

          setUserData(arr);

      })
      .catch(function(error){

          console.log(error);

      });

  }

    async function _loadUserInformation(){

      const userInformation = await Helper.getUserLogin();
  
      if(userInformation){
  
        setUserId(userInformation.user_id);
  
        setEmailAddress(userInformation.email_address);
  
        setAuthId(userInformation.auth_id);
  
        setUserId(userInformation.company_id);
  
        setUsername(userInformation.username);
  
        let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));
  
        setPermissions(strPermissions);
  
        if(!strPermissions.includes(superUser)){// if not Permission Type Edit Incident
  
              history.push("Unauthorize");

              // window.location.replace(`${window.location.origin}/Index/Unauthorize`);
  
              // window.location.replace("http://localhost:3000/Index/Unauthorize");
  
          }
  
      }
  
    }

    async function _loadDataAll(cachenameparam){

      let arrCache = await caches.keys();// get cache

      await axios.get(Uri.rootUri + `cache/`)
      .then(async function(response){

        // console.log(response);

        //get version data if there is an update
        let dataResponse = response.data;

        //get data cache where cache name
        let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);
        
        // console.log(resultObjCache);

        // get cache name users
        if(!arrCache.includes("users")){// if empty cache name in browser

          // console.log("cache in browser empty");

          await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
          .then(function(response){

            // console.log(response);

            let dataResponse = response.data;

            Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache

          })
          .catch(function(error){

            console.log(error);

          });

          if(resultObjCache.length == 0){// if data cache in database empty

            let cacheName = {

              "name" : cachenameparam,
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
            .then(function(response){

                // console.log(response);


            })
            .catch(function(error){


                console.log(error);

            });

          }else{

                // console.log("set version");

                // if data cache in database not empty
                Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

          }

        }else{ // if cache name available

          let resultData = arrCache.filter( x => x == "users"); 

          //Operation Caching Validation

          resultData.forEach(async cachename => {

            let cacheStorage = await caches.open(cachename);

            let usersResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/users');
            //get version in cache for check update data
            let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

            if(!cacheResponse || cacheResponse == undefined || !usersResponse || usersResponse == undefined){// check data valid or no valid

                await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){
      
                  // console.log(response);
      
                  let dataResponse = response.data;
      
                  Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` , dataResponse);//set cache

                })
                .catch(function(error){
      
                  console.log(error);
      
                });


                if(resultObjCache.length == 0){// if data cache in database empty

                  let cacheName = {
      
                    "name" : cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")
      
                  }
      
                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function(response){
      
                      // console.log(response);
    
      
                  })
                  .catch(function(error){
      
      
                      console.log(error);
      
                  });
      
                }else{
      
                      // if data cache in database not empty
                      Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache
      
                }

            }else{

              let dataResponseVersion = await cacheResponse.json();

              let dataResponseUsers = await usersResponse.json();

              if(!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseUsers || dataResponseUsers.length == 0){/*check data into cache empty or not empty*/

                  await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                  .then(function(response){
        
                    // console.log(response);
        
                    let dataResponse = response.data;
        
                    Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache

                     // Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , );//set cache
        
                  })
                  .catch(function(error){
        
                    console.log(error);
        
                  }); 


                  if(resultObjCache.length == 0){// if data cache in database empty

                    let cacheName = {
        
                      "name" : cachenameparam,
                      "created_by": localStorage.getItem("usernameLogin"),
                      "updated_by": localStorage.getItem("usernameLogin")
        
                    }
        
                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function(response){
        
                        // console.log(response);
        
        
                    })
                    .catch(function(error){
        
        
                        console.log(error);
        
                    });
        
                  }else{
        
                        // if data cache in database not empty
                        Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache
        
                  }

              }else{

                  // dataResponseVersion and dataResponseUsers there must be !

                  if(resultObjCache.length == 0){// if data cache in database empty

                    await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                    .then(function(response){
          
                      // console.log(response);
          
                      let dataResponse = response.data;
          
                      Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache
  
                       // Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , );//set cache
          
                    })
                    .catch(function(error){
          
                      console.log(error);
          
                    }); 

                    let cacheName = {
        
                      "name" : cachenameparam,
                      "created_by": localStorage.getItem("usernameLogin"),
                      "updated_by": localStorage.getItem("usernameLogin")
        
                    }
        
                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function(response){
        
                        // console.log(response);
        
                    })
                    .catch(function(error){
        
                        console.log(error);
        
                    });
        
                  }else{

                        //check version  cache local browser you and database api , resultObjCache there must be !
                        let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                        if(resultVersion){

                            // console.log("Update cache new version ");

                            await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                            .then(function(response){
                  
                              // console.log(response);
                  
                              let dataResponse = response.data;
                  
                              Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache
          
                              // Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , );//set cache
                  
                            })
                            .catch(function(error){
                  
                              console.log(error);
                  
                            }); 
                            
                            Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                        }else{

                            Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users`, dataResponseUsers);

                            Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                        }
        
                        // if data cache in database not empty
        
                  }


              } 

              // console.log(dataResponse);

            }

          });

        }

      })
      .catch(function(error){

        console.log(error);

      });
      
      // console.log(arrCache);
      // let arrCache = await caches.keys();// get cache

      // if(!arrCache.includes("users")){

      //     await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
      //     .then(function(response){

      //       // console.log(response);

      //       let dataResponse = response.data;

      //       Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache

      //     })
      //     .catch(function(error){

      //       console.log(error);

      //     });

      // }else{

      //   let resultData = arrCache.filter( x => x == "users"); 

      //     resultData.forEach(async cachename => {

      //       let cacheStorage = await caches.open(cachename);

      //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/users');

      //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

      //         await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
      //         .then(function(response){

      //           // console.log(response);

      //           let dataResponse = response.data;

      //           Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache

      //         })
      //         .catch(function(error){

      //           console.log(error);

      //         });

      //       }else{// if data in cache not empty

      //         let dataResponse = await cacheResponse.json();

      //         if(!dataResponse || dataResponse.length == 0){

      //           await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
      //           .then(function(response){
      
      //             // console.log(response);
      
      //             let dataResponse = response.data;
      
      //             Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache
      
      //           })
      //           .catch(function(error){
      
      //             console.log(error);
      
      //           });

      //         }

      //       }

      //     });

      // }


    }

    useEffect(()=>{
      const effect = async () => {
          // ajax call pending set loading
          // axios.setLoadingInterceptors(setLoading);
          await Helper.authorizationLogin(axios, msalInstance);

          await _loadUserInformation();

          await _loadData(1,50);
          await _loadDataAll("users");
          await _loadCompanyData();
          await _loadPermissionData();
          
          setLoading(false);
        }
        effect();
    },[]);

    return(
      <Spin spinning={loading} size={"large"}>
        <div className="UserPage">

            <div className="row mb-4">
                <div className="col-lg-4">
                    <Link to="/Index/AddNewUser"><button type="button" class="btn btn-primary btn-sm"><i class="bi bi-plus-circle-fill"></i> Add New User</button>
                    </Link>
                </div>
                <div className="col-lg-8 col-sm-12 col-xs-12">
                    <div className='d-flex align-items-center ml-5 mb-3'>
                        <div className="d-inline-block mx-2" >
                            <label htmlFor="search" className="col-form-label">Search: </label>
                        </div>
                        <div class="input-group" style={{ width:250 }}>
                            <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={_searchForm}/>
                            <button class="btn btn-primary" type="submit" id="button-addon2" onClick={_onSearch}><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {/* {console.log(userData)} */}
                    <Table 
                      columns={columns}
                      dataSource={userData}
                      pagination={false}
                      size={"small"}
                      scroll={{ x:'max-content'}}
                    />
                    <div className='text-center mt-3'>
                        <PaginationComponent
                            currentPage = {currentPage}
                            setCurrentPage = {setCurrentPage}
                            pageSize = {pageSize}
                            setPageSize = {setPageSize}
                            onChange = {onChange}
                            showSizeChanger = {false}
                            total = {totalData ? totalData : 0}
                            defaultPageSize = {50}
                        />
                    </div>
                </div>
            </div>

                <div className="modal fade" id={`exampleModal-delete`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Delete this user ?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <Link className="text-decoration-none text-white" onClick={(e)=>_deleteData(e,userId,activeUsername)}><button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button></Link>
                        </div>
                        </div>
                    </div>
                </div>

        </div>
      </Spin>
    )
}