import React, { useEffect, useState } from "react";
import { Pagination } from "antd";

export default function PaginationComponent(props){

    // console.log(props);

    // function onChange(page, pageSize){

    //     // console.log(page,pageSize); //page = new previous  page and new next page

    //     props.setCurrentPage(page);

    // }
    // function onShowSizeChange(current,size){

    //     // console.log(current,size);

    //     props.setPageSize(size);

    // }
    
    return(
        <Pagination 
            current={props.currentPage ? props.currentPage : 1}
            defaultCurrent={1}
            total={props.total ? props.total : 0}
            pageSize={props.pageSize ? props.pageSize : props.defaultPageSize}
            pageSizeOptions={props.pageSizeOptions}
            onChange={props.onChange}
            onShowSizeChange={props.onShowSizeChange}
            showSizeChanger={props.showSizeChanger}
        />
    )
}