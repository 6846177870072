import { PublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import { loginRequest } from "./authConfig";
import Uri from "./Uri";

const instance = axios.create({
  // baseURL: Uri.localUri,
  baseURL: Uri.rootUri,
  timeout: 30000,
});

export abstract class Adapter {
  abstract get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>>;
  abstract post<T>(
    url: string,
    object: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>>;
  abstract put<T>(
    url: string,
    object: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>>;
}

export class AxiosAdapter extends Adapter {
  constructor() {
    super();
  }

  async get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    if (config) {
      return instance.get(url, config);
    }
    return instance.get(url);
  }

  async post<T>(
    url: string,
    object: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    if (config) {
      return instance.post(url, object, config);
    }
    return instance.post(url, object);
  }

  async put<T>(
    url: string,
    object: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    if (config) {
      return instance.put(url, object, config);
    }
    return instance.put(url, object);
  }
}

export class AxiosService {
  public config: AxiosRequestConfig;
  private setState: Function;
  private msalInstance: PublicClientApplication;
  private token: string;
  private numberOfPendingCall: number;

  constructor(
    accessToken?: string,
    setState?: Function,
    msalInstance?: PublicClientApplication
  ) {
    this.config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    this.setState = setState;
    this.msalInstance = msalInstance;
    this.token = accessToken;
    this.numberOfPendingCall = 0;
  }

  adapter = new AxiosAdapter();

  private updateTokenFromStorage = () => {
    const storageToken = localStorage.getItem("token");
    // console.log(storageToken);

    if (!!!storageToken) return;

    this.token = storageToken;
    this.config = {
      headers: {
        Authorization: `Bearer ${storageToken}`,
      },
    };
    return;
  };

  private refreshTokenIfExpired = async () => {
    console.log(this.msalInstance.getAllAccounts());

    const tkn: string | null = this.token;
    if (!!!tkn) return;

    const decodedToken: any = jwtDecode(tkn);
    const now = new Date();

    try {
      if (decodedToken.exp && decodedToken.exp * 1000 < now.getTime()) {
        // Token expired
        const authResult = await this.msalInstance.acquireTokenSilent(
          loginRequest
        );
        const newToken = authResult.idToken;

        this.setState(
          new AxiosService(newToken, this.setState, this.msalInstance)
        );

        this.config = {
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        };
        this.token = newToken;
        localStorage.setItem("token", newToken);
      }
    } catch (error) {
      console.error(error);
    }
  };

  public setLoadingInterceptors(setLoading: Function) {
    let numberOfPendingCall = 0;

    instance.interceptors.request.use(
      (config) => {
        numberOfPendingCall++;
        setLoading(true);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response) => {
        numberOfPendingCall--;
        if (numberOfPendingCall == 0) {
          setLoading(false);
        }
        return response;
      },
      (error) => {
        numberOfPendingCall--;
        if (numberOfPendingCall == 0) {
          setLoading(false);
        }
        return Promise.reject(error);
      }
    );
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<any> {
    this.updateTokenFromStorage();
    await this.refreshTokenIfExpired();
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.adapter.get(url, this.config);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async post<T>(
    url: string,
    body: any,
    config?: AxiosRequestConfig
  ): Promise<any> {
    await this.refreshTokenIfExpired();
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.adapter.post(url, body, this.config);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async put<T>(
    url: string,
    body: any,
    config?: AxiosRequestConfig
  ): Promise<any> {
    this.updateTokenFromStorage();
    await this.refreshTokenIfExpired();
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.adapter.put(url, body, this.config);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}
