import React, { useEffect, useState } from "react";
import { Radio, Table } from 'antd';
import StateRegion from "./childdatavalidations/StateRegion";
import Township from "./childdatavalidations/Township";
import TypeOfIncident from "./childdatavalidations/TypeOfIncident";
import SubTypeOfIncident from "./childdatavalidations/SubTypeOfIncident";
import TargetHuman from "./childdatavalidations/TargetHuman";
import TargetFacality from "./childdatavalidations/TargetFacality";
import GeoPrecision from "./childdatavalidations/GeoPrecision";
import Party1MyanmarSecurityForces from "./childdatavalidations/Party1MyanmarSecurityForces";
import Party2EstabilishedEao from "./childdatavalidations/Party2EstabilishedEao";
import Party3CivilianSelfDefenceForces from "./childdatavalidations/Party3CivilianSelfDefenceForces";
import Party4Civilians from "./childdatavalidations/Party4Civilians";
import Party5Others from "./childdatavalidations/Party5Others";
import Helper from "./helpers/Helper";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAxios } from "../App";
import { useMsal } from "@azure/msal-react";

export default function DataValidation(props){
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();

    let [tabPane, setTabPane] = useState();
    let [active, setActive] = useState();

    let history = useHistory();
    //User Information

    let [username, setUsername] = useState("");
    let [emailAddress, setEmailAddress] = useState("");
    let [authId, setAuthId] = useState("");
    let [companyId, setCompanyId] = useState("");
    let [userId, setUserId] = useState("");
    let [permissions, setPermissions] = useState([]);
    let [approveIncident, setApproveIncident] = useState("1");
    let [customerManager, setCustomerManager] = useState("2");
    let [editIncident, setEditIncident] = useState("3");
    let [poiSelfService, setPoiSelfService] = useState("4");
    let [readOnly, setReadOnly] = useState("5");
    let [superUser, setSuperUser] = useState("6");

    function onChangeTabs(e) {

        setTabPane(e.target.value);

        setActive(e.target.value);

    }

    function onTabClicked(tab) {
      return (() => {
        setTabPane(tab);
        setActive(tab);
      })
    }

    async function _loadUserInformation(){

        const userInformation = await Helper.getUserLogin();

        if(userInformation){

          setUserId(userInformation.user_id);

          setEmailAddress(userInformation.email_address);

          setAuthId(userInformation.auth_id);

          setCompanyId(userInformation.company_id);

          setUsername(userInformation.username);

          let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));

          setPermissions(strPermissions);

          if(!strPermissions.includes(superUser)){// if not Permission Type Edit Incident

                history.push("Unauthorize");

                // window.location.replace(`${window.location.origin}/Index/Unauthorize`);

                // window.location.replace("http://localhost:3000/Index/Unauthorize");

            }

        }

      }

    useEffect(()=>{

        Helper.authorizationLogin(axios, msalInstance);

        _loadUserInformation();

        setTabPane(1);
        setActive(1);

    },[]);

    return(
        <div className="dataValidation">
            <div className="row mb-3">
                <div className="col-lg-12">
                    <h3 className="text-center">Data Validation List</h3>
                </div>
            </div>
            <div className="row justify-content-center mb-4">
                <div className="col-lg-12 text-center">
                    <Radio.Group defaultValue="1">
                        <Radio.Button value="1" onClick={onTabClicked(1)} className={ active == "1" ? "text-white bg-secondary border border-white" : "text-dark"}>Incident Category</Radio.Button>
                        <Radio.Button value="2" onClick={onTabClicked(2)} className={ active == "2" ? "text-white bg-secondary border border-white" : "text-dark"}>Sub-type of Incident </Radio.Button>
                        <Radio.Button value="3" onClick={onTabClicked(3)} className={ active == "3" ? "text-white bg-secondary border border-white" : "text-dark"}>Party 1</Radio.Button>
                        <Radio.Button value="4" onClick={onTabClicked(4)} className={ active == "4" ? "text-white bg-secondary border border-white" : "text-dark"}>Party 2</Radio.Button>
                        <Radio.Button value="5" onClick={onTabClicked(5)} className={ active == "5" ? "text-white bg-secondary border border-white" : "text-dark"}>Party 3</Radio.Button>
                        <Radio.Button value="6" onClick={onTabClicked(6)} className={ active == "6" ? "text-white bg-secondary border border-white" : "text-dark"}>Party 4</Radio.Button>
                        <Radio.Button value="7" onClick={onTabClicked(7)} className={ active == "7" ? "text-white bg-secondary border border-white" : "text-dark"}>Party 5</Radio.Button>
                        <Radio.Button value="8" onClick={onTabClicked(8)} className={ active == "8" ? "text-white bg-secondary border border-white" : "text-dark"}>Target (Human)</Radio.Button>
                        <Radio.Button value="9" onClick={onTabClicked(9)} className={ active == "9" ? "text-white bg-secondary border border-white" : "text-dark"}>Target (Facility)</Radio.Button>
                        <Radio.Button value="10" onClick={onTabClicked(10)} className={ active == "10" ? "text-white bg-secondary border border-white" : "text-dark"}>State / Region</Radio.Button>
                        <Radio.Button value="11" onClick={onTabClicked(11)} className={ active == "11" ? "text-white bg-secondary border border-white" : "text-dark" }>Township</Radio.Button>
                        <Radio.Button value="12" onClick={onTabClicked(12)} className={ active == "12" ? "text-white bg-secondary border border-white" :
                        "text-dark" }>Geo-precision</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {   tabPane == 1 &&

                        <TypeOfIncident/>

                    }

                    {   tabPane == 2 &&

                        <SubTypeOfIncident/>

                    }

                    {   tabPane == 3 &&

                        <Party1MyanmarSecurityForces/>

                    }

                    {   tabPane == 4 &&

                        <Party2EstabilishedEao/>

                    }

                    {   tabPane == 5 &&

                        <Party3CivilianSelfDefenceForces/>

                    }

                    {   tabPane == 6 &&

                        <Party4Civilians/>

                    }

                    {   tabPane == 7 &&

                        <Party5Others/>

                    }

                    {   tabPane == 8 &&

                        <TargetHuman/>

                    }

                    {   tabPane == 9 &&

                        <TargetFacality/>

                    }

                    {   tabPane == 10 &&
                        <StateRegion/>
                    }


                    {   tabPane == 11 &&
                        <Township/>
                    }

                    {   tabPane == 12 &&
                        <GeoPrecision/>
                    }
                </div>
            </div>
        </div>
    )
}