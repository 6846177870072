import React, { useState, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { Modal, Button } from 'react-bootstrap';
import Uri from '../../Uri';
import axios from 'axios';
import PaginationComponent from '../utils/PaginationComponent';
import Helper from "../helpers/Helper";
import { useAxios } from '../../App';
import { useMsal } from '@azure/msal-react';

export default function SubTypeOfIncident(props) {
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();
    

    const authToken = localStorage.getItem("token");

    let [subTypeOfIncident, setSubTypeOfIncident] = useState("");
    let [subTypeOfIncidentUpdate, setSubTypeOfIncidentUpdate] = useState("");
    let [typeOfIncident, setTypeOfIncident] = useState("");
    let [typeOfIncidentUpdate, setTypeOfIncidentUpdate] = useState("");

    let [pageSize, setPageSize] = useState();
    let [currentPage, setCurrentPage] = useState();
    let [totalData, setTotalData] = useState();


    let [dataSource, setDataSource] = useState([]);
    let [isInvalid, setIsInvalid] = useState();
    let [isInvalidUpdate, setIsInvalidUpdate] = useState();
    let [search, setSearch] = useState("");
    let [loading, setLoading] = useState(true);
    let [idData, setIdData] = useState();


    // Element
    let [elTypeOfIncident, setElTypeOfIncident] = useState([]);
    let [typeOfIncidentData, setTypeOfIncidentData] = useState([]);


    const [showUpdate, setShowUpdate] = useState();
    const [showDelete, setShowDelete] = useState();

    function handleCloseDelete(e) {

        setShowDelete(false);

    };
    function handleShowDelete(e) {

        setShowDelete(true);

    }

    function handleCloseUpdate(e) {

        setShowUpdate(false);

    };
    function handleShowUpdate(e) {

        setShowUpdate(true);

    }

    const columns = [
        {
            title: 'Sub-type of Incident',
            dataIndex: 'name',
            // key: 'name',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            // responsive: ['sm','md','lg','xs'],
        },
        {
            title: 'Incident Category',
            dataIndex: 'incident_name',
            key: 'incident_name',
            sorter: (a, b) => a.incident_name.localeCompare(b.incident_name),
            responsive: ['sm', 'md', 'lg', 'xs'],
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            responsive: ['sm', 'md', 'lg', 'xs'],
            render: (text, record, index) => {
                return (
                    <>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                                handleShowUpdate(e);
                                setSubTypeOfIncidentUpdate(record.name);
                                setTypeOfIncidentUpdate(record.incident_id);
                                setIdData(record.id);
                            }} id="update">
                                <i className="bi bi-pencil-fill"></i>
                            </button>

                            <button type="button" className="btn btn-primary btn-sm"
                                onClick={(e) => {
                                    handleShowDelete(e);
                                    setIdData(record.id);
                                }} id="delete"><i className="bi bi-trash-fill"></i></button>
                        </div>
                    </>
                )
            }
        }
    ]

    function _searchForm(e) {

        e.preventDefault();

        let keyword = e.target.value;

        if (keyword) {

            setSearch(keyword);

        } else {

            setSearch("");

        }

    }

    function _onChangeSubTypeOfIncident(e) {

        if (e.target.value) {

            setSubTypeOfIncident(e.target.value);

        } else {

            setSubTypeOfIncident("");

        }

    }
    function _onChangeSubTypeOfIncidentUpdate(e) {

        if (e.target.value) {

            setSubTypeOfIncidentUpdate(e.target.value);

        } else {

            setSubTypeOfIncidentUpdate("");

        }

    }
    function _typeOfIncident(e) {

        if (e.target.value) {

            setTypeOfIncident(e.target.value);

        } else {

            setTypeOfIncident("");

        }

    }
    function _onChangeTypeOfIncidentUpdate(e) {

        if (e.target.value) {

            setTypeOfIncidentUpdate(e.target.value);

        } else {

            setTypeOfIncidentUpdate("");

        }

    }

    async function _deleteSubTypeOfIncident(e, id) {

        e.preventDefault();



        if (id) {

            let data = {

                "deleted_by": localStorage.getItem("usernameLogin")

            }

            // console.lo

            await axios.put(Uri.rootUri + `master/subincident/${id}/delete/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(async function (response) {

                    console.log(response);

                    caches.delete("subincident"); //delete cache for update

                    let cacheName = {

                        "name": "subincident",
                        "created_by": localStorage.getItem("usernameLogin"),
                        "updated_by": localStorage.getItem("usernameLogin")

                    }

                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                        .then(function (response) {

                            // console.log(response);


                        })
                        .catch(function (error) {


                            console.log(error);

                        });

                    _loadDataAll("subincident");

                    _loadTypeOfIncidentData("incident");

                    Helper.alert("Success", "success", "Success delete data", 3000);

                    setShowDelete(false);

                    _loadData(currentPage, pageSize);

                })
                .catch(function (error) {

                    setShowDelete(false);
                    let errorMessage = error.response?.data?.res;
                    if (error.response?.status == 400 && errorMessage == "Cannot delete, used in an incident") {
                        errorMessage = Helper.errorMsgDataValidation("Sub Type of Incident");
                        Helper.alertHtml("Failed", "error", errorMessage, 3000);
                    }
                })



        } else {



            setShowDelete(false);

        }


    }

    async function _saveData() {
        let data = {
            "name": subTypeOfIncident,
            "incident_id": Helper.hiddenChar(typeOfIncident),
            "created_by": localStorage.getItem("usernameLogin"),
            "updated_by": localStorage.getItem("usernameLogin")

        }
        await axios.post(Uri.rootUri + `master/subincident/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(async function (response) {
                // console.log(response);
                caches.delete("subincident"); //delete cache for update
                let cacheName = {
                    "name": "subincident",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }
                await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .catch(function (error) {
                        console.log(error);
                    });
                _loadDataAll("subincident");
                _loadTypeOfIncidentData("incident");
                Helper.alert("Success", "success", "Success save data", 3000);
                setTypeOfIncident("");
                setSubTypeOfIncident("");
                _loadData(currentPage, pageSize);
            })
            .catch(function (error) {
                // console.log(error);
                let objError = error.toJSON();
                if (objError.status == 400) {
                    Helper.alert("Failed", "error", "Sub type of incident already exist", 3000);
                }
            });
    }

    async function _handleValidation(typeSubName, typeName, setInvalid, callback) {

        if (!typeSubName || !typeName) {
            setInvalid(true);
            setTimeout(() => setInvalid(false), 3000);
        } else {

            const cacheStorage = await caches.open("subincident");
            const cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/subincident`);
            const responseData = await cacheResponse.json();

            // Are incident type and incident sub type the same?
            const isBothDataExist = responseData.find((item) => item.name == typeSubName && item.incident_id == Helper.hiddenChar(typeName));

            if (isBothDataExist) {
                Helper.alert("Failed", "error", "This incident name already exists and is already assigned to this incident category", 3000);
            } else {
                await callback();
            }
        }
    }
    async function _updateData() {
        let data = {
            "incident_id": Helper.hiddenChar(typeOfIncidentUpdate),
            "name": subTypeOfIncidentUpdate,
            "updated_by": localStorage.getItem("usernameLogin"),
        }
        await axios.put(Uri.rootUri + `master/subincident/${idData}/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(async function (response) {
                // console.log(response);
                caches.delete("subincident"); //delete cache for update
                let cacheName = {

                    "name": "subincident",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }
                await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .catch(function (error) {
                        console.log(error);
                    });
                _loadDataAll("subincident");
                _loadTypeOfIncidentData("incident");
                Helper.alert("Success", "success", "Success update data", 3000);
                setTypeOfIncidentUpdate("");
                setSubTypeOfIncidentUpdate("");
                setShowUpdate(false);
                _loadData(currentPage, pageSize);
            })
            .catch(function (error) {
                console.log(error);
                setShowUpdate(false);
            });
    }

    async function _loadTypeOfIncidentData(cachenameparam) {


        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("incident")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            let dataResponse = response.data;

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                            })

                            setElTypeOfIncident(arr);

                            setTypeOfIncidentData(dataResponse);

                            Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);



                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "incident");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let incidentResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/incident');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !incidentResponse || incidentResponse == undefined) {// check data valid or no valid

                            // console.log("set version into cache");

                            await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    let dataResponse = response.data;

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                    })

                                    setElTypeOfIncident(arr);

                                    setTypeOfIncidentData(dataResponse);

                                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);



                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        //   console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                //   console.log("set version");

                                // if data cache in database not empty
                                Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }


                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseIncident = await incidentResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseIncident || dataResponseIncident.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        let dataResponse = response.data;

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                        })

                                        setElTypeOfIncident(arr);

                                        setTypeOfIncidentData(dataResponse);

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);



                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);


                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // console.log("set version");

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseIncident there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            let dataResponse = response.data;

                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                            })

                                            setElTypeOfIncident(arr);

                                            setTypeOfIncidentData(dataResponse);

                                            Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);



                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);


                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                let dataResponse = response.data;

                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                                })

                                                setElTypeOfIncident(arr);

                                                setTypeOfIncidentData(dataResponse);

                                                Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);



                                            });

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        // console.log("data incident and version available in cache");

                                        dataResponseIncident.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                        })

                                        setElTypeOfIncident(arr);

                                        setTypeOfIncidentData(dataResponse);

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponseIncident);

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arr = [];

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("incident")){

        //     await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

        //         })

        //         setElTypeOfIncident(arr);

        //         setTypeOfIncidentData(dataResponse);

        //         Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponse);


        //     })
        //     .catch(function(error){

        //         console.log(error);



        //     });

        // }else{


        //   let resultData = arrCache.filter( x => x == "incident");

        //   resultData.forEach(async cachename => {

        //     let cacheStorage = await caches.open(cachename);

        //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/incident');

        //     // console.log(dataResponse);

        //     if(!cacheResponse || cacheResponse == undefined){// if empty data in cache

        //       // console.log("Set Cache Incident");

        //       await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

        //             })

        //             setElTypeOfIncident(arr);

        //             setTypeOfIncidentData(dataResponse);

        //             Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponse);

        //         })
        //         .catch(function(error){

        //             console.log(error);



        //         });


        //     }else{// if data not empty in cache

        //     // console.log(dataResponse);

        //       let dataResponse = await cacheResponse.json();

        //       if(!dataResponse || dataResponse.length == 0){

        //         await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

        //             })

        //             setElTypeOfIncident(arr);

        //             setTypeOfIncidentData(dataResponse);

        //             Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponse);


        //         })
        //         .catch(function(error){

        //             console.log(error);


        //         });

        //       }else{


        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

        //         })

        //         setElTypeOfIncident(arr);

        //         setTypeOfIncidentData(dataResponse);

        //       }

        //     }


        //   });

        // }

    }
    async function _loadData(page, pageSize) {

        setCurrentPage(page);

        setPageSize(pageSize);

        await axios.get(Uri.rootUri + `master/subincident/?page=${page}&page_size=${pageSize}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let dataResponse = response.data.results;

                // console.log(response);

                setTotalData(response.data.count);

                setDataSource(dataResponse);


            })
            .catch(function (error) {

                // console.log(error);



            });

    }

    async function _loadDataAll(cachenameparam) {

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("subincident")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "subincident");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let subIncidentResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/subincident');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !subIncidentResponse || subIncidentResponse == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseSubIncident = await subIncidentResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseSubIncident || dataResponseSubIncident.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseSubIncident there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponseSubIncident);

                                        Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("subincident")){

        //     await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponse);


        //     })
        //     .catch(function(error){

        //         // console.log(error);



        //     });

        // }else{

        //     let resultDataIncident = arrCache.filter( x => x == "subincident"); //get where cache name

        //     resultDataIncident.map(async cachename => {

        //     let cacheStorage = await caches.open(cachename);

        //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/subincident`);

        //     if(!cacheResponse || cacheResponse == undefined){

        //         await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponse);


        //         })
        //         .catch(function(error){

        //             // console.log(error);



        //         });

        //     }else{

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponse);


        //             })
        //             .catch(function(error){

        //                 // console.log(error);



        //             });

        //         }

        //     }

        //     });

        // }

    }

    async function onChange(page, pageSize) {

        // console.log(page,pageSize,currentPage); //page = new previous page and new next page

        setPageSize(pageSize);

        setCurrentPage(page);

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=20&';


        await axios.get(Uri.rootUri + `master/subincident/?${currentPageOnChange}${currentPageSizeOnChange}`, { headers: { "Authorization": `Bearer ${authToken}` } })

            .then(function (response) {

                let dataResponse = response.data.results;

                // console.log(dataResponse);

                setTotalData(response.data.count);//total all data;

                setDataSource(dataResponse);


            })
            .catch(function (error) {

                //   console.log(error);

                //   setLoading(false);

            });

    }

    async function _onSearch(e) {

        e.preventDefault();


        let searchText = search ? `search=${search}&` : "";

        await axios.get(Uri.rootUri + `master/subincident/?page=1&${searchText}page_size=20&`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let responseData = response.data;

                setDataSource(responseData.results);

                setTotalData(responseData.count);

                setCurrentPage(1);

            })
            .catch(function (error) {

                console.log(error);

            });

    }

    useEffect(() => {
      const effect = async () => {
        //ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadData(1, 100);
        await _loadDataAll("subincident");
        await _loadTypeOfIncidentData("incident");
        
        setLoading(false);
      }
      effect();
    }, []);

    return (
        <Spin size='large' spinning={loading}>
            <div className="subtypeofincident">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Add New Sub-type of Incident:</h4>
                        <div className="mb-3">
                            <label htmlFor="typeofincident" className='m-1 d-flex align-items-start'>Sub-type of Incident <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                            {isInvalid && !subTypeOfIncident ?

                                <div id="typeofincident" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert Sub-type of Incident.
                                </div>
                                :
                                <></>
                            }
                            <input type="text" className="form-control" name="typeofincident" id="typeofincident" onChange={_onChangeSubTypeOfIncident} value={subTypeOfIncident && subTypeOfIncident} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor='typeofincident ' className='m-1 d-flex align-items-start'>Incident category<i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                            {isInvalid && !typeOfIncident ?

                                <div id="typeofincident" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please select Incident category
                                </div>
                                :
                                <></>
                            }
                            <select className="form-select" aria-label="Default select example" onChange={_typeOfIncident} required value={typeOfIncident ? typeOfIncident : ""}>
                                <option selected value=""></option>
                                {elTypeOfIncident}
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }} onClick={() => _handleValidation(subTypeOfIncident, typeOfIncident, setIsInvalid, _saveData)}><i className="bi bi-plus-circle-fill"></i> Add to Master Data</button>
                    </div>
                    <div className="col-lg-8">
                        <h4>Sub-type of Incident Data</h4>
                        <div className='d-flex align-items-center ml-5 mb-3'>
                            <div className="d-inline-block mx-2" >
                                <label htmlFor="search" className="col-form-label">Search: </label>
                            </div>
                            <div class="input-group" style={{ width: 250 }}>
                                <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={_searchForm} value={search ? search : ""} />
                                <button class="btn btn-primary" type="submit" id="button-addon2" onClick={_onSearch}><i class="bi bi-search"></i></button>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            scroll={{ x: 'max-content' }}
                            pagination={false}
                        />
                        <div className='text-center mt-3'>
                            {/* {console.log(pageSize)} */}
                            <PaginationComponent
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                onChange={onChange}
                                // onShowSizeChange = {onShowSizeChange}
                                total={totalData ? totalData : 0}
                                // pageSizeOptions = {[50,100,150,200]}
                                showSizeChanger={false}
                                defaultPageSize={20}
                            />
                        </div>
                    </div>
                </div>
                <>

                    <Modal show={showUpdate} onHide={handleCloseUpdate} id={`update`}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <>
                                <div className='mb-3'>
                                    <label htmlFor='type ' className='m-1 d-flex align-items-start'>Sub-Type of Incident <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                    {isInvalidUpdate && !subTypeOfIncidentUpdate ?

                                        <div id="type" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                            Please insert Sub-Type of Incident.
                                        </div>
                                        :
                                        <></>
                                    }
                                    <input type="text" className="form-control" onChange={_onChangeSubTypeOfIncidentUpdate} value={subTypeOfIncidentUpdate ? subTypeOfIncidentUpdate : ""} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor='typeofincident ' className='m-1 d-flex align-items-start'>Incident category <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                    {isInvalidUpdate && !typeOfIncidentUpdate ?

                                        <div id="typeofincident" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                            Please select Incident category.
                                        </div>
                                        :
                                        <></>
                                    }
                                    <select className="form-select" aria-label="Default select example" onChange={_onChangeTypeOfIncidentUpdate} required value={typeOfIncidentUpdate ? typeOfIncidentUpdate : ""}>
                                        <option selected value=""></option>
                                        {elTypeOfIncident}
                                    </select>
                                </div>
                            </>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseUpdate}>
                                Close
                            </Button>
                            <Button type="submit" variant="primary" onClick={() => _handleValidation(subTypeOfIncidentUpdate, typeOfIncidentUpdate, setIsInvalidUpdate, _updateData)}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='m-0'>Delete this Sub-Type of Incident ?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseDelete}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={(e) => _deleteSubTypeOfIncident(e, idData)}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>

            </div>
        </Spin>
    );
}
