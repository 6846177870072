// This is Authorization page after that click link what's inside email

import React, { useEffect } from 'react'
import { Spin } from "antd";
import { useParams, useHistory } from 'react-router-dom';
// import axios from 'axios';
import Uri from '../Uri';
import Helper from './helpers/Helper';
import { useMsal } from '@azure/msal-react';
import { useAxios } from '../App';

export default function Authorization() {
    const axios = useAxios();

    let history = useHistory();
    const { token } = useParams();
    const { instance, inProgress } = useMsal();

    // console.log(token);

    async function _authorizationLogin(){

        // await axios.get( Uri.rootUri + `user/auth/${token}/verify/` )
        await axios.get( Uri.rootUri + `user/auth/${token}/verify2/`)
        .then( async function(response){

            // console.log(response);

            let dataResponse = response.data;

            //set information user to localstorage 
            const result = Helper.authorization(dataResponse);

            // console.log(result);

            if(result){

                await axios.get( Uri.rootUri + `master/party/five/` , { headers: {"Authorization" : `Bearer ${token}`} })// Tes token authorization
                .then(function(response){

                    // console.log(response);

                    localStorage.setItem("token", token);//set token login

                    history.push(`/Index`);//redirect to page main

                })
                .catch(function(error){
                    history.push({

                        pathname : `/Login`,
                        state : { error : true }
    
                    });
                    
                });

            }else{
                    history.push({

                    pathname : `/Login`,
                    state : { error : true }

                });

            }


        })
        .catch(function(error){

            // console.log(error);

            history.push({

                pathname : `/Login`,
                state : { error : true }

            });

        });

    }

    useEffect(()=>{

        _authorizationLogin();

    },[]);  

    return (
        <div style={{ height:600}} className='d-flex justify-content-center align-items-center'>
            <div className='my-5 text-center'>
                <Spin size='large' spinning={true}></Spin>
                <p className='text-secondary'><strong>Loading...</strong></p>
            </div>
        </div>

  )
}
