import Swal from "sweetalert2";
import axios from "axios";
import Uri from "../../Uri";
import moment from "moment";

export default class Helper {

    // get village tract, town, province, country
    // administrative_area_level_1 -> province
    // administrative_area_level_2 -> town
    // administrative_area_level_3 -> district
    // administrative_area_level_4 -> village/tract
    // country -> country/region

    static findResult(results, name) {

        return results.find(item => item.types.includes(name) ? item : "");

    }

    static hiddenChar(text) {//hidden character

        // if(text){

        return text.replace(/[^\w\s]/gi, '');

        // }

    }

    static refreshParent() { // refresh parent opener window

        window.opener.location.reload();

    }

    static alert(title, icon, message, timer) {//alert notification
        Swal.fire({ title: `${title}`, icon: `${icon}`, text: `${message}`, timer: `${timer}` });
    }

    static alertHtml(title, icon, html, timer) {//alert notification
        Swal.fire({ title: `${title}`, icon: `${icon}`, html: `${html}`, timer: `${timer}` });
    }
    static dialogConfirmation(text, approveDialog) {

        Swal.fire({
            title: "Are you sure?",
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.isConfirmed) approveDialog();
          })
    }

    static ajaxCallPending(setLoading) {

        let numberOfAjaxCAllPending = 0;
        // Add a request interceptor
        axios.interceptors.request.use(function (config) { //get count request API
            numberOfAjaxCAllPending++;
              // console.log("+++++++++++  Ajax pending", numberOfAjaxCAllPending);
              // show loader

            setLoading(true);

            // console.log(config);

            return config;

        }, function (error) {
            return Promise.reject(error);
        });

        // Add a response interceptor
        axios.interceptors.response.use(function (response) { //get count response
            numberOfAjaxCAllPending--;
              // console.log("------------  Ajax pending", numberOfAjaxCAllPending);

            if (numberOfAjaxCAllPending == 0) {
                //hide loader
                setLoading(false);

            }
            return response;
        }, function (error) {
            numberOfAjaxCAllPending--;
            if (numberOfAjaxCAllPending == 0) {
                //hide loader
                setLoading(false);

            }
            return Promise.reject(error);
        });

    }


    static authorization(data) {

        if (data) {

            localStorage.setItem("auth_id", data.auth_id);

            localStorage.setItem("company_id", data.company_id);

            localStorage.setItem("user_id", data.id);

            localStorage.setItem("email_address", data.emailaddress);

            localStorage.setItem("permissions", data.permissions);

            localStorage.setItem("usernameLogin", data.username);

            return true;

        } else {

            return false;

        }

    }

    static async authorizationLogin(axios, msalInstance) {

        // console.log("renew login information");

        const token = localStorage.getItem("token");

        // await axios.get(Uri.rootUri + `user/auth/${authToken}/verify/`)
        const response = await axios.get(Uri.rootUri + `user/auth/${token}/verify2/`)
            .catch(function (error) {

                console.log(error);

                localStorage.setItem("verified", 0);
                localStorage.setItem("authError", 1);

                msalInstance.logoutRedirect();
                // window.location.replace(Uri.hostUri + `Login`);

            });

         let dataResponse = response.data;

         //set information user to localstorage
         Helper.authorization(dataResponse);
         //set token
         localStorage.setItem("verified", 1);
         return(dataResponse);
    }

    // get user login
    static getUserLogin() {

        if (localStorage.getItem("verified") == 1) {

            let authId = localStorage.getItem("auth_id");

            let companyId = localStorage.getItem("company_id");

            let userId = localStorage.getItem("user_id");

            let emailAddress = localStorage.getItem("email_address");

            let permissions = localStorage.getItem("permissions");

            let username = localStorage.getItem("usernameLogin");

            let objUser = {

                "auth_id": authId,
                "company_id": companyId,
                "user_id": userId,
                "email_address": emailAddress,
                "permissions": permissions,
                "username": username
            }

            return objUser;

        } else {

            return false;

        }

    }

    //check validate token login
    static async tokenValidation(axios) {

        const token = localStorage.getItem("token");

        // await axios.get(Uri.rootUri + `user/auth/${token}/verify/`)
        await axios.get(Uri.rootUri + `user/auth/${token}/verify2/`)
            .then(function (response) {

                // console.log(response);

                localStorage.setItem("token", token);//set token login

                localStorage.setItem("verified", 1);

            })
            .catch(function (error) {
                // alert(window.location.href);
                console.log(error);
                localStorage.setItem("verified", 0);
            });

    }

    //Add / Update data in cache
    static addDataIntoCache(cachename, url, response) {

        // console.log(response);

        const data = new Response(JSON.stringify(response));

        // console.log(data);

        if ('caches' in window) {

            caches.open(cachename).then((cache) => {//create cache

                cache.put(url, data);//adding cache

                //   console.log("Success data into cache");

            });
        }

    }

    // get host current url
    static getHostCurrentUrl() {

        return window.location.protocol + '//' + window.location.host;

    }

    //Auto generate description
    // data consists of date, state/region, township
    static autoGenerateDescription(data) {
        let incidentDate = data.incidentDate;
        let stateRegion = data.stateRegion;
        let township = data.township;

        let time = data.hasOwnProperty("time") ? data.time : undefined;
        let town = data.hasOwnProperty("town") ? data.town : undefined;
        let village = data.hasOwnProperty("village") ? data.village : undefined;

        let string = `On ${moment(incidentDate).format("DD MMMM YYYY")} `

        if (time) string = string.concat(`at ${time} hrs `);
        // in <Township name>, <State/Region>
        if (!!!town && !!!village) string = string.concat(`in an undisclosed area of ${township} Township (${stateRegion})`);
        if (town && !!!village) string = string.concat(`in ${town} town (${township} Township, ${stateRegion})`);
        if (!!!town && village) string = string.concat(`in ${village} (${township} Township, ${stateRegion})`);
        if (town && village) string = string.concat(`in ${village} (${town} town (${township} Township, ${stateRegion}))`);

        return string;

    }

    static errorMsgDataValidation(dataValidationName) {
        return `Cannot delete <strong>${dataValidationName}</strong>, because this data is already used in 1 of the incident records.`
    }
}