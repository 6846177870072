import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import $ from 'jquery';
import { useMediaQuery } from 'react-responsive'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import IncidentSubmissions from './IncidentSubmissions';
import AddNewDraft from './AddNewDraft';
import vanguardLogo from '../images/header-vanguard.png';
import PointOfInterest from './PointOfInterest';
import UserPage from './UserPage';
import AddNewUser from './AddEditNewUser';
import DataValidation from './DataValidation';
import ApprovedIncidents from './ApprovedIncidents';
import Company from './Company';
import AddEditNewCompany from './AddEditNewCompany';
import axios from 'axios';
import Uri from '../Uri';
import "../../node_modules/antd/dist/antd.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../App.css";
import Loading from './utils/Loading';
import Helper from './helpers/Helper';
import Unauthorize from './Unauthorize';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useAxios } from '../App';
import TableauEmbedded from './TableauEmbedded';

export default function MainDashboard({ pageLoading }) {
  const axios = useAxios();

  const { instance: msalInstance } = useMsal();
  const authToken = localStorage.getItem("token");
  const companyname = localStorage.getItem("companyname");
  const usernameLogin = localStorage.getItem("usernameLogin");

  let [collapsed, setCollapsed] = useState(true);
  let [countSubmitted, setCountSubmitted] = useState();
  let [countDraft, setCountDraft] = useState();
  let [resultCount, setResultCount] = useState();
  let [permissionData, setPermissionData] = useState([]);
  let [username, setUsername] = useState("");
  let [emailAddress, setEmailAddress] = useState("");
  let [authId, setAuthId] = useState("");
  let [companyId, setCompanyId] = useState("");
  let [userId, setUserId] = useState("");
  let [permissions, setPermissions] = useState([]);
  const approveIncident = "1";
  const customerManager = "2";
  const editIncident = "3";
  const poiSelfService = "4";
  const readOnly = "5";
  const superUser = "6";
  let [isPoiUser, setIsPoiUser] = useState(false);

  let [visible, setVisible] = useState(true);
  let history = useHistory();
  let { pathname } = useLocation();
  const { url, path } = useRouteMatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const laptopOrDesktop = useMediaQuery({ query: '(min-width: 992px)' });


  let toggle = (e) => { //sidebar collaps

    e?.preventDefault();

    $("#wrapper").toggleClass("toggled");

    if (collapsed) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  };

  function _modalTrigger(e) {

    let exampleModal = document.getElementById('exampleModal');
    exampleModal.addEventListener('show.bs.modal', function (event) {
      // Button that triggered the modal
      let button = event.relatedTarget;
      // Extract info from data-bs-* attributes
      // let recipient = button.getAttribute('data-bs-whatever');
      // If necessary, you could initiate an AJAX request here
      // and then do the updating in a callback.
      //
      // Update the modal's content.
      let modalTitle = exampleModal.querySelector('.modal-title');
      let modalBodyInput = exampleModal.querySelector('.modal-body input');

      modalTitle.textContent = 'Sign Out';
      // modalBodyInput.value = recipient
    });
  }

  function _logout() {

    localStorage.clear();
    sessionStorage.clear();
    const account = msalInstance.getActiveAccount();

    msalInstance.logoutRedirect({
      account: account,
      postLogoutRedirectUri: '/Login'
    });
  }

  async function _loadCountIncident() {

    let countSubmittedData;
    let countDraftData;

    await axios.get(Uri.rootUri + `incident/list/?status=Submitted`, { headers: { "Authorization": `Bearer ${authToken}` } })
      .then(function (response) {

        let dataResponse = response.data;

        setCountSubmitted(dataResponse.length);

        countSubmittedData = dataResponse.length

      })
      .catch(function (error) {

        console.log(error)

      });

    await axios.get(Uri.rootUri + `incident/list/?status=Draft`, { headers: { "Authorization": `Bearer ${authToken}` } })
      .then(function (response) {

        let dataResponse = response.data;

        setCountDraft(dataResponse.length);

        countDraftData = dataResponse.length;

      })
      .catch(function (error) {

        console.log(error);

      });

    setResultCount(countSubmittedData + countDraftData);

  }

  async function _loadPermissionData() {

    const dataPermission = [

      {
        "id": "1",
        "permissionname": "Approve Incident"
      },
      {
        "id": "2",
        "permissionname": "Customer Manager"
      },
      {
        "id": "3",
        "permissionname": "Edit Incident"
      },
      {
        "id": "4",
        "permissionname": "POI Self Service"
      },
      {
        "id": "5",
        "permissionname": "Read-Only"
      },
      {
        "id": "6",
        "permissionname": "Super user"
      },
    ]

    setPermissionData(dataPermission);

  }

  async function _loadUserInformation() {

    const userInformation = await Helper.getUserLogin();

    if (userInformation) {

      setUserId(userInformation.user_id);

      setEmailAddress(userInformation.email_address);

      setAuthId(userInformation.auth_id);

      setCompanyId(userInformation.company_id);

      setUsername(userInformation.username);

      let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));

      if (strPermissions.length === 1 && strPermissions[0] === poiSelfService) {
        setIsPoiUser(true);
      } // Set state to flag user as poi only user

      setPermissions(strPermissions);


    }

  }


  async function _loadCompanyName(company_id) {
      await axios.get(Uri.rootUri + `company/${company_id}/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
          .then(function(response){
              localStorage.setItem('companyname', response.data?.companyname)
          })
          .catch(function(error){
              console.log(error);
          });
  }

  //check validation token login
  async function _loadAuthToken() {

    await Helper.tokenValidation(axios); //token validation

    if (localStorage.getItem("verified") == 1) {

      setVisible(false);

      const dataResponse = await Helper.authorizationLogin(axios, msalInstance);

      _loadCompanyName(dataResponse.company_id);
      _loadCountIncident();
      _loadPermissionData();
      _loadUserInformation();

    } else {

      setVisible(false);
      const account = msalInstance.getActiveAccount();
      localStorage.setItem("authError", 1);

      msalInstance.logoutRedirect({
        account: account,
        postLogoutRedirectUri: '/Login',
      });
      // history.push({

      //   pathname: `/Login`,
      //   state: { error: true }

      // });

    }

  }

  useEffect(() => {
    // At the initial render, hide sidebar if it's in the tableau report page

    const effect = async () => {
      console.log(window.location.pathname);
      await _loadAuthToken();
    }
    effect()
  }, []);


  return (
    <>
      {visible ?
        <Loading />
        :
        <div className="dashboard">
            {/* <Spin spinning={pageLoading}> */}
            <div id="wrapper" className='toggled'>
              {laptopOrDesktop &&
                <>
                  <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: "#002a8c", height: 58 }}>
                    <div className="container mx-5" style={{ maxWidth: 'none' }}>
                      <div className='d-flex align-items-center'>
                        {collapsed && //when true false (artinya tidak false)
                          <div className='d-inline-block' style={{ marginRight: 60 }}>
                            <i className="bi bi-list text-white" style={{ fontSize: 32 }} onClick={toggle}></i>
                          </div>
                        }
                        <div className='d-inline-block'>
                          <img src={vanguardLogo} alt="vanguard" className='img-fluid ml-5' style={{ width: 140, height: 35 }} />
                        </div>
                      </div>
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                          <li className="nav-item" style={{ alignContent: 'center' }}>
                            {(companyname && usernameLogin) && <span style={{ fontSize: 16, marginRight: 16 }} className='text-white'>{`${usernameLogin} (${companyname})`}</span>}
                           </li>
                          <li className="nav-item">
                            <button type='button' style={{ backgroundColor: "#f0f0f0" }} data-bs-toggle="modal" data-bs-target="#exampleModal" className='btn' onClick={_modalTrigger} ><i className="bi bi-box-arrow-in-left"></i> Sign Out</button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </>
              }
              {isTabletOrMobile &&
                <>
                  <nav className="navbar navbar-expand-lg navbar-light mb-5" style={{ backgroundColor: "#002a8c", height: 58 }}>
                    <div className="container">
                      <div className='d-flex align-items-center'>
                        {!collapsed && //when true false (artinya tidak false)
                          <div className='d-inline-block' style={{ marginRight: 40 }}>
                            <i className="bi bi-list text-white" style={{ fontSize: 32 }} onClick={toggle}></i>
                          </div>
                        }
                        <div className='d-inline-block'>
                          <img src={vanguardLogo} alt="vanguard" className='img-fluid' style={{ width: 140, height: 35 }} />
                        </div>
                      </div>
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarNavAltMarkup" style={{ backgroundColor: "#002a8c" }}>
                        <div className="btn-group nav-link">
                          <button type='button' style={{ backgroundColor: "#f0f0f0" }} data-bs-toggle="modal" data-bs-target="#exampleModal" className='btn' onClick={_modalTrigger} ><i className="bi bi-box-arrow-in-left"></i> Sign Out</button>
                        </div>
                      </div>
                    </div>
                  </nav>
                </>
              }


              <>
                {/* <!-- Sidebar --> */}
                <div id="sidebar-wrapper" >
                  <ul className="sidebar-nav mt-2">
                    <li className="sidebar-brand">
                      {
                        isTabletOrMobile &&

                        collapsed &&
                        <div className="text-start">
                          <i className="bi bi-list text-white" style={{ fontSize: 32 }} onClick={toggle}></i>
                        </div>

                      }
                      {
                        laptopOrDesktop &&
                        !collapsed &&
                        <div className="text-start">
                          <i className="bi bi-list text-white" style={{ fontSize: 32 }} onClick={toggle}></i>
                        </div>

                      }
                    </li>
                    <li>
                      <p style={{ fontSize: 20 }} className='text-white'>Page Navigation</p>
                    </li>
                    {permissions.includes(approveIncident) || permissions.includes(editIncident) ? // if permission type Approve Incident and Edit Incident

                      <li>
                        <Link to={`${url}/IncidentSubmissions`} style={{ fontSize: 14 }} >Incident Submissions
                          {permissions.includes(approveIncident) &&
                            <span className='text-dark rounded mx-1' style={{ padding: 2, backgroundColor: "#fcd703", fontSize: 12 }}>
                              <strong>{resultCount}</strong>
                            </span>
                          }
                        </Link>
                      </li>
                      :
                      <></>
                    }

                    {permissions.includes(editIncident) || permissions.includes(approveIncident) || permissions.includes(readOnly) ?
                      <li>
                        <Link to={`${url}/ApprovedIncidents`} style={{ fontSize: 14 }} >Approved Incidents</Link>
                      </li>
                      :
                      <></>
                    }
                    {permissions.includes(superUser) ?

                      <li>
                        <Link to={`${url}/DataValidation`} style={{ fontSize: 14 }}  >Data Validation</Link>
                      </li>
                      :
                      <></>
                    }
                    {permissions.includes(customerManager) ?
                      <li>
                        <Link to={`${url}/Company`} style={{ fontSize: 14 }} >Company</Link>
                      </li>
                      :
                      <></>
                    }
                    {permissions.includes(superUser) ?
                      <li>
                        <Link to={`${url}/UserPage`} style={{ fontSize: 14 }}  >User</Link>
                      </li>
                      :
                      <></>
                    }
                    {permissions.includes(poiSelfService) ?

                      <li>
                        <Link to={`${url}/PointOfInterest`} style={{ fontSize: 14 }}  >Point of Interest (POI) Data </Link>
                      </li>
                      :
                      <></>

                    }

                    {/* Links to embedded tableau reports */}
                    <li>
                      <Link
                        to={`${url}/embed/LandingPage`}
                        style={{ fontSize: 14 }}
                        onClick={(e) => {
                          toggle();
                        }}
                      >
                        Vanguard Dashboards
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* end sidebar */}
                {/* <!-- /#sidebar-wrapper --> */}
              </>

              {/* <!-- Page Content --> */}
              <div id="page-content-wrapper" >
                {/* <div className="container-fluid"> */}
                  {/* $url = Index */}
                  <Switch>
                    <Route path={`${url}/IncidentSubmissions`} component={IncidentSubmissions} />
                    <Route path={`${url}/Unauthorize`} component={Unauthorize} />
                    <Route path={`${url}/AddNewDraft`} component={AddNewDraft} />
                    <Route path={`${url}/ViewDraftIncident/:incidentSubmissionId`} component={AddNewDraft} />
                    <Route path={`${url}/PointOfInterest`} component={PointOfInterest} />
                    <Route path={`${url}/ViewPointOfInterest/:pointOfIncidentId`} component={PointOfInterest} />
                    <Route path={`${url}/UserPage`} component={UserPage} />
                    <Route path={`${url}/AddNewUser`} component={AddNewUser} />
                    <Route path={`${url}/EditNewUser/:userId`} component={AddNewUser} />
                    <Route path={`${url}/DataValidation`} component={DataValidation} />
                    <Route path={`${url}/ApprovedIncidents`} component={ApprovedIncidents} />
                    <Route path={`${url}/Company`} component={Company} />
                    <Route path={`${url}/ViewCompany/:companyId`} component={AddEditNewCompany} />
                    <Route path={`${url}/AddNewCompany`} component={AddEditNewCompany} />
                    <Route path={`${url}/embed/:embedPathname`}
                      component={TableauEmbedded}
                    />

                    {/* if page not found */}
                    <Route path={`${url}/*`} component={Unauthorize} />
                  </Switch>
                {/* </div> */}
              </div>
              {/* <!-- /#page-content-wrapper --> */}

            </div>
            {/* <!-- /#wrapper --> */}
            {/* </Spin> */}
          <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  Confirm you want to sign out?
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                  <Link className="text-decoration-none text-white" onClick={_logout}><button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button></Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      }
    </>
  );
}