import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import Uri from "../../Uri";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Helper from "../helpers/Helper";
import PaginationComponent from "../utils/PaginationComponent";
import ErrorMsgBarParty from "../utils/ErrorMsgBarParty";
import Swal from "sweetalert2";
import { useAxios } from "../../App";
import { useMsal } from "@azure/msal-react";

export default function Party5Others(props) {
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();
    

    const authToken = localStorage.getItem("token");

    let [party5Others, setParty5Others] = useState("");
    let [party5OthersUpdate, setParty5OthersUpdate] = useState("");
    let [dataSource, setDataSource] = useState([]);
    let [isInvalid, setIsInvalid] = useState();
    let [isInvalidUpdate, setIsInvalidUpdate] = useState();
    let [idData, setIdData] = useState("");
    let [loading, setLoading] = useState(true);
    let [isError, setIsError] = useState(false);

    let [currentPage, setCurrentPage] = useState();
    let [pageSize, setPageSize] = useState();
    let [totalData, setTotalData] = useState();

    const [showUpdate, setShowUpdate] = useState();
    const [showDelete, setShowDelete] = useState();

    function handleCloseDelete(e) {

        setShowDelete(false);

    };
    function handleShowDelete(e) {

        setShowDelete(true);

    }

    function handleCloseUpdate(e) {

        setShowUpdate(false);

    };

    function handleShowUpdate(e) {

        setShowUpdate(true);

    }

    function _onChangeParty5Others(e) {

        e.preventDefault();

        if (e.target.value) {

            setParty5Others(e.target.value);

        } else {

            setParty5Others("");

        }

    }
    function _onChangeParty5OthersUpdate(e) {

        e.preventDefault();

        if (e.target.value) {

            setParty5OthersUpdate(e.target.value);

        } else {

            setParty5OthersUpdate("");

        }

    }

    async function _saveData(e) {

        e.preventDefault();



        if (!party5Others) {

            setIsInvalid(true);

            setTimeout(() => setIsInvalid(false), 3000);

        } else {


            if ((party5Others.split(',').length > 1) || (party5Others.split(';').length > 1)) {

                setIsError(true);
                setTimeout(() => setIsError(false), 3000)

            } else {

                let data = {

                    "name": party5Others,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `master/party/five/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(async function (response) {

                        // console.log(response);
                        caches.delete("party5");// delete cache for update

                        let cacheName = {

                            "name": "party5",
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                // console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                        _loadDataAll("party5");

                        setParty5Others("");

                        Helper.alert("Success", "success", "Success save data", 3000);

                        _loadData(currentPage, pageSize);

                    })
                    .catch(function (error) {

                        // console.log(error);
                        let objError = error.toJSON();

                        if (objError.status == 400) {

                            Helper.alert("Failed", "error", "This party value already exist", 3000);

                        }



                    });

            }

        }

    }


    async function _updateParty5Others(e) {

        e.preventDefault();

        if (!party5OthersUpdate) {

            setIsInvalidUpdate(true);

            setTimeout(() => setIsInvalidUpdate(false), 3000);

        } else {

            if ((party5OthersUpdate.split(',').length > 1) || (party5OthersUpdate.split(';').length > 1)) {

                setIsError(true);
                setTimeout(() => setIsError(false), 3000)

            } else {

                let data = {

                    "name": party5OthersUpdate,
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.put(Uri.rootUri + `master/party/five/${idData}/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(async function (response) {

                        caches.delete("party5");// delete cache for update

                        let cacheName = {

                            "name": "party5",
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                // console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                        _loadDataAll("party5");

                        setParty5OthersUpdate("");

                        setShowUpdate(false);

                        Helper.alert("Success", "success", "Success update data", 3000);

                        _loadData(currentPage, pageSize);





                    })
                    .catch(function (error) {

                        // console.log(error);

                        setShowUpdate(false);



                    });
            }

        }
    }

    async function _approveDelete(id, confirmation) {
        let data = {
            "confirmation": confirmation,
            "deleted_by": localStorage.getItem("usernameLogin")
        }

        try {

            await axios.put(Uri.rootUri + `master/party/five/${id}/delete/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(async function (response) {
                    caches.delete("party5");// delete cache for update
                    let cacheName = {
                        "name": "party5",
                        "created_by": localStorage.getItem("usernameLogin"),
                        "updated_by": localStorage.getItem("usernameLogin")
                    }
                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                        .catch(function (error) {
                            console.log(error);
                        });
                    _loadDataAll("party5");
                    setShowDelete(false);
                    Helper.alert("Success", "success", "Success delete data", 3000);
                    _loadData(currentPage, pageSize);
                })

        } catch (error) {
            setShowDelete(false);
            console.log(error);
            return error.response;
        }

    }

    async function _deleteParty5Others(e, id) {


        e.preventDefault();
        setShowDelete(false);
        Swal.fire({
            title: 'Loading...',
            html: 'Please wait just a moment',
            timerProgressBar: true,
            didOpen: () => Swal.showLoading()
        })
        if (id) {
            const response = await _approveDelete(id, false);
            console.log(response);
            if (response?.status == 400 && response?.data?.res?.message == "Party is used in incident") {
                Helper.dialogConfirmation(`This party is used in ${response?.data?.res?.countOfIncident} incident(s). You cannot revert this operation.`, async () => await _approveDelete(id, true))
            }
        } else {
            setShowDelete(false);
        }
    }

    const columns = [
        {
            title: 'Party 5 : Others',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: '80%',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record, index) => (
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                        handleShowUpdate(e);
                        setParty5OthersUpdate(record.name);
                        setIdData(record.id);
                    }} id="update"><i className="bi bi-pencil-fill"></i>
                    </button>

                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                        handleShowDelete(e);
                        setIdData(record.id);
                    }} id="delete"><i className="bi bi-trash-fill"></i>
                    </button>
                </div>
            )
        }
    ];

    async function _loadData(page, pageSize) {

        setCurrentPage(page);

        setPageSize(pageSize);

        await axios.get(Uri.rootUri + `master/party/five/?page=${page}&page_size=${pageSize}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let dataResponse = response.data;

                setTotalData(dataResponse.count)

                setDataSource(dataResponse.results);

            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("party5")){

        //     await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         setDataSource(dataResponse);

        //         Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5` , dataResponse);

        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "party5"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party5`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             setDataSource(dataResponse);

        //             Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5` , dataResponse);

        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //       }else{ //if data in cache not empty


        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 setDataSource(dataResponse);

        //                 Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5` , dataResponse);

        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });

        //         }else{


        //             setDataSource(dataResponse);


        //         }


        //       }


        //     });

        // }
    }

    async function _loadDataAll(cachenameparam) {

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("party5")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "party5");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let party5Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party5');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !party5Response || party5Response == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseParty5 = await party5Response.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty5 || dataResponseParty5.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseParty5 there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponseParty5);

                                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("party5")){

        //     await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5` , dataResponse);

        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultDataIncident = arrCache.filter( x => x == "party5"); //get where cache name

        //     resultDataIncident.map(async cachename => {

        //     let cacheStorage = await caches.open(cachename);

        //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party5`);

        //     if(!cacheResponse || cacheResponse == undefined){

        //         await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5` , dataResponse);

        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //     }else{

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5` , dataResponse);

        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });

        //         }

        //     }

        //     });

        // }
    }

    async function onChange(page, pageSize) {

        // console.log(page,pageSize,currentPage); //page = new previous  page and new next page

        setPageSize(pageSize);

        setCurrentPage(page);

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=20&';

        await axios.get(Uri.rootUri + `master/party/five/?${currentPageOnChange}${currentPageSizeOnChange}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let dataResponse = response.data;

                setTotalData(dataResponse.count);

                setDataSource(dataResponse.results);

            })
            .catch(function (error) {

                console.log(error);

            });

    }

    useEffect(() => {
        // console.log(props.dataSource);
        const effect = async () => {

        //ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadData(1, 100);
        await _loadDataAll("party5");
        
        setLoading(false);
      }
      effect();
    }, []);
    return (
        <Spin size="large" spinning={loading}>
            <div className="party5others">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Add New Party 5 : Others:</h4>
                        <div className="mb-3">
                            <label htmlFor="party5others">Party 5 : Others</label>
                            {isInvalid && !party5Others ?

                                <div id="party5others" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert Party 5 : Others.
                                </div>
                                :
                                <></>
                            }
                            <input type="text" className="form-control" name="party4civilians" id="party4civilians" onChange={_onChangeParty5Others} value={party5Others && party5Others} />
                        </div>
                        {(isError && !!!showUpdate) && <ErrorMsgBarParty />}
                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }} onClick={_saveData}><i className="bi bi-plus-circle-fill"></i> Add to Master Data</button>
                    </div>
                    <div className="col-lg-8">
                        <h4>Party 5 : Others Data</h4>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            scroll={{ x: 'max-content' }}

                        />
                        <div className="text-center mt-3">
                            <PaginationComponent
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                onChange={onChange}
                                showSizeChanger={false}
                                total={totalData ? totalData : 0}
                                defaultPageSize={20}
                            />
                        </div>
                    </div>

                    <>
                        <Modal show={showUpdate} onHide={handleCloseUpdate} id={`update`}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Data</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="mb-3">
                                    <label htmlFor="party5others">Party 5 : Others</label>
                                    {isInvalidUpdate && !party5OthersUpdate ?

                                        <div id="party5others" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                            Please insert Party 5 : Others
                                        </div>
                                        :
                                        <></>
                                    }
                                    <input type="text" className="form-control" name="party5others" id="party5others" onChange={_onChangeParty5OthersUpdate} value={party5OthersUpdate && party5OthersUpdate} />
                                </div>
                                {(isError && showUpdate) && <ErrorMsgBarParty />}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseUpdate}>
                                    Close
                                </Button>
                                <Button type="submit" variant="primary" onClick={_updateParty5Others}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='m-0'>Delete this data ?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseDelete}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={(e) => _deleteParty5Others(e, idData)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>

                </div>
            </div>
        </Spin>
    )
}