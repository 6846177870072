import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalConfig } from './authConfig';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';


const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback(async (event) => {
  if (
    ( event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    console.log(event.payload);
    localStorage.setItem("token", event.payload.idToken);
    msalInstance.setActiveAccount(event.payload.account);
  }
  // if (event.eventType === EventType.LOGOUT_SUCCESS) {
  //   console.log(msalInstance.getActiveAccount());
  //   window.alert("Logout successfully");
  // }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App instance={msalInstance} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
