import React, { useEffect, useReducer, useState } from 'react'
import { GoogleMap, LoadScript, Marker, InfoWindow, Autocomplete, Data } from '@react-google-maps/api';
import { useMediaQuery } from 'react-responsive'
import { Tag, Spin, Select, TimePicker, Tooltip } from 'antd';
import { useParams, useHistory, Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import Uri from '../Uri';
import Geocode from "react-geocode";
import Helper from './helpers/Helper';
import BasicModal from './utils/BasicModal';
import { useAxios } from '../App';
import { useMsal } from '@azure/msal-react';
import { _loadCache } from './IncidentSubmissions';
import DatePicker from './utils/DatePicker';
import MapControl from './maps/MapControl';
import $ from "jquery";

export default function AddNewDraft(props) {
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();

    const authToken = localStorage.getItem("token");
    const { incidentSubmissionId } = useParams();

    const { Option } = Select;

    // let Date = new Date();

    const format = 'HH:mm';

    let query = useQuery();

    let duplicateIncident = query.get("duplicate");//get duplicate

    let history = useHistory();

    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;


    //Geo Code
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage("en");
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1199px)' });
    const laptopOrDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

    //User Information

    let [username, setUsername] = useState("");
    let [emailAddress, setEmailAddress] = useState("");
    let [authId, setAuthId] = useState("");
    let [companyId, setCompanyId] = useState("");
    let [userId, setUserId] = useState("");
    let [permissions, setPermissions] = useState([]);
    let [approveIncident, setApproveIncident] = useState("1");
    let [customerManager, setCustomerManager] = useState("2");
    let [editIncident, setEditIncident] = useState("3");
    let [poiSelfService, setPoiSelfService] = useState("4");
    let [readOnly, setReadOnly] = useState("5");
    let [superUser, setSuperUser] = useState("6");

    let [isInvalid, setIsInvalid] = useState();
    let [zoom, setZoom] = useState();
    let [number, setNumber] = useState();
    let [incidentId, setIncidentId] = useState("");
    let [lastUpdatedOn, setLastUpdatedOn] = useState("");
    let [draftedBy, setDraftedBy] = useState("");
    let [approvedDate, setApprovedDate] = useState("");
    let [approvedBy, setApprovedBy] = useState("");
    let [approvedAt, setApprovedAt] = useState("");
    let [updatedBy, setUpdatedBy] = useState("");
    let [statusIncident, setStatusIncident] = useState("");
    let [createdBy, setCreatedBy] = useState("");
    let [loading, setLoading] = useState(true);
    let [townshipData, setTownshipData] = useState([]);
    let [currentPageTownship, setCurrentPageTownship] = useState();

    // Incident Headers
    let [highPriority, setHighPriority] = useState();
    let [incidentDate, setIncidentDate] = useState("");
    let [typeOfIncident, setTypeOfIncident] = useState("");
    let [numberOfOccurences, setNumberOfOccurences] = useState();
    let [party2Estabilishedeao, setParty2Estabilishedeao] = useState([]);
    let [party4Civillians, setParty4Civillians] = useState("");
    let [targetHuman, setTargetHuman] = useState("");
    let [artillerySupport, setArtillerySupport] = useState();
    let [airSupport, setAirSupport] = useState();
    let [time, setTime] = useState("");
    let [subTypeOfIncident, setSubTypeOfIncident] = useState("");
    let [party1MyanmarSecurityForces, setParty1MyanmarSecurityForces] = useState([]);
    let [party3CivillianSelfDefenceForces, setParty3CivillianSelfDefenceForces] = useState([]);
    let [party5Others, setParty5Others] = useState("");
    let [targetAssets, setTargetAssets] = useState("");
    let [claimedByNug, setClaimedByNug] = useState();
    let [tacticaldevelopment, setTacticalDevelopment] = useState();

    //Temporary Type of Incident
    let [typeOfIncidentData, setTypeOfIncidentData] = useState([]);
    let [subTypeOfIncidentData, setSubTypeOfIncidentData] = useState([]);
    let [party2EstabilishedeaoData, setParty2EstabilishedeaoData] = useState([]);
    let [targetHumanData, setTargetHumanData] = useState([]);
    let [party1MyanmarSecurityForcesData, setParty1MyanmarSecurityForcesData] = useState([]);
    let [party3CivillianSelfDefenceForcesData, setParty3CivillianSelfDefenceForcesData] = useState([]);
    let [party4CivilliansData, setParty4CivilliansData] = useState([]);
    let [party5OthersData, setParty5OthersData] = useState([]);
    let [targetAssetsData, setTargetAssetsData] = useState([]);
    let [stateRegionData, setStateRegionData] = useState([]);
    let [geoPrecisionData, setGeoPrecisionData] = useState([]);


    // Element master data
    let [elTypeOfIncident, setElTypeOfIncident] = useState([]);
    let [elParty2Estabilishedeao, setElParty2Estabilishedeao] = useState([]);
    let [elParty4Civillians, setElParty4Civillians] = useState([]);
    let [elTargetHuman, setElTargetHuman] = useState([]);
    let [elParty1MyanmarSecurityForces, setElParty1MyanmarSecurityForces] = useState([]);
    let [elParty3CivillianSelfDefenceForces, setElParty3CivillianSelfDefenceForces] = useState([]);
    let [elParty5Others, setElParty5Others] = useState([]);
    let [elTargetAssets, setElTargetAssets] = useState([]);
    let [elStateRegion, setElStateRegion] = useState([]);
    let [elGeoPrecision, setElGeoPrecision] = useState([]);
    let [elTownship, setElTownShip] = useState([]);
    let [elSubTypeIncident, setElSubTypeIncident] = useState([]);

    //Ordinary Civilian Toll
    let [killedOct, setKilledOct] = useState();
    let [injuredOct, setInjuredOct] = useState();
    let [arrestedOct, setArrestedOct] = useState();
    let [civilAdministratorResignationsOct, setCivilAdministratorResignationsOct] = useState();

    // Pro-SAC Civilian Toll
    let [killedPct, setKilledPct] = useState();
    let [injuredPct, setInjuredPct] = useState();
    let [abductedPct, setAbductedPct] = useState();

    // Civilian Toll by Civilian Pro-SAC Group
    let [killedCtg, setKilledCtg] = useState();
    let [injuredCtg, setInjuredCtg] = useState();
    let [abductedCtg, setAbductedCtg] = useState();

    // Security Forces Toll
    let [killedSft, setKilledSft] = useState();
    let [injuredSft, setInjuredSft] = useState();
    let [capturedSft, setCapturedSft] = useState();
    let [desertionsSft, setDesertionsSft] = useState();

    // Resistance Fighters Toll
    let [killedRft, setKilledRft] = useState();
    let [injuredRft, setInjuredRft] = useState();
    let [arrestedRft, setArrestedRft] = useState();

    // EAO Fighters Toll
    let [killedEft, setKilledEft] = useState();
    let [injuredEft, setInjuredEft] = useState();
    let [arrestedEft, setArrestedEft] = useState();

    // Others/Unknown Toll
    let [killedOt, setKilledOt] = useState();
    let [injuredOt, setInjuredOt] = useState();
    let [arrestedOt, setArrestedOt] = useState();

    let [incidentDescription, setIncidentDescription] = useState("");
    let [detectEditIncidentDescription, setDetectEditIncidentDescription] = useState(false);
    let [sourceTwo, setSourceTwo] = useState("");

    // Location
    let [latitude, setLatitude] = useState();
    let [longitude, setLongitude] = useState();
    let [roadStreet, setRoadStreet] = useState("");
    let [villageTract, setVillageTract] = useState("");
    let [town, setTown] = useState("");
    let [township, setTownship] = useState("");
    let [stateRegion, setStateRegion] = useState("");
    let [geoPrecision, setGeoPricision] = useState("");
    let [highway, setHighway] = useState();
    let [commentOnLocation, setCommentOnLocation] = useState("");
    let [autoComplete, setAutoComplete] = useState();
    let [formatAddress, setFormatAddress] = useState("");
    let [titleAddress, setTitleAddress] = useState("");

    // auto generate description
    let [isShowConfirm, setIsShowConfirm] = useState(false);

    // google map api states
    let [isGridOn, toggleGrid] = useReducer(x => !x, true);
    let [toggleLoading, setToggleLoading] = useState(false);
    let [gridData, setGridData] = useState(null);

    let colorTag;
    let iconTag;

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: latitude ? Number(latitude) : 21.954510,
        lng: longitude ? Number(longitude) : 96.093292
    };

    const onLoadAutoComplete = autocomplete => {
        // console.log('autoComplete: ', autocomplete)
        setAutoComplete(autocomplete)
    }

    function onDrag(e) {

        setLatitude(e.latLng.lat());

        setLongitude(e.latLng.lng());

    }

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }


    function refreshParent() {
        window.opener.location.reload();
    }

    function onClikMap(e) {

        // console.log(e);

        setLatitude(e.latLng.lat());

        setLongitude(e.latLng.lng());

    }
    const onLoadMarker = marker => {
        // console.log('marker: ', marker)
    }

    const Status = (props) => {

        if (props.statusIncident == "Rejected") {
            colorTag = "Red";
            iconTag = <i className="bi bi-x-circle-fill"></i>
        }
        if (props.statusIncident == "Submitted") {
            colorTag = "Green";
            iconTag = <i className="bi bi-check-circle-fill"></i>
        }
        if (props.statusIncident == "Draft") {
            colorTag = "Orange";
            iconTag = <i className="bi bi-file-bar-graph-fill"></i>
        }
        return (
            <>
                <Tag color={colorTag} className={props.statusIncident == "Draft" && "text-dark"}><strong>{statusIncident}</strong>  {iconTag}</Tag>
            </>
        )
    }

    function _modalTrigger(e) {

        let exampleModal = document.getElementById('exampleModal');
        exampleModal.addEventListener('show.bs.modal', function (event) {
            // Button that triggered the modal
            let button = event.relatedTarget;
            // Extract info from data-bs-* attributes
            let recipient = button.getAttribute('data-bs-whatever');
            // If necessary, you could initiate an AJAX request here
            // and then do the updating in a callback.
            //
            // Update the modal's content.
            let modalTitle = exampleModal.querySelector('.modal-title');
            let modalBodyInput = exampleModal.querySelector('.modal-body input');

            modalTitle.textContent = 'New message to ' + recipient
            modalBodyInput.value = recipient
        });
    }

    // incidnet headers
    function _incidentId(e) {

        if (e.target.value) {

            setIncidentId(e.target.value);

        } else {

            setIncidentId("");

        }

    }

    function _lastUpdatedOn(e) {

        if (e.target.value) {

            setLastUpdatedOn(e.target.value);

        } else {

            setLastUpdatedOn("");

        }

    }

    function _draftedBy(e) {

        if (e.target.value) {

            setDraftedBy(e.target.value);

        } else {

            setDraftedBy();

        }

    }

    function _statusIncident(e) {

        if (e.target.value) {

            setStatusIncident(e.target.value);

        } else {

            setStatusIncident("");

        }

    }


    // Incident Headers
    function _highPriority(e) {

        e.preventDefault();

        if (e.target.value) {

            if (highPriority == "Y") {

                setHighPriority("N");

            } else {

                setHighPriority("Y");

            }

        } else {

            setHighPriority("N");

        }
    }

    function _incidentDate(dateString) {
        if (dateString) {
            setIncidentDate(dateString);
        } else {
            setIncidentDate("");
        }
    }

    function _typeOfIncident(e) {

        setSubTypeOfIncident("");

        if (e.target.value) {

            setTypeOfIncident(e.target.value);

            _loadSubTypeOfIncidentData(e.target.value);

        } else {

            setTypeOfIncident("");

            _loadSubTypeOfIncidentData();

        }

    }

    function _numberOfOccurences(e) {

        if (e.target.value) {

            setNumberOfOccurences(e.target.value);

        } else {

            setNumberOfOccurences();

        }

    }

    function _party2Estabilishedeao(value) {

        // console.log(value);

        if (value.length > 0) {

            let arr = [];

            value.map(item => {

                if (Helper.hiddenChar(item) !== "11111111111111111111111111111111") {

                    arr.push(item);

                }

            });

            // console.log(arr);

            setParty2Estabilishedeao(arr);

        } else {

            setParty2Estabilishedeao(['11111111111111111111111111111111']);

        }

    }

    function _party4Civillians(e) {

        // console.log(value);

        // if(value.length > 0){

        //     let arr = [];

        //     value.map(item=>{

        //         if(item !== "1"){

        //             arr.push(item);

        //         }

        //     });

        //     // console.log(arr);

        //     setParty4Civillians(arr);

        // }else{

        //     setParty4Civillians(['1']);

        // }

        if (e.target.value) {

            setParty4Civillians(e.target.value);

        } else {

            setParty4Civillians("");

        }


    }

    function _targetHuman(e) {

        if (e.target.value) {

            setTargetHuman(e.target.value);

        } else {

            setTargetHuman("");

        }

    }

    function _artillerySupport(e) {

        if (e.target.value) {

            if (artillerySupport == "Y") {

                setArtillerySupport("N");

            } else {

                setArtillerySupport("Y");

            }

        } else {

            setArtillerySupport("N");

        }

    }

    function _airSupport(e) {

        if (e.target.value) {

            if (airSupport == "Y") {

                setAirSupport("N");

            } else {

                setAirSupport("Y");

            }


        } else {

            setAirSupport("N");

        }

    }
    function _time(time, timeString) {

        // console.log(timeString);

        if (timeString) {

            setTime(timeString);

        } else {

            setTime("");

        }

    }
    // function _time(e){

    //     // console.log(timeString);

    //     if(e.target.value){

    //         setTime(e.target.value);

    //     }else{

    //         setTime("");

    //     }

    // }

    function _subTypeOfIncident(e) {

        // console.log(e);

        if (e) {

            setSubTypeOfIncident(e);

        } else {

            setSubTypeOfIncident("");

        }

    }

    function _party1MyanmarSecurityForces(value) {

        // console.log(value);

        if (value.length > 0) {

            let arr = [];

            value.map(item => {

                if (Helper.hiddenChar(item) !== "11111111111111111111111111111111") {

                    arr.push(item);

                }

            });

            // console.log(arr);

            setParty1MyanmarSecurityForces(arr);

        } else {

            setParty1MyanmarSecurityForces(['11111111111111111111111111111111']);

        }

    }

    function _party3CivillianSelfDefenceForces(value) {

        if (value.length > 0) {

            let arr = [];

            value.map(item => {

                if (Helper.hiddenChar(item) !== "11111111111111111111111111111111") {

                    arr.push(item);

                }

            });

            // console.log(arr);

            setParty3CivillianSelfDefenceForces(arr);

        } else {

            setParty3CivillianSelfDefenceForces(['11111111111111111111111111111111']);

        }

    }
    function _party5Others(e) {


        if (e.target.value) {

            setParty5Others(e.target.value);

        } else {

            setParty5Others("");

        }

    }
    function _targetAssets(e) {

        if (e.target.value) {

            setTargetAssets(e.target.value);

        } else {

            setTargetAssets("");

        }

    }

    function _claimedByNug(e) {

        if (e.target.value) {

            if (claimedByNug == "Y") {

                setClaimedByNug("N");

            } else {

                setClaimedByNug("Y");

            }


        } else {

            setClaimedByNug("Y");

        }

    }

    function _tacticalDevelopment(e) {

        if (e.target.value) {

            if (tacticaldevelopment == "Y") {

                setTacticalDevelopment("N");

            } else {

                setTacticalDevelopment("Y");

            }

        } else {

            setTacticalDevelopment("N");

        }

    }

    // Ordinary Civilian Toll
    function _killedOct(e) {

        if (e.target.value) {


            if (e.target.value < 0) {

                setKilledOct("0");

            } else {

                setKilledOct(e.target.value);
            }

        } else {

            setKilledOct();

        }

    }

    function _injuredOct(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setInjuredOct("0");

            } else {

                setInjuredOct(e.target.value);

            }

        } else {

            setInjuredOct();

        }

    }

    function _arrestedOct(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setArrestedOct("0");

            } else {

                setArrestedOct(e.target.value);

            }


        } else {

            setArrestedOct();

        }

    }

    function _civilAdministratorResignationsOct(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setCivilAdministratorResignationsOct("0");

            } else {

                setCivilAdministratorResignationsOct(e.target.value);

            }


        } else {

            setCivilAdministratorResignationsOct();

        }

    }

    // Pro-SAC Civilian Toll
    function _killedPct(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setKilledPct("0");

            } else {

                setKilledPct(e.target.value);

            }

        } else {

            setKilledPct();

        }

    }

    function _injuredPct(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setInjuredPct("0");

            } else {

                setInjuredPct(e.target.value);

            }

        } else {

            setInjuredPct();

        }

    }

    function _abductedPct(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setAbductedPct("0");

            } else {

                setAbductedPct(e.target.value);

            }


        } else {

            setAbductedPct();

        }

    }

    // Civilian Toll by Civilian Pro-SAC Group
    function _killedCtg(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setKilledCtg("0");

            } else {

                setKilledCtg(e.target.value);

            }


        } else {

            setKilledCtg();

        }

    }

    function _injuredCtg(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setInjuredCtg("0");

            } else {

                setInjuredCtg(e.target.value);

            }


        } else {

            setInjuredCtg();

        }

    }

    function _abductedCtg(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setAbductedCtg("0");

            } else {

                setAbductedCtg(e.target.value);

            }


        } else {

            setAbductedCtg("");

        }

    }

    //Security Forces Toll
    function _killedSft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setKilledSft("0");

            } else {

                setKilledSft(e.target.value);
            }


        } else {

            setKilledSft();

        }

    }

    function _injuredSft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setInjuredSft("0");

            } else {

                setInjuredSft(e.target.value);

            }

        } else {

            setInjuredSft();

        }

    }

    function _capturedSft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setCapturedSft("0");

            } else {

                setCapturedSft(e.target.value);

            }

        } else {

            setCapturedSft();

        }

    }
    function _desertionsSft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setDesertionsSft("0");

            } else {

                setDesertionsSft(e.target.value);

            }


        } else {

            setDesertionsSft();

        }

    }

    // Resistance Fighters Toll
    function _killedRft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setKilledRft("0");

            } else {

                setKilledRft(e.target.value);

            }

        } else {

            setKilledRft();

        }

    }

    function _injuredRft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setInjuredRft("0");

            } else {

                setInjuredRft(e.target.value);
            }

        } else {

            setInjuredRft();

        }

    }
    function _arrestedRft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setArrestedRft("0");

            } else {

                setArrestedRft(e.target.value);

            }



        } else {

            setArrestedRft();

        }

    }

    // EAO Fighters Toll
    function _killedEft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setKilledEft("0");

            } else {

                setKilledEft(e.target.value);

            }

        } else {

            setKilledEft();

        }

    }

    function _injuredEft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setInjuredEft("0");

            } else {

                setInjuredEft(e.target.value);
            }

        } else {

            setInjuredEft();

        }

    }

    function _arrestedEft(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setArrestedEft("0");

            } else {

                setArrestedEft(e.target.value);

            }


        } else {

            setArrestedEft();

        }

    }

    // Others/Unknown Toll
    function _killedOt(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setKilledOt("0");

            } else {

                setKilledOt(e.target.value);

            }

        } else {

            setKilledOt();

        }

    }

    function _injuredOt(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setInjuredOt("0");

            } else {

                setInjuredOt(e.target.value);
            }

        } else {

            setInjuredOt();

        }

    }

    function _arrestedOt(e) {

        if (e.target.value) {

            if (e.target.value < 0) {

                setArrestedOt("0");

            } else {

                setArrestedOt(e.target.value);

            }


        } else {

            setArrestedOt();

        }

    }

    function _incidentDescription(e) {
        setDetectEditIncidentDescription(true)
        if (e.target.value) {

            setIncidentDescription(e.target.value);

        } else {

            setIncidentDescription("");

        }

    }

    function _sourceTwo(e) {

        if (e.target.value) {

            setSourceTwo(e.target.value);

        } else {

            setSourceTwo("");

        }

    }


    function onPlaceChanged(e) {

        if (autoComplete !== null) {

            let objAutoComplete = autoComplete.getPlace();

            // console.log(objAutoComplete);

            let resultAddressComponents = objAutoComplete.address_components;

            let villageTract = Helper.findResult(resultAddressComponents, "administrative_area_level_4") ? Helper.findResult(resultAddressComponents, "administrative_area_level_4").long_name : ""; //get village tract

            // let town = MapsHelper.findResult(resultAddressComponents, "locality) ? MapsHelper.findResult(resultAddressComponents, "locality).long_name : ""; // get town

            // let stateregion = MapsHelper.findResult(resultAddressComponents, "administrative_area_level_1") ? MapsHelper.findResult(resultAddressComponents, "administrative_area_level_1").long_name : ""; // get region

            let stateregion = Helper.findResult(resultAddressComponents, "locality") ? Helper.findResult(resultAddressComponents, "locality").long_name : ""; // get region


            // console.log(stateregion);

            // setTownship(town);

            _loadStateRegionData(stateregion);

            setVillageTract(villageTract);

            setRoadStreet(objAutoComplete.formatted_address);

            setLatitude(objAutoComplete.geometry.location.lat());

            setLongitude(objAutoComplete.geometry.location.lng());

            setZoom(16);

            // console.log(objAutoComplete);
        } else {

            console.log('Autocomplete is not loaded yet!');

        }
    }

    function _latitude(e) {

        e.preventDefault();

        let dataLatitude = e.target.value;

        if (dataLatitude) {

            setLatitude(dataLatitude);

        } else {

            setLatitude();

        }
    }

    function _longitude(e) {

        e.preventDefault();

        let dataLongitude = e.target.value;

        if (dataLongitude) {

            setLongitude(dataLongitude);

        } else {

            setLongitude();

        }
    }

    function _roadStreet(e) {

        if (e.target.value) {

            setRoadStreet(e.target.value);

        } else {

            setRoadStreet("");

        }

    }

    function _villageTract(e) {

        if (e.target.value) {

            setVillageTract(e.target.value);

        } else {

            setVillageTract("");

        }

    }

    function _town(e) {

        if (e.target.value) {

            setTown(e.target.value);

        } else {

            setTown("");

        }

    }

    function _township(e) {

        if (e) {

            setTownship(e);

        } else {

            setTownship("");

        }

    }

    function _stateRegion(e) {

        setTownship("");

        if (e.target.value) {

            setStateRegion(e.target.value);

            _loadTownhsipData(e.target.value);

        } else {

            setStateRegion("");

            _loadTownhsipData();

        }

    }

    function _geoPrecision(e) {

        if (e.target.value) {

            setGeoPricision(e.target.value);

        } else {

            setGeoPricision("");

        }

    }

    // function _highway(e){

    //     if(e.target.value){

    //         if(highway == "Y"){

    //             setHighway("N");

    //         }else{

    //             setHighway("Y");
    //         }

    //     }else{

    //         setHighway("N");

    //     }

    // }

    function _commentOnLocation(e) {

        if (e.target.value) {

            setCommentOnLocation(e.target.value);

        } else {

            setCommentOnLocation("");

        }

    }

    function _cancel(e) {

        e.preventDefault();

        // if(incidentSubmissionId && duplicateIncident){

        window.close();

        // }

        // history.push(`IncidentSubmissions`);
    }
    const ButtonAction = (props) => {

        // console.log(props);

        if (props.incidentSubmissionId && props.duplicateIncident) {

            return (
                <div className="row mb-3">
                    <div className="col-lg-12 text-center">
                        <button type="submit" className="btn btn-primary mx-1" onClick={(e) => _saveData(e, "Draft")}>Save as Draft</button>
                        <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-save-submitted`} >Submit for Approval</button>
                        <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-cancel`}>Cancel</button>
                    </div>
                </div>
            )

        } else if (props.incidentSubmissionId) {

            if (props.statusIncident == "Submitted") {

                return (
                    <div className="row mb-3">
                        <div className="col-lg-12 text-center">
                            {permissions.includes(editIncident) ?
                                <>
                                    <button type="submit" className="btn btn-primary mx-1" onClick={(e) => _updateData(e, props.statusIncident, props.statusIncident)}>Save</button>
                                    <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-delete`}><i className="bi bi-trash-fill"></i> Discard</button>
                                </>
                                :
                                <></>
                            }
                            {permissions.includes(approveIncident) ?
                                <>
                                    <button type="submit" className="btn btn-primary mx-1" onClick={(e) => _updateData(e, "Approve", props.statusIncident)}><i className="bi bi-file-earmark-check-fill"></i> Approve</button>
                                    <button type="submit" className="btn btn-primary mx-1" onClick={(e) => _updateData(e, "Rejected", props.statusIncident)}><i className="bi bi-x-circle-fill"></i> Reject</button>
                                </>

                                :
                                <></>
                            }
                        </div>
                    </div>
                )

            } else if (props.statusIncident == "Rejected") {

                return (
                    <div className="row mb-3">
                        <div className="col-lg-12 text-center">
                            {permissions.includes(approveIncident) ?

                                <button type="submit" className="btn btn-primary mx-1" onClick={(e) => _updateData(e, "Approve", props.statusIncident)}><i className="bi bi-file-earmark-check-fill"></i> Approve</button>
                                :
                                <></>

                            }

                            {permissions.includes(editIncident) ?

                                <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-delete`}><i className="bi bi-trash-fill"></i> Discard</button>
                                :
                                <></>
                            }
                        </div>
                    </div>
                );

            } else if (props.statusIncident == "Approve") {

                if (permissions.includes(editIncident) || permissions.includes(approveIncident)) {
                    return (
                        <div className="row mb-3">
                            <div className="col-lg-12 text-center">
                                <button type="submit" className="btn btn-primary mx-1" onClick={(e) => _updateData(e, props.statusIncident, props.statusIncident)}>Save</button>
                                <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-delete`}><i class="bi bi-trash-fill"></i> Discard</button>
                            </div>
                        </div>
                    )
                } else {

                    return (<></>)

                }
            } else {

                if (permissions.includes(editIncident)) {

                    return (
                        <div className="row mb-3">
                            <div className="col-lg-12 text-center">
                                <button type="submit" className="btn btn-primary mx-1" onClick={(e) => _updateData(e, props.statusIncident, props.statusIncident)}>Save</button>
                                <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-update-submitted`}><i className="bi bi-file-earmark-check-fill"></i> Submit for Approval</button>
                                <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-delete`} ><i className="bi bi-trash-fill"></i> Discard</button>
                            </div>
                        </div>
                    )

                } else {

                    return (
                        <></>
                    )

                }

            }

        } else {

            if (permissions.includes(editIncident)) {
                return (
                    <div className="row mb-3">
                        <div className="col-lg-12 text-center">
                            <button type="submit" className="btn btn-primary mx-1" onClick={(e) => _saveData(e, "Draft")}>Save as Draft</button>
                            <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-save-submitted`} >Submit for Approval</button>
                            <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-cancel`}>Cancel</button>
                        </div>
                    </div>
                )
            } else {

                return (
                    <>
                    </>
                )

            }

        }

    }

    async function _loadTownhsipData(idStateRegion) {

        // console.log(idStateRegion);

        await axios.get(Uri.rootUri + `master/township/?region_id=${idStateRegion}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                // console.log(response);

                let arr = [];

                let dataResponse = response.data;

                // console.log(result);

                dataResponse.sort((a, b) => { //ascending sort Township

                    let fa = a.name.toLowerCase();
                    let fb = b.name.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }

                    if (fa > fb) {
                        return 1;
                    }
                    return 0;

                });

                dataResponse.forEach(x => {

                    arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                })

                setTownshipData(dataResponse);

                setElTownShip(arr);

            })
            .catch(function (error) {



                // console.log(error)

            });

    }

    async function _saveData(e, status) {

        e.preventDefault();



        const isLatitude = isFinite(latitude) && Math.abs(latitude) <= 90; //validasi latitude
        const isLongitude = isFinite(longitude) && Math.abs(longitude) <= 180; //validasi longitude

        if (!incidentDate || !typeOfIncident || !party2Estabilishedeao || !party4Civillians || !targetHuman || !subTypeOfIncident || !party1MyanmarSecurityForces || !party3CivillianSelfDefenceForces || !party5Others || !targetAssets || !incidentDescription || !sourceTwo || !township || !stateRegion || !geoPrecision || !latitude || !longitude) {

            setIsInvalid(true);



            setTimeout(() => setIsInvalid(false), 5000);


        } else {


            if (!isLatitude || !isLongitude) {

                setLoading(false);

                Helper.alert("Failed", "error", "Please fill in latitude and longitude correctly", 3000);

                // console.log("latitude and longitude false");

            } else {

                let timeIncident;

                if (incidentSubmissionId && duplicateIncident) {//when duplicate

                    timeIncident = time

                } else if (time) { // when add

                    timeIncident = time + ":00";

                } else {

                    timeIncident = null
                }

                // let suffix = '['
                // let prefix = ']'

                let suffix = ""
                let prefix = ""

                let arrParty1MyanmarSecurityForces = [];
                let arrParty2EstablishedEao = [];
                let arrParty3CivillianSelfDefenceForces = [];

                party1MyanmarSecurityForces.forEach(x => {

                    // let str = "'" + Helper.hiddenChar(x) + "'";
                    let str = Helper.hiddenChar(x);

                    arrParty1MyanmarSecurityForces.push(str);

                });
                party2Estabilishedeao.forEach(x => {

                    // let str = "'" + Helper.hiddenChar(x) + "'";
                    let str = Helper.hiddenChar(x);

                    arrParty2EstablishedEao.push(str);

                });
                party3CivillianSelfDefenceForces.forEach(x => {

                    // let str = "'" + Helper.hiddenChar(x) + "'";
                    let str = Helper.hiddenChar(x);

                    arrParty3CivillianSelfDefenceForces.push(str);

                });

                // let arrParty1Value = suffix + arrParty1MyanmarSecurityForces + prefix;
                // let arrParty2Value = suffix + arrParty2EstablishedEao + prefix;
                // let arrParty3Value = suffix + arrParty3CivillianSelfDefenceForces + prefix;
                let arrParty1Value = suffix + arrParty1MyanmarSecurityForces + prefix;
                let arrParty2Value = suffix + arrParty2EstablishedEao + prefix;
                let arrParty3Value = suffix + arrParty3CivillianSelfDefenceForces + prefix;

                let data = {
                    "drafted_by": draftedBy ? draftedBy : "system",
                    "updated_by": updatedBy ? updatedBy : "system",
                    "status": status ? status : "Draft",
                    "highpriority": highPriority ? highPriority : "N",
                    "incidentdate": moment(incidentDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
                    "typeofincident_id": Helper.hiddenChar(typeOfIncident),
                    "numberofoccurences": Number(numberOfOccurences) ? Number(numberOfOccurences) : 0,
                    "party2estalabilishedeao_id": arrParty2Value ? arrParty2Value : "['11111111111111111111111111111111']",
                    "party4civillians_id": party4Civillians ? party4Civillians : "11111111111111111111111111111111",
                    "targethuman_id": targetHuman ? targetHuman : "22222222222222222222222222222222",
                    "artillerysupport": artillerySupport ? artillerySupport : "N",
                    "airsupport": airSupport ? airSupport : "N",
                    "time": timeIncident,
                    "subtypeofincident_id": Helper.hiddenChar(subTypeOfIncident),
                    "party1myanmarsecurityforces_id": arrParty1Value ? arrParty1Value : "['11111111111111111111111111111111']",
                    "party3civillianselfdefenceforces_id": arrParty3Value ? arrParty3Value : "['11111111111111111111111111111111']",
                    "party5others_id": party5Others ? party5Others : "11111111111111111111111111111111",
                    "targetassets_id": targetAssets ? targetAssets : "22222222222222222222222222222222",
                    "claimedbynug": claimedByNug ? claimedByNug : "N",
                    "tacticaldevelopment": tacticaldevelopment ? tacticaldevelopment : "N",
                    "killedoct": Number(killedOct) ? Number(killedOct) : 0,
                    "injuredoct": Number(injuredOct) ? Number(injuredOct) : 0,
                    "arrestedoct": Number(arrestedOct) ? Number(arrestedOct) : 0,
                    "civiladministratorresignationsoct": Number(civilAdministratorResignationsOct) ? Number(civilAdministratorResignationsOct) : 0,
                    "killedpct": Number(killedPct) ? Number(killedPct) : 0,
                    "injuredpct": Number(injuredPct) ? Number(injuredPct) : 0,
                    "abductedpct": Number(abductedPct) ? Number(abductedPct) : 0,
                    "killedctg": Number(killedCtg) ? Number(killedCtg) : 0,
                    "injuredctg": Number(injuredCtg) ? Number(injuredCtg) : 0,
                    "abductedctg": Number(abductedCtg) ? Number(abductedCtg) : 0,
                    "killedsft": Number(killedSft) ? Number(killedSft) : 0,
                    "injuredsft": Number(injuredSft) ? Number(injuredSft) : 0,
                    "capturedsft": Number(capturedSft) ? Number(capturedSft) : 0,
                    "desertionssft": Number(desertionsSft) ? Number(desertionsSft) : 0,
                    "killedrft": Number(killedRft) ? Number(killedRft) : 0,
                    "injuredrft": Number(injuredRft) ? Number(injuredRft) : 0,
                    "arrestedrft": Number(arrestedRft) ? Number(arrestedRft) : 0,
                    "killedeft": Number(killedEft) ? Number(killedEft) : 0,
                    "injuredeft": Number(injuredEft) ? Number(injuredEft) : 0,
                    "arrestedeft": Number(arrestedEft) ? Number(arrestedEft) : 0,
                    "killedot": Number(killedOt) ? Number(killedOt) : 0,
                    "injuredot": Number(injuredOt) ? Number(injuredOt) : 0,
                    "arrestedot": Number(arrestedOt) ? Number(arrestedOt) : 0,
                    "incidentdescription": incidentDescription,
                    "sourcetwo": sourceTwo,
                    "source": "https://localhost",//actually not use
                    "latitude": String(latitude),
                    "longitude": String(longitude),
                    "roadstreet": roadStreet ? roadStreet : "",
                    "villagetract": villageTract ? villageTract : "",
                    "town": town ? town : "",
                    "township_id": Helper.hiddenChar(township),
                    "stateregion_id": Helper.hiddenChar(stateRegion),
                    "geoprecision_id": Helper.hiddenChar(geoPrecision),
                    "highway": "N",
                    // "highway" : highway ? highway : "N",
                    "commentonlocation": commentOnLocation ? commentOnLocation : ""
                }

                console.log(data);

                await axios.post(Uri.rootUri + 'incident/', data, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                        // let dataResponse = response.data;

                        // console.log(response);
                        setStatusIncident("");
                        setDraftedBy("");
                        setUpdatedBy("");
                        setApprovedAt("");
                        setIncidentDate("");
                        setTypeOfIncident("");
                        setNumberOfOccurences();
                        setParty2Estabilishedeao("");
                        setParty4Civillians("");
                        setTargetHuman("");
                        setTime("");
                        setSubTypeOfIncident("");
                        setParty1MyanmarSecurityForces("");
                        setParty3CivillianSelfDefenceForces("");
                        setParty5Others("");
                        setTargetAssets("");
                        setKilledOct();
                        setInjuredOct();
                        setArrestedOct();
                        setCivilAdministratorResignationsOct();
                        setKilledPct();
                        setInjuredPct();
                        setAbductedPct();
                        setKilledCtg();
                        setInjuredCtg();
                        setAbductedCtg();
                        setKilledSft();
                        setInjuredSft();
                        setCapturedSft();
                        setDesertionsSft();
                        setKilledRft();
                        setInjuredRft();
                        setArrestedRft();
                        setKilledEft();
                        setInjuredEft();
                        setArrestedEft();
                        setKilledOt();
                        setInjuredOt();
                        setArrestedOt();
                        setIncidentDescription("");
                        setSourceTwo("");
                        setLatitude();
                        setLongitude();
                        setRoadStreet("");
                        setVillageTract("");
                        setTown("");
                        setTownship("");
                        setStateRegion("");
                        setGeoPricision("");
                        // setHighway();
                        setCommentOnLocation("");

                        if (status == "Submitted") {

                            Helper.alert("Success", "success", "Submitted and save data", 3000);

                        } else {

                            Helper.alert("Success", "success", "Success save data", 3000);
                        }



                        if (incidentSubmissionId || duplicateIncident) { //when duplicate or view

                            window.onunload = refreshParent();

                            setTimeout(() => {
                                window.close();
                            }, 2000);

                        } else {

                            window.onunload = refreshParent();

                            setTimeout(() => {
                                window.close();
                            }, 2000);

                            history.push(`/Index/IncidentSubmissions`);

                        }




                    })
                    .catch(function (error) {

                        console.log(error);



                    });
            }

        }

    }
    async function _updateData(e, status, previousStatus) {

        e.preventDefault();



        const isLatitude = isFinite(latitude) && Math.abs(latitude) <= 90; //validasi latitude
        const isLongitude = isFinite(longitude) && Math.abs(longitude) <= 180; //validasi longitude

        if (!incidentDate || !typeOfIncident || !party2Estabilishedeao || !party4Civillians || !targetHuman || !subTypeOfIncident || !party1MyanmarSecurityForces || !party3CivillianSelfDefenceForces || !party5Others || !targetAssets || !incidentDescription || !sourceTwo || !township || !stateRegion || !geoPrecision || !latitude || !longitude) {

            setIsInvalid(true);

            setLoading(false);

            setTimeout(() => setIsInvalid(false), 5000);


        } else {


            if (!isLatitude || !isLongitude) {

                setLoading(false);

                Helper.alert("Failed", "error", "Please fill in latitude and longitude correctly", 3000);

                // console.log("latitude and longitude false");


            } else {

                // let suffix = '['
                // let prefix = ']'

                let approved_by;
                let approved_at;

                let suffix = ""
                let prefix = ""

                let arrParty1MyanmarSecurityForces = [];
                let arrParty2EstablishedEao = [];
                let arrParty3CivillianSelfDefenceForces = [];

                party1MyanmarSecurityForces.forEach(x => {

                    // let str = "'" + Helper.hiddenChar(x) + "'";

                    let str = Helper.hiddenChar(x);

                    arrParty1MyanmarSecurityForces.push(str);

                });
                party2Estabilishedeao.forEach(x => {

                    // let str = "'" + Helper.hiddenChar(x) + "'";

                    let str = Helper.hiddenChar(x);

                    arrParty2EstablishedEao.push(str);
                });
                party3CivillianSelfDefenceForces.forEach(x => {

                    // let str = "'" + Helper.hiddenChar(x) + "'";

                    let str = Helper.hiddenChar(x);

                    arrParty3CivillianSelfDefenceForces.push(str);

                });

                let arrParty1Value = suffix + arrParty1MyanmarSecurityForces + prefix;
                let arrParty2Value = suffix + arrParty2EstablishedEao + prefix;
                let arrParty3Value = suffix + arrParty3CivillianSelfDefenceForces + prefix;

                if (previousStatus == "Approve") {

                    if (status == "Approve" && !approvedBy) { //if user approve with sistem

                        approved_by = "";

                        approved_at = moment(approvedAt, "YYYY-MM-DD[T]HH:mm:ss").format("DD-MM-YYYY");

                    } else if (status == "Approve" && approvedBy) {

                        approved_by = approvedBy;

                        approved_at = moment(approvedAt, "YYYY-MM-DD[T]HH:mm:ss").format("DD-MM-YYYY");
                    }

                } else {

                    if (status == "Approve" && !approvedBy) { //if user approve incident

                        approved_by = localStorage.getItem("usernameLogin");

                        approved_at = moment().format("DD-MM-YYYY");

                    } else {

                        approved_by = "";

                        approved_at = moment(approvedAt, "YYYY-MM-DD[T]HH:mm:ss").format("DD-MM-YYYY");

                    }

                }

                let data = {
                    "drafted_by": draftedBy,
                    "updated_by": updatedBy,
                    "approved_by": approved_by,
                    "approved_at": approved_at,
                    "status": status ? status : statusIncident,
                    "highpriority": highPriority ? highPriority : "N",
                    "incidentdate": moment(incidentDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
                    "typeofincident_id": Helper.hiddenChar(typeOfIncident),
                    "numberofoccurences": Number(numberOfOccurences) ? Number(numberOfOccurences) : 0,
                    "party2estalabilishedeao_id": arrParty2Value ? arrParty2Value : "['11111111111111111111111111111111']",
                    "party4civillians_id": Helper.hiddenChar(party4Civillians) ? Helper.hiddenChar(party4Civillians) : "11111111111111111111111111111111",
                    "targethuman_id": Helper.hiddenChar(targetHuman) ? Helper.hiddenChar(targetHuman) : "22222222222222222222222222222222",
                    "artillerysupport": artillerySupport ? artillerySupport : "N",
                    "airsupport": airSupport ? airSupport : "N",
                    "time": time ? `${time}` : null,
                    "subtypeofincident_id": Helper.hiddenChar(subTypeOfIncident),
                    "party1myanmarsecurityforces_id": arrParty1Value ? arrParty1Value : "['11111111111111111111111111111111']",
                    "party3civillianselfdefenceforces_id": arrParty3Value ? arrParty3Value : "['11111111111111111111111111111111']",
                    "party5others_id": Helper.hiddenChar(party5Others) ? Helper.hiddenChar(party5Others) : "11111111111111111111111111111111",
                    "targetassets_id": Helper.hiddenChar(targetAssets) ? Helper.hiddenChar(targetAssets) : "22222222222222222222222222222222",
                    "claimedbynug": claimedByNug ? claimedByNug : "N",
                    "tacticaldevelopment": tacticaldevelopment ? tacticaldevelopment : "N",
                    "killedoct": Number(killedOct) ? Number(killedOct) : 0,
                    "injuredoct": Number(injuredOct) ? Number(injuredOct) : 0,
                    "arrestedoct": Number(arrestedOct) ? Number(arrestedOct) : 0,
                    "civiladministratorresignationsoct": Number(civilAdministratorResignationsOct) ? Number(civilAdministratorResignationsOct) : 0,
                    "killedpct": Number(killedPct) ? Number(killedPct) : 0,
                    "injuredpct": Number(injuredPct) ? Number(injuredPct) : 0,
                    "abductedpct": Number(abductedPct) ? Number(abductedPct) : 0,
                    "killedctg": Number(killedCtg) ? Number(killedCtg) : 0,
                    "injuredctg": Number(injuredCtg) ? Number(injuredCtg) : 0,
                    "abductedctg": Number(abductedCtg) ? Number(abductedCtg) : 0,
                    "killedsft": Number(killedSft) ? Number(killedSft) : 0,
                    "injuredsft": Number(injuredSft) ? Number(injuredSft) : 0,
                    "capturedsft": Number(capturedSft) ? Number(capturedSft) : 0,
                    "desertionssft": Number(desertionsSft) ? Number(desertionsSft) : 0,
                    "killedrft": Number(killedRft) ? Number(killedRft) : 0,
                    "injuredrft": Number(injuredRft) ? Number(injuredRft) : 0,
                    "arrestedrft": Number(arrestedRft) ? Number(arrestedRft) : 0,
                    "killedeft": Number(killedEft) ? Number(killedEft) : 0,
                    "injuredeft": Number(injuredEft) ? Number(injuredEft) : 0,
                    "arrestedeft": Number(arrestedEft) ? Number(arrestedEft) : 0,
                    "killedot": Number(killedOt) ? Number(killedOt) : 0,
                    "injuredot": Number(injuredOt) ? Number(injuredOt) : 0,
                    "arrestedot": Number(arrestedOt) ? Number(arrestedOt) : 0,
                    "incidentdescription": incidentDescription,
                    "sourcetwo": sourceTwo,
                    "source": "https://localhost", //actually not use
                    "latitude": String(latitude),
                    "longitude": String(longitude),
                    "roadstreet": roadStreet ? roadStreet : "",
                    "villagetract": villageTract ? villageTract : "",
                    "town": town ? town : "",
                    "township_id": Helper.hiddenChar(township),
                    "stateregion_id": Helper.hiddenChar(stateRegion),
                    "geoprecision_id": Helper.hiddenChar(geoPrecision),
                    "highway": "N",
                    // "highway": highway ? highway : "N",
                    "commentonlocation": commentOnLocation ? commentOnLocation : ""
                }

                console.log(data);

                await axios.put(Uri.rootUri + `incident/${incidentId}/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                        // console.log(response);
                        setStatusIncident("");
                        setDraftedBy("");
                        setUpdatedBy("");
                        setApprovedAt("");
                        setApprovedBy("");
                        setIncidentDate("");
                        setTypeOfIncident("");
                        setNumberOfOccurences();
                        setParty2Estabilishedeao("");
                        setParty4Civillians("");
                        setTargetHuman("");
                        setTime("");
                        setSubTypeOfIncident("");
                        setParty1MyanmarSecurityForces("");
                        setParty3CivillianSelfDefenceForces("");
                        setParty5Others("");
                        setTargetAssets("");
                        setKilledOct();
                        setInjuredOct();
                        setArrestedOct();
                        setCivilAdministratorResignationsOct();
                        setKilledPct();
                        setInjuredPct();
                        setAbductedPct();
                        setKilledCtg();
                        setInjuredCtg();
                        setAbductedCtg();
                        setKilledSft();
                        setInjuredSft();
                        setCapturedSft();
                        setDesertionsSft();
                        setKilledRft();
                        setInjuredRft();
                        setArrestedRft();
                        setKilledEft();
                        setInjuredEft();
                        setArrestedEft();
                        setKilledOt();
                        setInjuredOt();
                        setArrestedOt();
                        setIncidentDescription("");
                        setSourceTwo("");
                        setLatitude();
                        setLongitude();
                        setRoadStreet("");
                        setVillageTract("");
                        setTown("");
                        setTownship("");
                        setStateRegion("");
                        setGeoPricision("");
                        // setHighway();
                        setCommentOnLocation("");

                        if (status == "Submitted") {

                            Helper.alert("Success", "success", "Success submitted data", 3000);

                        } else if (status == "Rejected") {

                            Helper.alert("Success", "success", "Success rejected data", 3000);

                        } else {

                            Helper.alert("Success", "success", "Success update data", 3000);
                        }



                        setTimeout(() => {
                            window.close();
                        }, 2000);

                        window.onunload = refreshParent();


                    })
                    .catch(function (error) {

                        console.log(error);

                    });

            }

        }

    }

    async function _deleteIncident(e, id) {

        e.preventDefault();



        let userLogin = {
            deleted_by: localStorage.getItem("usernameLogin")
        }

        await axios.put(Uri.rootUri + `incident/${id}/delete`, userLogin, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                //   console.log(response);

                Helper.alert("Success", "success", "Success delete data", 3000);

                //   setLoading(false);

                setTimeout(() => {

                    window.close();

                }, 2000);

                window.onunload = refreshParent();

                _loadData(id)

            })
            .catch(function (error) {

                console.log(error);

            });

    }



    async function _loadData(id) {

        if (id && duplicateIncident) {//when duplicate incident data



            await axios.get(Uri.rootUri + `incident/${id}/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(async function (response) {

                    let dataResponse = response.data;

                    // console.log(dataResponse);

                    let arrParty1 = [];
                    let arrParty2 = [];
                    let arrParty3 = [];

                    // let strParty1Prefix = dataResponse.party1myanmarsecurityforces_id.substr(1);
                    // let strParty1Suffix = strParty1Prefix.substr(0,strParty1Prefix.length - 1);
                    let convertArrParty1 = Array.from(dataResponse.party1myanmarsecurityforces_id.split(','));

                    convertArrParty1.forEach(x => [

                        arrParty1.push(Helper.hiddenChar(x))

                    ]);

                    // let strParty2Prefix = dataResponse.party2estalabilishedeao_id.substr(1);
                    // let strParty2Suffix = strParty2Prefix.substr(0,strParty2Prefix.length - 1);
                    let convertArrParty2 = Array.from(dataResponse.party2estalabilishedeao_id.split(','));

                    convertArrParty2.forEach(x => {

                        arrParty2.push(Helper.hiddenChar(x))

                    })

                    // let strParty3Prefix = dataResponse.party3civillianselfdefenceforces_id.substr(1);
                    // let strParty3Suffix = strParty3Prefix.substr(0,strParty3Prefix.length - 1);
                    let convertArrParty3 = Array.from(dataResponse.party3civillianselfdefenceforces_id.split(','));
                    // console.log(arrParty1);

                    convertArrParty3.forEach(x => {

                        arrParty3.push(Helper.hiddenChar(x));

                    });

                    setDraftedBy(dataResponse.drafted_by ? dataResponse.drafted_by : "system");
                    setUpdatedBy(localStorage.getItem("usernameLogin"));
                    setStatusIncident("Draft");
                    setHighPriority(dataResponse.highpriority);
                    setIncidentDate(moment(dataResponse.incidentdate).format("DD/MM/YYYY"));
                    setApprovedBy(dataResponse.approved_by ? dataResponse.approved_by : "");
                    setApprovedAt(dataResponse.approved_at ? dataResponse.approved_at : "");
                    setTypeOfIncident(Helper.hiddenChar(dataResponse.typeofincident_id));
                    setNumberOfOccurences(dataResponse.numberofoccurences);
                    setParty2Estabilishedeao(arrParty2);
                    setParty4Civillians(Helper.hiddenChar(dataResponse.party4civillians_id));
                    setTargetHuman(Helper.hiddenChar(dataResponse.targethuman_id));
                    setArtillerySupport(dataResponse.artillerysupport);
                    setAirSupport(dataResponse.airsupport);
                    setTime(dataResponse.time);
                    setSubTypeOfIncident(Helper.hiddenChar(dataResponse.subtypeofincident_id));
                    setParty1MyanmarSecurityForces(arrParty1);
                    setParty3CivillianSelfDefenceForces(arrParty3);
                    setParty5Others(Helper.hiddenChar(dataResponse.party5others_id));
                    setTargetAssets(Helper.hiddenChar(dataResponse.targetassets_id));
                    setClaimedByNug(dataResponse.claimedbynug);
                    setTacticalDevelopment(dataResponse.tacticaldevelopment);
                    setKilledOct(dataResponse.killedoct);
                    setInjuredOct(dataResponse.injuredoct);
                    setArrestedOct(dataResponse.arrestedoct);
                    setCivilAdministratorResignationsOct(dataResponse.civiladministratorresignationsoct);
                    setKilledPct(dataResponse.killedpct);
                    setInjuredPct(dataResponse.injuredpct);
                    setAbductedPct(dataResponse.abductedpct)
                    setKilledCtg(dataResponse.killedctg);
                    setInjuredCtg(dataResponse.injuredctg);
                    setAbductedCtg(dataResponse.abductedctg);
                    setKilledSft(dataResponse.killedsft);
                    setInjuredSft(dataResponse.injuredsft);
                    setCapturedSft(dataResponse.capturedsft);
                    setDesertionsSft(dataResponse.desertionssft);
                    setKilledRft(dataResponse.killedrft);
                    setInjuredRft(dataResponse.injuredrft);
                    setArrestedRft(dataResponse.arrestedrft);
                    setKilledEft(dataResponse.killedeft);
                    setInjuredEft(dataResponse.injuredeft);
                    setArrestedEft(dataResponse.arrestedeft);
                    setKilledOt(dataResponse.killedot);
                    setInjuredOt(dataResponse.injuredot);
                    setArrestedOt(dataResponse.arrestedot);
                    setIncidentDescription(dataResponse.incidentdescription);
                    setSourceTwo(dataResponse.sourcetwo);
                    setLatitude(dataResponse.latitude);
                    setLongitude(dataResponse.longitude);
                    setRoadStreet(dataResponse.roadstreet);
                    setVillageTract(dataResponse.villagetract);
                    setTown(dataResponse.town);
                    setTownship(Helper.hiddenChar(dataResponse.township_id));
                    setStateRegion(Helper.hiddenChar(dataResponse.stateregion_id));
                    setGeoPricision(Helper.hiddenChar(dataResponse.geoprecision_id));
                    // setHighway(dataResponse.highway);
                    setCommentOnLocation(dataResponse.commentonlocation);
                    setCreatedBy(Helper.hiddenChar(dataResponse.created_by));
                    await _loadTownhsipData(Helper.hiddenChar(dataResponse.stateregion_id));
                    await _loadSubTypeOfIncidentData(Helper.hiddenChar(dataResponse.typeofincident_id));


                    // console.log(response);



                })
                .catch(function (error) {

                    console.log(error)

                })

        } else if (id) { //only view incident



            await axios.get(Uri.rootUri + `incident/${id}/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(async function (response) {

                    let dataResponse = response.data;

                    console.log(dataResponse);

                    let arrParty1 = [];
                    let arrParty2 = [];
                    let arrParty3 = [];
                    // let strParty1Prefix = dataResponse.party1myanmarsecurityforces_id.substr(1);
                    // let strParty1Suffix = strParty1Prefix.substr(0,strParty1Prefix.length - 1);
                    let convertArrParty1 = Array.from(dataResponse.party1myanmarsecurityforces_id.split(','));

                    convertArrParty1.forEach(x => [

                        arrParty1.push(Helper.hiddenChar(x))

                    ]);

                    // let strParty2Prefix = dataResponse.party2estalabilishedeao_id.substr(1);
                    // let strParty2Suffix = strParty2Prefix.substr(0,strParty2Prefix.length - 1);
                    let convertArrParty2 = Array.from(dataResponse.party2estalabilishedeao_id.split(','));

                    convertArrParty2.forEach(x => {

                        arrParty2.push(Helper.hiddenChar(x))

                    })

                    // let strParty3Prefix = dataResponse.party3civillianselfdefenceforces_id.substr(1);
                    // let strParty3Suffix = strParty3Prefix.substr(0,strParty3Prefix.length - 1);
                    let convertArrParty3 = Array.from(dataResponse.party3civillianselfdefenceforces_id.split(','));
                    // console.log(arrParty1);

                    convertArrParty3.forEach(x => {

                        arrParty3.push(Helper.hiddenChar(x));

                    });

                    // console.log(arrParty1);

                    setNumber(dataResponse.number);
                    setIncidentId(dataResponse.id);
                    setLastUpdatedOn(moment(dataResponse.updated_at).format("DD/MM/YYYY"));
                    setDraftedBy(dataResponse.drafted_by ? dataResponse.drafted_by : "system");
                    setUpdatedBy(localStorage.getItem("usernameLogin"));
                    setApprovedBy(dataResponse.approved_by ? dataResponse.approved_by : "");
                    setApprovedAt(dataResponse.approved_at ? dataResponse.approved_at : "");
                    setStatusIncident(dataResponse.status);
                    setHighPriority(dataResponse.highpriority);
                    setIncidentDate(moment(dataResponse.incidentdate).format("DD/MM/YYYY"));
                    setTypeOfIncident(Helper.hiddenChar(dataResponse.typeofincident_id));
                    setNumberOfOccurences(dataResponse.numberofoccurences);
                    setParty2Estabilishedeao(arrParty2);
                    setParty4Civillians(Helper.hiddenChar(dataResponse.party4civillians_id));
                    setTargetHuman(Helper.hiddenChar(dataResponse.targethuman_id));
                    setArtillerySupport(dataResponse.artillerysupport);
                    setAirSupport(dataResponse.airsupport);
                    setTime(dataResponse.time);
                    setSubTypeOfIncident(Helper.hiddenChar(dataResponse.subtypeofincident_id));
                    setParty1MyanmarSecurityForces(arrParty1);
                    setParty3CivillianSelfDefenceForces(arrParty3);
                    setParty5Others(Helper.hiddenChar(dataResponse.party5others_id));
                    setTargetAssets(Helper.hiddenChar(dataResponse.targetassets_id));
                    setClaimedByNug(dataResponse.claimedbynug);
                    setTacticalDevelopment(dataResponse.tacticaldevelopment);
                    setKilledOct(dataResponse.killedoct);
                    setInjuredOct(dataResponse.injuredoct);
                    setArrestedOct(dataResponse.arrestedoct);
                    setCivilAdministratorResignationsOct(dataResponse.civiladministratorresignationsoct);
                    setKilledPct(dataResponse.killedpct);
                    setInjuredPct(dataResponse.injuredpct);
                    setAbductedPct(dataResponse.abductedpct)
                    setKilledCtg(dataResponse.killedctg);
                    setInjuredCtg(dataResponse.injuredctg);
                    setAbductedCtg(dataResponse.abductedctg);
                    setKilledSft(dataResponse.killedsft);
                    setInjuredSft(dataResponse.injuredsft);
                    setCapturedSft(dataResponse.capturedsft);
                    setDesertionsSft(dataResponse.desertionssft);
                    setKilledRft(dataResponse.killedrft);
                    setInjuredRft(dataResponse.injuredrft);
                    setArrestedRft(dataResponse.arrestedrft);
                    setKilledEft(dataResponse.killedeft);
                    setInjuredEft(dataResponse.injuredeft);
                    setArrestedEft(dataResponse.arrestedeft);
                    setKilledOt(dataResponse.killedot);
                    setInjuredOt(dataResponse.injuredot);
                    setArrestedOt(dataResponse.arrestedot);
                    setIncidentDescription(dataResponse.incidentdescription);
                    setSourceTwo(dataResponse.sourcetwo);
                    setLatitude(dataResponse.latitude);
                    setLongitude(dataResponse.longitude);
                    setRoadStreet(dataResponse.roadstreet);
                    setVillageTract(dataResponse.villagetract);
                    setTown(dataResponse.town);
                    setTownship(Helper.hiddenChar(dataResponse.township_id));
                    setStateRegion(Helper.hiddenChar(dataResponse.stateregion_id));
                    setGeoPricision(Helper.hiddenChar(dataResponse.geoprecision_id));
                    // setHighway(dataResponse.highway);
                    setCommentOnLocation(dataResponse.commentonlocation);
                    setCreatedBy(dataResponse.created_by);
                    await _loadTownhsipData(Helper.hiddenChar(dataResponse.stateregion_id));
                    await _loadSubTypeOfIncidentData(Helper.hiddenChar(dataResponse.typeofincident_id));

                    // console.log(response);


                })
                .catch(function (error) {

                    console.log(error)

                })
        } else {


            setParty1MyanmarSecurityForces(['11111111111111111111111111111111']);
            setParty2Estabilishedeao(['11111111111111111111111111111111']);
            setParty3CivillianSelfDefenceForces(['11111111111111111111111111111111']);
            setParty5Others('11111111111111111111111111111111');
            setParty4Civillians('11111111111111111111111111111111');
            setTargetHuman("22222222222222222222222222222222");
            setTargetAssets("22222222222222222222222222222222");
            setNumberOfOccurences(1);
            setStatusIncident("Draft");
            setDraftedBy(localStorage.getItem("usernameLogin"));
            setUpdatedBy(localStorage.getItem("usernameLogin"));

        }



    }
    // async function _loadTypeOfIncidentData(){

    // //    setLoading(true);
    //     let arr = [];

    //     let arrTypeOfIncident = [];

    //     let arrSubTypeOfIncident = [];

    //     let arrCache = await caches.keys();// get cache

    //     if(!arrCache.includes("incident") || !arrCache.includes("subincident") ){

    //         await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //         .then(async function(response){

    //             let dataResponse = response.data;
    //             let incidentSub ;

    //             // console.log(dataResponse);

    //             await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //             .then(function(response){

    //                 incidentSub = response.data;

    //                 // console.log(incidentSub);



    //             })
    //             .catch(function(error){



    //             });


    //             dataResponse.forEach(item=>{

    //                 let arrSubIncident = incidentSub.filter(x => x.incident_id == Helper.hiddenChar(item.id));

    //                 // console.log(arrSubIncident);

    //                 if(arrSubIncident.length > 0){

    //                     arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //                 }



    //             })

    //             // console.log(arr);
    //             setTypeOfIncidentData(dataResponse);

    //             setSubTypeOfIncidentData(incidentSub);

    //             setElTypeOfIncident(arr);

    //             Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);//set data to cache

    //             Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, incidentSub);//set data to cache



    //         })
    //         .catch(function(error){

    //             // console.log(error);


    //         });

    //     }else{

    //         let resultDataIncident = arrCache.filter( x => x == "incident"); //get where cache name

    //         let resultDataSubIncident = arrCache.filter( x => x == "subincident"); //get where cache name

    //         resultDataIncident.forEach(async cachename => {

    //           let cacheStorage = await caches.open(cachename);

    //           let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/incident`);

    //           let dataResponse = await cacheResponse.json();

    //           dataResponse.forEach(item=>{

    //               arrTypeOfIncident.push(item);

    //           });

    //         });

    //         resultDataSubIncident.forEach(async cachename => {

    //             let cacheStorage = await caches.open(cachename);

    //             let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/subincident`);

    //             let dataResponse = await cacheResponse.json();

    //             dataResponse.forEach(item=>{

    //                 arrSubTypeOfIncident.push(item);

    //             });

    //         });

    //         if( arrSubTypeOfIncident.length == 0 || arrTypeOfIncident.length === 0 ){

    //             await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //             .then(async function(response){

    //                 let dataResponse = response.data;
    //                 let incidentSub ;

    //                 // console.log(dataResponse);

    //                 await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //                 .then(function(response){

    //                     incidentSub = response.data;

    //                     // console.log(incidentSub);



    //                 })
    //                 .catch(function(error){



    //                 });


    //                 dataResponse.forEach(item=>{

    //                     let arrSubIncident = incidentSub.filter(x => x.incident_id == Helper.hiddenChar(item.id));

    //                     // console.log(arrSubIncident);

    //                     if(arrSubIncident.length > 0){

    //                         arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //                     }



    //                 })

    //                 // console.log(arr);
    //                 setTypeOfIncidentData(dataResponse);

    //                 setSubTypeOfIncidentData(incidentSub);

    //                 setElTypeOfIncident(arr);

    //                 Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);//set data to cache

    //                 Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, incidentSub);//set data to cache



    //             })
    //             .catch(function(error){

    //                 // console.log(error);


    //             });

    //         }else{


    //             arrTypeOfIncident.forEach(item=>{

    //                 let arrSubIncident = arrSubTypeOfIncident.filter(x => x.incident_id == Helper.hiddenChar(item.id));

    //                 // console.log(arrSubIncident);

    //                 if(arrSubIncident.length > 0){

    //                     arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //                 }



    //             })

    //             // console.log(arr);
    //             setTypeOfIncidentData(arrTypeOfIncident);

    //             setSubTypeOfIncidentData(arrSubTypeOfIncident);

    //             setElTypeOfIncident(arr);



    //         }

    //     }

    // }
    async function _loadTypeOfIncidentData(cachenameparam) {

        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("incident")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            let dataResponse = response.data;

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                            })

                            setElTypeOfIncident(arr);

                            setTypeOfIncidentData(dataResponse);

                            Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);



                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "incident");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let incidentResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/incident');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !incidentResponse || incidentResponse == undefined) {// check data valid or no valid

                            // console.log("set version into cache");

                            await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    let dataResponse = response.data;

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                    })

                                    setElTypeOfIncident(arr);

                                    setTypeOfIncidentData(dataResponse);

                                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);



                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        //   console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                //   console.log("set version");

                                // if data cache in database not empty
                                Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }


                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseIncident = await incidentResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseIncident || dataResponseIncident.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        let dataResponse = response.data;

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                        })

                                        setElTypeOfIncident(arr);

                                        setTypeOfIncidentData(dataResponse);

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);



                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);


                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // console.log("set version");

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseIncident there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            let dataResponse = response.data;

                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                            })

                                            setElTypeOfIncident(arr);

                                            setTypeOfIncidentData(dataResponse);

                                            Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);



                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);


                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                let dataResponse = response.data;

                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                                })

                                                setElTypeOfIncident(arr);

                                                setTypeOfIncidentData(dataResponse);

                                                Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);



                                            });

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        // console.log("data incident and version available in cache");

                                        dataResponseIncident.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                        })

                                        setElTypeOfIncident(arr);

                                        setTypeOfIncidentData(dataResponseIncident);

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponseIncident);

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        //    setLoading(true);
        // let arr = [];

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("incident")){

        //     await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(async function(response){

        //         let dataResponse = response.data;


        //         dataResponse.forEach(item=>{

        //                 arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

        //         })

        //         // console.log(arr);
        //         setTypeOfIncidentData(dataResponse);

        //         setElTypeOfIncident(arr);

        //         Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);//set data to cache



        //     })
        //     .catch(function(error){

        //         console.log(error);


        //     });

        // }else{

        //     let resultDataIncident = arrCache.filter( x => x == "incident"); //get where cache name


        //     resultDataIncident.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/incident`);

        //       if(!cacheResponse || cacheResponse == undefined){

        //         await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(async function(response){

        //             let dataResponse = response.data;

        //             dataResponse.forEach(item=>{

        //                     arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

        //             })

        //             // console.log(arr);
        //             setTypeOfIncidentData(dataResponse);

        //             setElTypeOfIncident(arr);

        //             Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);//set data to cache



        //         })
        //         .catch(function(error){

        //             console.log(error);


        //         });

        //       }else{

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(async function(response){

        //                 let dataResponse = response.data;

        //                 dataResponse.forEach(item=>{

        //                         arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

        //                 })

        //                 // console.log(arr);
        //                 setTypeOfIncidentData(dataResponse);

        //                 setElTypeOfIncident(arr);

        //                 Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);//set data to cache



        //             })
        //             .catch(function(error){

        //                 console.log(error);


        //             });

        //         }else{

        //             dataResponse.forEach(item=>{

        //                 arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

        //             });

        //             // console.log(arr);
        //             setTypeOfIncidentData(dataResponse);

        //             setElTypeOfIncident(arr);

        //         }

        //       }

        //     });

        // }

    }

    async function _loadTargetHumanData(cachenameparam) {

        let arr = [];
        //  setLoading(true);
        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("targethuman")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                            });

                            setTargetHumanData(dataResponse);

                            setElTargetHuman(arr);

                            Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "targethuman");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let targetHumanResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/targethuman');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !targetHumanResponse || targetHumanResponse == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                    });

                                    setTargetHumanData(dataResponse);

                                    setElTargetHuman(arr);


                                    Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseTargetHuman = await targetHumanResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseTargetHuman || dataResponseTargetHuman.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                        });

                                        setTargetHumanData(dataResponse);

                                        setElTargetHuman(arr);


                                        Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseTargetHuman there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                            });

                                            setTargetHumanData(dataResponse);

                                            setElTargetHuman(arr);


                                            Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                                });

                                                setTargetHumanData(dataResponse);

                                                setElTargetHuman(arr);


                                                Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                        dataResponseTargetHuman.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                        });

                                        setTargetHumanData(dataResponseTargetHuman);

                                        setElTargetHuman(arr);


                                        Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponseTargetHuman);

                                        Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });


        // let arr = [];

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("targethuman")){

        //     await axios.get(Uri.rootUri + `master/target/human/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         // console.log(dataResponse);

        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //         });

        //         setTargetHumanData(dataResponse);

        //         setElTargetHuman(arr);

        //         Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);//set data to cache

        //     })
        //     .catch(function(error){



        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "targethuman"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/targethuman`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/target/human/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             // console.log(dataResponse);

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //             });

        //             setTargetHumanData(dataResponse);

        //             setElTargetHuman(arr);

        //             Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);//set data to cache

        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //       }else{ //if data in cache not empty

        //             let dataResponse = await cacheResponse.json();

        //             if(!dataResponse || dataResponse.length == 0){

        //                 await axios.get(Uri.rootUri + `master/target/human/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(function(response){

        //                     let dataResponse = response.data;

        //                     // console.log(dataResponse);

        //                     dataResponse.forEach(x=>{

        //                         arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                     });

        //                     setTargetHumanData(dataResponse);

        //                     setElTargetHuman(arr);

        //                     Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);//set data to cache

        //                 })
        //                 .catch(function(error){

        //                     console.log(error);

        //                 });


        //             }else{

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                 });

        //                 setTargetHumanData(dataResponse);

        //                 setElTargetHuman(arr);

        //             }
        //       }


        //     });
        // }

    }

    // async function _loadParty1MyanmarSecurityForcesData(){


    //     let arr = [];

    //     if(party1MyanmarSecurityForcesDataTemp.length == 0){

    //         await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //         .then(function(response){

    //             let dataResponse = response.data;

    //             // console.log(dataResponse);

    //             dataResponse.forEach(x=>{

    //                 arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

    //             });

    //             // console.log(arr);

    //             setParty1MyanmarSecurityForcesDataTemp(dataResponse);
    //             setElParty1MyanmarSecurityForces(arr);




    //         })
    //         .catch(function(error){

    //             console.log(error);

    //         });

    //     }else{

    //         party1MyanmarSecurityForcesDataTemp.forEach(x=>{

    //             arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

    //         });

    //         setElParty1MyanmarSecurityForces(arr);

    //     }



    // }
    async function _loadParty1MyanmarSecurityForcesData(cachenameparam) {

        let arr = []

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("party1")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/party/one/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            arr.push(<Option key={'11111111111111111111111111111111'}>Not Involved</Option>);

                            dataResponse.forEach(x => {

                                arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                            });

                            // console.log(arr);

                            setParty1MyanmarSecurityForcesData(dataResponse);

                            setElParty1MyanmarSecurityForces(arr);

                            Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "party1");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let party1Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party1');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !party1Response || party1Response == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/party/one/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    arr.push(<Option key={'11111111111111111111111111111111'}>Not Involved</Option>);

                                    dataResponse.forEach(x => {

                                        arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                                    });

                                    // console.log(arr);

                                    setParty1MyanmarSecurityForcesData(dataResponse);

                                    setElParty1MyanmarSecurityForces(arr);

                                    Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseParty1 = await party1Response.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty1 || dataResponseParty1.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/party/one/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        arr.push(<Option key={'11111111111111111111111111111111'}>Not Involved</Option>);

                                        dataResponse.forEach(x => {

                                            arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                                        });

                                        // console.log(arr);

                                        setParty1MyanmarSecurityForcesData(dataResponse);

                                        setElParty1MyanmarSecurityForces(arr);

                                        Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseParty1 there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/party/one/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            arr.push(<Option key={'11111111111111111111111111111111'}>Not Involved</Option>);

                                            dataResponse.forEach(x => {

                                                arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                                            });

                                            // console.log(arr);

                                            setParty1MyanmarSecurityForcesData(dataResponse);

                                            setElParty1MyanmarSecurityForces(arr);

                                            Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/party/one/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                arr.push(<Option key={'11111111111111111111111111111111'}>Not Involved</Option>);

                                                dataResponse.forEach(x => {

                                                    arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                                                });

                                                // console.log(arr);

                                                setParty1MyanmarSecurityForcesData(dataResponse);

                                                setElParty1MyanmarSecurityForces(arr);

                                                Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        arr.push(<Option key={'11111111111111111111111111111111'}>Not Involved</Option>);

                                        dataResponseParty1.forEach(x => {

                                            arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                                        });

                                        // console.log(arr);

                                        setParty1MyanmarSecurityForcesData(dataResponseParty1);

                                        setElParty1MyanmarSecurityForces(arr);

                                        Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponseParty1);

                                        Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });


        // let arr = [];

        // let arrCache = await caches.keys();// get cache

        // // console.log(arrCache);

        // if(!arrCache.includes("party1")){

        //     await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         // console.log(dataResponse);

        //         dataResponse.forEach(x=>{

        //             arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

        //         });

        //         // console.log(arr);

        //         setParty1MyanmarSecurityForcesData(dataResponse);

        //         setElParty1MyanmarSecurityForces(arr);

        //         Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponse);//set data to cache


        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "party1"); //get where cache name

        //     // console.log(resultData);

        //       resultData.forEach(async cachename => {

        //         let cacheStorage = await caches.open(cachename);

        //         // console.log(cacheStorage);

        //         let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party1`);

        //         // console.log(cacheResponse);

        //         // console.log(dataResponse);

        //         if(!cacheResponse || cacheResponse == undefined){// if data in cache empty


        //             await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 // console.log(dataResponse);

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

        //                 });

        //                 // console.log(arr);

        //                 // console.log("Set party1");

        //                 setParty1MyanmarSecurityForcesData(dataResponse);

        //                 setElParty1MyanmarSecurityForces(arr);

        //                 Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponse);//set data to cache


        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });

        //         }else{ //if data in cache not empty

        //             let dataResponse = await cacheResponse.json();

        //             if(!dataResponse || dataResponse.length == 0){

        //                 await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(function(response){

        //                     let dataResponse = response.data;

        //                     // console.log(dataResponse);

        //                     dataResponse.forEach(x=>{

        //                         arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

        //                     });

        //                     // console.log(arr);

        //                     // console.log("Set party1");

        //                     setParty1MyanmarSecurityForcesData(dataResponse);

        //                     setElParty1MyanmarSecurityForces(arr);

        //                     Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponse);//set data to cache


        //                 })
        //                 .catch(function(error){

        //                     console.log(error);

        //                 });


        //             }else{


        //                 dataResponse.forEach(item=>{

        //                     arr.push(<Option key={Helper.hiddenChar(item.id)}>{item.name}</Option>);

        //                 });

        //                 setParty1MyanmarSecurityForcesData(dataResponse);

        //                 setElParty1MyanmarSecurityForces(arr);

        //             }

        //         }


        //       });

        // }



    }
    async function _loadParty2EstabilishedeaoData(cachenameparam) {

        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("party2")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/party/two/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                            dataResponse.forEach(x => {

                                arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                            });

                            // console.log(arr);

                            setParty2EstabilishedeaoData(dataResponse);

                            setElParty2Estabilishedeao(arr);

                            Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "party2");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let party2Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party2');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !party2Response || party2Response == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/party/two/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                    dataResponse.forEach(x => {

                                        arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                    });

                                    // console.log(arr);

                                    setParty2EstabilishedeaoData(dataResponse);

                                    setElParty2Estabilishedeao(arr);

                                    Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseParty2 = await party2Response.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty2 || dataResponseParty2.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/party/two/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                        dataResponse.forEach(x => {

                                            arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                        });

                                        // console.log(arr);

                                        setParty2EstabilishedeaoData(dataResponse);

                                        setElParty2Estabilishedeao(arr);

                                        Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseParty2 there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/party/two/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                            dataResponse.forEach(x => {

                                                arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                            });

                                            // console.log(arr);

                                            setParty2EstabilishedeaoData(dataResponse);

                                            setElParty2Estabilishedeao(arr);

                                            Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/party/two/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                                dataResponse.forEach(x => {

                                                    arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                                });

                                                // console.log(arr);

                                                setParty2EstabilishedeaoData(dataResponse);

                                                setElParty2Estabilishedeao(arr);

                                                Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                        dataResponseParty2.forEach(x => {

                                            arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                        });

                                        // console.log(arr);

                                        setParty2EstabilishedeaoData(dataResponseParty2);

                                        setElParty2Estabilishedeao(arr);

                                        Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponseParty2);

                                        Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });


        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("party2")){

        //     await axios.get(Uri.rootUri + `master/party/two/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         // console.log(dataResponse);

        //         dataResponse.forEach(x=>{

        //             arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

        //         });

        //         // console.log(arr);

        //         setParty2EstabilishedeaoData(dataResponse);

        //         setElParty2Estabilishedeao(arr);

        //         Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponse);//set data to cache



        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "party2"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //     //   console.log(cacheStorage);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party2`);

        //     //   console.log(cacheResponse);

        //     //   console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/party/two/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             // console.log(dataResponse);

        //             dataResponse.forEach(x=>{

        //                 arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

        //             });

        //             // console.log(arr);

        //             setParty2EstabilishedeaoData(dataResponse);

        //             setElParty2Estabilishedeao(arr);

        //             Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponse);//set data to cache



        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //       }else{ //if data in cache not empty

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/party/two/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 // console.log(dataResponse);

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

        //                 });

        //                 // console.log(arr);

        //                 setParty2EstabilishedeaoData(dataResponse);

        //                 setElParty2Estabilishedeao(arr);

        //                 Helper.addDataIntoCache("party2", Helper.getHostCurrentUrl() + `/party2`, dataResponse);//set data to cache



        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });


        //         }else{


        //             dataResponse.forEach(item=>{

        //                 arr.push(<Option key={Helper.hiddenChar(item.id)}>{item.name}</Option>);

        //             });

        //             setParty2EstabilishedeaoData(dataResponse);

        //             setElParty2Estabilishedeao(arr);


        //         }

        //       }


        //     });

        // }

    }

    async function _loadParty3CivillianSelfDefenceForcesData(cachenameparam) {

        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("party3")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                            dataResponse.forEach(x => {

                                arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                            });

                            // console.log(arr);

                            setParty3CivillianSelfDefenceForcesData(dataResponse);

                            setElParty3CivillianSelfDefenceForces(arr);

                            Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "party3");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let party3Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party3');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !party3Response || party3Response == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                    dataResponse.forEach(x => {

                                        arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                    });

                                    // console.log(arr);

                                    setParty3CivillianSelfDefenceForcesData(dataResponse);

                                    setElParty3CivillianSelfDefenceForces(arr);


                                    Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseParty3 = await party3Response.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty3 || dataResponseParty3.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                        dataResponse.forEach(x => {

                                            arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                        });

                                        // console.log(arr);

                                        setParty3CivillianSelfDefenceForcesData(dataResponse);

                                        setElParty3CivillianSelfDefenceForces(arr);


                                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseParty3 there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                            dataResponse.forEach(x => {

                                                arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                            });

                                            // console.log(arr);

                                            setParty3CivillianSelfDefenceForcesData(dataResponse);

                                            setElParty3CivillianSelfDefenceForces(arr);


                                            Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                                dataResponse.forEach(x => {

                                                    arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                                });

                                                // console.log(arr);

                                                setParty3CivillianSelfDefenceForcesData(dataResponse);

                                                setElParty3CivillianSelfDefenceForces(arr);


                                                Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {


                                        arr.push(<Option key={'11111111111111111111111111111111'} >Not Involved</Option>);

                                        dataResponseParty3.forEach(x => {

                                            arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                                        });

                                        // console.log(arr);

                                        setParty3CivillianSelfDefenceForcesData(dataResponseParty3);

                                        setElParty3CivillianSelfDefenceForces(arr);


                                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponseParty3);

                                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("party3")){

        //     await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         // console.log(dataResponse);

        //         dataResponse.forEach(x=>{

        //             arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

        //         });

        //         // console.log(arr);

        //         setParty3CivillianSelfDefenceForcesData(dataResponse);

        //         setElParty3CivillianSelfDefenceForces(arr);

        //         Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);//set data to cache


        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "party3"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party3`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             // console.log(dataResponse);

        //             dataResponse.forEach(x=>{

        //                 arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

        //             });

        //             // console.log(arr);

        //             setParty3CivillianSelfDefenceForcesData(dataResponse);

        //             setElParty3CivillianSelfDefenceForces(arr);

        //             Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);//set data to cache


        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //       }else{ //if data in cache not empty

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 // console.log(dataResponse);

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

        //                 });

        //                 // console.log(arr);

        //                 setParty3CivillianSelfDefenceForcesData(dataResponse);

        //                 setElParty3CivillianSelfDefenceForces(arr);

        //                 Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);//set data to cache


        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });

        //         }else{


        //             dataResponse.forEach(x=>{

        //                 arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

        //             });

        //             // console.log(arr);

        //             setParty3CivillianSelfDefenceForcesData(dataResponse);

        //             setElParty3CivillianSelfDefenceForces(arr);
        //         }

        //       }


        //     });

        // }

    }

    async function _loadParty4CivilliansData(cachenameparam) {

        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("party4")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/party/four/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                            });

                            setElParty4Civillians(arr);

                            setParty4CivilliansData(dataResponse);

                            Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "party4");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let party4Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party4');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !party4Response || party4Response == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/party/four/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                    });

                                    setElParty4Civillians(arr);

                                    setParty4CivilliansData(dataResponse);

                                    Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseParty4 = await party4Response.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty4 || dataResponseParty4.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/party/four/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                        });

                                        setElParty4Civillians(arr);

                                        setParty4CivilliansData(dataResponse);

                                        Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseParty4 there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/party/four/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            arr.push(<option value="11111111111111111111111111111111" >Not Involved</option>);

                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                            });

                                            setElParty4Civillians(arr);

                                            setParty4CivilliansData(dataResponse);

                                            Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/party/four/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                arr.push(<option value="11111111111111111111111111111111" >Not Involved</option>);

                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                                });

                                                setElParty4Civillians(arr);

                                                setParty4CivilliansData(dataResponse);

                                                Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        arr.push(<option value="11111111111111111111111111111111" >Not Involved</option>);

                                        dataResponseParty4.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                        });

                                        setElParty4Civillians(arr);

                                        setParty4CivilliansData(dataResponseParty4);

                                        Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponseParty4);

                                        Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });


        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("party4")){

        //     await axios.get(Uri.rootUri + `master/party/four/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){


        //         let dataResponse = response.data;

        //         // console.log(dataResponse);

        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //         });

        //         setElParty4Civillians(arr);

        //         setParty4CivilliansData(dataResponse)

        //         Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponse);//set data to cache


        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "party4"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party4`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/party/four/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){


        //             let dataResponse = response.data;

        //             // console.log(dataResponse);

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //             });

        //             setElParty4Civillians(arr);

        //             setParty4CivilliansData(dataResponse);

        //             Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponse);//set data to cache


        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //       }else{ //if data in cache not empty



        //             let dataResponse = await cacheResponse.json();

        //             if(!dataResponse || dataResponse.length == 0){

        //                 await axios.get(Uri.rootUri + `master/party/four/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(function(response){


        //                     let dataResponse = response.data;

        //                     // console.log(dataResponse);

        //                     dataResponse.forEach(x=>{

        //                         arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                     });

        //                     setElParty4Civillians(arr);

        //                     setParty4CivilliansData(dataResponse);

        //                     Helper.addDataIntoCache("party4", Helper.getHostCurrentUrl() + `/party4`, dataResponse);//set data to cache


        //                 })
        //                 .catch(function(error){

        //                     console.log(error);

        //                 });

        //             }else{

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                 });

        //                 setElParty4Civillians(arr);

        //                 setParty4CivilliansData(dataResponse);
        //             }


        //       }

        //     });

        // }


    }

    async function _loadParty5OthersData(cachenameparam) {

        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("party5")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                            });

                            setElParty5Others(arr);

                            setParty5OthersData(dataResponse);

                            Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "party5");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let party5Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party5');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !party5Response || party5Response == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                    });

                                    setElParty5Others(arr);

                                    setParty5OthersData(dataResponse);

                                    Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseParty5 = await party5Response.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty5 || dataResponseParty5.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                        });

                                        setElParty5Others(arr);

                                        setParty5OthersData(dataResponse);

                                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseParty5 there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                            });

                                            setElParty5Others(arr);

                                            setParty5OthersData(dataResponse);

                                            Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/party/five/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                                });

                                                setElParty5Others(arr);

                                                setParty5OthersData(dataResponse);

                                                Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        arr.push(<option value="11111111111111111111111111111111">Not Involved</option>);

                                        dataResponseParty5.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                        });

                                        setElParty5Others(arr);

                                        setParty5OthersData(dataResponseParty5);

                                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponseParty5);

                                        Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });


        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("party5")){

        //     await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         // console.log(dataResponse);

        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //         });

        //         setElParty5Others(arr);

        //         setParty5OthersData(dataResponse);

        //         Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);//set data to cache


        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "party5"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //     //   console.log(cacheStorage);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party5`);

        //     //   console.log(cacheResponse);

        //     //   console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             // console.log(dataResponse);

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //             });

        //             setElParty5Others(arr);

        //             setParty5OthersData(dataResponse);

        //             Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);//set data to cache


        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //       }else{ //if data in cache not empty

        //             let dataResponse = await cacheResponse.json();

        //             if(!dataResponse || dataResponse.length == 0){

        //                 await axios.get(Uri.rootUri + `master/party/five/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(function(response){

        //                     let dataResponse = response.data;

        //                     // console.log(dataResponse);

        //                     dataResponse.forEach(x=>{

        //                         arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                     });

        //                     setElParty5Others(arr);

        //                     setParty5OthersData(dataResponse);

        //                     Helper.addDataIntoCache("party5", Helper.getHostCurrentUrl() + `/party5`, dataResponse);//set data to cache


        //                 })
        //                 .catch(function(error){

        //                     console.log(error);

        //                 });


        //             }else{

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                 });

        //                 setElParty5Others(arr);

        //                 setParty5OthersData(dataResponse);
        //             }

        //       }

        //     });

        // }

    }

    async function _loadGeoPrecisionData(cachenameparam) {

        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("geoprec")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            dataResponse.sort((a, b) => { //ascending sort geo prec

                                return a.code - b.code;

                            });

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

                            });

                            setGeoPrecisionData(dataResponse);

                            setElGeoPrecision(arr);

                            Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "geoprec");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let geoprecResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/geoprec');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !geoprecResponse || geoprecResponse == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    dataResponse.sort((a, b) => { //ascending sort geo prec

                                        return a.code - b.code;

                                    });

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

                                    });

                                    setGeoPrecisionData(dataResponse);

                                    setElGeoPrecision(arr);

                                    Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseGeoprec = await geoprecResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseGeoprec || dataResponseGeoprec.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        dataResponse.sort((a, b) => { //ascending sort geo prec

                                            return a.code - b.code;

                                        });

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

                                        });

                                        setGeoPrecisionData(dataResponse);

                                        setElGeoPrecision(arr);

                                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseGeoprec there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            dataResponse.sort((a, b) => { //ascending sort geo prec

                                                return a.code - b.code;

                                            });

                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

                                            });

                                            setGeoPrecisionData(dataResponse);

                                            setElGeoPrecision(arr);

                                            Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/geoprec/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                dataResponse.sort((a, b) => { //ascending sort geo prec

                                                    return a.code - b.code;

                                                });

                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

                                                });

                                                setGeoPrecisionData(dataResponse);

                                                setElGeoPrecision(arr);

                                                Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        dataResponseGeoprec.sort((a, b) => { //ascending sort geo prec

                                            return a.code - b.code;

                                        });

                                        dataResponseGeoprec.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

                                        });

                                        setGeoPrecisionData(dataResponseGeoprec);

                                        setElGeoPrecision(arr);

                                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponseGeoprec);

                                        Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arr = [];

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("geoprec")){

        //     await axios.get(Uri.rootUri + `master/geoprec/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){


        //         let dataResponse = response.data;

        //         // console.log(dataResponse);

        //         dataResponse.sort((a, b) => { //ascending sort geo prec

        //             return a.code - b.code;

        //         });

        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

        //         });

        //         setGeoPrecisionData(dataResponse);

        //         setElGeoPrecision(arr);

        //         Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);//set data to cache



        //     })
        //     .catch(function(error){

        //         console.log(error)

        //     });

        // }else{


        //     let resultData = arrCache.filter( x => x == "geoprec"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/geoprec`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/geoprec/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){


        //             let dataResponse = response.data;

        //             // console.log(dataResponse);

        //             dataResponse.sort((a, b) => { //ascending sort geo prec

        //                 return a.code - b.code;

        //             });

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

        //             });

        //             setGeoPrecisionData(dataResponse);

        //             setElGeoPrecision(arr);

        //             Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);//set data to cache



        //         })
        //         .catch(function(error){

        //             console.log(error)

        //         });

        //       }else{ //if data in cache not empty

        //             let dataResponse = await cacheResponse.json();

        //             if(!dataResponse || dataResponse.length == 0){

        //                 await axios.get(Uri.rootUri + `master/geoprec/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(function(response){


        //                     let dataResponse = response.data;

        //                     // console.log(dataResponse);

        //                     dataResponse.sort((a, b) => { //ascending sort geo prec

        //                         return a.code - b.code;

        //                     });

        //                     dataResponse.forEach(x=>{

        //                         arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

        //                     });

        //                     setGeoPrecisionData(dataResponse);

        //                     setElGeoPrecision(arr);

        //                     Helper.addDataIntoCache("geoprec", Helper.getHostCurrentUrl() + `/geoprec`, dataResponse);//set data to cache



        //                 })
        //                 .catch(function(error){

        //                     console.log(error)

        //                 });

        //             }else{

        //                 dataResponse.sort((a, b) => { //ascending sort geo prec

        //                     return a.code - b.code;

        //                 });

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<option value={Helper.hiddenChar(x.id)} >{x.code} - {x.name}</option>);

        //                 });

        //                 setGeoPrecisionData(dataResponse);

        //                 setElGeoPrecision(arr);
        //             }


        //       }

        //     });

        // }

    }

    async function _loadTargetAssetsData(cachenameparam) {


        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("targetassets")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                            });

                            setTargetAssetsData(dataResponse);

                            setElTargetAssets(arr);

                            Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "targetassets");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let targetAssetsResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/targetassets');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !targetAssetsResponse || targetAssetsResponse == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                    });

                                    setTargetAssetsData(dataResponse);

                                    setElTargetAssets(arr);

                                    Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseTargetAssets = await targetAssetsResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseTargetAssets || dataResponseTargetAssets.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                        });

                                        setTargetAssetsData(dataResponse);

                                        setElTargetAssets(arr);

                                        Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseTargetAssets there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                            });

                                            setTargetAssetsData(dataResponse);

                                            setElTargetAssets(arr);

                                            Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                                });

                                                setTargetAssetsData(dataResponse);

                                                setElTargetAssets(arr);

                                                Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        arr.push(<option value={'22222222222222222222222222222222'}>NA</option>);

                                        dataResponseTargetAssets.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                                        });

                                        setTargetAssetsData(dataResponseTargetAssets);

                                        setElTargetAssets(arr);

                                        Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponseTargetAssets);

                                        Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("targetassets")){

        //     await axios.get(Uri.rootUri + `master/target/facility/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         // console.log(dataResponse);

        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //         });

        //         setTargetAssetsData(dataResponse);

        //         setElTargetAssets(arr);

        //         Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);//set data to cache


        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "targetassets"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/targetassets`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/target/facility/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             // console.log(dataResponse);

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //             });

        //             setTargetAssetsData(dataResponse);

        //             setElTargetAssets(arr);

        //             Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);//set data to cache


        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //       }else{ //if data in cache not empty

        //             let dataResponse = await cacheResponse.json();

        //             if(!dataResponse || dataResponse.length == 0){

        //                 await axios.get(Uri.rootUri + `master/target/facility/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(function(response){

        //                     let dataResponse = response.data;

        //                     // console.log(dataResponse);

        //                     dataResponse.forEach(x=>{

        //                         arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                     });

        //                     setTargetAssetsData(dataResponse);

        //                     setElTargetAssets(arr);

        //                     Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);//set data to cache


        //                 })
        //                 .catch(function(error){

        //                     console.log(error);

        //                 });

        //             }else{

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                 });

        //                 setTargetAssetsData(dataResponse);

        //                 setElTargetAssets(arr);
        //             }

        //       }

        //     });

        // }


    }

    async function _loadStateRegionData(cachenameparam) {

        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("region")) {// if empty cache name in browser

                    //   console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            let dataResponse = response.data;

                            dataResponse.sort((a, b) => { //ascending sort state region

                                let fa = a.name.toLowerCase();
                                let fb = b.name.toLowerCase();

                                if (fa < fb) {
                                    return -1;
                                }

                                if (fa > fb) {
                                    return 1;
                                }

                                return 0;

                            });

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                            });

                            setElStateRegion(arr);

                            setStateRegionData(dataResponse);

                            Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);



                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                // console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        // console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "region");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let regionResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/region');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !regionResponse || regionResponse == undefined) {// check data valid or no valid

                            //   console.log("set version into cache");

                            await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    let dataResponse = response.data;

                                    dataResponse.sort((a, b) => { //ascending sort state region

                                        let fa = a.name.toLowerCase();
                                        let fb = b.name.toLowerCase();

                                        if (fa < fb) {
                                            return -1;
                                        }

                                        if (fa > fb) {
                                            return 1;
                                        }

                                        return 0;

                                    });

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                                    });

                                    setElStateRegion(arr);

                                    setStateRegionData(dataResponse);

                                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);



                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // console.log("set version");

                                // if data cache in database not empty
                                Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }


                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseRegion = await regionResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseRegion || dataResponseRegion.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        let dataResponse = response.data;

                                        dataResponse.sort((a, b) => { //ascending sort state region

                                            let fa = a.name.toLowerCase();
                                            let fb = b.name.toLowerCase();

                                            if (fa < fb) {
                                                return -1;
                                            }

                                            if (fa > fb) {
                                                return 1;
                                            }

                                            return 0;

                                        });

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                                        });

                                        setElStateRegion(arr);

                                        setStateRegionData(dataResponse);

                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);



                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            //   console.log(response);


                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //   console.log("set version");

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseRegion there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            let dataResponse = response.data;

                                            dataResponse.sort((a, b) => { //ascending sort state region

                                                let fa = a.name.toLowerCase();
                                                let fb = b.name.toLowerCase();

                                                if (fa < fb) {
                                                    return -1;
                                                }

                                                if (fa > fb) {
                                                    return 1;
                                                }

                                                return 0;

                                            });

                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                                            });

                                            setElStateRegion(arr);

                                            setStateRegionData(dataResponse);

                                            Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);



                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            //   console.log(response);


                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        //   console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                let dataResponse = response.data;

                                                dataResponse.sort((a, b) => { //ascending sort state region

                                                    let fa = a.name.toLowerCase();
                                                    let fb = b.name.toLowerCase();

                                                    if (fa < fb) {
                                                        return -1;
                                                    }

                                                    if (fa > fb) {
                                                        return 1;
                                                    }

                                                    return 0;

                                                });

                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                                                });

                                                setElStateRegion(arr);

                                                setStateRegionData(dataResponse);

                                                Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);



                                            });

                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        // console.log("data incident and version available in cache");
                                        dataResponseRegion.sort((a, b) => { //ascending sort state region

                                            let fa = a.name.toLowerCase();
                                            let fb = b.name.toLowerCase();

                                            if (fa < fb) {
                                                return -1;
                                            }

                                            if (fa > fb) {
                                                return 1;
                                            }

                                            return 0;

                                        });

                                        dataResponseRegion.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                        })

                                        setElStateRegion(arr);

                                        setStateRegionData(dataResponseRegion);

                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponseRegion);

                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arr = [];

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("region")){

        //     await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} }) //get region
        //     .then(async function(response){

        //         let townshipData ;

        //         let dataResponse = response.data;

        //         dataResponse.sort((a, b) => { //ascending sort state region

        //             let fa = a.name.toLowerCase();
        //             let fb = b.name.toLowerCase();

        //             if (fa < fb) {
        //                 return -1;
        //             }

        //             if (fa > fb) {
        //                 return 1;
        //             }

        //             return 0;

        //         });

        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //         });

        //         setStateRegionData(dataResponse);

        //         setElStateRegion(arr);

        //         Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);//set data to cache

        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{


        //     let resultData = arrCache.filter( x => x == "region"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/region`);
        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} }) //get region
        //         .then(async function(response){

        //             let townshipData ;

        //             let dataResponse = response.data;

        //             dataResponse.sort((a, b) => { //ascending sort state region

        //                 let fa = a.name.toLowerCase();
        //                 let fb = b.name.toLowerCase();

        //                 if (fa < fb) {
        //                     return -1;
        //                 }

        //                 if (fa > fb) {
        //                     return 1;
        //                 }

        //                 return 0;

        //             });

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //             });

        //             setStateRegionData(dataResponse);

        //             setElStateRegion(arr);

        //             Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);//set data to cache

        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //       }else{ //if data in cache not empty


        //             let dataResponse = await cacheResponse.json();

        //             if(!dataResponse || dataResponse.length == 0){

        //                 await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} }) //get region
        //                 .then(async function(response){

        //                     let townshipData ;

        //                     let dataResponse = response.data;

        //                     dataResponse.sort((a, b) => { //ascending sort state region

        //                         let fa = a.name.toLowerCase();
        //                         let fb = b.name.toLowerCase();

        //                         if (fa < fb) {
        //                             return -1;
        //                         }

        //                         if (fa > fb) {
        //                             return 1;
        //                         }

        //                         return 0;

        //                     });

        //                     dataResponse.forEach(x=>{

        //                         arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                     });

        //                     setStateRegionData(dataResponse);

        //                     setElStateRegion(arr);

        //                     Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);//set data to cache

        //                 })
        //                 .catch(function(error){

        //                     console.log(error);

        //                 });


        //             }else{

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        //                 });

        //                 setStateRegionData(dataResponse);

        //                 setElStateRegion(arr);

        //             }

        //       }

        //     });

        // }

    }



    async function _loadSubTypeOfIncidentData(id) {

        if (id) {

            await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                    let arr = [];



                    let dataResponse = response.data;

                    // console.log(dataResponse);

                    let result = dataResponse.filter(x => x.incident_id == Helper.hiddenChar(id));

                    result.forEach(x => {

                        arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                    });

                    setElSubTypeIncident(arr);

                })
                .catch(function (error) {

                    console.log(error);



                })

        } else {


            setElSubTypeIncident([]);

        }

    }

    function _back(e) {

        e.preventDefault();

        window.close();

    }

    function _handleAutoGenerateDescription(e) {
        let params = {
            incidentDate: incidentDate,
            township: townshipData.find((item) => Helper.hiddenChar(item.id) == township)?.name,
            stateRegion: stateRegionData.find((item) => Helper.hiddenChar(item.id) == stateRegion)?.name,
        }

        if (time) params = { ...params, time: time.slice(0, 5) }
        if (town) params = { ...params, town: town }
        if (villageTract) params = { ...params, village: villageTract }
        let resultDescription = Helper.autoGenerateDescription(params);

        setIncidentDescription(resultDescription);
    }

    async function _loadUserInformation() {

        const userInformation = await Helper.getUserLogin();

        if (userInformation) {

            setUserId(userInformation.user_id);

            setEmailAddress(userInformation.email_address);

            setAuthId(userInformation.auth_id);

            setCompanyId(userInformation.company_id);

            setUsername(userInformation.username);

            let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));

            setPermissions(strPermissions);

            if (!strPermissions.includes(editIncident) && !strPermissions.includes(approveIncident) && !strPermissions.includes(readOnly)) {// if not Permission Type Edit Incident

                // history.push("/Unauthorize");

                window.location.replace(`${window.location.origin}/Index/Unauthorize`);

                // window.location.replace("http://localhost:3000/Index/Unauthorize");

            }

        }

    }

    const cacheNames = [
        {
            name: "incident",
            pathUri: "master/incident/",
            setMasterData: setTypeOfIncidentData,
            setElMasterData: setElTypeOfIncident
        },
        {
            name: "region",
            pathUri: "master/region/",
            setMasterData: setStateRegionData,
            setElMasterData: setElStateRegion
        },
        {
            name: "party1",
            pathUri: "master/party/one/",
            setMasterData: setParty1MyanmarSecurityForcesData,
            setElMasterData: setElParty1MyanmarSecurityForces
        },
        {
            name: "party2",
            pathUri: "master/party/two/",
            setMasterData: setParty2EstabilishedeaoData,
            setElMasterData: setElParty2Estabilishedeao
        },
        {
            name: "party3",
            pathUri: "master/party/three/",
            setMasterData: setParty3CivillianSelfDefenceForcesData,
            setElMasterData: setElParty3CivillianSelfDefenceForces
        },
        {
            name: "party4",
            pathUri: "master/party/four/",
            setMasterData: setParty4CivilliansData,
            setElMasterData: setElParty4Civillians
        },
        {
            name: "party5",
            pathUri: "master/party/five/",
            setMasterData: setParty5OthersData,
            setElMasterData: setElParty5Others
        },
        {
            name: "geoprec",
            pathUri: "master/geoprec/",
            setMasterData: setGeoPrecisionData,
            setElMasterData: setElGeoPrecision
        },
        {
            name: "targetassets",
            pathUri: "master/target/facility/",
            setMasterData: setTargetAssetsData,
            setElMasterData: setElTargetAssets
        },
        {
            name: "targethuman",
            pathUri: "master/target/human/",
            setMasterData: setTargetHumanData,
            setElMasterData: setElTargetHuman
        },
    ];
    useEffect(() => {      
      const effect = async () => {
      // ajax call pending set loading
      // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadUserInformation();

        await _loadData(incidentSubmissionId);
        await _loadCache(cacheNames);

        setLoading(false);
      }
      effect();
    }, []);

    /**
      * Function that handles the loading of map data.
      *
      * @param {Object} mapData - The map data object.
      */
    const onMapLoaded = async (mapData) => {
      try {
        if (!gridData) {
          setToggleLoading(true);
    
          const gridDataUrl = "%PUBLIC_URL%/mmr_polbnda_adm3_250k_mimu_1.json";
          const cacheStorage = await caches.open("polygon");
          
          let jsonData = null;
          const cachedData = await cacheStorage.match(gridDataUrl);
    
          // Check if data is in cache
          if (cachedData) {
            jsonData = await cachedData.json();
          } else {
            // Fetch data if not in cache
            const response = await fetch(gridDataUrl);
            await cacheStorage.put(gridDataUrl, response);
            jsonData = await (await cacheStorage.match(gridDataUrl)).json();
          }
    
          setGridData(jsonData);
          mapData.addGeoJson(jsonData);
          setToggleLoading(false);
        } else {
          mapData.addGeoJson(gridData);
        }
      } catch (error) {
        console.error('Error: failed on loading map grid', error);
        setToggleLoading(false);
      }
    };
    

    return (

        <Spin size='large' spinning={loading} >
            <div className='addNewDraft' >
                {/* row */}
                <div className="row mb-3 align-items-center">
                    {incidentSubmissionId ?
                        <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 ">
                            <button type="submit" className="btn btn-primary mx-1" onClick={_back}><i className="bi bi-arrow-left-circle-fill"></i> Back</button>
                        </div>

                        :

                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 ">

                        </div>
                    }
                    <div className="col-lg-2 col-md-2 col-sm-2 col-2 ">
                        {incidentId &&
                            <>
                                <h6 className='m-0 text-end'>Incident ID : {number} </h6>
                                <input type="hidden" id='incidentid' value={incidentId} onChange={_incidentId} />
                            </>
                        }
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                        {lastUpdatedOn &&
                            <>
                                <h6 className='m-0 text-end'>Last Updated On : {lastUpdatedOn} </h6>
                                <input type="hidden" id='lastupdatedon' value={lastUpdatedOn} onChange={_lastUpdatedOn} />
                            </>
                        }
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-2 col-2">
                        <h6 className='m-0 text-end'>Drafted By : {draftedBy} </h6>
                        <input type="hidden" id='draftedby' value={draftedBy} onChange={_draftedBy} />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                        <h6 className='m-0 text-end'>Status :  <Status statusIncident={statusIncident} />
                        </h6>
                        <input type="hidden" id='status' value={statusIncident} onChange={_statusIncident} />
                    </div>
                </div>
                {/* end row */}

                {/* row 2 */}
                <div className="row mb-3">

                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                Incident Headers
                            </div>

                            <div className="card-body">

                                <div className="row mb-3">
                                    <div className="col-lg-12">
                                        <div className="form-check">
                                            {highPriority == "Y" &&

                                                <input className="form-check-input disabled" type="checkbox" id="highpriority" name="highpriority" onChange={_highPriority} checked disabled={!permissions.includes(editIncident) ? true : false} />

                                            }
                                            {highPriority == "N" &&

                                                <input className="form-check-input" type="checkbox" id="highpriority" name="highpriority" onChange={_highPriority} disabled={!permissions.includes(editIncident) ? true : false} />

                                            }
                                            {highPriority === undefined &&

                                                <input className="form-check-input" type="checkbox" id="highpriority" name="highpriority" onChange={_highPriority} disabled={!permissions.includes(editIncident) ? true : false} />

                                            }

                                            <label className="form-check-label" for="highpriority">
                                                High Priority (Y/N)
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* row */}
                                <div className="row">

                                    <div className="col-lg-6">

                                        <div className='mb-3'>
                                            <label htmlFor='incidentdate ' className='m-1 d-flex align-items-start'>Incident Date:  <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !incidentDate ?

                                                <div id="incidentdate" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please insert Incident Date.
                                                </div>
                                                :
                                                <></>
                                            }

                                            {/* <input type="date" className='form-control' id="incidentdate" onChange={_incidentDate} required value={incidentDate ? incidentDate : ""} disabled={!permissions.includes(editIncident) ? true : false} /> */}
                                            <DatePicker id="incidentdate" value={incidentDate ? incidentDate : ""} onChange={_incidentDate} required disabled={!permissions.includes(editIncident) ? true : false}  />
                                        </div>

                                        <div className='mb-3'>
                                            <label htmlFor='typeofincident ' className='m-1 d-flex align-items-start'>Incident Category <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !typeOfIncident ?

                                                <div id="typeofincident" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Incident category.
                                                </div>
                                                :
                                                <></>
                                            }
                                            <select className="form-select" aria-label="Default select example" onChange={_typeOfIncident} required value={typeOfIncident ? typeOfIncident : ""} disabled={!permissions.includes(editIncident) ? true : false} >
                                                <option value="" selected></option>
                                                {elTypeOfIncident}
                                            </select>
                                        </div>

                                        <div className='mb-3'>
                                            <label htmlFor='typeofincident ' className='m-1 d-flex align-items-start'>Number of Occurences:</label>
                                            <input type="number" className="form-control" id='numberofoccurences' onChange={_numberOfOccurences} value={numberOfOccurences ? numberOfOccurences : ""} disabled={!permissions.includes(editIncident) ? true : false} />
                                        </div>

                                        <div className='mb-3'>
                                            <label htmlFor='party2estabilishedeao ' className='m-1 d-flex align-items-start'>Party 2 - Established EAO<i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !party2Estabilishedeao ?

                                                <div id="party2estalabilishedeao" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Party 2 - Established EAO.
                                                </div>
                                                :
                                                <></>
                                            }

                                            {/* <select className="form-select" aria-label="Default select example" onChange={_party2Estabilishedeao} required value={party2Estabilishedeao ? party2Estabilishedeao : "1"}>
                                                        <option value="1" selected>Not Involved</option>
                                                        {elParty2Estabilishedeao}
                                                    </select> */}
                                            <Select
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                onChange={_party2Estabilishedeao}
                                                value={party2Estabilishedeao ? party2Estabilishedeao : '11111111111111111111111111111111'}
                                                size="large"
                                                required
                                                disabled={!permissions.includes(editIncident) ? true : false}
                                                filterOption={(input, option) =>
                                                    // console.log(option, input)
                                                    // console.log(option.children.toLowerCase())
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >

                                                {elParty2Estabilishedeao}

                                            </Select>

                                        </div>

                                        <div className='mb-3'>
                                            <label htmlFor='party4civillians ' className='m-1 d-flex align-items-start'>Party 4 - Civillians<i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !party4Civillians ?

                                                <div id="party4civillians" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Party 4 - Civillians.
                                                </div>
                                                :
                                                <></>
                                            }
                                            {/* <Select
                                                        mode='tags'
                                                        style={{width:'100%'}}
                                                        onChange={_party4Civillians}
                                                        value={party4Civillians && party4Civillians}
                                                        size="large"
                                                        required
                                                    >
                                                        {elParty4Civillians}
                                                    </Select> */}

                                            <select className="form-select" aria-label="Default select example" onChange={_party4Civillians} required value={party4Civillians ? party4Civillians : "11111111111111111111111111111111"} disabled={!permissions.includes(editIncident) ? true : false}>

                                                {elParty4Civillians}

                                            </select>
                                        </div>

                                        <div className='mb-3'>
                                            <label htmlFor='targethuman ' className='m-1 d-flex align-items-start'>Target (Human): <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !targetHuman ?

                                                <div id="targethuman" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Target (Human).
                                                </div>
                                                :
                                                <></>
                                            }
                                            <select className="form-select" aria-label="Default select example" onChange={_targetHuman} required value={targetHuman ? targetHuman : "22222222222222222222222222222222"} disabled={!permissions.includes(editIncident) ? true : false}>
                                                {elTargetHuman}
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-check">
                                                {artillerySupport == "Y" &&

                                                    <input className="form-check-input" type="checkbox" id="artillerysupport" name="artillerysupport" onChange={_artillerySupport} checked disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                {artillerySupport == "N" &&

                                                    <input className="form-check-input" type="checkbox" id="artillerysupport" name="artillerysupport" onChange={_artillerySupport} disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                {artillerySupport == undefined &&

                                                    <input className="form-check-input" type="checkbox" id="artillerysupport" name="artillerysupport" onChange={_artillerySupport} disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                <label className="form-check-label" for="artillerysupport">
                                                    Artillery Support (Y/N)
                                                </label>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-check">
                                                {airSupport == "Y" &&

                                                    <input className="form-check-input" type="checkbox" id="airsupport" name="airsupport" onChange={_airSupport} checked disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                {airSupport == "N" &&

                                                    <input className="form-check-input" type="checkbox" id="airsupport" name="airsupport" onChange={_airSupport} disabled={!permissions.includes(editIncident) ? true : false} />

                                                }

                                                {airSupport == undefined &&

                                                    <input className="form-check-input" type="checkbox" id="airsupport" name="airsupport" onChange={_airSupport} disabled={!permissions.includes(editIncident) ? true : false} />

                                                }

                                                <label className="form-check-label" for="airsupport">
                                                    Air Support (Y/N)
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    {/* end col */}
                                    <div className="col-lg-6">

                                        <div className="mb-3">
                                            <label htmlFor='time ' className='m-1 d-flex align-items-start'>Time: </label>
                                            {/* <input type="time" className='form-control' id='time' onChange={_time} value={time && time} disabled={ !permissions.includes(editIncident) ? true : false }/> */}
                                            <TimePicker defaultValue={moment('00:00', format)} format={format} size="large" id='time' onChange={_time} value={time && moment(time, format)} disabled={!permissions.includes(editIncident) ? true : false} style={{ width: "100%" }} />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor='subtypeofincident ' className='m-1 d-flex align-items-start'>Sub-Type of Incident <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !subTypeOfIncident ?

                                                <div id="subtypeofincident" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Sub-Type of Incident.
                                                </div>
                                                :
                                                <></>
                                            }
                                            <Select
                                                showSearch
                                                style={{ width: "100%" }}
                                                onChange={_subTypeOfIncident}
                                                value={subTypeOfIncident ? subTypeOfIncident : ""}
                                                filterOption={(input, option) =>
                                                    // console.log(option, input)
                                                    // console.log(option.children.toLowerCase())
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                disabled={!permissions.includes(editIncident) ? true : false}
                                                size={'large'}
                                            >

                                                {elSubTypeIncident}

                                            </Select>
                                            {/* <select className="form-select" aria-label="Default select example" onChange={_subTypeOfIncident} required value={subTypeOfIncident ? subTypeOfIncident : ""} disabled={ !permissions.includes(editIncident) ? true : false }>
                                                        <option value="" selected></option>
                                                        {elSubTypeIncident}
                                                        <SubTypeOfIncident
                                                            typeofincidentkey = {typeOfIncident ? typeOfIncident : 0}
                                                        />
                                                            // subtypeofincidentkey = {subTypeOfIncident ? subTypeOfIncident : 0}
                                                            // createdby = {createdBy ? createdBy : ""}
                                                    </select> */}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor='party1myanmarsecurityforces' className='m-1 d-flex align-items-start'>Party 1 - Myanmar Security Forces: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !party1MyanmarSecurityForces ?

                                                <div id="party1myanmarsecurityforces" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Party 1 - Myanmar Security Forces.
                                                </div>
                                                :
                                                <></>
                                            }
                                            <Select
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                onChange={_party1MyanmarSecurityForces}
                                                value={party1MyanmarSecurityForces ? party1MyanmarSecurityForces : '11111111111111111111111111111111'}
                                                size="large"
                                                required
                                                disabled={!permissions.includes(editIncident) ? true : false}
                                                filterOption={(input, option) =>
                                                    // console.log(option, input)
                                                    // console.log(option.children.toLowerCase())
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >

                                                {elParty1MyanmarSecurityForces}

                                            </Select>

                                            {/* <select className="form-select" aria-label="Default select example" onChange={_party1MyanmarSecurityForces} required value={party1MyanmarSecurityForces ? party1MyanmarSecurityForces : "1"}>

                                                        <option value="1" selected>Not Involved</option>
                                                        {elParty1MyanmarSecurityForces}
                                                    </select> */}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor='party3civillianselfdefenceforces' className='m-1 d-flex align-items-start'>Party 3 - Civillian Self-Defence Forces: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !party3CivillianSelfDefenceForces ?

                                                <div id="party3vivillianselfdefenceforces" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Party 3 - Civillian Self-Defence Forces.
                                                </div>
                                                :
                                                <></>
                                            }
                                            <Select
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                onChange={_party3CivillianSelfDefenceForces}
                                                value={party3CivillianSelfDefenceForces ? party3CivillianSelfDefenceForces : '11111111111111111111111111111111'}
                                                size="large"
                                                required
                                                disabled={!permissions.includes(editIncident) ? true : false}
                                                filterOption={(input, option) =>
                                                    // console.log(option, input)
                                                    // console.log(option.children.toLowerCase())
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >

                                                {elParty3CivillianSelfDefenceForces}

                                            </Select>
                                            {/* <select className="form-select" aria-label="Default select example" onChange={_party3CivillianSelfDefenceForces} required value={party3CivillianSelfDefenceForces ? party3CivillianSelfDefenceForces : "1"}>
                                                        <option value="1" selected >Not Involved</option>
                                                        {elParty3CivillianSelfDefenceForces}
                                                    </select> */}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor='party5others' className='m-1 d-flex align-items-start'>Party 5 - Others: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !party5Others ?

                                                <div id="party5others" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Party 5 - Others.
                                                </div>
                                                :
                                                <></>
                                            }
                                            {/* <Select
                                                        mode='tags'
                                                        style={{width:'100%'}}
                                                        onChange={_party5Others}
                                                        value={party5Others && party5Others}
                                                        size="large"
                                                        required
                                                    >
                                                        {elParty5Others}
                                                    </Select> */}
                                            <select className="form-select" aria-label="Default select example" onChange={_party5Others} required value={party5Others ? party5Others : "11111111111111111111111111111111"} disabled={!permissions.includes(editIncident) ? true : false} >

                                                {elParty5Others}

                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor='target' className='m-1 d-flex align-items-start'>Target (Assets): <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !targetAssets ?

                                                <div id="target" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Target (Assets).
                                                </div>
                                                :
                                                <></>
                                            }
                                            <select className="form-select" aria-label="Default select example" onChange={_targetAssets} required value={targetAssets ? targetAssets : "22222222222222222222222222222222"} disabled={!permissions.includes(editIncident) ? true : false} >
                                                {elTargetAssets}
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-check">

                                                {claimedByNug == "Y" &&

                                                    <input className="form-check-input" type="checkbox" id="claimedbynug" onChange={_claimedByNug} required checked disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                {claimedByNug == "N" &&

                                                    <input className="form-check-input" type="checkbox" id="claimedbynug" onChange={_claimedByNug} required disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                {claimedByNug == undefined &&

                                                    <input className="form-check-input" type="checkbox" id="claimedbynug" onChange={_claimedByNug} required disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                <label className="form-check-label d-flex align-items-start" for="claimedbynug">
                                                    Claimed by NUG (Y/N)
                                                </label>
                                            </div>
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-check">
                                                {tacticaldevelopment == "Y" &&

                                                    <input className="form-check-input" type="checkbox" id="tacticaldevelopment" onChange={_tacticalDevelopment} checked disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                {tacticaldevelopment == "N" &&

                                                    <input className="form-check-input" type="checkbox" id="tacticaldevelopment" onChange={_tacticalDevelopment} disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                {tacticaldevelopment == undefined &&

                                                    <input className="form-check-input" type="checkbox" id="tacticaldevelopment" onChange={_tacticalDevelopment} disabled={!permissions.includes(editIncident) ? true : false} />

                                                }
                                                <label className="form-check-label d-flex align-items-start" for="tacticaldevelopment">
                                                    Tactical Development (Y/N)
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {/* end row */}

                            </div>
                        </div>
                    </div>
                </div>
                {/* end row 2 */}
                {/* row 3 */}
                <div className="row mb-3">

                    <div className="col-lg-4 mb-3">
                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                Ordinary Civilian Toll
                            </div>
                            <div className="card-body">
                                <div className='mb-3'>
                                    <label className='m-1'>Killed: </label>
                                    <input type="number" className="form-control" onChange={_killedOct} value={killedOct ? killedOct : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Injured: </label>
                                    <input type="number" className="form-control" onChange={_injuredOct} value={injuredOct ? injuredOct : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Arrested: </label>
                                    <input type="number" className="form-control" onChange={_arrestedOct}
                                        value={arrestedOct ? arrestedOct : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Civil Administrator Resignations: </label>
                                    <input type="number" className="form-control" onChange={_civilAdministratorResignationsOct} value={civilAdministratorResignationsOct ? civilAdministratorResignationsOct : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 mb-3">
                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                Pro-SAC Civilian Toll
                            </div>
                            <div className="card-body">
                                <div className='mb-3'>
                                    <label className='m-1'>Killed: </label>
                                    <input type="number" className="form-control" onChange={_killedPct} value={killedPct ? killedPct : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Injured: </label>
                                    <input type="number" className="form-control" onChange={_injuredPct} value={injuredPct ? injuredPct : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Abducted: </label>
                                    <input type="number" className="form-control" onChange={_abductedPct} value={abductedPct ? abductedPct : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 mb-3">
                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                Civilian Toll by Civilian Pro-SAC Group
                            </div>
                            <div className="card-body">
                                <div className='mb-3'>
                                    <p className='m-1'>Killed: </p>
                                    <input type="number" className="form-control" onChange={_killedCtg} value={killedCtg ? killedCtg : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <p className='m-1'>Injured: </p>
                                    <input type="number" className="form-control" onChange={_injuredCtg} value={injuredCtg ? injuredCtg : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <p className='m-1'>Abducted: </p>
                                    <input type="number" className="form-control" onChange={_abductedCtg} value={abductedCtg ? abductedCtg : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* end row 3*/}
                {/* row 4 */}
                <div className="row mb-3">

                    <div className="col-lg-3 mb-3">
                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                Security Forces Toll
                            </div>
                            <div className="card-body">
                                <div className='mb-3'>
                                    <label className='m-1'>Killed:</label>
                                    <input type="number" className="form-control" onChange={_killedSft} value={killedSft ? killedSft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Injured:</label>
                                    <input type="number" className="form-control" onChange={_injuredSft} value={injuredSft ? injuredSft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Captured:</label>
                                    <input type="number" className="form-control" onChange={_capturedSft} value={capturedSft ? capturedSft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Desertions:</label>
                                    <input type="number" className="form-control" onChange={_desertionsSft} value={desertionsSft ? desertionsSft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-3">
                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                Resistance Fighters Toll
                            </div>
                            <div className="card-body">
                                <div className='mb-3'>
                                    <label className='m-1'>Killed:</label>
                                    <input type="number" className="form-control" onChange={_killedRft} value={killedRft ? killedRft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Injured:</label>
                                    <input type="number" className="form-control" onChange={_injuredRft} value={injuredRft ? injuredRft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Arrested:</label>
                                    <input type="number" className="form-control" onChange={_arrestedRft} value={arrestedRft ? arrestedRft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-3">
                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                EAO Fighters Toll
                            </div>
                            <div className="card-body">
                                <div className='mb-3'>
                                    <label className='m-1'>Killed:</label>
                                    <input type="number" className="form-control" onChange={_killedEft} value={killedEft ? killedEft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Injured:</label>
                                    <input type="number" className="form-control" onChange={_injuredEft} value={injuredEft ? injuredEft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Arrested:</label>
                                    <input type="number" className="form-control" onChange={_arrestedEft} value={arrestedEft ? arrestedEft : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-3">
                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                Others/Unknown Toll
                            </div>
                            <div className="card-body">
                                <div className='mb-3'>
                                    <label className='m-1'>Killed:</label>
                                    <input type="number" className="form-control" onChange={_killedOt} value={killedOt ? killedOt : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Injured:</label>
                                    <input type="number" className="form-control" onChange={_injuredOt} value={injuredOt ? injuredOt : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                                <div className='mb-3'>
                                    <label className='m-1'>Arrested:</label>
                                    <input type="number" className="form-control" onChange={_arrestedOt} value={arrestedOt ? arrestedOt : ""} disabled={!permissions.includes(editIncident) ? true : false} min='0' />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* end row 4 */}
                {/* row 5 */}
                <div className="row mb-3">

                    <div className="col-lg-12 text-end">
                        <div className='mb-3'>
                            <label className='m-1 d-flex align-items-start'>Incident Description: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                            {isInvalid && !incidentDescription ?

                                <div id="incidentdescription" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert Incident Description.
                                </div>
                                :
                                <></>
                            }
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" onChange={_incidentDescription} required value={incidentDescription ? incidentDescription : ""} disabled={!permissions.includes(editIncident) ? true : false}>{incidentDescription ? incidentDescription : ""}</textarea>
                        </div>
                        {(!!!incidentDate || !!!stateRegion || !!!township) ?
                            <Tooltip
                                title={() => (
                                    <div className='text-dark p-2'>
                                        <div><strong>Please fill the following fields</strong></div>
                                        <ul>
                                            <li>Incident Date</li>
                                            <li>State/Region</li>
                                            <li>Township</li>
                                        </ul>
                                    </div>
                                )}
                                placement="top"
                                color={"#fff"}
                            >
                                <div style={{ display: 'inline-block' }}>
                                    <button type="submit" className={`btn btn-secondary mx-1`} disabled={(!!!incidentDate || !!!stateRegion || !!!township)}>Generate Description</button>
                                </div>
                            </Tooltip>
                            :
                            <div style={{ display: 'inline-block' }}>
                                <button type="submit" className={`btn btn-primary mx-1`} onClick={() => {
                                    if (!!!incidentDescription) {
                                        _handleAutoGenerateDescription();
                                    } else {
                                        if (incidentDescription || detectEditIncidentDescription || incidentSubmissionId || duplicateIncident) {
                                            setIsShowConfirm(true);
                                        }
                                    }

                                }}>Generate Description</button>
                            </div>

                        }
                    </div>

                    <div className="col-lg-12">
                        <div className='mb-3'>
                            <label className='m-1 d-flex align-items-start'>Source: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                            {isInvalid && !sourceTwo ?

                                <div id="sourcetwo" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert Source.
                                </div>
                                :
                                <></>
                            }
                            {/* <input type="text" className="form-control" id='source' onChange={_source} required value={source ? source : ""} disabled={ !permissions.includes(editIncident) ? true : false }/> */}
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="2" onChange={_sourceTwo} required value={sourceTwo ? sourceTwo : ""} disabled={!permissions.includes(editIncident) ? true : false}>{sourceTwo ? sourceTwo : ""}</textarea>
                        </div>
                    </div>

                </div>
                {/* end row 5 */}

                {/* row 6 */}
                <div className="row mb-3">

                    <div className="col-lg-12">

                        <div className="card">
                            <div className="card-header bg-secondary text-white text-center">
                                Location
                            </div>
                            <div className="card-body">
                                {/* row */}
                                <div className="row mb-3">
                                    <div className="col-lg-6">
                                        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["places"]}>
                                            <GoogleMap
                                                mapContainerStyle={laptopOrDesktop ? containerStyle : { width: '100%', height: '500px' }}
                                                center={center}
                                                zoom={zoom ? zoom : 6}
                                                onClick={onClikMap}
                                                mapTypeId='hybrid'
                                                label={true}
                                                disabled={!permissions.includes(editIncident) ? true : false}
                                            >
                                                <MapControl 
                                                    position="LEFT_TOP" 
                                                    onToggle={toggleGrid}
                                                    loading={toggleLoading}
                                                />
                                                {isGridOn && <Data 
                                                    onLoad={onMapLoaded}
                                                />}
                                                <Marker
                                                    onLoad={onLoadMarker}
                                                    position={center}
                                                    draggable={true}
                                                    onDrag={onDrag}
                                                    disabled={!permissions.includes(editIncident) ? true : false}
                                                />
                                                <Autocomplete
                                                    onLoad={onLoadAutoComplete}
                                                    onPlaceChanged={onPlaceChanged}
                                                    disabled={!permissions.includes(editIncident) ? true : false}
                                                >
                                                    <div style={{
                                                        position: `relative`,
                                                        display: `inline-block`,
                                                        marginTop: 90,
                                                        marginLeft: 10,
                                                        marginRight: 10,
                                                        background: `#fff`,
                                                        width: 350
                                                        // padding : 10
                                                    }}>
                                                        <div className=' rounded' style={{
                                                            width: `100%`,
                                                            padding: 5,
                                                            background: `#fff`

                                                        }}>
                                                            <h6 className='text-white bg-primary py-1 px-2 rounded'>Autocomplete Search</h6>

                                                            <input
                                                                type="text"
                                                                placeholder="Search in google map"
                                                                className='form-control form-control-sm'
                                                            />
                                                        </div>
                                                    </div>
                                                </Autocomplete>
                                                { /* Child components, such as markers, info windows, etc. */}
                                            </GoogleMap>
                                        </LoadScript>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className='mb-3'>
                                            <label className='m-1 d-flex align-items-start'>Latitude: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !latitude ?

                                                <div id="latitude" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please insert Latitude correctly.
                                                </div>
                                                :
                                                <></>
                                            }
                                            <input type="text" className="form-control" placeholder='0.00000000' id='latitude' name='latitude' required value={latitude ? latitude : ""} disabled={!permissions.includes(editIncident) ? true : false} onChange={_latitude} />
                                        </div>
                                        <div className='mb-3'>
                                            <label className='m-1 d-flex align-items-start'>Longitude: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !longitude ?

                                                <div id="longitude" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please insert Longitude correctly.
                                                </div>
                                                :
                                                <></>
                                            }
                                            <input type="text" className="form-control" placeholder='0.00000000' id='longitude' name='longitude' onChange={_longitude} required value={longitude ? longitude : ""} disabled={!permissions.includes(editIncident) ? true : false} />
                                        </div>
                                        <div className='mb-3'>
                                            <label className='m-1'>Road/Street: </label>
                                            <input type="text" className="form-control" name='roadstreet' id='roadstreet'
                                                value={roadStreet ? roadStreet : ""} onChange={_roadStreet} disabled={!permissions.includes(editIncident) ? true : false} />
                                        </div>
                                        <div className='mb-3'>
                                            <label className='m-1'>Ward/village/village tract: </label>
                                            <input type="text" className="form-control" name='wardvillagetract' id='wardvillagetract' value={villageTract ? villageTract : ""} onChange={_villageTract} disabled={!permissions.includes(editIncident) ? true : false} />
                                        </div>
                                        <div className='mb-3'>
                                            <label className='m-1'>Town: </label>
                                            <input type="text" className="form-control" name='town' id='town' value={town ? town : ""} onChange={_town} disabled={!permissions.includes(editIncident) ? true : false} />
                                        </div>
                                        <div className='mb-3'>
                                            <label className='m-1 d-flex align-items-start'>State/Region: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !stateRegion ?
                                                <div id="stateregion" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select State/Region.
                                                </div>
                                                :
                                                <></>
                                            }
                                            <select className="form-select" aria-label="Default select example" onChange={_stateRegion} required value={stateRegion ? stateRegion : ""} disabled={!permissions.includes(editIncident) ? true : false}>
                                                <option value="" selected></option>
                                                {elStateRegion}
                                            </select>
                                        </div>
                                        <div className='mb-3'>
                                            <label className='m-1'>Township:  <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !township ?
                                                <div id="township" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Township.
                                                </div>
                                                :
                                                <></>
                                            }
                                            {/* <select className="form-select" aria-label="Default select example" onChange={_township} required value={township ? township  : ""} disabled={ !permissions.includes(editIncident) ? true : false }>
                                                            <option value="" selected></option>
                                                            {elTownship}
                                                        </select> */}

                                            <Select
                                                showSearch
                                                style={{ width: "100%" }}
                                                onChange={_township}
                                                value={township ? township : ""}
                                                size={"large"}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                disabled={!permissions.includes(editIncident) ? true : false}
                                            >
                                                {/* { !loading ? elTownship : elTownship.concat(<Option key="" >Loading...</Option>)} */}
                                                {elTownship}
                                            </Select>

                                        </div>
                                        <div className='mb-3'>
                                            <label className='m-1 d-flex align-items-start'>Geo-Precision: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                            {isInvalid && !geoPrecision ?
                                                <div id="geoprecision" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                    Please select Geo-Precision.
                                                </div>
                                                :
                                                <></>
                                            }
                                            <select className="form-select" aria-label="Default select example" onChange={_geoPrecision} required value={geoPrecision ? geoPrecision : ""} disabled={!permissions.includes(editIncident) ? true : false}>
                                                <option value="" selected></option>
                                                {elGeoPrecision}
                                            </select>
                                        </div>
                                        {/* <div className="mb-3">
                                                        <div className="form-check">
                                                            {   highway == "Y" &&

                                                                <input className="form-check-input" type="checkbox" id="highway" name="highway" onChange={_highway} checked disabled={ !permissions.includes(editIncident) ? true : false }/>

                                                            }
                                                            {   highway == "N" &&

                                                                <input className="form-check-input" type="checkbox" id="highway" name="highway" onChange={_highway} disabled={ !permissions.includes(editIncident) ? true : false }/>

                                                            }
                                                            {   highway == undefined &&

                                                                <input className="form-check-input" type="checkbox" id="highway" name="highway" onChange={_highway} disabled={ !permissions.includes(editIncident) ? true : false }/>

                                                            }
                                                            <label className="form-check-label d-flex align-items-start" for="highway">
                                                                Less Than 5 km from Highway (Y/N)
                                                            </label>
                                                        </div>
                                                    </div> */}
                                    </div>
                                </div>
                                {/* endrow */}
                                {/* row */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label for="commentonlocation" className="form-label">Comment on Location:</label>
                                            <textarea className="form-control" id="commentonlocation" rows="3" onChange={_commentOnLocation} value={commentOnLocation ? commentOnLocation : ""} disabled={!permissions.includes(editIncident) ? true : false}></textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* end row */}
                            </div>
                        </div>

                    </div>
                </div>
                {/* end row 6*/}

                {/* Button Action Element */}
                <ButtonAction
                    incidentSubmissionId={incidentSubmissionId}
                    statusIncident={statusIncident}
                    duplicateIncident={duplicateIncident}
                />

                <div className="modal fade" id={`exampleModal-save-submitted`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Submit this incident for Approval?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <Link className="text-decoration-none text-white" onClick={(e) => _saveData(e, "Submitted")}><button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id={`exampleModal-update-submitted`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Submit this incident for Approval?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <Link className="text-decoration-none text-white" onClick={(e) => _updateData(e, "Submitted", statusIncident)}><button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id={`exampleModal-cancel`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Cancel this incident ?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <Link className="text-decoration-none text-white" onClick={_cancel}><button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id={`exampleModal-delete`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Delete this incident ?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <Link className="text-decoration-none text-white" onClick={(e) => _deleteIncident(e, incidentId)}><button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <BasicModal
                    isShow={isShowConfirm}
                    title={"Confirmation"}
                    onHide={() => setIsShowConfirm(false)}
                    textBody={'This will reset any changes made in description field, are you sure you want to continue?'}
                    handleCancelDialog={() => setIsShowConfirm(false)}
                    textCancelButton={'No'}
                    handleApproveDialog={() => {
                        _handleAutoGenerateDescription();
                        setIsShowConfirm(false);
                    }}
                    textApproveButton={'Yes'}
                />
            </div>
        </Spin>
    )
}