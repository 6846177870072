import React, { useState, useEffect } from "react";
import { Select, Spin } from 'antd';
import axios from "axios";
import Uri from "../Uri";
// import Swal from "sweetalert2";
import { useParams, useHistory } from "react-router-dom";
import Helper from "./helpers/Helper";
import { useAxios } from "../App";
import { useMsal } from "@azure/msal-react";

export default function AddEditNewUser() {
  const axios = useAxios();
  const { instance: msalInstance } = useMsal();

    const authToken = localStorage.getItem("token");

    let [username, setUsername] = useState("");
    let [email, setEmail] = useState("");
    let [permissions, setPermissions] = useState([]);
    let [company, setCompany] = useState("");
    let [authId, setAuthId] = useState("");
    let [updatedBy, setUpdatedBy] = useState("");
    let [createdBy, setCreatedBy] = useState("");
    let [isInvalid, setIsInvalid] = useState();
    let [elCompany, setElcompany] = useState([]);
    let [elPermission, setElPermission] = useState([]);
    let [companyData, setCompanyData] = useState([]);
    let [permissionData, setPermissionData] = useState([]);
    let [loading, setLoading] = useState(true);

    //User Information

    let [usernameUser, setUsernameUser] = useState("");
    let [emailAddress, setEmailAddress] = useState("");
    let [authIdUser, setAuthIdUser] = useState("");
    let [companyIdUser, setCompanyIdUser] = useState("");
    let [userIdlogin, setUserIdLogin] = useState("");
    let [permissionsUser, setPermissionsUser] = useState([]);
    let [approveIncident, setApproveIncident] = useState("1");
    let [customerManager, setCustomerManager] = useState("2");
    let [editIncident, setEditIncident] = useState("3");
    let [poiSelfService, setPoiSelfService] = useState("4");
    let [readOnly, setReadOnly] = useState("5");
    let [superUser, setSuperUser] = useState("6");

    const { Option } = Select;
    let history = useHistory();
    let arrPermissions = [];
    const { userId } = useParams();
    const permissionstype = [
        <Option key="All">All</Option>,
        <Option key="1">Approve Incident</Option>,
        <Option key="2">Customer Manager</Option>,
        <Option key="3">Edit Incident</Option>,
        <Option key="4">POI Self Service</Option>,
        <Option key="5">Read-Only</Option>,
        <Option key="6">Super user</Option>,

    ];

    function refreshParent() {

        window.opener.location.reload();

    }

    function validateEmail(email) {
      const mailFormat = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
      return mailFormat.test(email);
    }


    async function _loadCompanyData() {

        let arr = [];
        let arrEl = [];

        let arrCache = await caches.keys();// get cache

        if (!arrCache.includes("company")) {

            await axios.get(Uri.rootUri + `company/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                    // console.log(response);


                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                        let dataObj = {
                            "id": Helper.hiddenChar(x.id),
                            "companyname": x.companyname,
                            "subscriptiondate": x.subscriptiondate,
                            "endofcontract": x.endofcontract

                        }

                        arr.push(dataObj);

                        arrEl.push(<Option key={Helper.hiddenChar(x.id)}>{x.companyname}</Option>);

                    });

                    setCompanyData(arr);

                    setElcompany(arrEl);

                    Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company`, dataResponse);//set cache



                })
                .catch(function (error) {

                    console.log(error);

                });

        } else {

            let resultData = arrCache.filter(x => x == "company");

            resultData.forEach(async cachename => {

                let cacheStorage = await caches.open(cachename);

                let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/company');

                if (!cacheResponse || cacheResponse == undefined) {// if data in cache empty

                    await axios.get(Uri.rootUri + `company/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let arr = [];
                            let arrEl = [];

                            let dataResponse = response.data;

                            dataResponse.forEach(x => {

                                let dataObj = {
                                    "id": Helper.hiddenChar(x.id),
                                    "companyname": x.companyname,
                                    "subscriptiondate": x.subscriptiondate,
                                    "endofcontract": x.endofcontract

                                }

                                arr.push(dataObj);

                                arrEl.push(<Option key={Helper.hiddenChar(x.id)}>{x.companyname}</Option>);

                            });

                            setCompanyData(arr);

                            setElcompany(arrEl);

                            Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company`, dataResponse);//set cache



                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                } else {

                    let dataResponse = await cacheResponse.json();

                    if (!dataResponse || dataResponse.length == 0) {

                        await axios.get(Uri.rootUri + `company/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                            .then(function (response) {

                                // console.log(response);

                                let arr = [];
                                let arrEl = [];

                                let dataResponse = response.data;

                                dataResponse.forEach(x => {

                                    let dataObj = {
                                        "id": Helper.hiddenChar(x.id),
                                        "companyname": x.companyname,
                                        "subscriptiondate": x.subscriptiondate,
                                        "endofcontract": x.endofcontract

                                    }

                                    arr.push(dataObj);

                                    arrEl.push(<Option key={Helper.hiddenChar(x.id)}>{x.companyname}</Option>);

                                });

                                setCompanyData(arr);

                                setElcompany(arrEl);

                                Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company`, dataResponse);//set cache



                            })
                            .catch(function (error) {

                                console.log(error);

                            });

                    } else {


                        dataResponse.forEach(x => {

                            let dataObj = {
                                "id": Helper.hiddenChar(x.id),
                                "companyname": x.companyname,
                                "subscriptiondate": x.subscriptiondate,
                                "endofcontract": x.endofcontract

                            }

                            arr.push(dataObj);

                            arrEl.push(<Option key={Helper.hiddenChar(x.id)}>{x.companyname}</Option>);

                        });

                        setCompanyData(arr);

                        setElcompany(arrEl);


                    }

                }

            });

        }

    }

    function _handleChangePermissions(value) {

        value.map(x => {

            if (x == 'All') {

                for (let i = 1; i < permissionstype.length; i++) {

                    if (!arrPermissions.includes(i.toString())) { // check jika key permissions sudah ada didalam array

                        arrPermissions.push(i.toString());

                    }

                }

            } else {

                arrPermissions.push(x)
            }
        })

        setPermissions(arrPermissions);

    }

    function _username(e) {

        if (e.target.value) {

            setUsername(e.target.value);

        } else {

            setUsername("");

        }

    }

    function _email(e) {

        if (e.target.value) {

            setEmail(e.target.value);

        } else {

            setEmail("");

        }

    }

    function _handleChangeCustomer(value) {

        if (value) {

            setCompany(value);

        } else {

            setCompany("");

        }

    }

    async function _saveData(e) {

        e.preventDefault();

        // console.log(e);

        setLoading(true);


        if (!username || !email || !company || permissions.length < 1) {

            setIsInvalid(true);

            setLoading(false);

            setTimeout(() => setIsInvalid(false), 3000);

        } else {

            if (!validateEmail(email)) {

                setIsInvalid(true);

                setLoading(false);

                setTimeout(() => setIsInvalid(false), 3000);

            } else {


                // let suffix = '['
                // let prefix = ']'
                let suffix = ""
                let prefix = ""

                let arrPermission = [];

                permissions.forEach(x => {

                    // let str = "'" + x + "'";

                    let str = Helper.hiddenChar(x);

                    arrPermission.push(str);
                });


                let permission = suffix + arrPermission + prefix;

                let data = {

                    "username": username,
                    "emailaddress": email,
                    "permissions": permission,
                    "company_id": company,
                    "created_by": localStorage.getItem("usernameLogin")
                }

                // console.log(data);

                await axios.post(Uri.rootUri + `user/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(async function (response) {

                        // console.log(response);

                        caches.delete("users");

                        Helper.alert("Success", "success", "Success save data", 3000);

                        setUsername("");

                        setEmail("");

                        setPermissions([]);

                        setCompany([]);

                        //API for update cache
                        let cacheName = {

                            "name": "users",
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                // console.log(response);

                            })
                            .catch(function (error) {


                                console.log(error);

                            });


                        history.push(`UserPage`);


                    })
                    .catch(function (error) {

                        console.log(error);

                    });




            }




        }


    }

    async function _updateData(e) {

        e.preventDefault();



        if (!username || !email || !company || permissions.length < 1) {


            setIsInvalid(true);

            setLoading(false);

            setTimeout(() => setIsInvalid(false), 3000);

        } else {

            if (!validateEmail(email)) {

                setIsInvalid(true);

                setLoading(false);

                setTimeout(() => setIsInvalid(false), 3000);

            } else {

                // console.log("berhasil");

                // let suffix = '['
                // let prefix = ']'
                let suffix = ""
                let prefix = ""

                let arrPermission = [];

                permissions.forEach(x => {

                    // let str = "'" + x + "'";
                    let str = Helper.hiddenChar(x);

                    arrPermission.push(str);

                });


                let permission = suffix + arrPermission + prefix;

                let data = {

                    "username": username,
                    "emailaddress": email,
                    "permissions": permission,
                    "company_id": company,
                    "auth_id": authId,
                    "updated_by": updatedBy,
                    "created_by": createdBy
                }

                // console.log(data);

                await axios.put(Uri.rootUri + `user/${userId}/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(async function (response) {

                        // console.log(response);

                        caches.delete("users");

                        Helper.alert("Success", "success", "Success update data", 3000);

                        setUsername("");

                        setEmail("");

                        setPermissions([]);

                        setCompany("");

                        //API for update cache
                        let cacheName = {

                            "name": "users",
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                // console.log(response);

                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                        setTimeout(() => {
                            window.close();
                        }, 2000);

                        window.onunload = refreshParent();

                    })
                    .catch(function (error) {

                        console.log(error);


                    });


            }




        }


    }

    async function _loadPermissionData() {

        let arr = [];
        const dataPermission = [

            {
                "id": "1",
                "permissionname": "Approve Incident"
            },
            {
                "id": "2",
                "permissionname": "Customer Manager"
            },
            {
                "id": "3",
                "permissionname": "Edit Incident"
            },
            {
                "id": "4",
                "permissionname": "POI Self Service"
            },
            {
                "id": "5",
                "permissionname": "Read-Only"
            },
            {
                "id": "6",
                "permissionname": "Super user"
            },
        ]

        dataPermission.forEach(x => {

            arr.push(<Option key={x.id}>{x.permissionname}</Option>)

        });


        setPermissionData(dataPermission);
        setElPermission(arr);

    }

    async function _loadData(id) {

        // console.log(id);



        if (id) {

            await axios.get(Uri.rootUri + `user/${id}/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                    let dataResponse = response.data;

                    // console.log(dataResponse);

                    let strPermissions = Array.from(dataResponse.permissions.split(','));

                    // console.log(strPermissions);

                    setUsername(dataResponse.username);
                    setEmail(dataResponse.emailaddress);
                    setCompany(dataResponse.company_id);
                    setAuthId(dataResponse.auth_id);
                    setCreatedBy(dataResponse.created_by);
                    setPermissions(strPermissions);
                    setUpdatedBy(localStorage.getItem("usernameLogin"));



                })
                .catch(function (error) {

                    console.log(error);


                });

        } else {

            setLoading(false);

        }

    }

    function _cancel(e) {

        e.preventDefault();

        if (userId) {

            window.close();

        }

        history.push(`UserPage`);

    }

    async function _loadUserInformation() {

        const userInformation = await Helper.getUserLogin();

        if (userInformation) {

            setUserIdLogin(userInformation.user_id);

            setEmailAddress(userInformation.email_address);

            setAuthIdUser(userInformation.auth_id);

            setCompanyIdUser(userInformation.company_id);

            setUsernameUser(userInformation.username);

            let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));

            setPermissionsUser(strPermissions);

            if (!strPermissions.includes(superUser)) {// if not Permission Type Edit Incident

                history.push("Unauthorize");

                // window.location.replace(`${window.location.origin}/Index/Unauthorize`);

                // window.location.replace("http://localhost:3000/Index/Unauthorize");

            }

        }

    }

    useEffect(() => {
    const effect = async () => {
        // ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadUserInformation();

        await _loadData(userId);
        await _loadPermissionData();
        await _loadCompanyData();

        setLoading(false);
    }
    effect();
    }, []);

    return (
        <Spin size="large" spinning={loading}>
        <React.Fragment>
            <div className="AddNewUser">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        {/* <form> */}
                        <div className="mb-3">
                            <label for="username" className="form-label">Username: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                            {isInvalid && !username ?
                                <div id="permissionselection" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert a username.
                                </div>
                                :
                                <></>
                            }
                            <input type="text" className="form-control" id="username" name="username" onChange={_username} required value={username ? username : ""} />
                        </div>
                        <div className="mb-3">
                            <label for="email" className="form-label">Email: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                            {(isInvalid && !validateEmail(email)) || (isInvalid && !email) ?
                                <div id="permissionselection" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert a valid email address.
                                </div>
                                :
                                <></>
                            }
                            <input type="email" className="form-control" id="email" name="email" onChange={_email} required value={email ? email : ""} />
                        </div>
                        <div className="mb-3">
                            <label for="permissionselection" className="form-label">Permissions Selection: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                            {isInvalid && permissions.length < 1 ?

                                <div id="permissionselection" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please choose a permission.
                                </div>
                                :
                                <></>
                            }

                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Multiple Selected ..."
                                onChange={_handleChangePermissions}
                                size={"large"}
                                value={permissions && permissions}
                                required
                            >
                                <Option key="All">All</Option>
                                {elPermission}
                            </Select>
                        </div>
                        <div className="mb-3">
                            <label for="cutomername" className="form-label">Associated Company Name: <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                            {isInvalid && !company ?
                                <div id="permissionselection" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please select an associated company.
                                </div>
                                :
                                <></>
                            }
                            <Select
                                style={{ width: "100%" }}
                                size={"large"}
                                onChange={_handleChangeCustomer}
                                value={company ? company : ""}
                                showSearch
                                filterOption={(inputValue, option)=>{
                                    return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) === 0;
                                }}
                                required
                            >
                                {elCompany}
                            </Select>
                        </div>
                        <div className="mb-3 text-end">
                            {userId ?

                                <button type="submit" className="btn btn-primary mx-1" onClick={_updateData}>Save</button>
                                :
                                <button type="submit" className="btn btn-primary mx-1" onClick={_saveData}>Save</button>
                            }
                            <button type="submit" className="btn btn-primary mx-1" data-bs-toggle="modal" data-bs-target={`#exampleModal-cancel`}>Cancel</button>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
            <div className="modal fade" id={`exampleModal-cancel`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Cancel this page ?
                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={_cancel}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        </Spin>
    )
}