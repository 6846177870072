import React from 'react'
import { Spin } from "antd";

export default function Loading() {

    return (
        <div style={{ height:600}} className='d-flex justify-content-center align-items-center'>
            <div className='my-5 text-center'>
                <Spin size='large' spinning={true}></Spin>
                <p className='text-secondary'><strong>Loading...</strong></p>
            </div>
        </div>

  )
}
