import React ,{useEffect, useState}from 'react';
import { Select,Spin } from 'antd';
import PointOfInterest from './PointOfInterest';
import moment from 'moment';
import Uri from '../Uri';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import Helper from './helpers/Helper';
import { useAxios } from '../App';
import { useMsal } from '@azure/msal-react';
import DatePicker from './utils/DatePicker';

export default function AddEditNewCompany(props) {
    const axios = useAxios();
    const authToken = localStorage.getItem("token");
    const { instance: msalInstance } = useMsal();

    const { companyId } = useParams();

    let history = useHistory();
    // console.log(companyId);

    const {Option} = Select;
    let [idCompany, setIdCompany] = useState("");
    let [incidentTypeSelection, setIncidentTypeSelection] = useState([]);
    let [companyName, setCompanyName] = useState("");
    let [tableau, setTableau] = useState("");
    let [subscriptionType, setSubscriptionType] = useState("1");
    let [elSubscriptionType, setElSubscriptionType] = useState([]);
    let [elTypeOfIncident, setElTypeOfIncident] = useState([]);
    let [subscriptionDate, setSubscriptionDate] = useState("");
    let [endOfContract, setEndOfContract] = useState("");
    let [incidentDateRange, setIncidentDateRange] = useState("");
    let [elIncidentDateRange, setElIncidentDateRange] = useState([]);
    let [maximumPoi, setMaximumPoi] = useState();
    let [quotaPoi, setQuotaPoi] = useState();

    let [latitude,setLatitude] = useState();
    let [longitude,setLongitude] = useState();
    let [poiName, setPoiName] = useState("");
    let [poiCategory, setPoiCategory] = useState("");
    let [freeField1, setFreeField1] = useState("");
    let [freeField2, setFreeField2] = useState("");
    let [poiShape, setPoiShape] = useState("");
    let [poiColor, setPoiColor] = useState("");

    let [isInvalid, setIsInvalid] = useState();
    let [loading, setLoading] = useState(true);
    let [show, setShow] = useState();
    let [createdBy, setCreatedBy] = useState("");
    let [updatedBy, setUpdatedBy] = useState("");

    //User Information

    let [username, setUsername] = useState("");
    let [emailAddress, setEmailAddress] = useState("");
    let [authId, setAuthId] = useState("");
    let [companyIdUser, setCompanyIdUser] = useState("");
    let [userId, setUserId] = useState("");
    let [permissions, setPermissions] = useState([]);
    let [approveIncident, setApproveIncident] = useState("1");
    let [customerManager, setCustomerManager] = useState("2");
    let [editIncident, setEditIncident] = useState("3");
    let [poiSelfService, setPoiSelfService] = useState("4");
    let [readOnly, setReadOnly] = useState("5");
    let [superUser, setSuperUser] = useState("6");

    let templateSubscriptionData = [
            {
                "subscriptiontype" : "1",
                "incidentdaterange_id" : "-1111",
                "incidenttypeselecteion" : "064d81c675fa402885fdc88e88db529d,a16b076f40e54692a8752e3124462acb,e572929d2cb843a0a09027c340a8cc01,fccc59ddcaed42b19a2de90e2c802353,06cd68b44bab48e492c12a8b5232a7fd,1d29667ae56f434fb848bf7be0639002,0a2fefabb97b4f2899cfe2c143c8a371,ef910b7b5a2247c8ac084946d9ece61a",
                "maximumpoi" : "100"
            },
            {
                "subscriptiontype" : "2",
                "incidentdaterange_id" : "-6",
                "incidenttypeselecteion" : "064d81c675fa402885fdc88e88db529d,a16b076f40e54692a8752e3124462acb,e572929d2cb843a0a09027c340a8cc01,fccc59ddcaed42b19a2de90e2c802353,06cd68b44bab48e492c12a8b5232a7fd,1d29667ae56f434fb848bf7be0639002,0a2fefabb97b4f2899cfe2c143c8a371,ef910b7b5a2247c8ac084946d9ece61a",
                "maximumpoi" : "100"
            },
            {
                "subscriptiontype" : "3",
                "incidentdaterange_id" : "-3",
                "incidenttypeselecteion" : "064d81c675fa402885fdc88e88db529d,a16b076f40e54692a8752e3124462acb,e572929d2cb843a0a09027c340a8cc01,fccc59ddcaed42b19a2de90e2c802353",
                "maximumpoi" : "30"
            },
            {
                "subscriptiontype" : "4",
                "incidentdaterange_id" : "-9999",
                "incidenttypeselecteion" : "11111111111111111111111111111111",
                "maximumpoi" : "0"
            }
    ]


    function _handleChangeIncidentTypeSelection(value){

        let arr = [];

        if(value.length > 0){

            value.map(x=>{

                // console.log(x);

                if(x !== "11111111111111111111111111111111"){

                    arr.push(x)

                }
            });

            setIncidentTypeSelection(arr);

        }else{

            setIncidentTypeSelection(["11111111111111111111111111111111"]);

        }


    }

    function _companyName(e){

        e.preventDefault();

        if(e.target.value){

            setCompanyName(e.target.value);

        }else{

            setCompanyName("");

        }

    }

    function _tableau(e){

        e.preventDefault();

        if(e.target.value){

            setTableau(e.target.value);

        }else{

            setTableau("");

        }

    }

    function _subscriptionType(e){

        e.preventDefault();

        if(e.target.value){

            setSubscriptionType(e.target.value);

            let result = templateSubscriptionData.filter( x => x.subscriptiontype == e.target.value); // template subscription data

            // console.log(result);

            if(result.length > 0){

                let arrIncidentType = [];

                result.forEach( x => {

                    setIncidentDateRange(x.incidentdaterange_id);

                    setMaximumPoi(x.maximumpoi);

                    let convertArrTypeIncident = Array.from(x.incidenttypeselecteion.split(','));

                    convertArrTypeIncident.forEach(x=>[

                        arrIncidentType.push(Helper.hiddenChar(x))

                    ]);

                    setIncidentTypeSelection(arrIncidentType);

                });

            }


        }else{

            setSubscriptionType("");

        }

    }

    function _subscriptionDate(dateString){
        if (dateString) {
            setSubscriptionDate(dateString);
        } else {
            setSubscriptionDate("");
        }
    }

    function _endOfContract(dateString){
      if (dateString) {
        setEndOfContract(dateString);
      } else {
        setEndOfContract("");
      }
  }

    function _incidentDateRange(e){

        e.preventDefault();

        if(e.target.value){

            setIncidentDateRange(e.target.value);

        }else{

            setIncidentDateRange("");

        }


    }

    function _maximumPoi(e){

        e.preventDefault();

        if(e.target.value){

            setMaximumPoi(e.target.value);

        }else{

            setMaximumPoi();

        }

    }

    async function _saveData(e){

        e.preventDefault();

        if(!companyName || !subscriptionType || !subscriptionDate || !endOfContract){

            setIsInvalid(true);

            setTimeout(()=>setIsInvalid(false),3000);

        }else{

            if( (poiName !== "" || poiCategory !== "" || latitude !== undefined || longitude !== undefined || poiShape !== "" || poiColor !== "")  && (poiName == "" || poiCategory == "" || latitude == undefined || longitude == undefined || poiShape == "" || poiColor == "") ){

                Helper.alert("Failed", "error", "The POI form is not complete, please fill in everything correctly", 3000);

            }else{

                if(poiName == ""){

                        // request hanya company
                        // let suffix = '['
                        // let prefix = ']'
                        let companyIdParams;
                        let suffix = ""
                        let prefix = ""

                        let arrIncidentType = [];

                        incidentTypeSelection.forEach(x=>{

                            // let str = "'" + Helper.hiddenChar(x) + "'";
                            let str = Helper.hiddenChar(x);

                            arrIncidentType.push(str);
                        });


                        let incidentType = suffix + arrIncidentType + prefix;

                        let dataCompany = {

                            "companyname" : companyName,
                            "tablelausoftwarelogin" : tableau,
                            "subscriptiontype" : subscriptionType,
                            "incidenttypeselecteion": incidentType,
                            "subscriptiondate" : moment(subscriptionDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
                            "endofcontract" : moment(endOfContract, "DD/MM/YYYY").format("DD-MM-YYYY"),
                            "incidentdaterange_id" : incidentDateRange,
                            "maximumpoi" : maximumPoi,
                            "created_by" : localStorage.getItem("usernameLogin"),
                            "updated_by" : localStorage.getItem("usernameLogin")

                        }
                        // console.log(dataCompany);
                        await axios.post(Uri.rootUri + `company/`, dataCompany,  { headers: {"Authorization" : `Bearer ${authToken}`} }) //create company
                        .then(async function(response){

                            // console.log(response.toJSON());
                            caches.delete("company");

                            let responseData = response.data;

                            companyIdParams = responseData.id;

                            setIsInvalid(false);

                            Helper.alert("Success", "success", "Success save data", 3000);

                            //API for update cache
                            let cacheName = {

                                "name" : "company",
                                "created_by": localStorage.getItem("usernameLogin"),
                                "updated_by": localStorage.getItem("usernameLogin")

                              }

                              await axios.post(Uri.rootUri + `cache/`, cacheName)
                              .then(function(response){

                                //   console.log(response);

                              })
                              .catch(function(error){

                                  console.log(error);

                              });

                        })
                        .catch(function(error){

                            let objError = error.toJSON();

                            // console.log(objError);

                            if(objError.status == 400){

                                Helper.alert("Failed", "error", "Company Name already exist", 3000);

                            }

                        });


                        window.onunload = Helper.refreshParent();

                        history.push(`ViewCompany/${companyIdParams}`);


                }else{

                    // request denghan company dan poi

                    let companyIdParams;
                    let suffix = ""
                    let prefix = ""

                    let arrIncidentType = [];

                    incidentTypeSelection.forEach(x=>{

                        // let str = "'" + Helper.hiddenChar(x) + "'";
                        let str = Helper.hiddenChar(x);

                        arrIncidentType.push(str);
                    });


                    let incidentType = suffix + arrIncidentType + prefix;

                    let dataCompany = {

                        "companyname" : companyName,
                        "tablelausoftwarelogin" : tableau,
                        "subscriptiontype" : subscriptionType,
                        "incidenttypeselecteion": incidentType,
                        "subscriptiondate" : moment(subscriptionDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
                        "endofcontract" : moment(endOfContract, "DD/MM/YYYY").format("DD-MM-YYYY"),
                        "incidentdaterange_id" : incidentDateRange,
                        "maximumpoi" : maximumPoi,
                        "created_by" : localStorage.getItem("usernameLogin"),
                        "updated_by" : localStorage.getItem("usernameLogin")

                    }

                    // console.log(data);

                    await axios.post(Uri.rootUri + `company/`, dataCompany,  { headers: {"Authorization" : `Bearer ${authToken}`} }) //create company
                    .then(async function(response){


                        let responseData = response.data;

                        caches.delete("company");

                        // console.log(responseData);

                        companyIdParams = responseData.id;

                        setIsInvalid(false);

                        //API for update cache
                        let cacheName = {

                           "name" : "company",
                           "created_by": localStorage.getItem("usernameLogin"),
                           "updated_by": localStorage.getItem("usernameLogin")

                         }

                         await axios.post(Uri.rootUri + `cache/`, cacheName)
                         .then(function(response){

                            //  console.log(response);

                         })
                         .catch(function(error){

                             console.log(error);

                         });

                    })
                    .catch(function(error){

                        let objError = error.toJSON();

                        // console.log(objError);

                        if(objError.status == 400){

                            Helper.alert("Failed", "error", "Company Name already exist", 3000);

                        }

                    });

                        let data  = {
                            // "company_id" :"dfa722f06b3040ed914cc2c821dff677",
                            "company_id" : Helper.hiddenChar(companyIdParams),
                            "name" : poiName,
                            "category" : poiCategory,
                            "free1" : freeField1,
                            "free2" : freeField2,
                            "latitude" : String(latitude),
                            "longitude": String(longitude),
                            "icon_shape" : poiShape,
                            "icon_color" : poiColor,
                            "updated_by" : localStorage.getItem("usernameLogin"),
                            "created_by" : localStorage.getItem("usernameLogin")
                        };

                        // console.log(data);

                        await axios.post(Uri.rootUri + `poi/`, data,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                        .then(function(response){

                            // console.log(response);

                            Helper.alert("Success", "success", "Success save data", 3000);

                        })
                        .catch(function(error){

                            console.log(error)

                        });

                        window.onunload = Helper.refreshParent();

                        history.push(`ViewCompany/${companyIdParams}`);

                    }

                    window.location.reload();
            }


        }

    }

    async function _updateData(e){

        e.preventDefault();

        if(!companyName || !subscriptionType || !subscriptionType || !endOfContract){

            setIsInvalid(true);

            setTimeout(()=>setIsInvalid(false),3000);

        }else{


            // let suffix = '['
            // let prefix = ']'
            let suffix = ""
            let prefix = ""

            let arrIncidentType = [];

            incidentTypeSelection.forEach(x=>{

                let str = Helper.hiddenChar(x);

                arrIncidentType.push(str);
            });


            let incidentType = suffix + arrIncidentType + prefix;

            let data = {

                "companyname" : companyName,
                "tablelausoftwarelogin" : tableau,
                "subscriptiontype" : subscriptionType,
                "incidenttypeselecteion": incidentType,
                "subscriptiondate" : moment(subscriptionDate, "DD/MM/YYYY").format("DD-MM-YYYY"),
                "endofcontract" : moment(endOfContract, "DD/MM/YYYY").format("DD-MM-YYYY"),
                "incidentdaterange_id" : incidentDateRange,
                "maximumpoi" : maximumPoi,
                "created_by" : createdBy,
                "updated_by" : updatedBy

            }

            // console.log(data);

            await axios.put(Uri.rootUri + `company/${companyId}/`, data ,  { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                // console.log(response);

                caches.delete("company");

                setIsInvalid(false);

                Helper.alert("Success", "success", "Success update data", 3000);

                _loadData(companyId);


                //API for update cache
                let cacheName = {

                    "name" : "company",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                    }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function(response){

                    // console.log(response);

                })
                .catch(function(error){

                    console.log(error);

                });

                window.onunload = Helper.refreshParent();

            })
            .catch(function(error){

                console.log(error);

            });

            // console.log(data);

        }

    }

    async function _loadData(id){

        // console.log(id);
        if(id){

            await axios.get(Uri.rootUri + `company/${id}/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(function(response){

                // console.log(response);

                let dataResponse = response.data;

                setIdCompany(Helper.hiddenChar(dataResponse.id));

                setCompanyName(dataResponse.companyname);

                setTableau(dataResponse.tablelausoftwarelogin);

                setSubscriptionType(dataResponse.subscriptiontype);

                setSubscriptionDate(moment(dataResponse.subscriptiondate).format("DD/MM/YYYY"));

                setEndOfContract(moment(dataResponse.endofcontract).format("DD/MM/YYYY"));

                setIncidentDateRange(dataResponse.incidentdaterange_id);

                setMaximumPoi(dataResponse.maximumpoi);

                setCreatedBy(dataResponse.created_by);

                setUpdatedBy(dataResponse.updated_by);

                setQuotaPoi(dataResponse.quotapoi);

                setShow(true);

                let arrIncidentType = [];

                let convertArrTypeIncident = Array.from(dataResponse.incidenttypeselecteion.split(','));

                convertArrTypeIncident.forEach(x=>[

                    arrIncidentType.push(Helper.hiddenChar(x))

                ]);
                // console.log(arrIncidentType);
                setIncidentTypeSelection(arrIncidentType);





            })
            .catch(function(error){

                console.log(error);

            });

        } else {
            const defaultSelectedSubscriptionType = 1; // Platinum
            const subscriptionData = templateSubscriptionData.find((x) => x.subscriptiontype == defaultSelectedSubscriptionType);
            const defaultIncidentTypeSelection = subscriptionData.incidenttypeselecteion.split(',').map(x => Helper.hiddenChar(x));

            setIncidentTypeSelection(defaultIncidentTypeSelection);
            setMaximumPoi(subscriptionData.maximumpoi);
            setShow(false);

            setIdCompany("");

        }


    }

    async function _loadSubscriptionTypeData(e){
        let arr = [
            <option value="1">Platinum</option>,
            <option value="2">Gold</option>,
            <option value="3">Silver</option>,
            <option value="4">Bronze</option>
        ]

        setElSubscriptionType(arr);

    }

    async function _loadIncidentTypeData(e){

        await axios.get(Uri.rootUri + `master/incident/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(function(response){

            let arr = [];

            let dataResponse = response.data;

            arr.push(<Option key='11111111111111111111111111111111' >Unselected</Option>);

            dataResponse.forEach(x=>{

                arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

            });

            setElTypeOfIncident(arr);

            // console.log(response);

        })
        .catch(function(error){

            console.log(error);

        });



    }

    async function _loadIncidentDateRangeData(){

        let arr = [
            <option value="-9999" selected>All Period</option>,
            <option value="-1111">Since 1 February 2021</option>,
            <option value="-6">Last 6 months sliding period</option>,
            <option value="-3">Last 3 months sliding period</option>,
        ]

        setElIncidentDateRange(arr);
    }

    async function _cancel(e){

        e.preventDefault();

        window.close();

    }

    async function _loadUserInformation(){

        const userInformation = await Helper.getUserLogin();

        if(userInformation){

          setUserId(userInformation.user_id);

          setEmailAddress(userInformation.email_address);

          setAuthId(userInformation.auth_id);

          setCompanyIdUser(userInformation.company_id);

          setUsername(userInformation.username);

          let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));

          setPermissions(strPermissions);

        //   console.log(strPermissions, customerManager);

          if(!strPermissions.includes(customerManager)){// if not Permission Type Edit Incident

                // history.push("Unauthorize");

                window.location.replace(`${window.location.origin}/Index/Unauthorize`);

                // window.location.replace("http://localhost:3000/Index/Unauthorize");

            }

        }

      }

    useEffect(()=>{
      const effect = async () => {
        // ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadUserInformation();

        await _loadData(companyId);
        await _loadSubscriptionTypeData();
        await _loadIncidentDateRangeData();
        await _loadIncidentTypeData();

        setLoading(false);
      }
      effect();
    },[]);


  return (
<Spin spinning={loading} size='large'>
    <div className="addEditNewCompany">
        {/* row */}
        <div className="row mb-3">
            <div className="col-lg-12">
                <div className="card">
                        <div className="card-header bg-secondary text-white text-center">
                            Customer Details
                        </div>
                        <div className="card-body">
                            {/* row */}
                           <div className="row">
                               <div className="col-lg-6">
                                   <div className="mb-3">
                                        <label htmlFor='typeofincident ' className='m-1 d-flex align-items-start'>Company Name: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>
                                        { isInvalid && !companyName ?
                                            <div id="companyname" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                Please insert a Company Name.
                                            </div>
                                            :
                                            <></>
                                        }
                                        <input type="text" className="form-control" id='companyname' onChange={_companyName} value={companyName ? companyName : ""}/>
                                   </div>
                               </div>
                               <div className="col-lg-6">
                                   <div className="mb-3">
                                        <label htmlFor='tablelau ' className='m-1 d-flex align-items-start'>Tableau Software Login:</label>
                                        <input type="email" className="form-control" id='tablelau' onChange={_tableau} value={tableau ? tableau : ""}/>
                                   </div>
                               </div>
                           </div>
                            {/* end row */}
                        </div>
                </div>
            </div>
        </div>
        {/* end row */}
        {/* row */}
        <div className="row mb-3">
            <div className="col-lg-12">
                <div className="card">
                        <div className="card-header bg-secondary text-white text-center">
                            Subscription Details
                        </div>
                        <div className="card-body">
                            {/* row */}
                           <div className="row">
                               <div className="col-lg-6">
                                   <div className="mb-3">
                                        <label htmlFor='typeofincident ' className='m-1 d-flex align-items-start'>Subscription Type: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>
                                        { isInvalid && !subscriptionType ?
                                            <div id="subscriptiontype" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                Please insert a Subscription Type.
                                            </div>
                                            :
                                            <></>
                                        }
                                        <select className="form-select" aria-label="Default select example" required onChange={_subscriptionType} value={subscriptionType ? subscriptionType : ""}>
                                            <option value="" selected></option>
                                            {elSubscriptionType}
                                        </select>
                                   </div>
                               </div>
                               <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor='typeofincident ' className='m-1 d-flex align-items-start'>Incident Category Selection:</label>
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Multiple Selected Incident ..."
                                            onChange={_handleChangeIncidentTypeSelection}
                                            value={incidentTypeSelection ? incidentTypeSelection :  "11111111111111111111111111111111"}
                                            size={"large"}
                                            required
                                            filterOption={(input, option) =>
                                                // console.log(option, input)
                                                // console.log(option.children.toLowerCase())
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {elTypeOfIncident}
                                        </Select>
                                   </div>
                               </div>
                               <div className="col-lg-3">
                                    <div className="mb-3">
                                        <label htmlFor='subscriptiondate ' className='m-1 d-flex align-items-start'>Subscription Date: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>
                                        {   isInvalid && !subscriptionDate ?

                                            <div id="subscriptiondate" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                Please insert a Subscription Date.
                                            </div>
                                        :
                                            <></>

                                        }
                                        {/* <input type="date" className="form-control" id='subscriptiondate' onChange={_subscriptionDate} value={subscriptionDate ? subscriptionDate : ""}/> */}
                                        <DatePicker id='subscriptiondate' onChange={_subscriptionDate} value={subscriptionDate ? subscriptionDate : ""} />
                                   </div>
                               </div>
                               <div className="col-lg-3">
                                    <div className="mb-3">
                                        <label htmlFor='endofcontract ' className='m-1 d-flex align-items-start'>End of Contract: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>

                                        {   isInvalid && !endOfContract ?

                                            <div id="endofcontract" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                Please insert a End of Contract.
                                            </div>
                                        :
                                            <></>

                                        }

                                        {/* <input type="date" className="form-control" id='endofcontract' onChange={_endOfContract} value={endOfContract ? endOfContract : ""}/> */}
                                        <DatePicker id='endofcontract'  onChange={_endOfContract} value={endOfContract ? endOfContract : ""} />
                                   </div>
                               </div>
                               <div className="col-lg-3">
                                    <div className="mb-3">
                                        <label htmlFor='incidentdaterange ' className='m-1 d-flex align-items-start'>Incident Date Range:</label>
                                        <select className="form-select" aria-label="Default select example" required onChange={_incidentDateRange} value={ incidentDateRange ? incidentDateRange : "" }>
                                            {elIncidentDateRange}
                                        </select>
                                   </div>
                               </div>
                               <div className="col-lg-3">
                                    <div className="mb-3">
                                        <label htmlFor='maximumpoi ' className='m-1 d-flex align-items-start'>Maximum POI:</label>
                                        <input type="number" className="form-control" id='maximumpoi' onChange={_maximumPoi} value={maximumPoi ? maximumPoi : ""}/>
                                   </div>
                               </div>
                           </div>
                           {/* end row */}
                        </div>
                </div>
            </div>
        </div>
        {/* end row */}

        {/* row */}
        <div className="row mb-3">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header bg-secondary text-white text-center">
                        POI Detail
                    </div>
                    <div className="card-body">
                        {/* {console.log(idCompany)} */}
                        <PointOfInterest
                            buttonAddPoi={show}
                            company = {true}
                            setPoiName = {setPoiName}
                            setPoiCategory = {setPoiCategory}
                            setFreeField1 = {setFreeField1}
                            setFreeField2 = {setFreeField2}
                            setLatitude = {setLatitude}
                            setLongitude = {setLongitude}
                            setPoiShape = {setPoiShape}
                            setPoiColor = {setPoiColor}
                            setIsInvalid = {setIsInvalid}
                            companyId = {companyId}
                            quotaPoi = {quotaPoi}
                        />
                    </div>
                </div>
            </div>
        </div>
        {/* end row */}
        <div className="row">
            <div className="col-lg-12">
                <div className="mb-3 text-end">
                    { companyId ?

                        <button type="submit" className="btn btn-primary mx-1" onClick={_updateData}>Save</button>

                        :

                        <button type="submit" className="btn btn-primary mx-1" onClick={_saveData}>Create</button>

                    }
                    <button type="submit" className="btn btn-primary mx-1" onClick={_cancel}>Cancel</button>
                </div>
            </div>
        </div>

    </div>
</Spin>
  );
}
