import React, { useEffect } from 'react'
import { Spin } from "antd";
import { useHistory } from 'react-router-dom';

export default function AuthLoading() {
  const history = useHistory();

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Check for token presence every second
      const localToken = localStorage.getItem("token");
      if (localToken) {
        clearInterval(intervalId);
        // history.replace("/Index");
        history.replace("/Index/embed/LandingPage");
        // history.replace("/Index");
      }
    }, 1000);

    return () => {
      clearInterval(intervalId); // Clean up on unmount or condition met
    };
  }, [])

  return (
    <div style={{ height: 600 }} className='d-flex justify-content-center align-items-center'>
      <div className='my-5 text-center'>
        <Spin size='large' spinning={true}></Spin>
        <p className='text-secondary'><strong>Loading...</strong></p>
      </div>
    </div>
  )
}
