import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import MainDashboard from './components/MainDashboard';
import Login from './components/Login';
import Authorization from './components/Authorization';
import Unauthorize from './components/Unauthorize';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { AxiosService } from './axios.service.ts';
import Loading from './components/utils/Loading';
import AuthLoading from './components/utils/AuthLoading';

export const AxiosContext = React.createContext(null);

export const useAxios = () => {
  return React.useContext(AxiosContext);
}

function App({ instance }) {
  const [axiosContextData, setAxiosContextData] = React.useState(null);
  const [authLoading, setAuthLoading] = React.useState(true);
  const isAuthenticated = useIsAuthenticated();

  const setUnauthenticated = () => {
    const oldToken = localStorage.getItem('token');
    setAxiosContextData(new AxiosService(oldToken, setAxiosContextData, instance));
    setAuthLoading(false);
  }

  // Set up the Axios instance with the accessToken in the header
  useEffect(() => {
    const effect = async () => {
      try {
        if (!!!isAuthenticated) {
          setUnauthenticated();
          return;
        }

        const accounts = instance.getAllAccounts();
        if (accounts?.length === 0) {
          setUnauthenticated();
          return;
        }

        let token = accounts[0].idToken;
        if (!!!token) {
          token = (await instance.ssoSilent(loginRequest)).idToken;

          if (!!!token) {
            setUnauthenticated();
            return;
          }
        };

        localStorage.setItem("token", token);
        setAxiosContextData(new AxiosService(token, setAxiosContextData, instance));
        setAuthLoading(false);
        console.log('Token refreshed');
      } catch (error) {
        console.log(error);
      }
    }
    effect();

  }, [instance]);



  if (authLoading) return (<Loading />)

  return (
    <MsalProvider instance={instance} >
      <AxiosContext.Provider value={axiosContextData}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/Login" />
          </Route>
          <Route path="/Login" component={Login} />
          <Route path="/User/SignIn/:token" component={Authorization} />
          <Route path="/Index" component={MainDashboard} />

          {/* Redirection page after sign in */}
          <Route exact path="/auth-redirect" render={(props) => (<AuthLoading {...props} />)} />

          {/* if page not found */}
          <Route path="/*" component={Unauthorize} />
        </Switch>
      </AxiosContext.Provider>
    </MsalProvider>
  )
}

export default App
