import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import axios from "axios";
import Uri from "../../Uri";
import { Modal, Button } from "react-bootstrap";
import Helper from "../helpers/Helper";
import PaginationComponent from "../utils/PaginationComponent";
import ErrorMsgBarParty from "../utils/ErrorMsgBarParty";
import Swal from 'sweetalert2';
import { useAxios } from "../../App";
import { useMsal } from "@azure/msal-react";

export default function Party3CivilianSelfDefenceForces(props) {
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();
    

    const authToken = localStorage.getItem("token");


    let [party3CivilianSelfDefenceForces, setParty3CivilianSelfDefenceForces] = useState("");
    let [party3CivilianSelfDefenceForcesUpdate, setParty3CivilianSelfDefenceForcesUpdate] = useState("");
    let [dataSource, setDataSource] = useState([]);
    let [dataSourceOld, setDataSourceOld] = useState([]);
    let [isInvalid, setIsInvalid] = useState();
    let [isInvalidUpdate, setIsInvalidUpdate] = useState();
    let [idData, setIdData] = useState("");
    let [search, setSearch] = useState("");
    let [loading, setLoading] = useState(true);
    let [showSizeChanger, setShowSizeChanger] = useState();
    let [columnField, setColumnField] = useState("");
    let [orderSort, setOrderSort] = useState("");
    let [isError, setIsError] = useState(false);


    let [currentPage, setCurrentPage] = useState();
    let [pageSize, setPageSize] = useState();
    let [totalData, setTotalData] = useState();


    const [showUpdate, setShowUpdate] = useState();
    const [showDelete, setShowDelete] = useState();

    function handleCloseDelete(e) {

        setShowDelete(false);

    };
    function handleShowDelete(e) {

        setShowDelete(true);

    }

    function handleCloseUpdate(e) {

        setShowUpdate(false);

    };

    function handleShowUpdate(e) {

        setShowUpdate(true);

    }

    function _onChangeParty3CivilianSelfDefenceForces(e) {

        e.preventDefault();

        if (e.target.value) {

            setParty3CivilianSelfDefenceForces(e.target.value);

        } else {

            setParty3CivilianSelfDefenceForces("");

        }

    }

    function _onChangeParty3CivilianSelfDefenceForcesUpdate(e) {

        e.preventDefault();

        if (e.target.value) {

            setParty3CivilianSelfDefenceForcesUpdate(e.target.value);

        } else {

            setParty3CivilianSelfDefenceForcesUpdate("");

        }

    }

    function _searchForm(e) {

        e.preventDefault();

        let keyword = e.target.value;

        if (keyword) {

            setSearch(keyword);

        } else {

            setSearch("");

        }

    }

    async function _saveData(e) {

        e.preventDefault();



        if (!party3CivilianSelfDefenceForces) {

            setIsInvalid(true);

            setTimeout(() => setIsInvalid(false), 3000);

        } else {

            if ((party3CivilianSelfDefenceForces.split(',').length > 1) || (party3CivilianSelfDefenceForces.split(';').length > 1)) {

                setIsError(true);
                setTimeout(() => setIsError(false), 3000)

            } else {

                let data = {

                    "name": party3CivilianSelfDefenceForces,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `master/party/three/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(async function (response) {

                        caches.delete("party3");// delete cache for update data in cache
                        // console.log(response);
                        let cacheName = {

                            "name": "party3",
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                // console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                        _loadDataAll("party3");

                        setParty3CivilianSelfDefenceForces("");

                        Helper.alert("Success", "success", "Success save data", 3000);

                        _loadData(currentPage, pageSize);

                    })
                    .catch(function (error) {

                        // console.log(error);
                        let objError = error.toJSON();

                        if (objError.status == 400) {

                            Helper.alert("Failed", "error", "This party value already exist", 3000);

                        }



                    });
            }

        }

    }

    async function _updateParty3CivilianSelfDefenceForces(e) {

        e.preventDefault();



        if (!party3CivilianSelfDefenceForcesUpdate) {

            setIsInvalidUpdate(true);

            setTimeout(() => setIsInvalidUpdate(false), 3000);

        } else {


            if ((party3CivilianSelfDefenceForcesUpdate.split(',').length > 1) || (party3CivilianSelfDefenceForcesUpdate.split(';').length > 1)) {

                setIsError(true);
                setTimeout(() => setIsError(false), 3000)

            } else {

                let data = {

                    "name": party3CivilianSelfDefenceForcesUpdate,
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.put(Uri.rootUri + `master/party/three/${idData}/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(async function (response) {

                        // console.log(response);

                        caches.delete("party3");// delete cache for update data in cache
                        // console.log(response);
                        let cacheName = {

                            "name": "party3",
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                // console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                        _loadDataAll("party3");

                        setParty3CivilianSelfDefenceForcesUpdate("");

                        Helper.alert("Success", "success", "Success update data", 3000);

                        setShowUpdate(false);

                        _loadData(currentPage, pageSize);

                    })
                    .catch(function (error) {

                        // console.log(error);

                        setShowUpdate(false);



                    });


            }
        }

    }
    async function _approveDelete(id, confirmation) {

        let data = {
            "confirmation": confirmation,
            "deleted_by": localStorage.getItem("usernameLogin")
        }

        try {
            await axios.put(Uri.rootUri + `master/party/three/${id}/delete/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(async function (response) {
                    caches.delete("party3");// delete cache for update data in cache
                    // console.log(response);
                    let cacheName = {
                        "name": "party3",
                        "created_by": localStorage.getItem("usernameLogin"),
                        "updated_by": localStorage.getItem("usernameLogin")
                    }

                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                        .catch(function (error) {
                            console.log(error);
                        });
                    _loadDataAll("party3");
                    setShowDelete(false);
                    Helper.alert("Success", "success", "Success delete data", 3000);
                    _loadData(currentPage, pageSize);
                })
        } catch (error) {
            setShowDelete(false);
            console.log(error);
            return error.response;
        }


    }
    async function _deleteParty3CivilianSelfDefenceForces(e, id) {

        e.preventDefault();
        setShowDelete(false);
        Swal.fire({
            title: 'Loading...',
            html: 'Please wait just a moment',
            timerProgressBar: true,
            didOpen: () => Swal.showLoading()
        })
        if (id) {
            const response = await _approveDelete(id, false);
            console.log(response);
            if (response?.status == 400 && response?.data?.res?.message == "Party is used in incident") {
                Helper.dialogConfirmation(`This party is used in ${response?.data?.res?.countOfIncident} incident(s). You cannot revert this operation.`, async () => await _approveDelete(id, true))
            }
        } else {
            setShowDelete(false);
        }
    }


    const columns = [
        {
            title: 'Party 3 : Civilian Self Defence Forces',
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            sorter: true,
            // sorter: (a, b) => a.name.localeCompare(b.name),
            width: '80%',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record, index) => (
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                        handleShowUpdate(e);
                        setParty3CivilianSelfDefenceForcesUpdate(record.name);
                        setIdData(record.id);
                    }} id="update"><i className="bi bi-pencil-fill"></i>
                    </button>

                    <button type="button" className="btn btn-primary btn-sm"
                        onClick={(e) => {
                            handleShowDelete(e);
                            setIdData(record.id);
                        }} id="delete"><i className="bi bi-trash-fill"></i>
                    </button>
                </div>
            )
        }
    ];

    async function _loadData(page, pageSize) {

        setCurrentPage(page)

        setPageSize(pageSize);

        // setColumnField(columnField ? columnField : "name");

        setOrderSort(orderSort ? orderSort : "asc");

        // let column = columnField ? `order_by=${columnField}&` : `order_by=name&`

        let order = orderSort ? `order=${orderSort}&` : `order=asc&`

        await axios.get(Uri.rootUri + `master/party/three/?page=${page}&page_size=${pageSize}&${order}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let dataResponse = response.data;

                setTotalData(dataResponse.count);

                setShowSizeChanger(response.data.count > 50 ? true : false);

                setDataSource(dataResponse.results);


            })
            .catch(function (error) {

                console.log(error);


            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("party3")){

        //     await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         setDataSource(dataResponse);

        //         Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

        //     })
        //     .catch(function(error){

        //         console.log(error);


        //     });

        // }else{

        //     let resultData = arrCache.filter( x => x == "party3"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party3`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             setDataSource(dataResponse);

        //             Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

        //         })
        //         .catch(function(error){

        //             console.log(error);


        //         });

        //       }else{ //if data in cache not empty


        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 setDataSource(dataResponse);

        //                 Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

        //             })
        //             .catch(function(error){

        //                 console.log(error);


        //             });

        //         }else{


        //             setDataSource(dataResponse);


        //         }


        //       }


        //     });

        // }
    }

    async function onChange(page, pageSize) {

        // console.log(page,pageSize,currentPage); //page = new previous  page and new next page

        setPageSize(pageSize);

        setCurrentPage(page);

        // let column = columnField ? `order_by=${columnField}&` : ""; //this is field columns

        let order = orderSort !== undefined ? `order=${orderSort}&` : "" //this is sort order (Ascending, Descending, Default);

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=100&';

        let searchText = search ? `search=${search}&` : "";

        await axios.get(Uri.rootUri + `master/party/three/?${currentPageOnChange}${currentPageSizeOnChange}${searchText}${order}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let dataResponse = response.data;

                setTotalData(dataResponse.count);

                setShowSizeChanger(response.data.count > 50 ? true : false);

                setDataSource(dataResponse.results);

            })
            .catch(function (error) {

                console.log(error);

            });

    }

    async function _loadDataAll(cachenameparam) {

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("party3")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "party3");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let party3Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party3');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !party3Response || party3Response == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseParty3 = await party3Response.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty3 || dataResponseParty3.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseParty3 there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/party/three/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponseParty3);

                                        Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("party3")){

        //     await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse = response.data;

        //         Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

        //     })
        //     .catch(function(error){

        //         console.log(error);


        //     });

        // }else{

        //     let resultDataIncident = arrCache.filter( x => x == "party3"); //get where cache name

        //     resultDataIncident.map(async cachename => {

        //     let cacheStorage = await caches.open(cachename);

        //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party3`);

        //     if(!cacheResponse || cacheResponse == undefined){

        //         await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

        //         })
        //         .catch(function(error){

        //             console.log(error);


        //         });

        //     }else{

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

        //             })
        //             .catch(function(error){

        //                 console.log(error);


        //             });

        //         }

        //     }

        //     });

        // }

    }

    async function _onSearch(e) {

        e.preventDefault();

        let onFilterPageSize = pageSize ? `page_size=${pageSize}&` : `page_size=100&`;

        let searchText = search ? `search=${search}&` : "";

        // let column = columnField ? `order_by=${columnField}&` : ""; //this is field columns

        let order = orderSort !== undefined ? `order=${orderSort}&` : "" //this is sort order (Ascending, Descending, Default);

        await axios.get(Uri.rootUri + `master/party/three/?page=1&${searchText}${onFilterPageSize}${order}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let responseData = response.data;

                setDataSource(responseData.results);

                setTotalData(responseData.count);

                setShowSizeChanger(response.data.count > 50 ? true : false);

                setCurrentPage(1);

            })
            .catch(function (error) {

                console.log(error);

            });

    }

    async function _showSizeChange(page, pageSize) {

        let searchText = search ? `search=${search}&` : "";

        let currentPageSize = pageSize ? `page_size=${pageSize}&` : `page_size=100&`

        let currentPageOnFilter = page ? `page=${page}&` : `page=1&`

        // let column = columnField ? `order_by=${columnField}&` : ""; //this is field columns

        let order = orderSort !== undefined ? `order=${orderSort}&` : "" //this is sort order (Ascending, Descending, Default);

        await axios.get(Uri.rootUri + `master/party/three/?${searchText}${currentPageOnFilter}${currentPageSize}${order}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let responseData = response.data;

                setDataSource(responseData.results);

                setTotalData(responseData.count);

                setShowSizeChanger(response.data.count > 50 ? true : false);

            })
            .catch(function (error) {

                console.log(error);

            });

    }

    function onShowSizeChange(current, size) {

        setCurrentPage(current);

        setPageSize(size);

        _showSizeChange(current, size);

    }

    async function handleTableChange(pagination, filters, sorter) {

        let fieldColumn = sorter;

        // let column = fieldColumn.field && ( fieldColumn.order !== undefined ) ? `order_by=${fieldColumn.field}&` : ""; //this is field columns

        let order;

        let orderState;

        if (fieldColumn.order !== undefined) {

            switch (fieldColumn.order) {
                case "ascend":
                    order = `order=asc&` //this is sort order (Ascending, Descending, Default);
                    orderState = "asc";
                    break;
                case "descend":
                    order = `order=desc&` //this is sort order (Ascending, Descending, Default);
                    orderState = "desc";
                    break;

                default:
                    break;
            }

        } else {

            order = "" //this is sort order (Ascending, Descending, Default);
            orderState = undefined;
            // order = `order=default&` //this is sort order (Ascending, Descending, Default);
            // orderState = 'default';
        }

        let searchText = search ? `search=${search}&` : "";

        let currentPageSize = pageSize ? `page_size=${pageSize}&` : `page_size=100&`

        let currentPageOnFilter = currentPage ? `page=${currentPage}&` : `page=1&`

        await axios.get(Uri.rootUri + `master/party/three/?${searchText}${currentPageOnFilter}${currentPageSize}${order}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let responseData = response.data;

                setDataSource(responseData.results);

                setTotalData(responseData.count);

                setShowSizeChanger(response.data.count > 50 ? true : false);

                // setColumnField(fieldColumn.field && (fieldColumn.order !== undefined) ? fieldColumn.field : ""); //set column field

                setOrderSort(orderState);//set order sort (Ascending, Descending, Default)

            })
            .catch(function (error) {

                console.log(error);

            });

    }


    useEffect(() => {
        // console.log(props.dataSource);
        const effect = async () => {

        //ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadData(1, 100);
        await _loadDataAll("party3");
        
        setLoading(false);
      }
      effect();
    }, []);
    return (

        <Spin size="large" spinning={loading}>
            <div className="party3civilianselfdefenceforces">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Add New Party 3 : Civilian Self Defence Forces:</h4>
                        <div className="mb-3">
                            <label htmlFor="party3civilianselfdefenceforces">Party 3 : Civilian Self Defence Forces</label>
                            {isInvalid && !party3CivilianSelfDefenceForces ?

                                <div id="party3civilianselfdefenceforces" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert Party 3 : Civilian Self Defence Forces.
                                </div>
                                :
                                <></>
                            }
                            <input type="text" className="form-control" name="party3civilianselfdefenceforces" id="party3civilianselfdefenceforces" onChange={_onChangeParty3CivilianSelfDefenceForces} value={party3CivilianSelfDefenceForces && party3CivilianSelfDefenceForces} />
                        </div>
                        {(isError && !!!showUpdate) && <ErrorMsgBarParty />}
                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }} onClick={_saveData}><i className="bi bi-plus-circle-fill"></i> Add to Master Data</button>
                    </div>
                    <div className="col-lg-8">
                        <h4>Party 3 : Civilian Self Defence Forces Data</h4>
                        <div className='d-flex align-items-center ml-5 mb-3'>
                            <div className="d-inline-block mx-2" >
                                <label htmlFor="search" className="col-form-label">Search: </label>
                            </div>
                            <div class="input-group" style={{ width: 250 }}>
                                <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={_searchForm} value={search ? search : ""} />
                                <button class="btn btn-primary" type="submit" id="button-addon2" onClick={_onSearch}><i class="bi bi-search"></i></button>
                            </div>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            scroll={{ x: 'max-content' }}
                            onChange={handleTableChange}
                        />
                        <div className="text-center mt-3">
                            <PaginationComponent
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                onChange={onChange}
                                showSizeChanger={showSizeChanger}
                                onShowSizeChange={onShowSizeChange}
                                pageSizeOptions={[50, 100, 150]}
                                total={totalData ? totalData : 0}
                                defaultPageSize={100}
                            />
                        </div>
                    </div>

                    <>
                        <Modal show={showUpdate} onHide={handleCloseUpdate} id={`update`}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Data</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="mb-3">
                                    <label htmlFor="party3civilianselfdefenceforces">Party 3 : Civilian Self Defence Forces</label>
                                    {isInvalidUpdate && !party3CivilianSelfDefenceForcesUpdate ?

                                        <div id="party3civilianselfdefenceforces" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                            Please insert Party 3 : Civilian Self Defence Forces
                                        </div>
                                        :
                                        <></>
                                    }
                                    <input type="text" className="form-control" name="party3civilianselfdefenceforces" id="party3civilianselfdefenceforces" onChange={_onChangeParty3CivilianSelfDefenceForcesUpdate} value={party3CivilianSelfDefenceForcesUpdate && party3CivilianSelfDefenceForcesUpdate} />
                                </div>
                                {(isError && showUpdate) && <ErrorMsgBarParty />}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseUpdate}>
                                    Close
                                </Button>
                                <Button type="submit" variant="primary" onClick={_updateParty3CivilianSelfDefenceForces}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='m-0'>Delete this data ?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseDelete}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={(e) => _deleteParty3CivilianSelfDefenceForces(e, idData)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>

                </div>
            </div>
        </Spin>
    )
}