import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Spin, Select } from "antd";
import moment from "moment";
import Uri from "../Uri";
import axios from "axios";
import PaginationComponent from "./utils/PaginationComponent";
import { useMediaQuery } from "react-responsive";
import Helper from './helpers/Helper';
import { ReadMoreMore } from 'read-more-more';
import { _loadCache } from "./IncidentSubmissions";
import { useAxios } from "../App";
import { useMsal } from "@azure/msal-react";
import DatePicker from "./utils/DatePicker";

export default function ApprovedIncidents(props) {
  const axios = useAxios();
  const { instance: msalInstance } = useMsal();

  const authToken = localStorage.getItem("token");

  const { Option } = Select;

  let history = useHistory();

  //User Information
  let [users, setUsers] = useState([]);
  let [username, setUsername] = useState("");
  let [emailAddress, setEmailAddress] = useState("");
  let [authId, setAuthId] = useState("");
  let [companyId, setCompanyId] = useState("");
  let [userId, setUserId] = useState("");
  let [permissions, setPermissions] = useState([]);
  let [approveIncident, setApproveIncident] = useState("1");
  let [customerManager, setCustomerManager] = useState("2");
  let [editIncident, setEditIncident] = useState("3");
  let [poiSelfService, setPoiSelfService] = useState("4");
  let [readOnly, setReadOnly] = useState("5");
  let [superUser, setSuperUser] = useState("6");
  let [showSizeChanger, setShowSizeChanger] = useState();

  let [currentPage, setCurrentPage] = useState();
  let [currentPageTownship, setCurrentPageTownship] = useState();
  let [dataSource, setDataSource] = useState([]);
  let [pageSize, setPageSize] = useState();
  let [totalData, setTotalData] = useState();
  let [nextPage, setNextPage] = useState();
  let [loading, setLoading] = useState(true);
  let [subTypeIncident, setSubTypeIncident] = useState();
  let [subTypeIncidentData, setSubTypeIncidentData] = useState([]);
  let [typeIncidentData, setTypeIncidentData] = useState([]);
  let [party1Data, setParty1Data] = useState([]);
  let [party3Data, setParty3Data] = useState([]);
  let [search, setSearch] = useState("");
  let [columnField, setColumnField] = useState("");
  let [orderSort, setOrderSort] = useState("");

  let [targetHumanData, setTargetHumanData] = useState([]);
  let [elTargetHuman, setElTargetHuman] = useState([]);
  let [targetAssetsData, setTargetAssetsData] = useState([]);
  let [elTargetAssets, setElTargetAssets] = useState([]);

  let [stateRegionData, setStateRegionData] = useState([]);
  let [townshipData, setTownshipData] = useState([]);
  let [arrTownshipTemp, setArrTownshipTemp] = useState([]);


  let [elStateRegion, setElStateRegion] = useState([]);
  let [elTownship, setElTownship] = useState([]);
  let [elSubTypeIncident, setElSubTypeIncident] = useState([]);
  let [elTypeOfIncident, setElTypeOfIncident] = useState([]);
  let [elUser, setElUser] = useState([]);
  let [elParty1, setElParty1] = useState([]);
  let [elParty3, setElParty3] = useState([]);

  //filter
  let [draftedBy, setDraftedBy] = useState("");
  let [incidentDate, setIncidentDate] = useState();
  let [incidentType, setIncidentType] = useState();
  let [stateRegion, setStateRegion] = useState();
  let [township, setTownship] = useState();
  let [party1, setParty1] = useState();
  let [party3, setParty3] = useState();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1199px)' });
  const laptopOrDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

  async function onChange(page, pageSize) {

    // console.log(page,pageSize); //page = new previous  page and new next page

    let previous;

    let arr = [];

    setPageSize(pageSize);

    setCurrentPage(page);

    let column = columnField ? `order_by=${columnField}&` : ""; //this is field columns
    // let order = orderSort !== undefined ? `order=${orderSort}&` : `order=default&` //this is sort order (Ascending, Descending, Default);
    let order = orderSort !== undefined ? `order=${orderSort}&` : "" //this is sort order (Ascending, Descending, Default);

    let currentPageSize = pageSize ? `page_size=${pageSize}&` : `page_size=50&`;
    let currentPageOnFilter = page ? `page=${page}&` : `page=1&`;
    let draftedByFilter = draftedBy ? `drafted_by=${draftedBy}&` : "";
    let incidentDateFilter = incidentDateFormatted ? `incidentdate=${incidentDateFormatted}&` : "";
    let typeOfIncidentIdFilter = incidentType ? `typeofincident_id=${incidentType}&` : "";
    let townshipIdFilter = township ? `township_id=${township}&` : "";
    let stateRegionIdFilter = stateRegion ? `stateregion_id=${stateRegion}&` : "";
    let subTypeOfIncidentIdFilter = subTypeIncident ? `subtypeofincident_id=${subTypeIncident}&` : "";
    let searchText = search ? `search=${search}&` : "";


    await axios.get(Uri.rootUri + `incident/list/?is_approve=${true}&${currentPageOnFilter}${currentPageSize}${draftedByFilter}${incidentDateFilter}${typeOfIncidentIdFilter}${subTypeOfIncidentIdFilter}${townshipIdFilter}${stateRegionIdFilter}${column}${order}${searchText}`, { headers: { "Authorization": `Bearer ${authToken}` } })

      .then(function (response) {

        let dataResponse = response.data.results;

        //   setPageSize(50); // size data per page

        setNextPage(response.data.next); //next page

        // if(response.data.previous !== null){

        //     previous = response.data.previous.search("page") !== -1 ? response.data.previous : response.data.previous + `&page=1`;

        // }

        // console.log(previous);

        // setPreviousPage(previous);//previous page

        setTotalData(response.data.count);//total all data;

        setShowSizeChanger(response.data.count > 50 ? true : false);

        dataResponse.forEach((x => {

          let arrParty1 = [];
          let arrParty2 = [];
          let arrParty3 = [];

          // let strParty1Prefix = x.party1myanmarsecurityforces_id.substr(1);
          // let strParty1Suffix = strParty1Prefix.substr(0,strParty1Prefix.length - 1);
          let convertArrParty1 = Array.from(x.party1myanmarsecurityforces_id.split(','));

          convertArrParty1.forEach(item => [

            arrParty1.push(Helper.hiddenChar(item))

          ]);

          // let strParty2Prefix = x.party2estalabilishedeao_id.substr(1);
          // let strParty2Suffix = strParty2Prefix.substr(0,strParty2Prefix.length - 1);
          let convertArrParty2 = Array.from(x.party2estalabilishedeao_id.split(','));

          convertArrParty2.forEach(item => {

            arrParty2.push(Helper.hiddenChar(item))

          })

          // let strParty3Prefix = x.party3civillianselfdefenceforces_id.substr(1);
          // let strParty3Suffix = strParty3Prefix.substr(0,strParty3Prefix.length - 1);
          let convertArrParty3 = Array.from(x.party3civillianselfdefenceforces_id.split(','));
          // console.log(arrParty1);

          convertArrParty3.forEach(item => {

            arrParty3.push(Helper.hiddenChar(item));

          });

          let obj = {
            "id": x.id,
            "number": x.number,
            "created_at": moment(x.created_at).format("DD-MM-YYYY"),
            "created_by": x.created_by,
            "deleted_at": moment(x.deleted_at).format("DD-MM-YYYY"),
            "deleted_by": x.deleted_by,
            "drafted_by": x.drafted_by,
            "updated_at": moment(x.updated_at).format("DD/MM/YYYY"),
            "updated_by": x.updated_by,
            "approved_at": moment(x.approved_at).format("DD/MM/YYYY"),
            "approved_by": x.approved_by,
            "status": x.status,
            "highpriority": x.highpriority,
            "incidentdate": x.incidentdate,
            "typeofincident_id": x.typeofincident_id,
            "numberofoccurences": x.numberofoccurences,
            "party2estalabilishedeao_id": arrParty2,
            "party4civillians_id": Helper.hiddenChar(x.party4civillians_id),
            "targethuman_id": Helper.hiddenChar(x.targethuman_id),
            "artillerysupport": x.artillerysupport,
            "airsupport": x.airsupport,
            "time": x.time,
            "subtypeofincident_id": Helper.hiddenChar(x.subtypeofincident_id),
            "party1myanmarsecurityforces_id": arrParty1,
            "party3civillianselfdefenceforces_id": arrParty3,
            "party5others_id": Helper.hiddenChar(x.party5others_id),
            "targetassets_id": Helper.hiddenChar(x.targetassets_id),
            "claimedbynug": x.claimedbynug,
            "tacticaldevelopment": x.tacticaldevelopment,
            "killedoct": x.killedoct,
            "injuredoct": x.injuredoct,
            "arrestedoct": x.arrestedoct,
            "civiladministratorresignationsoct": x.civiladministratorresignationsoct,
            "killedpct": x.killedpct,
            "injuredpct": x.injuredpct,
            "abductedpct": x.abductedpct,
            "killedctg": x.killedctg,
            "injuredctg": x.injuredctg,
            "abductedctg": x.abductedctg,
            "killedsft": x.killedsft,
            "injuredsft": x.injuredsft,
            "capturedsft": x.capturedsft,
            "desertionssft": x.desertionssft,
            "killedrft": x.killedrft,
            "injuredrft": x.injuredrft,
            "arrestedrft": x.arrestedrft,
            "killedeft": x.killedeft,
            "injuredeft": x.injuredeft,
            "arrestedeft": x.arrestedeft,
            "killedot": x.killedot,
            "injuredot": x.injuredot,
            "arrestedot": x.arrestedot,
            "incidentdescription": x.incidentdescription,
            "sourcetwo": x.sourcetwo,
            "latitude": x.latitude,
            "longitude": x.longitude,
            "roadstreet": x.roadstreet,
            "villagetract": x.villagetract,
            "town": x.town,
            "township_id": Helper.hiddenChar(x.township_id),
            "stateregion_id": Helper.hiddenChar(x.stateregion_id),
            "geoprecision_id": Helper.hiddenChar(x.geoprecision_id),
            "highway": x.highway,
            "commentonlocation": x.commentonlocation
          }

          arr.push(obj);

        }))

        setDataSource(arr);



      })
      .catch(function (error) {

        console.log(error);

      })


  }

  async function _showSizeChange(page, pageSize) {

    // console.log(page,pageSize); //page = new previous  page and new next page

    let previous;

    let arr = [];

    setPageSize(pageSize);

    setCurrentPage(page);

    let column = columnField ? `order_by=${columnField}&` : ""; //this is field columns
    // let order = orderSort !== undefined ? `order=${orderSort}&` : `order=default&` //this is sort order (Ascending, Descending, Default);
    let order = orderSort !== undefined ? `order=${orderSort}&` : "" //this is sort order (Ascending, Descending, Default);

    let currentPageSize = pageSize ? `page_size=${pageSize}&` : `page_size=50&`;
    let currentPageOnFilter = page ? `page=${page}&` : `page=1&`;
    let draftedByFilter = draftedBy ? `drafted_by=${draftedBy}&` : "";
    let incidentDateFilter = incidentDateFormatted ? `incidentdate=${incidentDateFormatted}&` : "";
    let typeOfIncidentIdFilter = incidentType ? `typeofincident_id=${incidentType}&` : "";
    let townshipIdFilter = township ? `township_id=${township}&` : "";
    let stateRegionIdFilter = stateRegion ? `stateregion_id=${stateRegion}&` : "";
    let subTypeOfIncidentIdFilter = subTypeIncident ? `subtypeofincident_id=${subTypeIncident}&` : "";
    let searchText = search ? `search=${search}&` : "";


    await axios.get(Uri.rootUri + `incident/list/?is_approve=${true}&${currentPageOnFilter}${currentPageSize}${draftedByFilter}${incidentDateFilter}${typeOfIncidentIdFilter}${subTypeOfIncidentIdFilter}${townshipIdFilter}${stateRegionIdFilter}${column}${order}${searchText}`, { headers: { "Authorization": `Bearer ${authToken}` } })

      .then(function (response) {

        let dataResponse = response.data.results;

        //   setPageSize(50); // size data per page

        setNextPage(response.data.next); //next page

        // if(response.data.previous !== null){

        //     previous = response.data.previous.search("page") !== -1 ? response.data.previous : response.data.previous + `&page=1`;

        // }

        // console.log(previous);

        // setPreviousPage(previous);//previous page

        setTotalData(response.data.count);//total all data;

        setShowSizeChanger(response.data.count > 50 ? true : false);

        dataResponse.forEach((x => {

          let arrParty1 = [];
          let arrParty2 = [];
          let arrParty3 = [];

          // let strParty1Prefix = x.party1myanmarsecurityforces_id.substr(1);
          // let strParty1Suffix = strParty1Prefix.substr(0,strParty1Prefix.length - 1);
          let convertArrParty1 = Array.from(x.party1myanmarsecurityforces_id.split(','));

          convertArrParty1.forEach(item => [

            arrParty1.push(Helper.hiddenChar(item))

          ]);

          // let strParty2Prefix = x.party2estalabilishedeao_id.substr(1);
          // let strParty2Suffix = strParty2Prefix.substr(0,strParty2Prefix.length - 1);
          let convertArrParty2 = Array.from(x.party2estalabilishedeao_id.split(','));

          convertArrParty2.forEach(item => {

            arrParty2.push(Helper.hiddenChar(item))

          })

          // let strParty3Prefix = x.party3civillianselfdefenceforces_id.substr(1);
          // let strParty3Suffix = strParty3Prefix.substr(0,strParty3Prefix.length - 1);
          let convertArrParty3 = Array.from(x.party3civillianselfdefenceforces_id.split(','));
          // console.log(arrParty1);

          convertArrParty3.forEach(item => {

            arrParty3.push(Helper.hiddenChar(item));

          });

          let obj = {
            "id": x.id,
            "number": x.number,
            "created_at": moment(x.created_at).format("DD-MM-YYYY"),
            "created_by": x.created_by,
            "deleted_at": moment(x.deleted_at).format("DD-MM-YYYY"),
            "deleted_by": x.deleted_by,
            "drafted_by": x.drafted_by,
            "updated_at": moment(x.updated_at).format("DD/MM/YYYY"),
            "updated_by": x.updated_by,
            "approved_at": moment(x.approved_at).format("DD/MM/YYYY"),
            "approved_by": x.approved_by,
            "status": x.status,
            "highpriority": x.highpriority,
            "incidentdate": x.incidentdate,
            "typeofincident_id": x.typeofincident_id,
            "numberofoccurences": x.numberofoccurences,
            "party2estalabilishedeao_id": arrParty2,
            "party4civillians_id": Helper.hiddenChar(x.party4civillians_id),
            "targethuman_id": Helper.hiddenChar(x.targethuman_id),
            "artillerysupport": x.artillerysupport,
            "airsupport": x.airsupport,
            "time": x.time,
            "subtypeofincident_id": Helper.hiddenChar(x.subtypeofincident_id),
            "party1myanmarsecurityforces_id": arrParty1,
            "party3civillianselfdefenceforces_id": arrParty3,
            "party5others_id": Helper.hiddenChar(x.party5others_id),
            "targetassets_id": Helper.hiddenChar(x.targetassets_id),
            "claimedbynug": x.claimedbynug,
            "tacticaldevelopment": x.tacticaldevelopment,
            "killedoct": x.killedoct,
            "injuredoct": x.injuredoct,
            "arrestedoct": x.arrestedoct,
            "civiladministratorresignationsoct": x.civiladministratorresignationsoct,
            "killedpct": x.killedpct,
            "injuredpct": x.injuredpct,
            "abductedpct": x.abductedpct,
            "killedctg": x.killedctg,
            "injuredctg": x.injuredctg,
            "abductedctg": x.abductedctg,
            "killedsft": x.killedsft,
            "injuredsft": x.injuredsft,
            "capturedsft": x.capturedsft,
            "desertionssft": x.desertionssft,
            "killedrft": x.killedrft,
            "injuredrft": x.injuredrft,
            "arrestedrft": x.arrestedrft,
            "killedeft": x.killedeft,
            "injuredeft": x.injuredeft,
            "arrestedeft": x.arrestedeft,
            "killedot": x.killedot,
            "injuredot": x.injuredot,
            "arrestedot": x.arrestedot,
            "incidentdescription": x.incidentdescription,
            "sourcetwo": x.sourcetwo,
            "latitude": x.latitude,
            "longitude": x.longitude,
            "roadstreet": x.roadstreet,
            "villagetract": x.villagetract,
            "town": x.town,
            "township_id": Helper.hiddenChar(x.township_id),
            "stateregion_id": Helper.hiddenChar(x.stateregion_id),
            "geoprecision_id": Helper.hiddenChar(x.geoprecision_id),
            "highway": x.highway,
            "commentonlocation": x.commentonlocation
          }

          arr.push(obj);

        }))

        setDataSource(arr);



      })
      .catch(function (error) {

        console.log(error);

      })


  }

  async function _draftedBy(e) {

    if (e.target.value) {

      let idUser = e.target.value;

      let result = users.find(x => x.id === idUser);

      setDraftedBy(result.username);

    } else {

      setDraftedBy("");

    }

  }

  function _incidentDate(dateString) {
    if (dateString) {
      setIncidentDate(dateString);
    } else {
      setIncidentDate();
    }
  }

  const incidentDateFormatted = useMemo(() => {
    return incidentDate ? moment(incidentDate, "DD/MM/YYYY").format("DD-MM-YYYY") : null
  }, [incidentDate]);

  function _incidentType(e) {

    if (e.target.value) {

      setIncidentType(e.target.value);

      _subTypeIncidentEl(e.target.value);

    } else {

      setIncidentType("");

      _subTypeIncidentEl();

    }

  }
  function _township(e) {

    if (e) {

      setTownship(e);

      stateRegionEl(e);

    } else {

      setTownship("");

      stateRegionEl();

    }

  }

  function _stateRegion(e) {

    if (e.target.value) {

      setStateRegion(e.target.value);

      townshipEl(e.target.value);

    } else {

      setStateRegion("");

      townshipEl();

    }

  }

  // function _party1(e){

  //   if(e){

  //     setParty1(e);

  //   }else{

  //     setParty1();

  //   }

  // }

  // function _party3(e){

  //   if(e){

  //     setParty3(e);

  //   }else{

  //     setParty3();

  //   }

  // }

  function _search(e) {

    if (e.target.value) {

      setSearch(e.target.value);

    } else {

      setSearch("");

    }

  }


  async function _onFilter(e) {

    e.preventDefault();

    let arr = [];

    let column = columnField ? `order_by=${columnField}&` : ""; //this is field columns
    // let order = orderSort !== undefined ? `order=${orderSort}&` : `order=default&` //this is sort order (Ascending, Descending, Default);
    let order = orderSort !== undefined ? `order=${orderSort}&` : "" //this is sort order (Ascending, Descending, Default);

    let currentPageSize = pageSize ? `page_size=${pageSize}&` : `page_size=50&`;
    let currentPageOnFilter = currentPage && `page=${1}&`;
    let draftedByFilter = draftedBy ? `drafted_by=${draftedBy}&` : "";
    let incidentDateFilter = incidentDateFormatted ? `incidentdate=${incidentDateFormatted}&` : "";
    let typeOfIncidentIdFilter = incidentType ? `typeofincident_id=${incidentType}&` : "";
    let townshipIdFilter = township ? `township_id=${township}&` : "";
    let stateRegionIdFilter = stateRegion ? `stateregion_id=${stateRegion}&` : "";
    let subTypeOfIncidentIdFilter = subTypeIncident ? `subtypeofincident_id=${subTypeIncident}&` : "";
    let searchText = search ? `search=${search}&` : "";

    await axios.get(Uri.rootUri + `incident/list/?is_approve=${true}&${currentPageOnFilter}${currentPageSize}${draftedByFilter}${incidentDateFilter}${typeOfIncidentIdFilter}${subTypeOfIncidentIdFilter}${townshipIdFilter}${stateRegionIdFilter}${searchText}${column}${order}`, { headers: { "Authorization": `Bearer ${authToken}` } })
      .then(function (response) {

        let dataResponse = response.data.results;

        // console.log(response);

        setNextPage(response.data.next);

        setTotalData(response.data.count);

        setShowSizeChanger(response.data.count > 50 ? true : false);

        setCurrentPage(1);

        dataResponse.forEach((x => {

          let arrParty1 = [];
          let arrParty2 = [];
          let arrParty3 = [];

          // let strParty1Prefix = x.party1myanmarsecurityforces_id.substr(1);
          // let strParty1Suffix = strParty1Prefix.substr(0,strParty1Prefix.length - 1);
          let convertArrParty1 = Array.from(x.party1myanmarsecurityforces_id.split(','));

          convertArrParty1.forEach(item => [

            arrParty1.push(Helper.hiddenChar(item))

          ]);

          // let strParty2Prefix = x.party2estalabilishedeao_id.substr(1);
          // let strParty2Suffix = strParty2Prefix.substr(0,strParty2Prefix.length - 1);
          let convertArrParty2 = Array.from(x.party2estalabilishedeao_id.split(','));

          convertArrParty2.forEach(item => {

            arrParty2.push(Helper.hiddenChar(item))

          })

          // let strParty3Prefix = x.party3civillianselfdefenceforces_id.substr(1);
          // let strParty3Suffix = strParty3Prefix.substr(0,strParty3Prefix.length - 1);
          let convertArrParty3 = Array.from(x.party3civillianselfdefenceforces_id.split(','));
          // console.log(arrParty1);

          convertArrParty3.forEach(item => {

            arrParty3.push(Helper.hiddenChar(item));

          });

          let obj = {
            "id": x.id,
            "number": x.number,
            "created_at": moment(x.created_at).format("DD-MM-YYYY"),
            "created_by": x.created_by,
            "deleted_at": moment(x.deleted_at).format("DD-MM-YYYY"),
            "deleted_by": x.deleted_by,
            "drafted_by": x.drafted_by,
            "updated_at": moment(x.updated_at).format("DD/MM/YYYY"),
            "updated_by": x.updated_by,
            "approved_at": moment(x.approved_at).format("DD/MM/YYYY"),
            "approved_by": x.approved_by,
            "status": x.status,
            "highpriority": x.highpriority,
            "incidentdate": x.incidentdate,
            "typeofincident_id": Helper.hiddenChar(x.typeofincident_id),
            "numberofoccurences": x.numberofoccurences,
            "party2estalabilishedeao_id": arrParty2,
            "party4civillians_id": Helper.hiddenChar(x.party4civillians_id),
            "targethuman_id": Helper.hiddenChar(x.targethuman_id),
            "artillerysupport": x.artillerysupport,
            "airsupport": x.airsupport,
            "time": x.time,
            "subtypeofincident_id": Helper.hiddenChar(x.subtypeofincident_id),
            "party1myanmarsecurityforces_id": arrParty1,
            "party3civillianselfdefenceforces_id": arrParty3,
            "party5others_id": Helper.hiddenChar(x.party5others_id),
            "targetassets_id": Helper.hiddenChar(x.targetassets_id),
            "claimedbynug": x.claimedbynug,
            "tacticaldevelopment": x.tacticaldevelopment,
            "killedoct": x.killedoct,
            "injuredoct": x.injuredoct,
            "arrestedoct": x.arrestedoct,
            "civiladministratorresignationsoct": x.civiladministratorresignationsoct,
            "killedpct": x.killedpct,
            "injuredpct": x.injuredpct,
            "abductedpct": x.abductedpct,
            "killedctg": x.killedctg,
            "injuredctg": x.injuredctg,
            "abductedctg": x.abductedctg,
            "killedsft": x.killedsft,
            "injuredsft": x.injuredsft,
            "capturedsft": x.capturedsft,
            "desertionssft": x.desertionssft,
            "killedrft": x.killedrft,
            "injuredrft": x.injuredrft,
            "arrestedrft": x.arrestedrft,
            "killedeft": x.killedeft,
            "injuredeft": x.injuredeft,
            "arrestedeft": x.arrestedeft,
            "killedot": x.killedot,
            "injuredot": x.injuredot,
            "arrestedot": x.arrestedot,
            "incidentdescription": x.incidentdescription,
            "sourcetwo": x.sourcetwo,
            "latitude": x.latitude,
            "longitude": x.longitude,
            "roadstreet": x.roadstreet,
            "villagetract": x.villagetract,
            "town": x.town,
            "township_id": Helper.hiddenChar(x.township_id),
            "stateregion_id": Helper.hiddenChar(x.stateregion_id),
            "geoprecision_id": Helper.hiddenChar(x.geoprecision_id),
            "highway": x.highway,
            "commentonlocation": x.commentonlocation
          }

          arr.push(obj);

        }))

        setDataSource(arr);



      })
      .catch(function (error) {

        console.log(error);

      });


  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'number',
      key: 'number',
      // sorter : (a,b)=> a.number - b.number ,
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 80
    },
    {
      title: 'Last Updated on',
      dataIndex: 'updated_at',
      key: 'updated_at',
      // sorter : (a,b)=> a.updated_at.localeCompare(b.updated_at) ,
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 150
    },
    {
      title: 'Drafted by',
      dataIndex: 'drafted_by',
      key: 'drafted_by',
      // sorter : (a,b)=> a.drafted_by.localeCompare(b.drafted_by),
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 100
    },
    {
      title: 'Approved by',
      dataIndex: 'approved_by',
      key: 'approved_by',
      // sorter : (a,b)=> a.approved_by.localeCompare(b.approved_by),
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 120
    },
    {
      title: 'Approved Date',
      dataIndex: 'approved_at',
      key: 'approved_at',
      // sorter : (a,b)=> a.approved_by.localeCompare(b.approved_by),
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 150
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 100,
      render: (text, record, index) => {
        // console.log(record);
        return (
          <>
            <div class="btn-group" role="group" aria-label="Basic example">
              <button type="button" className="btn btn-primary btn-sm" onClick={(e) => window.open(`${window.location.origin}/Index/ViewDraftIncident/${record.id}/?duplicate=${true}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")}>
                <i class="bi bi-files"></i>

                {/* //development */}
                {/* <Link onClick={(e)=>window.open(`http://localhost:3000/Index/ViewDraftIncident/${record.id}/?duplicate=${true}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} className='text-white'>
                              <i class="bi bi-files"></i>
                            </Link> */}
              </button>
              <button type="button" className="btn btn-primary btn-sm" onClick={(e) => window.open(`${window.location.origin}/Index/ViewDraftIncident/${record.id}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} >
                {/* //production */}
                <i class="bi bi-eye-fill"></i>
              </button>
              {/* <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>window.open(`http://localhost:3000/Index/ViewDraftIncident/${record.id}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} >
                            //production
                              <i class="bi bi-eye-fill"></i>
                          </button> */}
            </div>
          </>
        )
      }
    },
    {
      title: 'Date of Incident',
      dataIndex: 'incidentdate',
      key: 'incidentdate',
      width: 150,
      // sorter : (a,b)=> a.incidentdate.localeCompare(b.incidentdate),
      defaultSortOrder: 'descend',
      sorter: true,
      // responsive: ['sm','md','lg','xs'],
      render: (text, record, index) => {

        return moment(text).format("DD/MM/YYYY");
      }
    },
    {
      title: 'Incident Category',
      dataIndex: 'typeofincident_id',
      key: 'typeofincident_id',
      // sorter : (a,b)=> a.typeofincident_id.localeCompare(b.typeofincident_id),
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 200,
      render: (text, record, index) => {
        return typeIncidentData.map(x => {

          if (Helper.hiddenChar(String(text)) == Helper.hiddenChar(String(x.id))) {

            return (x.name);

          }

        });

      }
    },
    {
      title: 'Sub-Type of Incident',
      dataIndex: 'subtypeofincident_id',
      key: 'subtypeofincident_id',
      // sorter : (a,b)=> a.subtypeofincident_id.localeCompare(b.subtypeofincident_id),
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 200,
      render: (text, record, index) => {

        return typeIncidentData.map(x => {

          if (Helper.hiddenChar(record.typeofincident_id) == Helper.hiddenChar(x.id)) {

            return subTypeIncidentData.map(item => {

              if (Helper.hiddenChar(item.id) == Helper.hiddenChar(text)) {


                return (item.name);

              } else {

                return "";

              }

            })

          } else {

            return "";

          }

        });


      }

    },
    {
      title: 'Target (Human)',
      dataIndex: 'targethuman_id',
      key: 'targethuman_id',
      // sorter : (a,b)=> a.targethuman_id.localeCompare(b.targethuman_id),
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 150,
      render: (text, record, index) => {
        if (Helper.hiddenChar(text) !== "22222222222222222222222222222222") {

          return targetHumanData.map(x => {

            if (Helper.hiddenChar(x.id) == Helper.hiddenChar(text)) {

              return (x.name);

            } else {

              return "";

            }

          });

        } else {

          return "NA";

        }
      }
    },
    {
      title: 'Target (Assets)',
      dataIndex: 'targetassets_id',
      key: 'targetassets_id',
      // sorter : (a,b)=> a.targetassets_id.localeCompare(b.targetassets_id),
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 200,
      render: (text, record, index) => {

        if (Helper.hiddenChar(text) !== "22222222222222222222222222222222") {

          return targetAssetsData.map(x => {

            if (Helper.hiddenChar(x.id) == Helper.hiddenChar(text)) {

              return (x.name);

            } else {

              return "";

            }


          });

        } else {

          return "NA";

        }
      }
    },
    {
      title: 'Township',
      dataIndex: 'township_id',
      key: 'township_id',
      // sorter : (a,b)=> a.township_id.localeCompare(b.township_id),
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 200,
      render: (text, record, index) => {

        return stateRegionData.map(x => {

          if (Helper.hiddenChar(record.stateregion_id) == Helper.hiddenChar(x.id)) {

            return townshipData.map(item => {

              if (Helper.hiddenChar(item.id) == Helper.hiddenChar(text)) {


                return (item.name);

              } else {

                return "";

              }

            })

          } else {

            return "";

          }

        });
      }
    },
    {
      title: 'State/Region',
      dataIndex: 'stateregion_id',
      key: 'stateregion_id',
      // sorter : (a,b)=> a.stateregion_id.localeCompare(b.stateregion_id),
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 200,
      render: (text, record, index) => {

        return stateRegionData.map(x => {

          if (Helper.hiddenChar(x.id) == Helper.hiddenChar(text)) {

            return (x.name);

          } else {

            return "";

          }

        });
      }
    },
    {
      title: 'Description',
      dataIndex: 'incidentdescription',
      key: 'incidentdescription',
      // sorter : (a,b)=> a.incidentdescription.localeCompare(b.incidentdescription),
      sorter: true,
      responsive: ['sm', 'md', 'lg', 'xs'],
      width: 300,
      render: (text, record, index) => {

        if (text.length > 350) {

          return (
            <>
              <div style={{ width: 800 }}>
                <ReadMoreMore
                  text={text}
                  checkFor={350}
                  transDuration={1}
                />
              </div>
            </>
          )

        } else {

          return (
            <>
              <div style={{ width: 800 }}>
                {text}
              </div>
            </>
          )

        }

      }
    },

  ];

  async function _loadData(page, pageSize) {

    let arr = [];

    setCurrentPage(page);

    setPageSize(pageSize);

    setColumnField(columnField ? columnField : "incidentdate");

    setOrderSort(orderSort ? orderSort : "desc");

    let column = columnField ? `order_by=${columnField}&` : `order_by=incidentdate&`; //this is field columns
    let order = orderSort ? `order=${orderSort}&` : `order=desc&` //this is sort order (Ascending, Descending, Default);

    await axios.get(Uri.rootUri + `incident/list/?is_approve=${true}&page=${page}&page_size=${pageSize}&${column}${order}`, { headers: { "Authorization": `Bearer ${authToken}` } })

      .then(function (response) {

        let dataResponse = response.data.results;

        setNextPage(response.data.next); //next page

        setTotalData(response.data.count);//total all data;

        setShowSizeChanger(response.data.count > 50 ? true : false);

        dataResponse.forEach((x => {

          let arrParty1 = [];
          let arrParty2 = [];
          let arrParty3 = [];

          // let strParty1Prefix = x.party1myanmarsecurityforces_id.substr(1);
          // let strParty1Suffix = strParty1Prefix.substr(0,strParty1Prefix.length - 1);
          let convertArrParty1 = Array.from(x.party1myanmarsecurityforces_id.split(','));

          convertArrParty1.forEach(item => [

            arrParty1.push(Helper.hiddenChar(item))

          ]);

          // let strParty2Prefix = x.party2estalabilishedeao_id.substr(1);
          // let strParty2Suffix = strParty2Prefix.substr(0,strParty2Prefix.length - 1);
          let convertArrParty2 = Array.from(x.party2estalabilishedeao_id.split(','));

          convertArrParty2.forEach(item => {

            arrParty2.push(Helper.hiddenChar(item))

          })

          // let strParty3Prefix = x.party3civillianselfdefenceforces_id.substr(1);
          // let strParty3Suffix = strParty3Prefix.substr(0,strParty3Prefix.length - 1);
          let convertArrParty3 = Array.from(x.party3civillianselfdefenceforces_id.split(','));
          // console.log(arrParty1);

          convertArrParty3.forEach(item => {

            arrParty3.push(Helper.hiddenChar(item));

          });

          let obj = {
            "id": x.id,
            "number": x.number,
            "created_at": moment(x.created_at).format("DD-MM-YYYY"),
            "created_by": x.created_by,
            "deleted_at": moment(x.deleted_at).format("DD-MM-YYYY"),
            "deleted_by": x.deleted_by,
            "drafted_by": x.drafted_by,
            "updated_at": moment(x.updated_at).format("DD/MM/YYYY"),
            "updated_by": x.updated_by,
            "approved_at": moment(x.approved_at).format("DD/MM/YYYY"),
            "approved_by": x.approved_by,
            "status": x.status,
            "highpriority": x.highpriority,
            "incidentdate": x.incidentdate,
            "typeofincident_id": Helper.hiddenChar(x.typeofincident_id),
            "numberofoccurences": x.numberofoccurences,
            "party2estalabilishedeao_id": arrParty2,
            "party4civillians_id": Helper.hiddenChar(x.party4civillians_id),
            "targethuman_id": Helper.hiddenChar(x.targethuman_id),
            "artillerysupport": x.artillerysupport,
            "airsupport": x.airsupport,
            "time": x.time,
            "subtypeofincident_id": Helper.hiddenChar(x.subtypeofincident_id),
            "party1myanmarsecurityforces_id": arrParty1,
            "party3civillianselfdefenceforces_id": arrParty3,
            "party5others_id": Helper.hiddenChar(x.party5others_id),
            "targetassets_id": Helper.hiddenChar(x.targetassets_id),
            "claimedbynug": x.claimedbynug,
            "tacticaldevelopment": x.tacticaldevelopment,
            "killedoct": x.killedoct,
            "injuredoct": x.injuredoct,
            "arrestedoct": x.arrestedoct,
            "civiladministratorresignationsoct": x.civiladministratorresignationsoct,
            "killedpct": x.killedpct,
            "injuredpct": x.injuredpct,
            "abductedpct": x.abductedpct,
            "killedctg": x.killedctg,
            "injuredctg": x.injuredctg,
            "abductedctg": x.abductedctg,
            "killedsft": x.killedsft,
            "injuredsft": x.injuredsft,
            "capturedsft": x.capturedsft,
            "desertionssft": x.desertionssft,
            "killedrft": x.killedrft,
            "injuredrft": x.injuredrft,
            "arrestedrft": x.arrestedrft,
            "killedeft": x.killedeft,
            "injuredeft": x.injuredeft,
            "arrestedeft": x.arrestedeft,
            "killedot": x.killedot,
            "injuredot": x.injuredot,
            "arrestedot": x.arrestedot,
            "incidentdescription": x.incidentdescription,
            "sourcetwo": x.sourcetwo,
            "latitude": x.latitude,
            "longitude": x.longitude,
            "roadstreet": x.roadstreet,
            "villagetract": x.villagetract,
            "town": x.town,
            "township_id": Helper.hiddenChar(x.township_id),
            "stateregion_id": Helper.hiddenChar(x.stateregion_id),
            "geoprecision_id": Helper.hiddenChar(x.geoprecision_id),
            "highway": x.highway,
            "commentonlocation": x.commentonlocation
          }

          arr.push(obj);

        }))

        setDataSource(arr);



      })
      .catch(function (error) {

        console.log(error);



      })

  }

  async function stateRegionEl(id) {

    let arrCache = await caches.keys();// get cache

    let arrTown = [];

    let arrState = [];

    // console.log(id);

    // console.log(stateRegion);

    if (id) {

      let townshipobj = townshipData.find(x => Helper.hiddenChar(x.id) == Helper.hiddenChar(id));

      // console.log(townshipobj);

      // console.log(townshipData);

      // console.log(arrTownshipTemp);

      let resultArrTownship = arrTownshipTemp.length > 0 ? arrTownshipTemp.filter(item => Helper.hiddenChar(item.region_id) == Helper.hiddenChar(townshipobj.region_id)) : [];

      if (arrTownshipTemp.length === 0 || resultArrTownship.length == 0) {

        await axios.get(Uri.rootUri + `master/township/?region_id=${townshipobj.region_id}`, { headers: { "Authorization": `Bearer ${authToken}` } })
          .then(function (response) {

            let arrTownship = response.data;

            // console.log(arrTownship);

            setArrTownshipTemp(arrTownship);//set if township where region id temporary

            arrTownship.forEach(x => {

              arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

            });

            stateRegionData.forEach(x => {

              // console.log(x,townshipobj);

              if (Helper.hiddenChar(x.id) == Helper.hiddenChar(townshipobj.region_id)) {

                if (stateRegion) {

                  arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                } else {

                  arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                }

                setStateRegion(townshipobj.region_id); // set automatically when user select township so direct select state region

              }
            })


          })
          .catch(function (error) {

            console.log(error);

          });

        setElTownship(arrTown);

        setElStateRegion(arrState);

      } else {


        resultArrTownship.forEach(x => {

          arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

        });

        stateRegionData.forEach(x => {

          // console.log(x,townshipobj);

          if (Helper.hiddenChar(x.id) == Helper.hiddenChar(townshipobj.region_id)) {

            if (stateRegion) {

              arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

            } else {

              arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

            }

            setStateRegion(townshipobj.region_id); // set automatically when user select township so direct select state region

          }
        })
        // console.log(arrTownship);

        setElTownship(arrTown);

        setElStateRegion(arrState);
        // console.log(idTownship);
      }

    } else {// if select all township

      await axios.get(Uri.rootUri + `cache/`)
        .then(async function (response) {

          //  get version data if there is an update
          let dataResponse = response.data;

          //get data cache where cache name
          let resultObjCache = dataResponse.filter(item => item.name === "township");

          if (!arrCache.includes("township")) {// if empty cache name in browser

            await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
              .then(function (response) {

                let dataResponse = response.data;

                //state township data filter

                dataResponse.forEach(x => {

                  if (stateRegion == Helper.hiddenChar(x.region_id)) {

                    arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                  }

                  setTownship();

                });
                // dataResponse.forEach(x=>{

                //   arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                //   setTownship();

                // });

                setTownshipData(dataResponse);

                Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);//add cache

              })
              .catch(function (error) {

                console.log(error);

              });

            //state region data filter
            stateRegionData.forEach(x => {

              // console.log(x,townshipobj);

              if (Helper.hiddenChar(x.id) == Helper.hiddenChar(stateRegion)) {

                if (stateRegion) {

                  arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                } else {

                  arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                }



              }

            });


            // stateRegionData.forEach(x=>{

            //     arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

            //     setStateRegion();
            // })

            if (resultObjCache.length == 0) {// if data cache in database empty

              let cacheName = {

                "name": "township",
                "created_by": localStorage.getItem("usernameLogin"),
                "updated_by": localStorage.getItem("usernameLogin")

              }

              await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function (response) {

                  // console.log(response);


                })
                .catch(function (error) {


                  console.log(error);

                });

            } else {

              // console.log("set version");

              // if data cache in database not empty
              Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

            }

            setElTownship(arrTown);
            setElStateRegion(arrState);


          } else {

            let resultData = arrCache.filter(x => x == "township");

            //Operation Caching Validation
            resultData.forEach(async cachename => {

              let cacheStorage = await caches.open(cachename);

              let townshipResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/township');
              //get version in cache for check update data
              let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

              if (!cacheResponse || cacheResponse == undefined || !townshipResponse || townshipResponse == undefined) {// check data valid or no valid

                await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    // console.log(response);

                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                      if (stateRegion == Helper.hiddenChar(x.region_id)) {

                        arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                      }

                      setTownship();

                    });

                    // dataResponse.forEach(x=>{

                    //     arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                    // });

                    setTownshipData(dataResponse);

                    setElTownship(arrTown);

                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                  })
                  .catch(function (error) {

                    console.log(error);

                  });

                stateRegionData.forEach(x => {

                  // console.log(x,townshipobj);

                  if (Helper.hiddenChar(x.id) == Helper.hiddenChar(stateRegion)) {

                    if (stateRegion) {

                      arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                    } else {

                      arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                    }



                  }

                });

                // stateRegionData.forEach(x=>{

                //   arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                //   setStateRegion();
                // })

                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": "towship",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      // console.log(response);


                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  // if data cache in database not empty
                  Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

                setElTownship(arrTown);
                setElStateRegion(arrState);

              } else {

                let dataResponseVersion = await cacheResponse.json();

                let dataResponseTownship = await townshipResponse.json();

                if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseTownship || dataResponseTownship.length == 0) {/*check data into cache empty or not empty*/

                  await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      // console.log(response);

                      let dataResponse = response.data;

                      dataResponse.forEach(x => {

                        if (stateRegion == Helper.hiddenChar(x.region_id)) {

                          arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                        }

                        setTownship();

                      });

                      // dataResponse.forEach(x=>{

                      //     arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                      // });

                      setTownshipData(dataResponse);

                      setElTownship(arrTown);

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                    })
                    .catch(function (error) {

                      console.log(error);

                    });

                  stateRegionData.forEach(x => {

                    // console.log(x,townshipobj);

                    if (Helper.hiddenChar(x.id) == Helper.hiddenChar(stateRegion)) {

                      if (stateRegion) {

                        arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                      } else {

                        arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                      }



                    }

                  });

                  // stateRegionData.forEach(x=>{

                  //   arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                  //   setStateRegion();
                  // })

                  if (resultObjCache.length == 0) {// if data cache in database empty

                    let cacheName = {

                      "name": "township",
                      "created_by": localStorage.getItem("usernameLogin"),
                      "updated_by": localStorage.getItem("usernameLogin")

                    }

                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                      .then(function (response) {

                        // console.log(response);

                      })
                      .catch(function (error) {


                        console.log(error);

                      });

                  } else {

                    // if data cache in database not empty
                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                  setElTownship(arrTown);
                  setElStateRegion(arrState);

                } else {

                  // dataResponseVersion and dataResponseTownship there must be !

                  if (resultObjCache.length == 0) {// if data cache in database empty

                    await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        // console.log(response);

                        let dataResponse = response.data;

                        dataResponse.forEach(x => {

                          if (stateRegion == Helper.hiddenChar(x.region_id)) {

                            arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                          }

                          setTownship();

                        });

                        // dataResponse.forEach(x=>{

                        //     arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                        // });

                        setTownshipData(dataResponse);

                        setElTownship(arrTown);

                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                      })
                      .catch(function (error) {

                        console.log(error);

                      });

                    stateRegionData.forEach(x => {

                      // console.log(x,townshipobj);

                      if (Helper.hiddenChar(x.id) == Helper.hiddenChar(stateRegion)) {

                        if (stateRegion) {

                          arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                        } else {

                          arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                        }



                      }

                    });

                    // stateRegionData.forEach(x=>{

                    //   arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                    //   setStateRegion();
                    // })

                    let cacheName = {

                      "name": "township",
                      "created_by": localStorage.getItem("usernameLogin"),
                      "updated_by": localStorage.getItem("usernameLogin")

                    }

                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                      .then(function (response) {

                        // console.log(response);

                      })
                      .catch(function (error) {


                        console.log(error);

                      });

                    setElTownship(arrTown);
                    setElStateRegion(arrState);

                  } else {

                    //check version  cache local browser you and database api , resultObjCache there must be !
                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                    if (resultVersion) {

                      // console.log("Update cache new version ");

                      await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                          // console.log(response);

                          let dataResponse = response.data;

                          dataResponse.forEach(x => {

                            if (stateRegion == Helper.hiddenChar(x.region_id)) {

                              arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                            }

                            setTownship();

                          });

                          // dataResponse.forEach(x=>{

                          //     arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                          // });

                          setTownshipData(dataResponse);

                          setElTownship(arrTown);

                          Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                        })
                        .catch(function (error) {

                          console.log(error);

                        });

                      stateRegionData.forEach(x => {

                        // console.log(x,townshipobj);

                        if (Helper.hiddenChar(x.id) == Helper.hiddenChar(stateRegion)) {

                          if (stateRegion) {

                            arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                          } else {

                            arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                          }



                        }

                      });

                      // stateRegionData.forEach(x=>{

                      //   arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                      //   setStateRegion();
                      // });

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                      setElTownship(arrTown);
                      setElStateRegion(arrState);

                    } else {


                      dataResponseTownship.forEach(x => {

                        // console.log(stateRegion);

                        if (stateRegion == Helper.hiddenChar(x.region_id)) {

                          arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                        }

                        setTownship();

                      });

                      stateRegionData.forEach(x => {

                        // console.log(x,townshipobj);

                        if (Helper.hiddenChar(x.id) == Helper.hiddenChar(stateRegion)) {

                          if (stateRegion) {

                            arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                          } else {

                            arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                          }



                        }

                      });

                      // stateRegionData.forEach(x=>{

                      //   arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                      //   setStateRegion();
                      // });

                      setElTownship(arrTown);

                      setElStateRegion(arrState);

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                  }

                }

              }

            })

          }

        });


    }

    // let arrTown = [];
    // let arrState = [];

    // // console.log(id);

    //     if(id){

    //         let townshipobj = townshipData.find(x => Helper.hiddenChar(x.id) == Helper.hiddenChar(id) );

    //         // console.log(townshipobj);

    //         // console.log(townshipData);

    //         // console.log(arrTownshipTemp);

    //         if(arrTownshipTemp.length === 0){

    //             await axios.get(Uri.rootUri + `master/township/?region_id=${townshipobj.region_id}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //             .then(function(response){

    //                 let arrTownship = response.data;

    //                 // console.log(arrTownship);

    //                 setTownshipData(arrTownship);

    //                 setArrTownshipTemp(arrTownship);//set if township where region id temporary

    //                 arrTownship.forEach(x=>{

    //                     arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

    //                 });


    //             })
    //             .catch(function(error){

    //                 console.log(error);

    //             });

    //             }else{


    //                 townshipData.forEach(x=>{

    //                     arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

    //                 });

    //             }

    //         stateRegionData.forEach(x=>{

    //             // console.log(x,townshipobj);

    //             if(Helper.hiddenChar(x.id ) == Helper.hiddenChar(townshipobj.region_id)){

    //                 if(stateRegion){

    //                     arrState.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

    //                 }else{

    //                     arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);
    //                 }


    //             }
    //         })
    //         // console.log(arrTownship);
    //         // console.log(idTownship);

    //     }else{


    //         await axios.get(Uri.rootUri + `master/township/?page=${1}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //         .then(function(response){

    //             let dataResponse = response.data.results;

    //             dataResponse.forEach(x=>{

    //                 arrTown.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

    //                 setTownship();

    //             });

    //             setTownshipData(dataResponse);

    //         })
    //         .catch(function(error){

    //             console.log(error);

    //         });

    //         stateRegionData.forEach(x=>{

    //             arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

    //             setStateRegion();
    //         })

    //         setArrTownshipTemp([]);

    //     }

    //     setElTownship(arrTown);
    //     setElStateRegion(arrState);

  }

  async function townshipEl(id) {
    // console.log(id);

    let arrCache = await caches.keys();// get cache

    let arrTown = [];

    let arrState = [];

    if (id) {

      // console.log(townshipData);
      await axios.get(Uri.rootUri + `master/township/?region_id=${id}`, { headers: { "Authorization": `Bearer ${authToken}` } })
        .then(function (response) {


          let dataResponse = response.data;

          // setTownshipData(dataResponse);

          dataResponse.forEach(x => {

            if (Helper.hiddenChar(x.region_id) == Helper.hiddenChar(id)) {

              arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);


            }

          });

          // console.log(dataResponse);


          stateRegionData.forEach(x => {

            if (Helper.hiddenChar(x.id) == Helper.hiddenChar(id)) {

              arrState.push(<option selected value={Helper.hiddenChar(x.id)} >{x.name}</option>);

            }

          });

        });

      setElTownship(arrTown);

      setElStateRegion(arrState);

    } else {

      await axios.get(Uri.rootUri + `cache/`)
        .then(async function (response) {

          //  get version data if there is an update
          let dataResponse = response.data;

          //get data cache where cache name
          let resultObjCache = dataResponse.filter(item => item.name === "township");

          if (!arrCache.includes("township")) {// if empty cache name in browser

            await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
              .then(function (response) {

                let dataResponse = response.data;

                dataResponse.forEach(x => {

                  arrTown.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                  setTownship();

                });

                setTownshipData(dataResponse);

                Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);//add cache

              })
              .catch(function (error) {

                console.log(error);

              });


            stateRegionData.forEach(x => {

              arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

              setStateRegion();
            })

            if (resultObjCache.length == 0) {// if data cache in database empty

              let cacheName = {

                "name": "township",
                "created_by": localStorage.getItem("usernameLogin"),
                "updated_by": localStorage.getItem("usernameLogin")

              }

              await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function (response) {

                  // console.log(response);


                })
                .catch(function (error) {


                  console.log(error);

                });

            } else {

              // console.log("set version");

              // if data cache in database not empty
              Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

            }

            setElTownship(arrTown);

            setElStateRegion(arrState);


          } else {

            let resultData = arrCache.filter(x => x == "township");

            //Operation Caching Validation
            resultData.forEach(async cachename => {

              let cacheStorage = await caches.open(cachename);

              let townshipResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/township');
              //get version in cache for check update data
              let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

              if (!cacheResponse || cacheResponse == undefined || !townshipResponse || townshipResponse == undefined) {// check data valid or no valid

                await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    // console.log(response);

                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                      arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                    });

                    setTownshipData(dataResponse);

                    setElTownship(arrTown);

                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                  })
                  .catch(function (error) {

                    console.log(error);

                  });

                stateRegionData.forEach(x => {

                  arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                  setStateRegion();
                })

                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": "towship",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      // console.log(response);


                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  // if data cache in database not empty
                  Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

                setElTownship(arrTown);
                setElStateRegion(arrState);

              } else {

                let dataResponseVersion = await cacheResponse.json();

                let dataResponseTownship = await townshipResponse.json();

                if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseTownship || dataResponseTownship.length == 0) {/*check data into cache empty or not empty*/

                  await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      // console.log(response);

                      let dataResponse = response.data;

                      dataResponse.forEach(x => {

                        arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                      });

                      setTownshipData(dataResponse);

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                    })
                    .catch(function (error) {

                      console.log(error);

                    });

                  stateRegionData.forEach(x => {

                    arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                    setStateRegion();
                  })

                  if (resultObjCache.length == 0) {// if data cache in database empty

                    let cacheName = {

                      "name": "township",
                      "created_by": localStorage.getItem("usernameLogin"),
                      "updated_by": localStorage.getItem("usernameLogin")

                    }

                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                      .then(function (response) {

                        // console.log(response);

                      })
                      .catch(function (error) {


                        console.log(error);

                      });

                  } else {

                    // if data cache in database not empty
                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                  setElTownship(arrTown);
                  setElStateRegion(arrState);

                } else {

                  // dataResponseVersion and dataResponseTownship there must be !

                  if (resultObjCache.length == 0) {// if data cache in database empty

                    await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        // console.log(response);

                        let dataResponse = response.data;

                        dataResponse.forEach(x => {

                          arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                        });

                        setTownshipData(dataResponse);

                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                      })
                      .catch(function (error) {

                        console.log(error);

                      });

                    stateRegionData.forEach(x => {

                      arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                      setStateRegion();
                    })

                    let cacheName = {

                      "name": "township",
                      "created_by": localStorage.getItem("usernameLogin"),
                      "updated_by": localStorage.getItem("usernameLogin")

                    }

                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                      .then(function (response) {

                        // console.log(response);

                      })
                      .catch(function (error) {


                        console.log(error);

                      });

                    setElTownship(arrTown);
                    setElStateRegion(arrState);

                  } else {

                    //check version  cache local browser you and database api , resultObjCache there must be !
                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                    if (resultVersion) {

                      // console.log("Update cache new version ");

                      await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                          // console.log(response);

                          let dataResponse = response.data;

                          dataResponse.forEach(x => {

                            arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                          });

                          setTownshipData(dataResponse);

                          Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                        })
                        .catch(function (error) {

                          console.log(error);

                        });

                      stateRegionData.forEach(x => {

                        arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                        setStateRegion();
                      });

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                      setElTownship(arrTown);
                      setElStateRegion(arrState);

                    } else {

                      dataResponseTownship.forEach(x => {

                        arrTown.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                      });

                      stateRegionData.forEach(x => {

                        arrState.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                        setStateRegion();
                      });


                      setElTownship(arrTown);

                      setElStateRegion(arrState);

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                  }

                }

              }

            })

          }

        });

    }


  }

  async function _subTypeIncident(e) {

    // console.log(e.target.value, subTypeIncidentData, typeIncidentData);

    if (e) {

      setSubTypeIncident(e);

      _incidentTypeEl(e);

    } else {

      setSubTypeIncident();

      _incidentTypeEl();

    }




  }

  async function _incidentTypeEl(id) {

    let arrSub = [];
    let arr = [];
    // console.log(typeIncidentData, subTypeIncidentData);

    // console.log(id);

    if (id) {

      let subIncidentTypeObj = subTypeIncidentData.find(x => Helper.hiddenChar(x.id) == Helper.hiddenChar(id));

      let incidentTypeObj = subTypeIncidentData.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(subIncidentTypeObj.incident_id));

      incidentTypeObj.forEach(x => {

        arrSub.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>)


      });

      typeIncidentData.forEach(x => {

        if (Helper.hiddenChar(x.id) == Helper.hiddenChar(subIncidentTypeObj.incident_id)) {

          if (incidentType) {

            arr.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>);

          } else {

            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

          }

        }

      })

      setSubTypeIncident(Helper.hiddenChar(id));

    } else {

      typeIncidentData.forEach(x => {

        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

        // setIncidentType();

      });

      // console.log(incidentType);

      if (incidentType) { // if incident type hire

        subTypeIncidentData.forEach(x => {

          if (incidentType == x.incident_id) {

            arrSub.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

          }

        });

      }


    }


    setElSubTypeIncident(arrSub);
    setElTypeOfIncident(arr);

  }

  async function _loadSubTypeOfIncidentData(cachenameparam) {

    let arr = [];

    let arrSubIncidentSameName = [];

    let arrSubIncident = [];

    let arrCache = await caches.keys();// get cache

    await axios.get(Uri.rootUri + `cache/`)
      .then(async function (response) {

        //get version data if there is an update
        let dataResponse = response.data;

        //get data cache where cache name
        let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

        if (!arrCache.includes("subincident")) {// if empty cache name in browser

          console.log("cache in browser empty");

          await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

              // console.log(response);

              let dataResponse = response.data;

              dataResponse.forEach(x => { //check name if double

                // console.log(x);

                arrSubIncidentSameName.push(x.name.toLowerCase());

              })

              dataResponse.forEach(x => {

                let filterNameSubSame = arrSubIncidentSameName.filter(item => item == x.name.toLowerCase());

                // console.log(filterNameSubSame);

                if (filterNameSubSame.length > 1) {

                  arrSubIncident.push({
                    "created_at": x.created_at,
                    "created_by": x.created_by,
                    "deleted_at": x.deleted_at,
                    "deleted_by": x.deleted_by,
                    "id": x.id,
                    "incident_id": Helper.hiddenChar(x.incident_id),
                    "incident_name": x.incident_name,
                    "name": `${x.name} - ${x.incident_name}`,
                    "updated_at": x.updated_at,
                    "updated_by": x.updated_by
                  });

                } else {

                  arrSubIncident.push({
                    "created_at": x.created_at,
                    "created_by": x.created_by,
                    "deleted_at": x.deleted_at,
                    "deleted_by": x.deleted_by,
                    "id": x.id,
                    "incident_id": Helper.hiddenChar(x.incident_id),
                    "incident_name": x.incident_name,
                    "name": x.name,
                    "updated_at": x.updated_at,
                    "updated_by": x.updated_by
                  });


                }


              });


              // console.log(arrSubIncident);

              arrSubIncident.forEach(x => {

                arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

              });


              setSubTypeIncidentData(arrSubIncident);

              // setElSubTypeIncident(arr);

              Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);//set cache


            })
            .catch(function (error) {

              console.log(error);

            });

          if (resultObjCache.length == 0) {// if data cache in database empty

            let cacheName = {

              "name": cachenameparam,
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
              .then(function (response) {

                //   console.log(response);


              })
              .catch(function (error) {


                console.log(error);

              });

          } else {

            //   console.log("set version");

            // if data cache in database not empty
            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

          }

        } else {

          let resultData = arrCache.filter(x => x == "subincident");

          //Operation Caching Validation

          resultData.forEach(async cachename => {

            let cacheStorage = await caches.open(cachename);

            let subIncidentResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/subincident');
            //get version in cache for check update data
            let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

            if (!cacheResponse || cacheResponse == undefined || !subIncidentResponse || subIncidentResponse == undefined) {// check data valid or no valid

              await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                  // console.log(response);

                  let dataResponse = response.data;

                  dataResponse.forEach(x => { //check name if double

                    // console.log(x);

                    arrSubIncidentSameName.push(x.name.toLowerCase());

                  })

                  dataResponse.forEach(x => {

                    let filterNameSubSame = arrSubIncidentSameName.filter(item => item == x.name.toLowerCase());

                    // console.log(filterNameSubSame);

                    if (filterNameSubSame.length > 1) {

                      arrSubIncident.push({
                        "created_at": x.created_at,
                        "created_by": x.created_by,
                        "deleted_at": x.deleted_at,
                        "deleted_by": x.deleted_by,
                        "id": x.id,
                        "incident_id": Helper.hiddenChar(x.incident_id),
                        "incident_name": x.incident_name,
                        "name": `${x.name} - ${x.incident_name}`,
                        "updated_at": x.updated_at,
                        "updated_by": x.updated_by
                      });

                    } else {

                      arrSubIncident.push({
                        "created_at": x.created_at,
                        "created_by": x.created_by,
                        "deleted_at": x.deleted_at,
                        "deleted_by": x.deleted_by,
                        "id": x.id,
                        "incident_id": Helper.hiddenChar(x.incident_id),
                        "incident_name": x.incident_name,
                        "name": x.name,
                        "updated_at": x.updated_at,
                        "updated_by": x.updated_by
                      });


                    }


                  });


                  // console.log(arrSubIncident);

                  arrSubIncident.forEach(x => {

                    arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                  });


                  setSubTypeIncidentData(arrSubIncident);

                  // setElSubTypeIncident(arr);

                  Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                })
                .catch(function (error) {

                  console.log(error);

                });

              if (resultObjCache.length == 0) {// if data cache in database empty

                let cacheName = {

                  "name": cachenameparam,
                  "created_by": localStorage.getItem("usernameLogin"),
                  "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function (response) {

                    // console.log(response);


                  })
                  .catch(function (error) {


                    console.log(error);

                  });

              } else {

                // if data cache in database not empty
                Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

              }

            } else {

              let dataResponseVersion = await cacheResponse.json();

              let dataResponseSubIncident = await subIncidentResponse.json();

              if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseSubIncident || dataResponseSubIncident.length == 0) {/*check data into cache empty or not empty*/

                await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    // console.log(response);

                    let dataResponse = response.data;

                    dataResponse.forEach(x => { //check name if double

                      // console.log(x);

                      arrSubIncidentSameName.push(x.name.toLowerCase());

                    })

                    dataResponse.forEach(x => {

                      let filterNameSubSame = arrSubIncidentSameName.filter(item => item == x.name.toLowerCase());

                      // console.log(filterNameSubSame);

                      if (filterNameSubSame.length > 1) {

                        arrSubIncident.push({
                          "created_at": x.created_at,
                          "created_by": x.created_by,
                          "deleted_at": x.deleted_at,
                          "deleted_by": x.deleted_by,
                          "id": x.id,
                          "incident_id": Helper.hiddenChar(x.incident_id),
                          "incident_name": x.incident_name,
                          "name": `${x.name} - ${x.incident_name}`,
                          "updated_at": x.updated_at,
                          "updated_by": x.updated_by
                        });

                      } else {

                        arrSubIncident.push({
                          "created_at": x.created_at,
                          "created_by": x.created_by,
                          "deleted_at": x.deleted_at,
                          "deleted_by": x.deleted_by,
                          "id": x.id,
                          "incident_id": Helper.hiddenChar(x.incident_id),
                          "incident_name": x.incident_name,
                          "name": x.name,
                          "updated_at": x.updated_at,
                          "updated_by": x.updated_by
                        });


                      }


                    });


                    // console.log(arrSubIncident);

                    arrSubIncident.forEach(x => {

                      arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                    });


                    setSubTypeIncidentData(arrSubIncident);

                    // setElSubTypeIncident(arr);

                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                  })
                  .catch(function (error) {

                    console.log(error);

                  });

                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      // console.log(response);

                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  // if data cache in database not empty
                  Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

              } else {

                // dataResponseVersion and dataResponseSubIncident there must be !

                if (resultObjCache.length == 0) {// if data cache in database empty

                  await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      // console.log(response);

                      let dataResponse = response.data;

                      dataResponse.forEach(x => { //check name if double

                        // console.log(x);

                        arrSubIncidentSameName.push(x.name.toLowerCase());

                      })

                      dataResponse.forEach(x => {

                        let filterNameSubSame = arrSubIncidentSameName.filter(item => item == x.name.toLowerCase());

                        // console.log(filterNameSubSame);

                        if (filterNameSubSame.length > 1) {

                          arrSubIncident.push({
                            "created_at": x.created_at,
                            "created_by": x.created_by,
                            "deleted_at": x.deleted_at,
                            "deleted_by": x.deleted_by,
                            "id": x.id,
                            "incident_id": Helper.hiddenChar(x.incident_id),
                            "incident_name": x.incident_name,
                            "name": `${x.name} - ${x.incident_name}`,
                            "updated_at": x.updated_at,
                            "updated_by": x.updated_by
                          });

                        } else {

                          arrSubIncident.push({
                            "created_at": x.created_at,
                            "created_by": x.created_by,
                            "deleted_at": x.deleted_at,
                            "deleted_by": x.deleted_by,
                            "id": x.id,
                            "incident_id": Helper.hiddenChar(x.incident_id),
                            "incident_name": x.incident_name,
                            "name": x.name,
                            "updated_at": x.updated_at,
                            "updated_by": x.updated_by
                          });


                        }


                      });


                      // console.log(arrSubIncident);

                      arrSubIncident.forEach(x => {

                        arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                      });


                      setSubTypeIncidentData(arrSubIncident);

                      // setElSubTypeIncident(arr);

                      Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                    })
                    .catch(function (error) {

                      console.log(error);

                    });

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      // console.log(response);

                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  //check version  cache local browser you and database api , resultObjCache there must be !
                  let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                  if (resultVersion) {

                    // console.log("Update cache new version ");

                    await axios.get(Uri.rootUri + `master/subincident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        // console.log(response);

                        let dataResponse = response.data;

                        dataResponse.forEach(x => { //check name if double

                          // console.log(x);

                          arrSubIncidentSameName.push(x.name.toLowerCase());

                        })

                        dataResponse.forEach(x => {

                          let filterNameSubSame = arrSubIncidentSameName.filter(item => item == x.name.toLowerCase());

                          // console.log(filterNameSubSame);

                          if (filterNameSubSame.length > 1) {

                            arrSubIncident.push({
                              "created_at": x.created_at,
                              "created_by": x.created_by,
                              "deleted_at": x.deleted_at,
                              "deleted_by": x.deleted_by,
                              "id": x.id,
                              "incident_id": Helper.hiddenChar(x.incident_id),
                              "incident_name": x.incident_name,
                              "name": `${x.name} - ${x.incident_name}`,
                              "updated_at": x.updated_at,
                              "updated_by": x.updated_by
                            });

                          } else {

                            arrSubIncident.push({
                              "created_at": x.created_at,
                              "created_by": x.created_by,
                              "deleted_at": x.deleted_at,
                              "deleted_by": x.deleted_by,
                              "id": x.id,
                              "incident_id": Helper.hiddenChar(x.incident_id),
                              "incident_name": x.incident_name,
                              "name": x.name,
                              "updated_at": x.updated_at,
                              "updated_by": x.updated_by
                            });


                          }


                        });


                        // console.log(arrSubIncident);

                        arrSubIncident.forEach(x => {

                          arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                        });


                        setSubTypeIncidentData(arrSubIncident);

                        // setElSubTypeIncident(arr);

                        Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponse);


                      })
                      .catch(function (error) {

                        console.log(error);

                      });

                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  } else {

                    dataResponseSubIncident.forEach(x => { //check name if double

                      // console.log(x);

                      arrSubIncidentSameName.push(x.name.toLowerCase());

                    })

                    dataResponseSubIncident.forEach(x => {

                      let filterNameSubSame = arrSubIncidentSameName.filter(item => item == x.name.toLowerCase());

                      // console.log(filterNameSubSame);

                      if (filterNameSubSame.length > 1) {

                        arrSubIncident.push({
                          "created_at": x.created_at,
                          "created_by": x.created_by,
                          "deleted_at": x.deleted_at,
                          "deleted_by": x.deleted_by,
                          "id": x.id,
                          "incident_id": Helper.hiddenChar(x.incident_id),
                          "incident_name": x.incident_name,
                          "name": `${x.name} - ${x.incident_name}`,
                          "updated_at": x.updated_at,
                          "updated_by": x.updated_by
                        });

                      } else {

                        arrSubIncident.push({
                          "created_at": x.created_at,
                          "created_by": x.created_by,
                          "deleted_at": x.deleted_at,
                          "deleted_by": x.deleted_by,
                          "id": x.id,
                          "incident_id": Helper.hiddenChar(x.incident_id),
                          "incident_name": x.incident_name,
                          "name": x.name,
                          "updated_at": x.updated_at,
                          "updated_by": x.updated_by
                        });


                      }


                    });


                    // console.log(arrSubIncident);

                    arrSubIncident.forEach(x => {

                      arr.push(<Option key={Helper.hiddenChar(x.id)}>{x.name}</Option>);

                    });


                    setSubTypeIncidentData(dataResponseSubIncident);

                    // setElSubTypeIncident(arr);

                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponseSubIncident);

                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                }


              }

            }

          });

        }


      })
      .catch(function (error) {

        console.log(error);

      });

    // let arr = [];

    // let arrCache = await caches.keys();// get cache

    // if(!arrCache.includes("subincident")){

    //     await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //     .then(function(response){


    //         let arrSubIncidentSameName = [];

    //         let arrSubIncident = [];

    //         let dataResponse = response.data;

    //         // dataResponse.forEach(x=>{

    //         //   arr.push(<option value={x.id}>{x.name}</option>);

    //         // })

    //         dataResponse.forEach(x=>{ //check name if double

    //         // console.log(x);

    //         arrSubIncidentSameName.push(x.name.toLowerCase());

    //         })

    //         dataResponse.forEach(x=>{

    //         let filterNameSubSame = arrSubIncidentSameName.filter(item=> item == x.name.toLowerCase());

    //         //   console.log(filterNameSubSame);

    //         if(filterNameSubSame.length > 1){

    //             arrSubIncident.push({
    //                 "created_at":x.created_at,
    //                 "created_by":x.created_by,
    //                 "deleted_at":x.deleted_at,
    //                 "deleted_by":x.deleted_by,
    //                 "id":x.id,
    //                 "incident_id":Helper.hiddenChar(x.incident_id),
    //                 "incident_name":x.incident_name,
    //                 "name":`${x.name} - ${x.incident_name}`,
    //                 "updated_at":x.updated_at,
    //                 "updated_by":x.updated_by
    //             });

    //         }else{

    //             arrSubIncident.push({
    //             "created_at":x.created_at,
    //             "created_by":x.created_by,
    //             "deleted_at":x.deleted_at,
    //             "deleted_by":x.deleted_by,
    //             "id":x.id,
    //             "incident_id":Helper.hiddenChar(x.incident_id),
    //             "incident_name":x.incident_name,
    //             "name": x.name,
    //             "updated_at":x.updated_at,
    //             "updated_by":x.updated_by
    //             });


    //         }


    //         });


    //         // console.log(arrSubIncident);

    //         arrSubIncident.forEach(x=>{

    //         arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

    //         });


    //         setSubTypeIncidentData(arrSubIncident);

    //         setElSubTypeIncident(arr);

    //         Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` ,arrSubIncident);//set cache

    //     })
    //     .catch(function(error){

    //         console.log(error)

    //     });

    // }else{


    //   let resultData = arrCache.filter( x => x == "subincident");

    //   resultData.forEach(async cachename => {

    //     let cacheStorage = await caches.open(cachename);

    //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/subincident`);

    //     // console.log(dataResponse);

    //     if(!cacheResponse || cacheResponse == undefined){ // if data in cache empty

    //       await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //       .then(function(response){

    //           let arrSubIncidentSameName = [];

    //           let arrSubIncident = [];

    //           let dataResponse = response.data;


    //           dataResponse.forEach(x=>{ //check name if double

    //             // console.log(x);

    //             arrSubIncidentSameName.push(x.name.toLowerCase());

    //           })

    //           dataResponse.forEach(x=>{

    //             let filterNameSubSame = arrSubIncidentSameName.filter(item=> item == x.name.toLowerCase());

    //             // console.log(filterNameSubSame);

    //             if(filterNameSubSame.length > 1){

    //               arrSubIncident.push({
    //                   "created_at":x.created_at,
    //                   "created_by":x.created_by,
    //                   "deleted_at":x.deleted_at,
    //                   "deleted_by":x.deleted_by,
    //                   "id":x.id,
    //                   "incident_id":Helper.hiddenChar(x.incident_id),
    //                   "incident_name":x.incident_name,
    //                   "name":`${x.name} - ${x.incident_name}`,
    //                   "updated_at":x.updated_at,
    //                   "updated_by":x.updated_by
    //                 });

    //             }else{

    //               arrSubIncident.push({
    //                 "created_at":x.created_at,
    //                 "created_by":x.created_by,
    //                 "deleted_at":x.deleted_at,
    //                 "deleted_by":x.deleted_by,
    //                 "id":x.id,
    //                 "incident_id":Helper.hiddenChar(x.incident_id),
    //                 "incident_name":x.incident_name,
    //                 "name": x.name,
    //                 "updated_at":x.updated_at,
    //                 "updated_by":x.updated_by
    //               });


    //             }


    //           });


    //           // console.log(arrSubIncident);

    //           arrSubIncident.forEach(x=>{

    //             arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

    //           });


    //           setSubTypeIncidentData(arrSubIncident);

    //           setElSubTypeIncident(arr);

    //           Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` ,arrSubIncident);//set cache
    //       });

    //     }else{//if data in cache not empty

    //       let dataResponse = await cacheResponse.json();

    //       if(!dataResponse || dataResponse.length == 0){

    //         await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //         .then(function(response){

    //             let arrSubIncidentSameName = [];

    //             let arrSubIncident = [];

    //             let dataResponse = response.data;


    //             dataResponse.forEach(x=>{ //check name if double

    //               // console.log(x);

    //               arrSubIncidentSameName.push(x.name.toLowerCase());

    //             })

    //             dataResponse.forEach(x=>{

    //               let filterNameSubSame = arrSubIncidentSameName.filter(item=> item == x.name.toLowerCase());

    //               // console.log(filterNameSubSame);

    //               if(filterNameSubSame.length > 1){

    //                 arrSubIncident.push({
    //                     "created_at":x.created_at,
    //                     "created_by":x.created_by,
    //                     "deleted_at":x.deleted_at,
    //                     "deleted_by":x.deleted_by,
    //                     "id":x.id,
    //                     "incident_id":Helper.hiddenChar(x.incident_id),
    //                     "incident_name":x.incident_name,
    //                     "name":`${x.name} - ${x.incident_name}`,
    //                     "updated_at":x.updated_at,
    //                     "updated_by":x.updated_by
    //                   });

    //               }else{

    //                 arrSubIncident.push({
    //                   "created_at":x.created_at,
    //                   "created_by":x.created_by,
    //                   "deleted_at":x.deleted_at,
    //                   "deleted_by":x.deleted_by,
    //                   "id":x.id,
    //                   "incident_id":Helper.hiddenChar(x.incident_id),
    //                   "incident_name":x.incident_name,
    //                   "name": x.name,
    //                   "updated_at":x.updated_at,
    //                   "updated_by":x.updated_by
    //                 });


    //               }


    //             });


    //             // console.log(arrSubIncident);

    //             arrSubIncident.forEach(x=>{

    //               arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

    //             });


    //             setSubTypeIncidentData(arrSubIncident);

    //             setElSubTypeIncident(arr);

    //             Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` ,arrSubIncident);//set cache
    //         });


    //       }else{

    //         dataResponse.forEach(item=>{

    //           arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //         });

    //         setSubTypeIncidentData(dataResponse);

    //         setElSubTypeIncident(arr);

    //       }


    //     }

    //   });

    // }

  }

  async function _loadIncidentTypeData(cachenameparam) {

    let arr = [];

    let arrCache = await caches.keys();// get cache

    await axios.get(Uri.rootUri + `cache/`)
      .then(async function (response) {

        //get version data if there is an update
        let dataResponse = response.data;

        //get data cache where cache name
        let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

        if (!arrCache.includes("incident")) {// if empty cache name in browser

          //   console.log("cache in browser empty");

          await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

              let dataResponse = response.data;

              dataResponse.forEach(x => {

                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

              })

              setElTypeOfIncident(arr);

              setTypeIncidentData(dataResponse);

              Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


            })
            .catch(function (error) {

              console.log(error);



            });

          if (resultObjCache.length == 0) {// if data cache in database empty

            let cacheName = {

              "name": cachenameparam,
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
              .then(function (response) {

                // console.log(response);


              })
              .catch(function (error) {


                console.log(error);

              });

          } else {

            // console.log("set version");

            // if data cache in database not empty
            Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

          }

        } else {

          let resultData = arrCache.filter(x => x == "incident");

          //Operation Caching Validation

          resultData.forEach(async cachename => {

            let cacheStorage = await caches.open(cachename);

            let incidentResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/incident');
            //get version in cache for check update data
            let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

            if (!cacheResponse || cacheResponse == undefined || !incidentResponse || incidentResponse == undefined) {// check data valid or no valid

              //   console.log("set version into cache");

              await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                  let dataResponse = response.data;

                  dataResponse.forEach(x => {

                    arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                  })

                  setElTypeOfIncident(arr);

                  setTypeIncidentData(dataResponse);

                  Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                })
                .catch(function (error) {

                  console.log(error);



                });

              if (resultObjCache.length == 0) {// if data cache in database empty

                let cacheName = {

                  "name": cachenameparam,
                  "created_by": localStorage.getItem("usernameLogin"),
                  "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function (response) {

                    // console.log(response);


                  })
                  .catch(function (error) {


                    console.log(error);

                  });

              } else {

                // console.log("set version");

                // if data cache in database not empty
                Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

              }


            } else {

              let dataResponseVersion = await cacheResponse.json();

              let dataResponseIncident = await incidentResponse.json();

              if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseIncident || dataResponseIncident.length == 0) {/*check data into cache empty or not empty*/

                await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                      arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                    })

                    setElTypeOfIncident(arr);

                    setTypeIncidentData(dataResponse);

                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                  })
                  .catch(function (error) {

                    console.log(error);



                  });

                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      //   console.log(response);


                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  //   console.log("set version");

                  // if data cache in database not empty
                  Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

              } else {

                // dataResponseVersion and dataResponseIncident there must be !

                if (resultObjCache.length == 0) {// if data cache in database empty

                  await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      let dataResponse = response.data;

                      dataResponse.forEach(x => {

                        arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                      })

                      setElTypeOfIncident(arr);

                      setTypeIncidentData(dataResponse);

                      Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                    })
                    .catch(function (error) {

                      console.log(error);



                    });

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      //   console.log(response);


                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  //check version  cache local browser you and database api , resultObjCache there must be !
                  let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                  if (resultVersion) {

                    //   console.log("Update cache new version ");

                    await axios.get(Uri.rootUri + `master/incident/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        let dataResponse = response.data;

                        dataResponse.forEach(x => {

                          arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                        })

                        setElTypeOfIncident(arr);

                        setTypeIncidentData(dataResponse);

                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponse);


                      })
                      .catch(function (error) {

                        console.log(error);



                      });

                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  } else {

                    // console.log("data incident and version available in cache");

                    dataResponseIncident.forEach(x => {

                      arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                    })

                    setElTypeOfIncident(arr);

                    setTypeIncidentData(dataResponseIncident);

                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponseIncident);

                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                }


              }

            }

          });

        }


      })
      .catch(function (error) {

        console.log(error);

      });


    //   let arr = [];

    //   let arrCache = await caches.keys();// get cache

    //   // console.log(dataIncident);

    //   if(!arrCache.includes('incident')){//check cache name incident

    //     // console.log("Get Api incident");

    //     await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //     .then(async function(response){

    //         let dataResponse = response.data;

    //         dataResponse.forEach(item=>{

    //             arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);


    //         });

    //         setTypeIncidentData(dataResponse);

    //         setElTypeOfIncident(arr);

    //         Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` ,dataResponse);//set cache



    //     })
    //     .catch(function(error){

    //         console.log(error);


    //     });


    // }else{

    //   let resultData = arrCache.filter( x => x == "incident");

    //   resultData.forEach(async cachename => {

    //     let cacheStorage = await caches.open(cachename);

    //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/incident');

    //     // console.log(dataResponse);

    //     if(!cacheResponse || cacheResponse == undefined){// if empty data in cache

    //       // console.log("Set Cache Incident");

    //       await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //       .then(function(response){

    //           let dataResponse = response.data;

    //           dataResponse.forEach(item=>{

    //               arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);


    //           });

    //           setTypeIncidentData(dataResponse);

    //           setElTypeOfIncident(arr);

    //           Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` ,dataResponse);//set cache



    //       })
    //       .catch(function(error){

    //           console.log(error);


    //       });


    //     }else{// if data not empty in cache

    //     // console.log(dataResponse);

    //       let dataResponse = await cacheResponse.json();

    //       if(!dataResponse || dataResponse.length == 0){

    //           await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //           .then(function(response){

    //               let dataResponse = response.data;

    //               dataResponse.forEach(item=>{

    //                   arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);


    //               });

    //               setTypeIncidentData(dataResponse);

    //               setElTypeOfIncident(arr);

    //               Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` ,dataResponse);//set cache



    //           })
    //           .catch(function(error){

    //               console.log(error);

    //           });

    //           }else{


    //           dataResponse.forEach(item=>{

    //               arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //           });

    //               setElTypeOfIncident(arr);

    //               setTypeIncidentData(dataResponse);

    //           }

    //       }

    //     });

    // }

  }
  async function _subTypeIncidentEl(id) {

    let arrSub = [];
    let arr = [];

    // console.log(id);

    if (id) {

      subTypeIncidentData.forEach(x => {

        if (Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(id)) {

          if (subTypeIncident) {

            arrSub.push(<option selected value={Helper.hiddenChar(x.id)}>{x.name}</option>)

          } else {

            arrSub.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)
          }


        }

      });

      typeIncidentData.forEach(x => {

        if (Helper.hiddenChar(x.id) == Helper.hiddenChar(id)) {

          if (incidentType) {

            arr.push(<option selected value={Helper.hiddenChar(x.id)} >{x.name}</option>);

          } else {

            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

          }

        }


      });

    } else {

      typeIncidentData.forEach(x => {

        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

      });

      setSubTypeIncident(); //set sub type incident to be all


    }

    setElSubTypeIncident(arrSub);

    setElTypeOfIncident(arr);

  }

  async function _loadUser(cachenameparam) {

    let arr = [];

    let arrCache = await caches.keys();// get cache

    await axios.get(Uri.rootUri + `cache/`)
      .then(async function (response) {

        // console.log(response);

        //get version data if there is an update
        let dataResponse = response.data;

        //get data cache where cache name
        let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

        // console.log(resultObjCache);

        // get cache name users
        if (!arrCache.includes("users")) {// if empty cache name in browser

          console.log("cache in browser empty");

          await axios.get(Uri.rootUri + `user/`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

              // console.log(response);

              let dataResponse = response.data;

              dataResponse.forEach(x => {

                let strPermissions = Array.from(Helper.hiddenChar(x.permissions));

                if (strPermissions.includes(editIncident)) {

                  arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

                }

              });

              setUsers(dataResponse);

              setElUser(arr);

              Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users`, dataResponse);//set cache

            })
            .catch(function (error) {

              console.log(error);

            });

          if (resultObjCache.length == 0) {// if data cache in database empty

            let cacheName = {

              "name": cachenameparam,
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
              .then(function (response) {

                // console.log(response);


              })
              .catch(function (error) {


                console.log(error);

              });

          } else {

            // console.log("set version");

            // if data cache in database not empty
            Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

          }

        } else { // if cache name available

          let resultData = arrCache.filter(x => x == "users");

          //Operation Caching Validation

          resultData.forEach(async cachename => {

            let cacheStorage = await caches.open(cachename);

            let usersResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/users');
            //get version in cache for check update data
            let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

            if (!cacheResponse || cacheResponse == undefined || !usersResponse || usersResponse == undefined) {// check data valid or no valid

              await axios.get(Uri.rootUri + `user/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                  // console.log(response);

                  let dataResponse = response.data;

                  dataResponse.forEach(x => {

                    let strPermissions = Array.from(Helper.hiddenChar(x.permissions));

                    if (strPermissions.includes(editIncident)) {

                      arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

                    }

                  });

                  setUsers(dataResponse);

                  setElUser(arr);

                  Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users`, dataResponse);//set cache

                })
                .catch(function (error) {

                  console.log(error);

                });


              if (resultObjCache.length == 0) {// if data cache in database empty

                let cacheName = {

                  "name": cachenameparam,
                  "created_by": localStorage.getItem("usernameLogin"),
                  "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function (response) {

                    //   console.log(response);


                  })
                  .catch(function (error) {


                    console.log(error);

                  });

              } else {

                // if data cache in database not empty
                Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

              }

            } else {

              let dataResponseVersion = await cacheResponse.json();

              let dataResponseUsers = await usersResponse.json();

              if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseUsers || dataResponseUsers.length == 0) {/*check data into cache empty or not empty*/

                await axios.get(Uri.rootUri + `user/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    // console.log(response);

                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                      let strPermissions = Array.from(Helper.hiddenChar(x.permissions));

                      if (strPermissions.includes(editIncident)) {

                        arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

                      }

                    });

                    setUsers(dataResponse);

                    setElUser(arr);

                    Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users`, dataResponse);//set cache

                    // Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version` , );//set cache

                  })
                  .catch(function (error) {

                    console.log(error);

                  });


                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      // console.log(response);


                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  // if data cache in database not empty
                  Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

              } else {

                // dataResponseVersion and dataResponseUsers there must be !

                if (resultObjCache.length == 0) {// if data cache in database empty

                  await axios.get(Uri.rootUri + `user/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      // console.log(response);

                      let dataResponse = response.data;

                      dataResponse.forEach(x => {

                        let strPermissions = Array.from(Helper.hiddenChar(x.permissions));

                        if (strPermissions.includes(editIncident)) {

                          arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

                        }

                      });

                      setUsers(dataResponse);

                      setElUser(arr);

                      Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users`, dataResponse);//set cache

                    })
                    .catch(function (error) {

                      console.log(error);

                    });

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      // console.log(response);

                    })
                    .catch(function (error) {

                      console.log(error);

                    });

                } else {

                  //check version  cache local browser you and database api , resultObjCache there must be !
                  let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                  if (resultVersion) {

                    // console.log("Update cache new version ");

                    await axios.get(Uri.rootUri + `user/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        // console.log(response);

                        let dataResponse = response.data;

                        dataResponse.forEach(x => {

                          let strPermissions = Array.from(Helper.hiddenChar(x.permissions));

                          if (strPermissions.includes(editIncident)) {

                            arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

                          }

                        });

                        setUsers(dataResponse);

                        setElUser(arr);

                        Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users`, dataResponse);//set cache

                      })
                      .catch(function (error) {

                        console.log(error);

                      });

                    Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  } else {

                    dataResponseUsers.forEach(x => {

                      let strPermissions = Array.from(Helper.hiddenChar(x.permissions));

                      if (strPermissions.includes(editIncident)) {

                        arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

                      }

                    });

                    setUsers(dataResponseUsers);

                    setElUser(arr);

                    Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users`, dataResponseUsers);

                    Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                  // if data cache in database not empty

                }


              }

              // console.log(dataResponse);

            }

          });

        }

      })
      .catch(function (error) {

        console.log(error);

      });


    // let arr = [];

    // let arrCache = await caches.keys();// get cache

    // if(!arrCache.includes("users")){

    //     await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //     .then(function(response){

    //       // console.log(response);

    //       let dataResponse = response.data;

    //       dataResponse.forEach(x=>{

    //         arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

    //       });

    //       setUsers(dataResponse);

    //       setElUser(arr);

    //       Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache

    //     })
    //     .catch(function(error){

    //       console.log(error);

    //     });

    // }else{

    //    let resultData = arrCache.filter( x => x == "users");

    //     resultData.forEach(async cachename => {

    //         let cacheStorage = await caches.open(cachename);

    //         let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/users');

    //         if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

    //           await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //           .then(function(response){

    //             // console.log(response);

    //             let dataResponse = response.data;

    //             dataResponse.forEach(x=>{

    //               arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

    //             });

    //             setUsers(dataResponse);

    //             setElUser(arr);

    //             Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache

    //           })
    //           .catch(function(error){

    //             console.log(error);

    //           });

    //         }else{// if data in cache not empty

    //           let dataResponse = await cacheResponse.json();

    //           if(!dataResponse || dataResponse.length == 0){

    //             await axios.get(Uri.rootUri + `user/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //             .then(function(response){

    //               // console.log(response);

    //               let dataResponse = response.data;

    //               dataResponse.forEach(x=>{

    //                 arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

    //               });

    //               setUsers(dataResponse);

    //               setElUser(arr);

    //               Helper.addDataIntoCache("users", Helper.getHostCurrentUrl() + `/users` ,dataResponse);//set cache

    //             })
    //             .catch(function(error){

    //               console.log(error);

    //             });

    //           }else{

    //             dataResponse.forEach(x=>{

    //               arr.push(<option value={`${x.id}`}>{`${x.username}`}</option>);

    //             });

    //             setElUser(arr);

    //             setUsers(dataResponse);
    //           }


    //         }

    //       });
    // }

  }

  async function _loadTargetHumanData(cachenameparam) {

    let arr = [];

    let arrCache = await caches.keys();// get cache

    await axios.get(Uri.rootUri + `cache/`)
      .then(async function (response) {

        //get version data if there is an update
        let dataResponse = response.data;

        //get data cache where cache name
        let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

        if (!arrCache.includes("targethuman")) {// if empty cache name in browser

          //   console.log("cache in browser empty");

          await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

              // console.log(response);

              let dataResponse = response.data;

              dataResponse.forEach(x => {

                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

              });


              setTargetHumanData(dataResponse);

              setElTargetHuman(arr);

              Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


            })
            .catch(function (error) {

              console.log(error);

            });

          if (resultObjCache.length == 0) {// if data cache in database empty

            let cacheName = {

              "name": cachenameparam,
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
              .then(function (response) {

                // console.log(response);


              })
              .catch(function (error) {


                console.log(error);

              });

          } else {

            // console.log("set version");

            // if data cache in database not empty
            Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

          }

        } else {

          let resultData = arrCache.filter(x => x == "targethuman");

          //Operation Caching Validation

          resultData.forEach(async cachename => {

            let cacheStorage = await caches.open(cachename);

            let targetHumanResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/targethuman');
            //get version in cache for check update data
            let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

            if (!cacheResponse || cacheResponse == undefined || !targetHumanResponse || targetHumanResponse == undefined) {// check data valid or no valid

              await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                  // console.log(response);

                  let dataResponse = response.data;

                  dataResponse.forEach(x => {

                    arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                  });


                  setTargetHumanData(dataResponse);

                  setElTargetHuman(arr);

                  Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                })
                .catch(function (error) {

                  console.log(error);

                });

              if (resultObjCache.length == 0) {// if data cache in database empty

                let cacheName = {

                  "name": cachenameparam,
                  "created_by": localStorage.getItem("usernameLogin"),
                  "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function (response) {

                    //   console.log(response);


                  })
                  .catch(function (error) {


                    console.log(error);

                  });

              } else {

                // if data cache in database not empty
                Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

              }

            } else {

              let dataResponseVersion = await cacheResponse.json();

              let dataResponseTargetHuman = await targetHumanResponse.json();

              if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseTargetHuman || dataResponseTargetHuman.length == 0) {/*check data into cache empty or not empty*/

                await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    // console.log(response);

                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                      arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                    });


                    setTargetHumanData(dataResponse);

                    setElTargetHuman(arr);

                    Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                  })
                  .catch(function (error) {

                    console.log(error);

                  });

                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      //   console.log(response);

                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  // if data cache in database not empty
                  Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

              } else {

                // dataResponseVersion and dataResponseTargetHuman there must be !

                if (resultObjCache.length == 0) {// if data cache in database empty

                  await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      // console.log(response);

                      let dataResponse = response.data;

                      dataResponse.forEach(x => {

                        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                      });


                      setTargetHumanData(dataResponse);

                      setElTargetHuman(arr);

                      Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                    })
                    .catch(function (error) {

                      console.log(error);

                    });

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      //   console.log(response);

                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  //check version  cache local browser you and database api , resultObjCache there must be !
                  let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                  if (resultVersion) {

                    //   console.log("Update cache new version ");

                    await axios.get(Uri.rootUri + `master/target/human/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        // console.log(response);

                        let dataResponse = response.data;

                        dataResponse.forEach(x => {

                          arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                        });


                        setTargetHumanData(dataResponse);

                        setElTargetHuman(arr);

                        Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);


                      })
                      .catch(function (error) {

                        console.log(error);

                      });

                    Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  } else {

                    dataResponseTargetHuman.forEach(x => {

                      arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                    });


                    setTargetHumanData(dataResponseTargetHuman);

                    setElTargetHuman(arr);

                    Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponseTargetHuman);

                    Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                }


              }

            }

          });

        }


      })
      .catch(function (error) {

        console.log(error);

      });

    // let arr = [];

    // let arrCache = await caches.keys();// get cache


    // if(!arrCache.includes("targethuman")){

    //   await axios.get(Uri.rootUri + `master/target/human/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //   .then(function(response){


    //       let dataResponse = response.data;

    //       dataResponse.forEach(x=>{

    //           arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //       });


    //       setTargetHumanData(dataResponse);

    //       setElTargetHuman(arr);

    //       Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);//set data to cache

    //   })
    //   .catch(function(error){

    //       console.log(error);

    //   });

    // }else{

    //   // console.log("Get target human data");

    //   let resultData = arrCache.filter( x => x == "targethuman"); //get where cache name

    //       resultData.forEach(async cachename => {

    //         let cacheStorage = await caches.open(cachename);

    //         let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/targethuman`);

    //         // console.log(dataResponse);

    //         if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

    //           await axios.get(Uri.rootUri + `master/target/human/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //           .then(function(response){


    //               let dataResponse = response.data;

    //               dataResponse.forEach(x=>{

    //                   arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //               });


    //               setTargetHumanData(dataResponse);

    //               setElTargetHuman(arr);

    //               Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);//set data to cache

    //           })
    //           .catch(function(error){

    //               console.log(error);

    //           });

    //         }else{ //if data in cache not empty


    //           let dataResponse = await cacheResponse.json();

    //           if(!dataResponse || dataResponse.length == 0){

    //               await axios.get(Uri.rootUri + `master/target/human/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //               .then(function(response){


    //                   let dataResponse = response.data;

    //                   dataResponse.forEach(x=>{

    //                       arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //                   });


    //                   setTargetHumanData(dataResponse);

    //                   setElTargetHuman(arr);

    //                   Helper.addDataIntoCache("targethuman", Helper.getHostCurrentUrl() + `/targethuman`, dataResponse);//set data to cache

    //               })
    //               .catch(function(error){

    //                   console.log(error);

    //               });

    //           }else{

    //             dataResponse.forEach(item=>{

    //               arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //             });

    //             setTargetHumanData(dataResponse);

    //             setElTargetHuman(arr);

    //           }


    //         }


    //       });
    // }
  }

  async function _loadTargetAssetsData(cachenameparam) {

    let arr = [];

    let arrCache = await caches.keys();// get cache

    await axios.get(Uri.rootUri + `cache/`)
      .then(async function (response) {

        //get version data if there is an update
        let dataResponse = response.data;

        //get data cache where cache name
        let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

        if (!arrCache.includes("targetassets")) {// if empty cache name in browser

          //   console.log("cache in browser empty");

          await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

              // console.log(response);

              let dataResponse = response.data;

              dataResponse.forEach(x => {

                arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

              });


              setTargetAssetsData(dataResponse);

              setElTargetAssets(arr);

              Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


            })
            .catch(function (error) {

              console.log(error);

            });

          if (resultObjCache.length == 0) {// if data cache in database empty

            let cacheName = {

              "name": cachenameparam,
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
              .then(function (response) {

                // console.log(response);


              })
              .catch(function (error) {


                console.log(error);

              });

          } else {

            // console.log("set version");

            // if data cache in database not empty
            Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

          }

        } else {

          let resultData = arrCache.filter(x => x == "targetassets");

          //Operation Caching Validation

          resultData.forEach(async cachename => {

            let cacheStorage = await caches.open(cachename);

            let targetAssetsResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/targetassets');
            //get version in cache for check update data
            let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

            if (!cacheResponse || cacheResponse == undefined || !targetAssetsResponse || targetAssetsResponse == undefined) {// check data valid or no valid

              await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                  // console.log(response);

                  let dataResponse = response.data;

                  dataResponse.forEach(x => {

                    arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                  });


                  setTargetAssetsData(dataResponse);

                  setElTargetAssets(arr);


                  Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                })
                .catch(function (error) {

                  console.log(error);

                });

              if (resultObjCache.length == 0) {// if data cache in database empty

                let cacheName = {

                  "name": cachenameparam,
                  "created_by": localStorage.getItem("usernameLogin"),
                  "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function (response) {

                    //   console.log(response);


                  })
                  .catch(function (error) {


                    console.log(error);

                  });

              } else {

                // if data cache in database not empty
                Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

              }

            } else {

              let dataResponseVersion = await cacheResponse.json();

              let dataResponseTargetAssets = await targetAssetsResponse.json();

              if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseTargetAssets || dataResponseTargetAssets.length == 0) {/*check data into cache empty or not empty*/

                await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    // console.log(response);

                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                      arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                    });


                    setTargetAssetsData(dataResponse);

                    setElTargetAssets(arr);


                    Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                  })
                  .catch(function (error) {

                    console.log(error);

                  });

                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      //   console.log(response);

                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  // if data cache in database not empty
                  Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

              } else {

                // dataResponseVersion and dataResponseTargetAssets there must be !

                if (resultObjCache.length == 0) {// if data cache in database empty

                  await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      // console.log(response);

                      let dataResponse = response.data;

                      dataResponse.forEach(x => {

                        arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                      });


                      setTargetAssetsData(dataResponse);

                      setElTargetAssets(arr);


                      Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                    })
                    .catch(function (error) {

                      console.log(error);

                    });

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      //   console.log(response);

                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  //check version  cache local browser you and database api , resultObjCache there must be !
                  let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                  if (resultVersion) {

                    //   console.log("Update cache new version ");

                    await axios.get(Uri.rootUri + `master/target/facility/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        // console.log(response);

                        let dataResponse = response.data;

                        dataResponse.forEach(x => {

                          arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                        });


                        setTargetAssetsData(dataResponse);

                        setElTargetAssets(arr);


                        Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);


                      })
                      .catch(function (error) {

                        console.log(error);

                      });

                    Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  } else {

                    dataResponseTargetAssets.forEach(x => {

                      arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

                    });


                    setTargetAssetsData(dataResponseTargetAssets);

                    setElTargetAssets(arr);


                    Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponseTargetAssets);

                    Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                }


              }

            }

          });

        }


      })
      .catch(function (error) {

        console.log(error);

      });


    // let arr = [];

    // let arrCache = await caches.keys();// get cache

    // if(!arrCache.includes("targetassets")){

    //     await axios.get(Uri.rootUri + `master/target/facility/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //     .then(function(response){

    //         let arr = [];

    //         let dataResponse = response.data;

    //         dataResponse.forEach(x=>{

    //             arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //         });


    //         setTargetAssetsData(dataResponse);

    //         setElTargetAssets(arr);

    //         Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);//set data to cache

    //     })
    //     .catch(function(error){

    //         console.log(error);

    //     });

    // }else{

    //     let resultData = arrCache.filter( x => x == "targetassets"); //get where cache name

    //         resultData.forEach(async cachename => {

    //           let cacheStorage = await caches.open(cachename);

    //           let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/targetassets`);

    //           // console.log(dataResponse);

    //           if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

    //             await axios.get(Uri.rootUri + `master/target/facility/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //             .then(function(response){

    //               let arr = [];

    //               let dataResponse = response.data;

    //               dataResponse.forEach(x=>{

    //                   arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //               });


    //               setTargetAssetsData(dataResponse);

    //               setElTargetAssets(arr);

    //               Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);//set data to cache

    //             })
    //             .catch(function(error){

    //                 console.log(error);

    //             });


    //           }else{ //if data in cache not empty

    //             let dataResponse = await cacheResponse.json();

    //             if(!dataResponse || dataResponse.length == 0){

    //               await axios.get(Uri.rootUri + `master/target/facility/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //               .then(function(response){

    //                 let arr = [];

    //                 let dataResponse = response.data;

    //                 dataResponse.forEach(x=>{

    //                     arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //                 });


    //                 setTargetAssetsData(dataResponse);

    //                 setElTargetAssets(arr);

    //                 Helper.addDataIntoCache("targetassets", Helper.getHostCurrentUrl() + `/targetassets`, dataResponse);//set data to cache

    //               })
    //               .catch(function(error){

    //                   console.log(error);

    //               });

    //             }else{

    //               dataResponse.forEach(item=>{

    //                 arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //               });

    //               setTargetAssetsData(dataResponse);

    //               setElTargetAssets(arr);

    //             }


    //           }


    //         });

    // }
  }

  async function _loadStateRegionData(cachenameparam) {

    let arr = [];

    let arrCache = await caches.keys();// get cache

    await axios.get(Uri.rootUri + `cache/`)
      .then(async function (response) {

        //get version data if there is an update
        let dataResponse = response.data;

        //get data cache where cache name
        let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

        if (!arrCache.includes("region")) {// if empty cache name in browser

          //   console.log("cache in browser empty");

          await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

              let dataResponse = response.data;

              dataResponse.forEach(x => {

                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

              });

              setElStateRegion(arr);

              setStateRegionData(dataResponse);

              Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


            })
            .catch(function (error) {

              console.log(error);



            });

          if (resultObjCache.length == 0) {// if data cache in database empty

            let cacheName = {

              "name": cachenameparam,
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
              .then(function (response) {

                // console.log(response);


              })
              .catch(function (error) {


                console.log(error);

              });

          } else {

            // console.log("set version");

            // if data cache in database not empty
            Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

          }

        } else {

          let resultData = arrCache.filter(x => x == "region");

          //Operation Caching Validation

          resultData.forEach(async cachename => {

            let cacheStorage = await caches.open(cachename);

            let regionResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/region');
            //get version in cache for check update data
            let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

            if (!cacheResponse || cacheResponse == undefined || !regionResponse || regionResponse == undefined) {// check data valid or no valid

              //   console.log("set version into cache");

              await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                  let dataResponse = response.data;

                  dataResponse.forEach(x => {

                    arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                  });

                  setElStateRegion(arr);

                  setStateRegionData(dataResponse);

                  Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                })
                .catch(function (error) {

                  console.log(error);



                });

              if (resultObjCache.length == 0) {// if data cache in database empty

                let cacheName = {

                  "name": cachenameparam,
                  "created_by": localStorage.getItem("usernameLogin"),
                  "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function (response) {

                    // console.log(response);


                  })
                  .catch(function (error) {


                    console.log(error);

                  });

              } else {

                // console.log("set version");

                // if data cache in database not empty
                Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

              }


            } else {

              let dataResponseVersion = await cacheResponse.json();

              let dataResponseRegion = await regionResponse.json();

              if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseRegion || dataResponseRegion.length == 0) {/*check data into cache empty or not empty*/

                await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                      arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                    });

                    setElStateRegion(arr);

                    setStateRegionData(dataResponse);

                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                  })
                  .catch(function (error) {

                    console.log(error);



                  });

                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      //   console.log(response);


                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  //   console.log("set version");

                  // if data cache in database not empty
                  Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

              } else {

                // dataResponseVersion and dataResponseRegion there must be !

                if (resultObjCache.length == 0) {// if data cache in database empty

                  await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      let dataResponse = response.data;

                      dataResponse.forEach(x => {

                        arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                      });

                      setElStateRegion(arr);

                      setStateRegionData(dataResponse);

                      Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                    })
                    .catch(function (error) {

                      console.log(error);



                    });

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      //   console.log(response);


                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  //check version  cache local browser you and database api , resultObjCache there must be !
                  let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                  if (resultVersion) {

                    //   console.log("Update cache new version ");

                    await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        let dataResponse = response.data;

                        dataResponse.forEach(x => {

                          arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                        });

                        setElStateRegion(arr);

                        setStateRegionData(dataResponse);

                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                      })
                      .catch(function (error) {

                        console.log(error);



                      });

                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  } else {

                    // console.log("data incident and version available in cache");

                    dataResponseRegion.forEach(x => {

                      arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                    })

                    setElStateRegion(arr);

                    setStateRegionData(dataResponseRegion);

                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponseRegion);

                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                }


              }

            }

          });

        }


      })
      .catch(function (error) {

        console.log(error);

      });


    // let arr = [];

    // let arrCache = await caches.keys();// get cache

    // if(!arrCache.includes("region")){

    //     await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //     .then(function(response){


    //         let dataResponse = response.data;

    //         dataResponse.forEach(x=>{

    //             arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //         });


    //         setStateRegionData(dataResponse);

    //         setElStateRegion(arr);

    //         Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);//set data to cache


    //     })
    //     .catch(function(error){



    //         console.log(error)

    //     });

    // }else{

    //     let resultData = arrCache.filter( x => x == "region");

    //       resultData.forEach(async cachename => {

    //         let cacheStorage = await caches.open(cachename);

    //         let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/region`);

    //         // console.log(dataResponse);

    //         if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

    //           await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //           .then(function(response){

    //               let dataResponse = response.data;

    //               dataResponse.forEach(x=>{

    //                   arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //               });

    //               Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);

    //               setStateRegionData(dataResponse);

    //               setElStateRegion(arr);



    //           })
    //           .catch(function(error){

    //               console.log(error);

    //           });

    //         }else{ // if data in cache not empty

    //           let dataResponse = await cacheResponse.json();

    //           if(!dataResponse || dataResponse.length == 0){

    //               await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //               .then(function(response){

    //                   let dataResponse = response.data;

    //                   dataResponse.forEach(x=>{

    //                       arr.push(<option value={Helper.hiddenChar(x.id)} >{x.name}</option>);

    //                   });

    //                   Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);

    //                   setStateRegionData(dataResponse);

    //                   setElStateRegion(arr);



    //               })
    //               .catch(function(error){

    //                   console.log(error);

    //               });

    //           }else{


    //             dataResponse.forEach(item=>{

    //               arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //             });

    //             setStateRegionData(dataResponse);

    //             setElStateRegion(arr);

    //           }


    //         }

    //       });
    // }

  }


  async function _loadTownshipData(cachenameparam) {

    // setCurrentPageTownship(page);
    let arr = [];

    let arrCache = await caches.keys();// get cache

    await axios.get(Uri.rootUri + `cache/`)
      .then(async function (response) {

        //get version data if there is an update
        let dataResponse = response.data;

        //get data cache where cache name
        let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

        if (!arrCache.includes("township")) {// if empty cache name in browser

          // console.log("cache in browser empty");

          await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

              // console.log(response);

              let dataResponse = response.data;

              dataResponse.forEach(x => {

                arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

              });

              setTownshipData(dataResponse);

              setElTownship(arr);

              Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


            })
            .catch(function (error) {

              console.log(error);

            });

          if (resultObjCache.length == 0) {// if data cache in database empty

            let cacheName = {

              "name": cachenameparam,
              "created_by": localStorage.getItem("usernameLogin"),
              "updated_by": localStorage.getItem("usernameLogin")

            }

            await axios.post(Uri.rootUri + `cache/`, cacheName)
              .then(function (response) {

                //   console.log(response);


              })
              .catch(function (error) {


                console.log(error);

              });

          } else {

            //   console.log("set version");

            // if data cache in database not empty
            Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

          }

        } else {

          let resultData = arrCache.filter(x => x == "township");

          //Operation Caching Validation

          resultData.forEach(async cachename => {

            let cacheStorage = await caches.open(cachename);

            let townshipResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/township');
            //get version in cache for check update data
            let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

            if (!cacheResponse || cacheResponse == undefined || !townshipResponse || townshipResponse == undefined) {// check data valid or no valid

              await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(function (response) {

                  // console.log(response);

                  let dataResponse = response.data;

                  dataResponse.forEach(x => {

                    arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                  });

                  setTownshipData(dataResponse);

                  setElTownship(arr);

                  Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                })
                .catch(function (error) {

                  console.log(error);

                });

              if (resultObjCache.length == 0) {// if data cache in database empty

                let cacheName = {

                  "name": cachenameparam,
                  "created_by": localStorage.getItem("usernameLogin"),
                  "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function (response) {

                    // console.log(response);


                  })
                  .catch(function (error) {


                    console.log(error);

                  });

              } else {

                // if data cache in database not empty
                Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

              }

            } else {

              let dataResponseVersion = await cacheResponse.json();

              let dataResponseTownship = await townshipResponse.json();

              if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseTownship || dataResponseTownship.length == 0) {/*check data into cache empty or not empty*/

                await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                  .then(function (response) {

                    // console.log(response);

                    let dataResponse = response.data;

                    dataResponse.forEach(x => {

                      arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                    });

                    setTownshipData(dataResponse);

                    setElTownship(arr);

                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                  })
                  .catch(function (error) {

                    console.log(error);

                  });

                if (resultObjCache.length == 0) {// if data cache in database empty

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      // console.log(response);

                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  // if data cache in database not empty
                  Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                }

              } else {

                // dataResponseVersion and dataResponseTownship there must be !

                if (resultObjCache.length == 0) {// if data cache in database empty

                  await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                    .then(function (response) {

                      // console.log(response);

                      let dataResponse = response.data;

                      dataResponse.forEach(x => {

                        arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                      });

                      setTownshipData(dataResponse);

                      setElTownship(arr);

                      Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                    })
                    .catch(function (error) {

                      console.log(error);

                    });

                  let cacheName = {

                    "name": cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .then(function (response) {

                      // console.log(response);

                    })
                    .catch(function (error) {


                      console.log(error);

                    });

                } else {

                  //check version  cache local browser you and database api , resultObjCache there must be !
                  let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                  if (resultVersion) {

                    // console.log("Update cache new version ");

                    await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                      .then(function (response) {

                        // console.log(response);

                        let dataResponse = response.data;

                        dataResponse.forEach(x => {

                          arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                        });

                        setTownshipData(dataResponse);

                        setElTownship(arr);

                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                      })
                      .catch(function (error) {

                        console.log(error);

                      });

                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  } else {

                    dataResponseTownship.forEach(x => {

                      arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

                    });

                    setTownshipData(dataResponseTownship);

                    setElTownship(arr);

                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);

                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                  }

                }


              }

            }

          });

        }


      })
      .catch(function (error) {

        console.log(error);

      });


    // let arr = [];

    // let arrCache = await caches.keys();// get cache

    // if(!arrCache.includes("township")){

    //   await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //   .then(function(response){

    //       let dataResponse = response.data;

    //       // console.log(dataResponse);

    //       dataResponse.forEach(x=>{

    //           arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

    //       });


    //       setTownshipData(dataResponse);

    //       setElTownship(arr);

    //       Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);//set data to cache


    //   })
    //   .catch(function(error){

    //       console.log(error);


    //   });

    // }else{

    //     let resultData = arrCache.filter( x => x == "township");

    //     resultData.forEach(async cachename => {

    //       let cacheStorage = await caches.open(cachename);

    //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/township`);

    //       // console.log(dataResponse);

    //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

    //         await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //         .then(function(response){

    //             let dataResponse = response.data;

    //             // console.log(dataResponse);

    //             dataResponse.forEach(x=>{

    //                 arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

    //             });


    //             setTownshipData(dataResponse);

    //             setElTownship(arr);

    //             Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);//set data to cache


    //         })
    //         .catch(function(error){

    //             console.log(error);


    //         });

    //       }else{

    //         let dataResponse = await cacheResponse.json();

    //         if(!dataResponse || dataResponse.length == 0){

    //             await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //             .then(function(response){

    //                 let dataResponse = response.data;

    //                 // console.log(dataResponse);

    //                 dataResponse.forEach(x=>{

    //                     arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

    //                 });


    //                 setTownshipData(dataResponse);

    //                 setElTownship(arr);

    //                 Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);//set data to cache


    //             })
    //             .catch(function(error){

    //                 console.log(error);


    //             });

    //         }else{

    //           dataResponse.forEach(item=>{

    //             arr.push(<option value={Helper.hiddenChar(item.id)}>{item.name}</option>);

    //           });

    //           setTownshipData(dataResponse);

    //           setElTownship(arr);

    //         }


    //       }

    //     });

    // }

  }
  async function _onScrollTownship(e) {


    let target = e.target;

    if (!township && !stateRegion) {

      if (!loading && target.scrollTop + target.offsetHeight === target.scrollHeight) {

        target.scrollTo(0, target.scrollHeight)

        // setTimeout(async ()=>{

        setCurrentPageTownship(currentPageTownship + 1)

        await axios.get(Uri.rootUri + `master/township/?page=${currentPageTownship + 1}`, { headers: { "Authorization": `Bearer ${authToken}` } })
          .then(function (response) {

            let arr = [];

            let dataResponse = response.data.results;

            // console.log(dataResponse);

            dataResponse.forEach(x => {

              arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

            });

            setTownshipData(townshipData.concat(dataResponse));

            setElTownship(elTownship.concat(arr));


          })
          .catch(function (error) {

            console.log(error);


          });

        // },1000);

      }

    }

  }


  async function _loadUserInformation() {

    const userInformation = await Helper.getUserLogin();

    if (userInformation) {

      setUserId(userInformation.user_id);

      setEmailAddress(userInformation.email_address);

      setAuthId(userInformation.auth_id);

      setCompanyId(userInformation.company_id);

      setUsername(userInformation.username);

      let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));

      setPermissions(strPermissions);

      if (!strPermissions.includes(editIncident) && !strPermissions.includes(approveIncident) && !strPermissions.includes(readOnly)) {// if not Permission Type Edit Incident

        history.push("Unauthorize");

      }

    }

  }

  function onShowSizeChange(current, size) {

    setCurrentPage(current);

    setPageSize(size);

    // console.log(size, current)

    _showSizeChange(current, size);

    // _loadData(current, size);




  }

  async function handleTableChange(pagination, filters, sorter) {

    let arr = [];

    let fieldColumn = sorter;

    // console.log(fieldColumn);

    let column = fieldColumn.field && (fieldColumn.order !== undefined) ? `order_by=${fieldColumn.field}&` : ""; //this is field columns

    let order;

    let orderState;

    if (fieldColumn.order !== undefined) {

      switch (fieldColumn.order) {
        case "ascend":
          order = `order=asc&` //this is sort order (Ascending, Descending, Default);
          orderState = "asc";
          break;
        case "descend":
          order = `order=desc&` //this is sort order (Ascending, Descending, Default);
          orderState = "desc";
          break;

        default:
          break;
      }

    } else {

      // order = `order=default&` //this is sort order (Ascending, Descending, Default);
      // orderState = 'default';

      order = "" //this is sort order (Ascending, Descending, Default);
      orderState = undefined;
    }

    let currentPageSize = pageSize ? `page_size=${pageSize}&` : `page_size=50&`;
    let currentPageOnFilter = currentPage ? `page=${currentPage}&` : `page=1&`;
    let draftedByFilter = draftedBy ? `drafted_by=${draftedBy}&` : "";
    let incidentDateFilter = incidentDateFormatted ? `incidentdate=${incidentDateFormatted}&` : "";
    let typeOfIncidentIdFilter = incidentType ? `typeofincident_id=${incidentType}&` : "";
    let townshipIdFilter = township ? `township_id=${township}&` : "";
    let stateRegionIdFilter = stateRegion ? `stateregion_id=${stateRegion}&` : "";
    let subTypeOfIncidentIdFilter = subTypeIncident ? `subtypeofincident_id=${subTypeIncident}&` : "";
    let searchText = search ? `search=${search}&` : "";

    // console.log(pagination, filters, sorter);

    await axios.get(Uri.rootUri + `incident/list/?is_approve=${true}&${currentPageOnFilter}${currentPageSize}${draftedByFilter}${incidentDateFilter}${typeOfIncidentIdFilter}${subTypeOfIncidentIdFilter}${townshipIdFilter}${stateRegionIdFilter}${searchText}${column}${order}`, { headers: { "Authorization": `Bearer ${authToken}` } })
      .then(function (response) {

        let dataResponse = response.data.results;

        // console.log(response);

        setNextPage(response.data.next);

        setTotalData(response.data.count);

        setShowSizeChanger(response.data.count > 50 ? true : false);

        setColumnField(fieldColumn.field && (fieldColumn.order !== undefined) ? fieldColumn.field : ""); //set column field

        setOrderSort(orderState);//set order sort (Ascending, Descending, Default)

        dataResponse.forEach((x => {

          let arrParty1 = [];
          let arrParty2 = [];
          let arrParty3 = [];

          // let strParty1Prefix = x.party1myanmarsecurityforces_id.substr(1);
          // let strParty1Suffix = strParty1Prefix.substr(0,strParty1Prefix.length - 1);
          let convertArrParty1 = Array.from(x.party1myanmarsecurityforces_id.split(','));

          convertArrParty1.forEach(item => [

            arrParty1.push(Helper.hiddenChar(item))

          ]);

          // let strParty2Prefix = x.party2estalabilishedeao_id.substr(1);
          // let strParty2Suffix = strParty2Prefix.substr(0,strParty2Prefix.length - 1);
          let convertArrParty2 = Array.from(x.party2estalabilishedeao_id.split(','));

          convertArrParty2.forEach(item => {

            arrParty2.push(Helper.hiddenChar(item))

          })

          // let strParty3Prefix = x.party3civillianselfdefenceforces_id.substr(1);
          // let strParty3Suffix = strParty3Prefix.substr(0,strParty3Prefix.length - 1);
          let convertArrParty3 = Array.from(x.party3civillianselfdefenceforces_id.split(','));
          // console.log(arrParty1);

          convertArrParty3.forEach(item => {

            arrParty3.push(Helper.hiddenChar(item));

          });

          let obj = {
            "id": x.id,
            "number": x.number,
            "created_at": moment(x.created_at).format("DD-MM-YYYY"),
            "created_by": x.created_by,
            "deleted_at": moment(x.deleted_at).format("DD-MM-YYYY"),
            "deleted_by": x.deleted_by,
            "drafted_by": x.drafted_by,
            "updated_at": moment(x.updated_at).format("DD/MM/YYYY"),
            "updated_by": x.updated_by,
            "approved_at": moment(x.approved_at).format("DD/MM/YYYY"),
            "approved_by": x.approved_by,
            "status": x.status,
            "highpriority": x.highpriority,
            "incidentdate": x.incidentdate,
            "typeofincident_id": Helper.hiddenChar(x.typeofincident_id),
            "numberofoccurences": x.numberofoccurences,
            "party2estalabilishedeao_id": arrParty2,
            "party4civillians_id": Helper.hiddenChar(x.party4civillians_id),
            "targethuman_id": Helper.hiddenChar(x.targethuman_id),
            "artillerysupport": x.artillerysupport,
            "airsupport": x.airsupport,
            "time": x.time,
            "subtypeofincident_id": Helper.hiddenChar(x.subtypeofincident_id),
            "party1myanmarsecurityforces_id": arrParty1,
            "party3civillianselfdefenceforces_id": arrParty3,
            "party5others_id": Helper.hiddenChar(x.party5others_id),
            "targetassets_id": Helper.hiddenChar(x.targetassets_id),
            "claimedbynug": x.claimedbynug,
            "tacticaldevelopment": x.tacticaldevelopment,
            "killedoct": x.killedoct,
            "injuredoct": x.injuredoct,
            "arrestedoct": x.arrestedoct,
            "civiladministratorresignationsoct": x.civiladministratorresignationsoct,
            "killedpct": x.killedpct,
            "injuredpct": x.injuredpct,
            "abductedpct": x.abductedpct,
            "killedctg": x.killedctg,
            "injuredctg": x.injuredctg,
            "abductedctg": x.abductedctg,
            "killedsft": x.killedsft,
            "injuredsft": x.injuredsft,
            "capturedsft": x.capturedsft,
            "desertionssft": x.desertionssft,
            "killedrft": x.killedrft,
            "injuredrft": x.injuredrft,
            "arrestedrft": x.arrestedrft,
            "killedeft": x.killedeft,
            "injuredeft": x.injuredeft,
            "arrestedeft": x.arrestedeft,
            "killedot": x.killedot,
            "injuredot": x.injuredot,
            "arrestedot": x.arrestedot,
            "incidentdescription": x.incidentdescription,
            "sourcetwo": x.sourcetwo,
            "latitude": x.latitude,
            "longitude": x.longitude,
            "roadstreet": x.roadstreet,
            "villagetract": x.villagetract,
            "town": x.town,
            "township_id": Helper.hiddenChar(x.township_id),
            "stateregion_id": Helper.hiddenChar(x.stateregion_id),
            "geoprecision_id": Helper.hiddenChar(x.geoprecision_id),
            "highway": x.highway,
            "commentonlocation": x.commentonlocation
          }

          arr.push(obj);

        }))

        setDataSource(arr);



      })
      .catch(function (error) {

        console.log(error);

      });

  }
  // async function _loadParty1(cachenameparam){

  //   let arr = [];

  //   let arrCache = await caches.keys();// get cache

  //   await axios.get(Uri.rootUri + `cache/`)
  //   .then(async function(response){

  //        //get version data if there is an update
  //       let dataResponse = response.data;

  //       //get data cache where cache name
  //       let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

  //       if(!arrCache.includes("party1")){// if empty cache name in browser

  //           console.log("cache in browser empty");

  //           await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //           .then(function(response){

  //               // console.log(response);

  //               let dataResponse = response.data;

  //               dataResponse.forEach(x=>{

  //                 arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //               });

  //               setElParty1(arr);

  //               setParty1Data(dataResponse);

  //               Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1` , dataResponse);


  //           })
  //           .catch(function(error){

  //               console.log(error);

  //           });

  //           if(resultObjCache.length == 0){// if data cache in database empty

  //             let cacheName = {

  //               "name" : cachenameparam,
  //               "created_by": localStorage.getItem("usernameLogin"),
  //               "updated_by": localStorage.getItem("usernameLogin")

  //             }

  //             await axios.post(Uri.rootUri + `cache/`, cacheName)
  //             .then(function(response){

  //               //   console.log(response);


  //             })
  //             .catch(function(error){


  //                 console.log(error);

  //             });

  //           }else{

  //               //   console.log("set version");

  //                 // if data cache in database not empty
  //                 Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //           }

  //         }else{

  //           let resultData = arrCache.filter( x => x == "party1");

  //           //Operation Caching Validation

  //           resultData.forEach(async cachename => {

  //             let cacheStorage = await caches.open(cachename);

  //             let party1Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party1');
  //             //get version in cache for check update data
  //             let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

  //             if(!cacheResponse || cacheResponse == undefined || !party1Response || party1Response == undefined){// check data valid or no valid

  //                   await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //                   .then(function(response){

  //                       // console.log(response);

  //                       let dataResponse = response.data;

  //                       dataResponse.forEach(x=>{

  //                         arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                       });

  //                       setElParty1(arr);

  //                       setParty1Data(dataResponse);

  //                       Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1` , dataResponse);


  //                   })
  //                   .catch(function(error){

  //                       console.log(error);

  //                   });

  //                   if(resultObjCache.length == 0){// if data cache in database empty

  //                   let cacheName = {

  //                       "name" : cachenameparam,
  //                       "created_by": localStorage.getItem("usernameLogin"),
  //                       "updated_by": localStorage.getItem("usernameLogin")

  //                   }

  //                   await axios.post(Uri.rootUri + `cache/`, cacheName)
  //                   .then(function(response){

  //                       // console.log(response);


  //                   })
  //                   .catch(function(error){


  //                       console.log(error);

  //                   });

  //                 }else{

  //                       // if data cache in database not empty
  //                       Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //                 }

  //             }else{

  //               let dataResponseVersion = await cacheResponse.json();

  //               let dataResponseParty1 = await party1Response.json();

  //               if(!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty1 || dataResponseParty1.length == 0){/*check data into cache empty or not empty*/

  //                       await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //                       .then(function(response){

  //                           // console.log(response);

  //                           let dataResponse = response.data;

  //                           dataResponse.forEach(x=>{

  //                             arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                           });

  //                           setElParty1(arr);

  //                           setParty1Data(dataResponse);

  //                           Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1` , dataResponse);


  //                       })
  //                       .catch(function(error){

  //                           console.log(error);

  //                       });

  //                       if(resultObjCache.length == 0){// if data cache in database empty

  //                           let cacheName = {

  //                               "name" : cachenameparam,
  //                               "created_by": localStorage.getItem("usernameLogin"),
  //                               "updated_by": localStorage.getItem("usernameLogin")

  //                           }

  //                           await axios.post(Uri.rootUri + `cache/`, cacheName)
  //                           .then(function(response){

  //                               // console.log(response);

  //                           })
  //                           .catch(function(error){


  //                               console.log(error);

  //                           });

  //                       }else{

  //                           // if data cache in database not empty
  //                           Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //                       }

  //               }else{

  //                   // dataResponseVersion and dataResponseParty1 there must be !

  //                   if(resultObjCache.length == 0){// if data cache in database empty

  //                       await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //                       .then(function(response){

  //                           // console.log(response);

  //                           let dataResponse = response.data;

  //                           dataResponse.forEach(x=>{

  //                             arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                           });

  //                           setElParty1(arr);

  //                           setParty1Data(dataResponse);

  //                           Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1` , dataResponse);


  //                       })
  //                       .catch(function(error){

  //                           console.log(error);

  //                       });

  //                       let cacheName = {

  //                           "name" : cachenameparam,
  //                           "created_by": localStorage.getItem("usernameLogin"),
  //                           "updated_by": localStorage.getItem("usernameLogin")

  //                       }

  //                       await axios.post(Uri.rootUri + `cache/`, cacheName)
  //                       .then(function(response){

  //                           // console.log(response);

  //                       })
  //                       .catch(function(error){


  //                           console.log(error);

  //                       });

  //                   }else{

  //                         //check version  cache local browser you and database api , resultObjCache there must be !
  //                         let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

  //                         // console.log(resultVersion);

  //                         if(resultVersion){

  //                               // console.log("Update cache new version ");

  //                               await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //                               .then(function(response){

  //                                   // console.log(response);

  //                                   let dataResponse = response.data;

  //                                   dataResponse.forEach(x=>{

  //                                     arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                                   });

  //                                   setElParty1(arr);

  //                                   setParty1Data(dataResponse);

  //                                   Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1` , dataResponse);


  //                               })
  //                               .catch(function(error){

  //                                   console.log(error);

  //                               });

  //                               Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //                         }else{

  //                             dataResponseParty1.forEach(x=>{

  //                               arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                             });

  //                             setElParty1(arr);

  //                             setParty1Data(dataResponseParty1);

  //                             Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1`, dataResponseParty1);

  //                             Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //                         }

  //                   }


  //               }

  //             }

  //           });

  //         }


  //   })
  //   .catch(function(error){

  //       console.log(error);

  //   });

  //   // let arrCache = await caches.keys();// get cache

  //   // if(!arrCache.includes("party1")){

  //   //     await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //   //     .then(function(response){

  //   //         // console.log(response);

  //   //         let dataResponse = response.data;

  //   //         Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1` , dataResponse);


  //   //     })
  //   //     .catch(function(error){

  //   //         console.log(error);

  //   //     });

  //   // }else{

  //   //     let resultDataIncident = arrCache.filter( x => x == "party1"); //get where cache name

  //   //     resultDataIncident.map(async cachename => {

  //   //     let cacheStorage = await caches.open(cachename);

  //   //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party1`);

  //   //     if(!cacheResponse || cacheResponse == undefined){

  //   //         await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //   //         .then(function(response){

  //   //             // console.log(response);

  //   //             let dataResponse = response.data;

  //   //             Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1` , dataResponse);


  //   //         })
  //   //         .catch(function(error){

  //   //             console.log(error);

  //   //         });

  //   //     }else{

  //   //         let dataResponse = await cacheResponse.json();

  //   //         if(!dataResponse || dataResponse.length == 0){

  //   //             await axios.get(Uri.rootUri + `master/party/one/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //   //             .then(function(response){

  //   //                 // console.log(response);

  //   //                 let dataResponse = response.data;

  //   //                 Helper.addDataIntoCache("party1", Helper.getHostCurrentUrl() + `/party1` , dataResponse);


  //   //             })
  //   //             .catch(function(error){

  //   //                 console.log(error);

  //   //             });

  //   //         }

  //   //     }

  //   //     });

  //   // }

  // }
  // async function _loadParty3(cachenameparam){

  //   let arr = [];

  //   let arrCache = await caches.keys();// get cache

  //   await axios.get(Uri.rootUri + `cache/`)
  //   .then(async function(response){

  //        //get version data if there is an update
  //       let dataResponse = response.data;

  //       //get data cache where cache name
  //       let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

  //       if(!arrCache.includes("party3")){// if empty cache name in browser

  //           // console.log("cache in browser empty");

  //           await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //           .then(function(response){

  //               // console.log(response);

  //               let dataResponse = response.data;

  //               dataResponse.forEach(x=>{

  //                 arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //               });

  //               setElParty3(arr);

  //               setParty3Data(dataResponse);

  //               Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);


  //           })
  //           .catch(function(error){

  //               console.log(error);

  //           });

  //           if(resultObjCache.length == 0){// if data cache in database empty

  //             let cacheName = {

  //               "name" : cachenameparam,
  //               "created_by": localStorage.getItem("usernameLogin"),
  //               "updated_by": localStorage.getItem("usernameLogin")

  //             }

  //             await axios.post(Uri.rootUri + `cache/`, cacheName)
  //             .then(function(response){

  //               //   console.log(response);


  //             })
  //             .catch(function(error){


  //                 console.log(error);

  //             });

  //           }else{

  //               //   console.log("set version");

  //                 // if data cache in database not empty
  //                 Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //           }

  //         }else{

  //           let resultData = arrCache.filter( x => x == "party3");

  //           //Operation Caching Validation

  //           resultData.forEach(async cachename => {

  //             let cacheStorage = await caches.open(cachename);

  //             let party3Response = await cacheStorage.match(Helper.getHostCurrentUrl() + '/party3');
  //             //get version in cache for check update data
  //             let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

  //             if(!cacheResponse || cacheResponse == undefined || !party3Response || party3Response == undefined){// check data valid or no valid

  //                   await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //                   .then(function(response){

  //                       // console.log(response);

  //                       let dataResponse = response.data;

  //                       dataResponse.forEach(x=>{

  //                         arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                       });

  //                       setElParty3(arr);

  //                       setParty3Data(dataResponse);

  //                       Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);


  //                   })
  //                   .catch(function(error){

  //                       console.log(error);

  //                   });

  //                   if(resultObjCache.length == 0){// if data cache in database empty

  //                   let cacheName = {

  //                       "name" : cachenameparam,
  //                       "created_by": localStorage.getItem("usernameLogin"),
  //                       "updated_by": localStorage.getItem("usernameLogin")

  //                   }

  //                   await axios.post(Uri.rootUri + `cache/`, cacheName)
  //                   .then(function(response){

  //                       // console.log(response);


  //                   })
  //                   .catch(function(error){


  //                       console.log(error);

  //                   });

  //                 }else{

  //                       // if data cache in database not empty
  //                       Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //                 }

  //             }else{

  //               let dataResponseVersion = await cacheResponse.json();

  //               let dataResponseParty3 = await party3Response.json();

  //               if(!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseParty3 || dataResponseParty3.length == 0){/*check data into cache empty or not empty*/

  //                       await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //                       .then(function(response){

  //                           // console.log(response);

  //                           let dataResponse = response.data;

  //                           dataResponse.forEach(x=>{

  //                             arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                           });

  //                           setElParty3(arr);

  //                           setParty3Data(dataResponse);

  //                           Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);


  //                       })
  //                       .catch(function(error){

  //                           console.log(error);

  //                       });

  //                       if(resultObjCache.length == 0){// if data cache in database empty

  //                           let cacheName = {

  //                               "name" : cachenameparam,
  //                               "created_by": localStorage.getItem("usernameLogin"),
  //                               "updated_by": localStorage.getItem("usernameLogin")

  //                           }

  //                           await axios.post(Uri.rootUri + `cache/`, cacheName)
  //                           .then(function(response){

  //                               // console.log(response);

  //                           })
  //                           .catch(function(error){


  //                               console.log(error);

  //                           });

  //                       }else{

  //                           // if data cache in database not empty
  //                           Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //                       }

  //               }else{

  //                   // dataResponseVersion and dataResponseParty3 there must be !

  //                   if(resultObjCache.length == 0){// if data cache in database empty

  //                       await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //                       .then(function(response){

  //                           // console.log(response);

  //                           let dataResponse = response.data;

  //                           dataResponse.forEach(x=>{

  //                             arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                           });

  //                           setElParty3(arr);

  //                           setParty3Data(dataResponse);

  //                           Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);


  //                       })
  //                       .catch(function(error){

  //                           console.log(error);

  //                       });

  //                       let cacheName = {

  //                           "name" : cachenameparam,
  //                           "created_by": localStorage.getItem("usernameLogin"),
  //                           "updated_by": localStorage.getItem("usernameLogin")

  //                       }

  //                       await axios.post(Uri.rootUri + `cache/`, cacheName)
  //                       .then(function(response){

  //                           // console.log(response);

  //                       })
  //                       .catch(function(error){


  //                           console.log(error);

  //                       });

  //                   }else{

  //                         //check version  cache local browser you and database api , resultObjCache there must be !
  //                         let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

  //                         if(resultVersion){

  //                               // console.log("Update cache new version ");

  //                               await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //                               .then(function(response){

  //                                   // console.log(response);

  //                                   let dataResponse = response.data;

  //                                   dataResponse.forEach(x=>{

  //                                     arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                                   });

  //                                   setElParty3(arr);

  //                                   setParty3Data(dataResponse);

  //                                   Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);


  //                               })
  //                               .catch(function(error){

  //                                   console.log(error);

  //                               });

  //                               Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //                         }else{

  //                             dataResponseParty3.forEach(x=>{

  //                               arr.push(<Option key={Helper.hiddenChar(x.id)} >{x.name}</Option>);

  //                             });

  //                             setElParty3(arr);

  //                             setParty3Data(dataResponseParty3);

  //                             Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3`, dataResponseParty3);

  //                             Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

  //                         }

  //                   }


  //               }

  //             }

  //           });

  //         }


  //   })
  //   .catch(function(error){

  //       console.log(error);

  //   });

  //   // let arrCache = await caches.keys();// get cache

  //   // if(!arrCache.includes("party3")){

  //   //     await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //   //     .then(function(response){

  //   //         let dataResponse = response.data;

  //   //         Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

  //   //     })
  //   //     .catch(function(error){

  //   //         console.log(error);


  //   //     });

  //   // }else{

  //   //     let resultDataIncident = arrCache.filter( x => x == "party3"); //get where cache name

  //   //     resultDataIncident.map(async cachename => {

  //   //     let cacheStorage = await caches.open(cachename);

  //   //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/party3`);

  //   //     if(!cacheResponse || cacheResponse == undefined){

  //   //         await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //   //         .then(function(response){

  //   //             let dataResponse = response.data;

  //   //             Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

  //   //         })
  //   //         .catch(function(error){

  //   //             console.log(error);


  //   //         });

  //   //     }else{

  //   //         let dataResponse = await cacheResponse.json();

  //   //         if(!dataResponse || dataResponse.length == 0){

  //   //             await axios.get(Uri.rootUri + `master/party/three/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
  //   //             .then(function(response){

  //   //                 let dataResponse = response.data;

  //   //                 Helper.addDataIntoCache("party3", Helper.getHostCurrentUrl() + `/party3` , dataResponse);

  //   //             })
  //   //             .catch(function(error){

  //   //                 console.log(error);


  //   //             });

  //   //         }

  //   //     }

  //   //     });

  //   // }

  // }

  const cacheNames = [
    {
      name: "users",
      pathUri: "user/",
      setMasterData: setUsers,
      setElMasterData: setElUser
    },
    {
      name: "incident",
      pathUri: "master/incident/",
      setMasterData: setTypeIncidentData,
      setElMasterData: setElTypeOfIncident
    },
    {
      name: "subincident",
      pathUri: "master/subincident/",
      setMasterData: setSubTypeIncidentData,
      setElMasterData: undefined
    },
    {
      name: "targethuman",
      pathUri: "master/target/human/",
      setMasterData: setTargetHumanData,
      setElMasterData: undefined
    },
    {
      name: "targetassets",
      pathUri: "master/target/facility/",
      setMasterData: setTargetAssetsData,
      setElMasterData: undefined
    },
    {
      name: "region",
      pathUri: "master/region/",
      setMasterData: setStateRegionData,
      setElMasterData: setElStateRegion
    },
    {
      name: "township",
      pathUri: "master/township/",
      setMasterData: setTownshipData,
      setElMasterData: setElTownship
    }
  ];
  useEffect(() => {

    // ajax call pending set loading
    const effect = async () => {
      // axios.setLoadingInterceptors(setLoading);
      await Helper.authorizationLogin(axios, msalInstance);

      await _loadUserInformation();

      await _loadData(1, 50);
      await _loadCache(cacheNames);
      
      setLoading(false);
    }
    effect();
  }, []);

  return (
    <div className="ApprovedIncidents">
      <Spin spinning={loading} size='large'>

        {isTabletOrMobile &&
          <>

            <div className="row">

              <div className="col-xl-12">
                <div className="mb-3">
                  {/* production */}
                  {/* <button type="button" className="btn btn-primary btn-sm"><Link onClick={(e)=>window.open(`${window.location.origin}/Index/AddNewDraft`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")}className='text-white text-decoration-none'><i className="bi bi-plus-circle-fill"></i> Add New Draft</Link></button> */}

                  {/* Development */}
                  {/* <button type="button" className="btn btn-primary btn-sm"><Link onClick={(e)=>window.open(`http://localhost:3000/Index/AddNewDraft`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")}className='text-white text-decoration-none'><i className="bi bi-plus-circle-fill"></i> Add New Draft</Link></button> */}
                </div>
              </div>

              <div className="col-xl-6">
                <div className="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: "23%" }}>Drafted by:</span>
                  <select className="form-select" aria-label="Default select example" onChange={_draftedBy} style={{ width: "77%" }}>
                    <option selected value="">All</option>
                    {elUser}
                  </select>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="input-group input-group-sm mb-3 ">
                  <span className="input-group-text" id="basic-addon1" style={{ width: "23%" }}>Search:</span>
                  <input type="text" className="form-control" aria-describedby="basic-addon1" onChange={_search} style={{ width: "77%" }} />
                </div>
              </div>

            </div>

            <div className="row justify-content-center">
              {/* <div className="col-xl-12">
                    <div className="mb-3">
                    production
                    <button type="button" className="btn btn-primary btn-sm"><Link onClick={(e)=>window.open(`${window.location.origin}/Index/AddNewDraft`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")}className='text-white text-decoration-none'><i className="bi bi-plus-circle-fill"></i> Add New Draft</Link></button>

                    Development
                    <button type="button" className="btn btn-primary btn-sm"><Link onClick={(e)=>window.open(`http://localhost:3000/Index/AddNewDraft`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")}className='text-white text-decoration-none'><i className="bi bi-plus-circle-fill"></i> Add New Draft</Link></button>
                    </div>
                </div> */}
              <div className="col-xl-6">
                <div className="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: "23%" }}> Incident Date:</span>
                  {/* <input type="date" className="form-control" aria-describedby="basic-addon1" onChange={_incidentDate} style={{ width: "77%" }} /> */}
                  <DatePicker value={incidentDate} onChange={_incidentDate} allowClear style={{ width: '77%', height: '31px', borderRadius: '0rem .25rem .25rem 0rem' }} />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: "23%" }}>Incident Category:</span>
                  <select className="form-select" aria-label="Default select example" onChange={_incidentType} value={incidentType ? incidentType : ""} style={{ width: "77%" }}>
                    <option selected value="">All</option>
                    {elTypeOfIncident}
                  </select>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: '23%' }}>Sub-Type Incident:</span>
                  <Select
                    showSearch
                    style={{ width: "77%" }}
                    onChange={_subTypeIncident}
                    value={subTypeIncident ? subTypeIncident : ""}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option selected key="">All</Option>
                    {!loading ? elSubTypeIncident : elSubTypeIncident.concat(<Option key="" >Loading...</Option>)}
                  </Select>
                  {/* <span className="input-group-text" id="inputGroup-sizing-default">Sub-Type Incident:</span>
                        <select className="form-select" aria-label="Default select example" onChange={_subTypeIncident} value={subTypeIncident ? subTypeIncident : ""} >
                            <option selected value="">All</option>
                            {elSubTypeIncident}
                        </select> */}
                </div>
              </div>
              {/* <div className="col-xl-6">
                  <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default" style={{ width:'23%' }}> Party 1:</span>
                      <Select
                        showSearch
                        style={{ width:"77%" }}
                        onChange={_party1}
                        value={party1 ? party1 : ""}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                          <Option selected key="">All</Option>
                          { !loading ? elParty1 : elParty1.concat(<Option key="" >Loading...</Option>)}
                      </Select>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default" style={{ width:'23%' }}> Party 3:</span>
                      <Select
                        showSearch
                        style={{ width:"77%" }}
                        onChange={_party3}
                        value={party3 ? party3 : ""}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                          <Option selected key="">All</Option>
                          { !loading ? elParty3 : elParty3.concat(<Option key="" >Loading...</Option>)}
                      </Select>
                  </div>
                </div> */}
              <div className="col-xl-6">
                <div className="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: "23%" }}>State/Region:</span>
                  <select className="form-select" aria-label="Default select example" onChange={_stateRegion} value={stateRegion ? stateRegion : ""} style={{ width: "77%" }}>
                    <option selected value="">All</option>
                    {elStateRegion}
                  </select>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="input-group input-group-sm mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: '23%' }}> Township:</span>
                  <Select
                    showSearch
                    style={{ width: "77%" }}
                    onChange={_township}
                    value={township ? township : ""}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option selected key="">All</Option>
                    {!loading ? elTownship : elTownship.concat(<Option key="" >Loading...</Option>)}
                  </Select>
                  {/* <span className="input-group-text" id="inputGroup-sizing-default"> <div style={{ marginRight:53 }}> Township:</div></span>
                        <select className="form-select" aria-label="Default select example" onChange={_township} value={township ? township : ""}>
                        <option selected value="">All</option>
                        {elTownship}
                            <Township
                            stateregionkey = {stateRegion ? stateRegion : 0}
                            />
                        </select> */}
                </div>
              </div>
              {/* <div className="col-xl-12">
                    <button type='submit' className="btn btn-primary btn-sm" onClick={_onFilter}><i className="bi bi-funnel-fill"></i> Apply Filter</button>
                </div> */}
            </div>
            <div className="row mb-3">
              <div className="col-xl-12">
                <button type='submit' className="btn btn-primary btn-sm" onClick={_onFilter}><i className="bi bi-funnel-fill"></i> Apply Filter</button>
              </div>
            </div>
          </>
        }

        {laptopOrDesktop &&
          <>

            <div className='d-flex mb-3 justify-content-center'>

              <div>{/*div flex*/}
                {/* <button type="button" className="btn btn-primary btn-sm"><Link onClick={(e)=>window.open(`${window.location.origin}/Index/AddNewDraft`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")}className='text-white text-decoration-none'><i className="bi bi-plus-circle-fill"></i> Add New Draft</Link></button> */}

                {/* <button type="button" className="btn btn-primary btn-sm"><Link onClick={(e)=>window.open(`http://localhost:3000/Index/AddNewDraft`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")}className='text-white text-decoration-none'><i className="bi bi-plus-circle-fill"></i> Add New Draft</Link></button> */}
              </div>{/*end div flex*/}

              <div className='mx-5'> {/*div flex*/}
                <div className="row">

                  <div className="col-xl-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: "35%" }}>Drafted by:</span>
                      <select className="form-select" aria-label="Default select example" onChange={_draftedBy} style={{ width: "65%" }}>
                        <option selected value="">All</option>
                        {elUser}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: '35%' }}>Incident Date:</span>
                      {/* <input type="date" className="form-control" aria-describedby="basic-addon1" onChange={_incidentDate} style={{ width: '65%' }} /> */}
                      <DatePicker value={incidentDate} onChange={_incidentDate} allowClear style={{ width: '65%', height: '31px', borderRadius: '0rem .25rem .25rem 0rem' }} />
                    </div>
                  </div>


                </div>

                <div className="row justify-content-center">

                  <div className="col-xl-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: "35%" }}>Incident Category:</span>
                      <select className="form-select" aria-label="Default select example" onChange={_incidentType} value={incidentType ? incidentType : ""} style={{ width: "65%" }}>
                        <option selected value="">All</option>
                        {elTypeOfIncident}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: '35%' }}>Sub-Type Incident:</span>
                      <Select
                        showSearch
                        style={{ width: "65%" }}
                        onChange={_subTypeIncident}
                        value={subTypeIncident ? subTypeIncident : ""}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option selected key="">All</Option>
                        {!loading ? elSubTypeIncident : elSubTypeIncident.concat(<Option key="" >Loading...</Option>)}
                      </Select>
                      {/* <select className="form-select" aria-label="Default select example" onChange={_subTypeIncident} value={subTypeIncident ? subTypeIncident : ""} >
                            <option selected value="">All</option>
                            {elSubTypeIncident}
                        </select> */}
                    </div>
                  </div>

                  {/* <div className="col-xl-12">
                    <button type='submit' className="btn btn-primary btn-sm" onClick={_onFilter}><i className="bi bi-funnel-fill"></i> Apply Filter</button>
                </div> */}
                </div>
                {/* <div className="row justify-content-center">
                    <div className="col-xl-6">
                      <div className="input-group input-group-sm mb-3">
                          <span className="input-group-text" id="inputGroup-sizing-default" style={{ width:'25%' }}> Party 1:</span>
                          <Select
                            showSearch
                            style={{ width:"75%" }}
                            onChange={_party1}
                            value={party1 ? party1 : ""}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            >
                              <Option selected key="">All</Option>
                              { !loading ? elParty1 : elParty1.concat(<Option key="" >Loading...</Option>)}
                          </Select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="input-group input-group-sm mb-3">
                          <span className="input-group-text" id="inputGroup-sizing-default" style={{ width:'33%' }}> Party 3:</span>
                          <Select
                            showSearch
                            style={{ width:"67%" }}
                            onChange={_party3}
                            value={party3 ? party3 : ""}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            >
                              <Option selected key="">All</Option>
                              { !loading ? elParty3 : elParty3.concat(<Option key="" >Loading...</Option>)}
                          </Select>
                      </div>
                    </div>
                </div> */}
                <div className="row">
                  <div className="col-xl-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: "35%" }}>State/Region:</span>
                      <select className="form-select" aria-label="Default select example" onChange={_stateRegion} value={stateRegion ? stateRegion : ""} style={{ width: "65%" }}>
                        <option selected value="">All</option>
                        {elStateRegion}
                      </select>
                    </div>
                  </div>

                  <div className="col-xl-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-default" style={{ width: '35%' }}> Township:</span>
                      <Select
                        showSearch
                        style={{ width: "65%" }}
                        onChange={_township}
                        value={township ? township : ""}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option selected key="">All</Option>
                        {!loading ? elTownship : elTownship.concat(<Option key="" >Loading...</Option>)}
                      </Select>
                    </div>
                    {/* <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default"><div style={{ marginRight:53}} >Township:</div></span>
                            <select className="form-select" aria-label="Default select example" onChange={_township} value={township ? township : ""}>
                            <option selected value="">All</option>
                            {elTownship}
                            <Township
                                stateregionkey = {stateRegion ? stateRegion : 0}
                            />
                            </select>
                        </div> */}
                  </div>

                  <div className="col-xl-12">
                    <div className="input-group input-group-sm mb-3 ">
                      <span className="input-group-text" id="basic-addon1" style={{ width: "17%" }}>Search:</span>
                      <input type="text" className="form-control" aria-describedby="basic-addon1" onChange={_search} style={{ width: "83%" }} />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <button type='submit' className="btn btn-primary btn-sm" onClick={_onFilter}><i className="bi bi-funnel-fill"></i> Apply Filter / Search</button>
                  </div>
                </div>

              </div> {/*end div flex*/}
            </div>
          </>

        }

        <div className="row">
          <div className="col-lg-12">
            {/* {console.log(loading)} */}
            {/* <Spin size="large" spinning={loading}> */}
            <div className='text-center mb-3'>
              <PaginationComponent
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                onChange={onChange}
                onShowSizeChange={onShowSizeChange}
                total={totalData ? totalData : 0}
                pageSizeOptions={[20, 50, 100]}
                showSizeChanger={showSizeChanger}
                defaultPageSize={50}
              />
            </div>

            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              size={"small"}
              scroll={{ x: 'max-content', y: 350 }}
              onChange={handleTableChange}
            // scroll={{ x: 'max-content'}}
            />

            <div className='text-center mt-3'>
              <PaginationComponent
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                onChange={onChange}
                onShowSizeChange={onShowSizeChange}
                total={totalData ? totalData : 0}
                pageSizeOptions={[20, 50, 100]}
                showSizeChanger={showSizeChanger}
                defaultPageSize={50}
              />
            </div>
            {/* </Spin> */}
          </div>
        </div>
      </Spin>
    </div>
  )

}