import React, { useMemo } from "react";
import { DatePicker as AntdDatePicker } from 'antd';
import moment from "moment";

const DatePicker = (props) => {
  const { value, onChange, style } = props;

  const _value = useMemo(() => {
    if (value)
      return moment(value, "DD/MM/YYYY");

    return null;
  }, [value]);

  const _onChange = (_, dateString) => {
    onChange(dateString);
  }

  return (
    <AntdDatePicker
      onChange={_onChange}
      value={_value}
      format="DD/MM/YYYY"
      style={{ height: '38px', width: '100%', borderRadius: '.25rem', ...style }}
    />
  )
}

export default DatePicker;