import React from "react"

export default function ErrorMsgBarParty(props){
  return(
      <div>
          <div className="alert alert-danger" role="alert">
              <p className="m-0">Input can not contain following special characters:</p>
              <p className="m-0">( , ) - coma</p>
              <p className="m-0">( ; ) - semicolon</p>
          </div>
      </div>   
  )
}