import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default function BasicModal(props) {
  return (
    <div>
       <Modal show={props.isShow} onHide={props.onHide} id={`update`}>
          {props.title &&
          <Modal.Header closeButton>
              <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>}
          <Modal.Body>
              <div className="mb-3">
                  <p>{props.textBody}</p>
              </div>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={props.handleCancelDialog}>
              {props.textCancelButton}
          </Button>
          <Button type="submit" variant="primary" onClick={props.handleApproveDialog}>
              {props.textApproveButton}
          </Button>
          </Modal.Footer>
      </Modal>
    </div>
  )
}