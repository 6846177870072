import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import Uri from "../../Uri";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import PaginationComponent from "../utils/PaginationComponent";
import Helper from "../helpers/Helper";
import { useAxios } from "../../App";
import { useMsal } from "@azure/msal-react";

export default function Township(props) {
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();
    

    const authToken = localStorage.getItem("token");

    let [stateRegion, setStateRegion] = useState("");
    let [stateRegionUpdate, setStateRegionUpdate] = useState("");
    let [townShip, setTownShip] = useState("");
    let [townShipUpdate, setTownShipUpdate] = useState("");
    let [dataSource, setDataSource] = useState([]);
    let [search, setSearch] = useState("");
    let [elStateRegion, setElStateRegion] = useState([]);

    let [currentPage, setCurrentPage] = useState();
    let [pageSize, setPageSize] = useState();
    let [totalData, setTotalData] = useState();

    let [isInvalid, setIsInvalid] = useState();
    let [isInvalidUpdate, setIsInvalidUpdate] = useState();
    let [idData, setIdData] = useState("");
    let [loading, setLoading] = useState(true);

    const [showUpdate, setShowUpdate] = useState();
    const [showDelete, setShowDelete] = useState();

    function handleCloseDelete(e) {

        setShowDelete(false);

    };
    function handleShowDelete(e) {

        setShowDelete(true);

    }

    function handleCloseUpdate(e) {

        setShowUpdate(false);

    };

    function handleShowUpdate(e) {

        setShowUpdate(true);

    }

    function _onChangeTownShip(e) {

        e.preventDefault();

        let township = e.target.value;

        if (township) {

            setTownShip(township);

        } else {

            setTownShip("");

        }


    }

    function _onChangeTownShipUpdate(e) {

        e.preventDefault();

        let township = e.target.value;

        if (township) {

            setTownShipUpdate(township);

        } else {

            setTownShipUpdate("");

        }


    }


    function _onChangeStateRegion(e) {

        e.preventDefault();

        let stateRegion = e.target.value;

        if (stateRegion) {

            setStateRegion(stateRegion);

        } else {

            setStateRegion("");

        }


    }

    function _onChangeStateRegionUpdate(e) {

        e.preventDefault();

        let stateRegion = e.target.value;

        if (stateRegion) {

            setStateRegionUpdate(stateRegion);

        } else {

            setStateRegionUpdate("");

        }


    }

    function _searchForm(e) {

        e.preventDefault();

        let keyword = e.target.value;

        if (keyword) {

            setSearch(keyword);

        } else {

            setSearch("");

        }
    }


    async function _saveData() {
        let data = {
            "name": townShip,
            "region_id": Helper.hiddenChar(stateRegion),
            "created_by": localStorage.getItem("usernameLogin"),
            "updated_by": localStorage.getItem("usernameLogin")
        }
        await axios.post(Uri.rootUri + `master/township/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(async function (response) {
                // console.log(response);
                caches.delete("township");
                let cacheName = {
                    "name": "township",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")
                }
                await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .catch(function (error) {
                        console.log(error);
                    });
                _loadDataAll("township");
                _loadStateRegion("region");
                Helper.alert("Success", "success", "Success save data", 3000);
                setTownShip("");
                setStateRegion("");
                _loadData(currentPage, pageSize);
            })
            .catch(function (error) {
                // console.log(error);
                setTownShip("");
                setStateRegion("");
                Helper.alert("Failed", "error", "Failed save data", 3000);
            });
    }

    async function _handleValidation(stateRegion, townShip, setInvalid, callback) {
        if (!stateRegion || !townShip) {
            setInvalid(true);
            setTimeout(() => setInvalid(false), 3000);
        } else {

            const cacheStorage = await caches.open("township");
            const cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/township`);
            const responseData = await cacheResponse.json();

            // Are incident type and incident sub type the same?
            const isBothDataExist = responseData.find((item) => item.name == townShip && item.region_id == Helper.hiddenChar(stateRegion));

            if (isBothDataExist) {
                Helper.alert("Failed", "error", "This township name already exists and is already assigned to this state/region", 3000);
            } else {
                await callback();
            }

        }

    }

    async function _updateData() {
        let data = {

            "name": townShipUpdate,
            "region_id": Helper.hiddenChar(stateRegionUpdate),
            "updated_by": localStorage.getItem("usernameLogin")

        }
        await axios.put(Uri.rootUri + `master/township/${idData}/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(async function (response) {
                // console.log(response);
                caches.delete("township");
                let cacheName = {
                    "name": "township",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")
                }
                await axios.post(Uri.rootUri + `cache/`, cacheName)
                    .catch(function (error) {
                        console.log(error);
                    });

                _loadDataAll("township");
                _loadStateRegion("region");
                Helper.alert("Success", "success", "Success update data", 3000);
                setTownShipUpdate("");
                setStateRegionUpdate("");
                setShowUpdate(false);
                _loadData(currentPage, pageSize);
            })
            .catch(function (error) {
                setTownShipUpdate("");
                setStateRegionUpdate("");
                setShowUpdate(false);
                Helper.alert("Failed", "error", "Failed update data", 3000);
            });

    }

    async function _deleteTownShip(e, id) {

        e.preventDefault();



        if (id) {

            let data = {

                "deleted_by": localStorage.getItem("usernameLogin")

            }

            await axios.put(Uri.rootUri + `master/township/${id}/delete/`, data, { headers: { "Authorization": `Bearer ${authToken}` } })
                .then(async function (response) {

                    // console.log(response);

                    caches.delete("township")

                    let cacheName = {

                        "name": "township",
                        "created_by": localStorage.getItem("usernameLogin"),
                        "updated_by": localStorage.getItem("usernameLogin")

                    }

                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                        .then(function (response) {

                            // console.log(response);


                        })
                        .catch(function (error) {


                            console.log(error);

                        });

                    _loadDataAll("township");

                    _loadStateRegion("region");

                    setShowDelete(false);

                    Helper.alert("Success", "success", "Success delete data", 3000);

                    _loadData(currentPage, pageSize);

                })
                .catch(function (error) {

                    setShowDelete(false);

                    let errorMessage = error.response?.data?.res;
                    if (error.response?.status == 400 && errorMessage == "Cannot delete, used in an incident") {
                        errorMessage = Helper.errorMsgDataValidation("Township");
                        Helper.alertHtml("Failed", "error", errorMessage, 3000);
                    }
                });

        } else {



            setShowDelete(false);

        }

    }


    const columns = [
        {
            title: 'Township',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: 'ascend',
            width: '40%',
        },
        {
            title: 'State/Region',
            dataIndex: 'region_name',
            sorter: (a, b) => a.region_name.localeCompare(b.region_name),
            width: '40%',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (text, record, index) => (
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                        handleShowUpdate(e);
                        setTownShipUpdate(record.name);
                        setStateRegionUpdate(record.region_id);
                        setIdData(record.id);
                    }} id="update"><i className="bi bi-pencil-fill"></i>
                    </button>

                    <button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                        handleShowDelete(e);
                        setIdData(record.id);
                    }} id="delete"><i className="bi bi-trash-fill"></i>
                    </button>
                </div>
            )
        }
    ];

    async function _loadStateRegion(cachenameparam) {

        let arr = [];

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("region")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            let dataResponse = response.data;

                            dataResponse.forEach(x => {

                                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                            });

                            setElStateRegion(arr);

                            Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);



                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "region");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let regionResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/region');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !regionResponse || regionResponse == undefined) {// check data valid or no valid

                            // console.log("set version into cache");

                            await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    let dataResponse = response.data;

                                    dataResponse.forEach(x => {

                                        arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                                    });

                                    setElStateRegion(arr);

                                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);



                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        //   console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                //   console.log("set version");

                                // if data cache in database not empty
                                Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }


                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseRegion = await regionResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseRegion || dataResponseRegion.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        let dataResponse = response.data;

                                        dataResponse.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                                        });

                                        setElStateRegion(arr);

                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);



                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);


                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // console.log("set version");

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseRegion there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            let dataResponse = response.data;


                                            dataResponse.forEach(x => {

                                                arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                                            });

                                            setElStateRegion(arr);

                                            Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);



                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);


                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/region/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                let dataResponse = response.data;


                                                dataResponse.forEach(x => {

                                                    arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

                                                });

                                                setElStateRegion(arr);

                                                Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);



                                            });

                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        // console.log("data incident and version available in cache");

                                        dataResponseRegion.forEach(x => {

                                            arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>)

                                        })

                                        setElStateRegion(arr);

                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponseRegion);

                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });


        // let arr = [];

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("region")){

        //     await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         // console.log(response);


        //         let dataResponse = response.data;

        //         dataResponse.forEach(x=>{

        //             arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

        //         });

        //         setElStateRegion(arr);

        //         Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);



        //     })
        //     .catch(function(error){



        //     })

        // }else{

        //     let resultData = arrCache.filter( x => x == "region"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/region`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //         await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             // console.log(response);


        //             let dataResponse = response.data;

        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

        //             });

        //             setElStateRegion(arr);


        //             Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);


        //         })
        //         .catch(function(error){



        //         })

        //       }else{ //if data in cache not empty


        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 // console.log(response);


        //                 let dataResponse = response.data;

        //                 dataResponse.forEach(x=>{

        //                     arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

        //                 });

        //                 setElStateRegion(arr);


        //                 Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);


        //             })
        //             .catch(function(error){



        //             })

        //         }else{


        //             dataResponse.forEach(x=>{

        //                 arr.push(<option value={Helper.hiddenChar(x.id)}>{x.name}</option>);

        //             });

        //             setElStateRegion(arr);


        //         }


        //       }

        //     });
        // }
        // setElChildren(childrenSelect);
    };

    async function _loadData(page, pageSize) {

        setCurrentPage(page);

        setPageSize(pageSize);

        await axios.get(Uri.rootUri + `master/township/?page=${page}&page_size=${pageSize}`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                let arr = [];

                let dataResponse = response.data.results;

                // console.log(response);

                dataResponse.forEach(x => {

                    let dataobj = {
                        "id": x.id,
                        "name": x.name,
                        "region_id": x.region_id,
                        "region_name": x.region_name
                    }

                    arr.push(dataobj);

                });

                setTotalData(response.data.count);

                // console.log(arr);

                setDataSource(arr);


            })
            .catch(function (error) {

                // console.log(error);



            });

    }

    async function _onSearch(e) {

        e.preventDefault();

        let searchText = search ? `search=${search}&` : "";

        await axios.get(Uri.rootUri + `master/township/?page=1&${searchText}page_size=20`, { headers: { "Authorization": `Bearer ${authToken}` } })
            .then(function (response) {

                // console.log(response);

                let responseData = response.data;

                setDataSource(responseData.results);

                setTotalData(responseData.count);

                setCurrentPage(1);

            })
            .catch(function (error) {



            });

    }

    async function onChange(page, pageSize) {

        // console.log(page,pageSize,currentPage); //page = new previous page and new next page

        let arr = [];



        setPageSize(pageSize);

        setCurrentPage(page);

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=20&';

        await axios.get(Uri.rootUri + `master/township/?${currentPageOnChange}${currentPageSizeOnChange}`, { headers: { "Authorization": `Bearer ${authToken}` } })

            .then(function (response) {

                let dataResponse = response.data.results;

                // console.log(dataResponse);

                setTotalData(response.data.count);//total all data;

                dataResponse.forEach(x => {

                    let dataobj = {
                        "id": x.id,
                        "name": x.name,
                        "region_id": x.region_id,
                        "region_name": x.region_name
                    }

                    arr.push(dataobj);

                });


                setDataSource(arr);


            })
            .catch(function (error) {

                //   console.log(error);


            })


    }
    async function _loadDataAll(cachenameparam) {

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
            .then(async function (response) {

                //get version data if there is an update
                let dataResponse = response.data;

                //get data cache where cache name
                let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

                if (!arrCache.includes("township")) {// if empty cache name in browser

                    // console.log("cache in browser empty");

                    await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                        .then(function (response) {

                            // console.log(response);

                            let dataResponse = response.data;

                            Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                        })
                        .catch(function (error) {

                            console.log(error);

                        });

                    if (resultObjCache.length == 0) {// if data cache in database empty

                        let cacheName = {

                            "name": cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")

                        }

                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function (response) {

                                //   console.log(response);


                            })
                            .catch(function (error) {


                                console.log(error);

                            });

                    } else {

                        //   console.log("set version");

                        // if data cache in database not empty
                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                    }

                } else {

                    let resultData = arrCache.filter(x => x == "township");

                    //Operation Caching Validation

                    resultData.forEach(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let townshipResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/township');
                        //get version in cache for check update data
                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                        if (!cacheResponse || cacheResponse == undefined || !townshipResponse || townshipResponse == undefined) {// check data valid or no valid

                            await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                .then(function (response) {

                                    // console.log(response);

                                    let dataResponse = response.data;

                                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                                })
                                .catch(function (error) {

                                    console.log(error);

                                });

                            if (resultObjCache.length == 0) {// if data cache in database empty

                                let cacheName = {

                                    "name": cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")

                                }

                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function (response) {

                                        // console.log(response);


                                    })
                                    .catch(function (error) {


                                        console.log(error);

                                    });

                            } else {

                                // if data cache in database not empty
                                Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                            }

                        } else {

                            let dataResponseVersion = await cacheResponse.json();

                            let dataResponseTownship = await townshipResponse.json();

                            if (!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseTownship || dataResponseTownship.length == 0) {/*check data into cache empty or not empty*/

                                await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                    .then(function (response) {

                                        // console.log(response);

                                        let dataResponse = response.data;

                                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                                    })
                                    .catch(function (error) {

                                        console.log(error);

                                    });

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    // if data cache in database not empty
                                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                }

                            } else {

                                // dataResponseVersion and dataResponseTownship there must be !

                                if (resultObjCache.length == 0) {// if data cache in database empty

                                    await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                        .then(function (response) {

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                                        })
                                        .catch(function (error) {

                                            console.log(error);

                                        });

                                    let cacheName = {

                                        "name": cachenameparam,
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function (response) {

                                            // console.log(response);

                                        })
                                        .catch(function (error) {


                                            console.log(error);

                                        });

                                } else {

                                    //check version  cache local browser you and database api , resultObjCache there must be !
                                    let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                    if (resultVersion) {

                                        // console.log("Update cache new version ");

                                        await axios.get(Uri.rootUri + `master/township/`, { headers: { "Authorization": `Bearer ${authToken}` } })
                                            .then(function (response) {

                                                // console.log(response);

                                                let dataResponse = response.data;

                                                Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponse);


                                            })
                                            .catch(function (error) {

                                                console.log(error);

                                            });

                                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    } else {

                                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);

                                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/version`, resultObjCache);//set cache

                                    }

                                }


                            }

                        }

                    });

                }


            })
            .catch(function (error) {

                console.log(error);

            });

        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("township")){

        //     await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })

        //         .then(function(response){

        //             let dataResponse = response.data;

        //             Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township` , dataResponse);


        //         })
        //         .catch(function(error){

        //             //   console.log(error);


        //     });

        // }else{

        //     let resultDataIncident = arrCache.filter( x => x == "township"); //get where cache name

        //     resultDataIncident.map(async cachename => {

        //     let cacheStorage = await caches.open(cachename);

        //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/township`);

        //     if(!cacheResponse || cacheResponse == undefined){

        //         await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse = response.data;

        //             Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township` , dataResponse);


        //         })
        //         .catch(function(error){

        //             //   console.log(error);


        //         });

        //     }else{

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse = response.data;

        //                 Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township` , dataResponse);


        //             })
        //             .catch(function(error){

        //                 //   console.log(error);


        //             });

        //         }

        //     }

        //     });


        // }


    }

    useEffect(() => {
        // console.log(props.dataSource);
        const effect = async () => {

        //ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadData(1, 100);
        await _loadDataAll("township");
        await _loadStateRegion("region");
        
        setLoading(false);
      }
      effect();
    }, []);
    return (
        <Spin size="large" spinning={loading}>
            <div className="township">
                <div className="row">
                    <div className="col-lg-4">
                        <h4>Add New Township:</h4>
                        <div className="mb-3">
                            <label htmlFor="township">Township</label>
                            {isInvalid && !townShip ?

                                <div id="township" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert Township.
                                </div>
                                :
                                <></>
                            }
                            <input type="text" className="form-control" name="township" id="township" onChange={_onChangeTownShip} value={townShip && townShip} />
                        </div>
                        <div className="mb-3">
                            <label>State/Region:</label>
                            {isInvalid && !stateRegion ?

                                <div id="stateregion" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                    Please insert State / Region.
                                </div>
                                :
                                <></>
                            }
                            <select className="form-select" aria-label="Default select example" onChange={_onChangeStateRegion} required value={stateRegion ? stateRegion : ""}>
                                <option value="" selected className="text-secondary"></option>
                                {elStateRegion}
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary" style={{ width: "100%" }} onClick={() => _handleValidation(stateRegion, townShip, setIsInvalid, _saveData)}><i className="bi bi-plus-circle-fill"></i> Add to Master Data</button>
                    </div>
                    <div className="col-lg-8">
                        <h4>Township Data</h4>
                        <div className='d-flex align-items-center ml-5 mb-3'>
                            <div className="d-inline-block mx-2" >
                                <label htmlFor="search" className="col-form-label">Search: </label>
                            </div>
                            <div class="input-group" style={{ width: 250 }}>
                                <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={_searchForm} value={search ? search : ""} />
                                <button class="btn btn-primary" type="submit" id="button-addon2" onClick={_onSearch}><i class="bi bi-search"></i></button>
                            </div>
                            {/* <div className="d-inline-block">
                                        <input type="text" id="search" className="form-control form-control-sm" onChange={_searchForm} value={search ? search : ""}  />
                                    </div>    */}
                        </div>
                        <Table
                            columns={columns}
                            // rowKey={record => record.login.uuid}
                            dataSource={dataSource}
                            pagination={false}
                        // pagination={{
                        //         position:["bottomCenter"],
                        // }}
                        />
                        <div className='text-center mt-3'>
                            {/* {console.log(pageSize)} */}
                            <PaginationComponent
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                onChange={onChange}
                                // onShowSizeChange = {onShowSizeChange}
                                total={totalData ? totalData : 0}
                                showSizeChanger={false}
                                defaultPageSize={20}
                            />
                        </div>
                    </div>


                    <>

                        <Modal show={showUpdate} onHide={handleCloseUpdate} id={`update`}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Data</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <>
                                    <div className='mb-3'>
                                        <label htmlFor='type ' className='m-1 d-flex align-items-start'>Township <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                        {isInvalidUpdate && !townShipUpdate ?

                                            <div id="type" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                Please insert Township.
                                            </div>
                                            :
                                            <></>
                                        }
                                        <input type="text" className="form-control" onChange={_onChangeTownShipUpdate} value={townShipUpdate ? townShipUpdate : ""} />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor='stateregion ' className='m-1 d-flex align-items-start'>State / Region <i className="bi bi-asterisk text-danger" style={{ fontSize: 8 }}></i></label>
                                        {isInvalidUpdate && !stateRegionUpdate ?

                                            <div id="stateregion" className="text-danger mb-1" style={{ fontSize: 14 }}>
                                                Please select State / Region.
                                            </div>
                                            :
                                            <></>
                                        }
                                        <select className="form-select" aria-label="Default select example" onChange={_onChangeStateRegionUpdate} required value={stateRegionUpdate ? stateRegionUpdate : ""}>
                                            <option selected value=""></option>
                                            {elStateRegion}
                                        </select>
                                    </div>
                                </>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseUpdate}>
                                    Close
                                </Button>
                                <Button type="submit" variant="primary" onClick={() => _handleValidation(stateRegionUpdate, townShipUpdate, setIsInvalidUpdate, _updateData)}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='m-0'>Delete this Township ?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseDelete}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={(e) => _deleteTownShip(e, idData)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>

                </div>
            </div>
        </Spin>
    )
}