export default class Uri {
  static get hostUri() {
    return process.env.REACT_APP_HOST_URI
  }

  static get rootUri() {
    return process.env.REACT_APP_ROOT_URI
  }

  static get embedUri() {
    return process.env.REACT_APP_EMBED_BASE_URL
  }

  static get animatedVizUri() {
    return process.env.REACT_APP_EMBED_ANIMATED_VIZ_URL
  }
}