import React,{useEffect, useState} from "react";
import { Table,Spin } from "antd";
import {Modal, Button} from "react-bootstrap";
import axios from "axios";
import Uri from "../../Uri";
import Helper from "../helpers/Helper";
import PaginationComponent from "../utils/PaginationComponent";
import { useAxios } from "../../App";
import { useMsal } from "@azure/msal-react";

export default function StateRegion(props){
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();
    

    const authToken = localStorage.getItem("token");

    let [isInvalid, setIsInvalid] = useState();
    let [stateRegion, setStateRegion] = useState("");
    let [stateRegionUpdate, setStateRegionUpdate] = useState("");
    let [dataSource, setDataSource] = useState([]);
    let [isInvalidUpdate, setIsInvalidUpdate] = useState();
    let [idData, setIdData] = useState("");
    let [loading, setLoading] = useState(true);
    let [arrDataTownship, setArrDataTownship] = useState([]);

    let [ currentPage, setCurrentPage] = useState();
    let [ pageSize, setPageSize ] = useState();
    let [ totalData, setTotalData] = useState();

    const [showUpdate, setShowUpdate] = useState();
    const [showDelete, setShowDelete] = useState();

    function handleCloseDelete(e){

        setShowDelete(false);

    };
    function handleShowDelete(e){

        setShowDelete(true);

    }

    function handleCloseUpdate(e){

        setShowUpdate(false);

    };

    function handleShowUpdate(e){

        setShowUpdate(true);

    }

    async function _saveData(e){

        e.preventDefault();



        if(!stateRegion){

            setIsInvalid(true);

            setTimeout(()=>setIsInvalid(false),3000);

        }else{

            let data = {

                "name" : stateRegion,
                "created_by" : localStorage.getItem("usernameLogin"),
                "updated_by" : localStorage.getItem("usernameLogin")

            }
            await axios.post(Uri.rootUri + `master/region/`, data, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                caches.delete("region");

                let cacheName = {

                    "name" : "region",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function(response){

                    // console.log(response);


                })
                .catch(function(error){


                    console.log(error);

                });

                _loadDataAll("region");
                // console.log(response);

                setStateRegion("");

                Helper.alert("Success", "success", "Success save data", 3000);

                _loadData(currentPage, pageSize);

            })
            .catch(function(error){

                // console.log(error);

                let objError = error.toJSON();

                if(objError.status == 400){

                        Helper.alert("Failed", "error", "This State/Region value already exist", 3000);

                }




            });

        }

    }

    function _onChangeStateRegion(e){

        e.preventDefault();

        let stateRegion = e.target.value;

        if(stateRegion){

            setStateRegion(stateRegion);

        }else{

            setStateRegion("");

        }


    }
    function _onChangeStateRegionUpdate(e){

        e.preventDefault();

        let stateRegion = e.target.value;

        if(stateRegion){

            setStateRegionUpdate(stateRegion);

        }else{

            setStateRegionUpdate("");

        }


    }

    async function _deleteStateRegion(e,id){

        e.preventDefault();


        if(id){

            let data = {

                "deleted_by" : localStorage.getItem("usernameLogin")

            }

            await axios.put(Uri.rootUri + `master/region/${id}/delete/`, data, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                caches.delete("region");

                let cacheName = {

                    "name" : "region",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function(response){

                    // console.log(response);


                })
                .catch(function(error){


                    console.log(error);

                });

                _loadDataAll("region");

                // console.log(response);

                setShowDelete(false);

                Helper.alert("Success", "success", "Success delete data", 3000);

                _loadData(currentPage, pageSize);

            })
            .catch(function(error){

                setShowDelete(false);

                let errorMessage = error.response?.data?.res;
                if(error.response?.status == 400 && errorMessage == "Cannot delete, used in an incident"){
                    errorMessage = Helper.errorMsgDataValidation("State / Region");
                    Helper.alertHtml("Failed", "error", errorMessage, 3000);
                }

            });

        }else{



            setShowDelete(false);

        }


    }

    const columns = [
        {
          title: 'State/Region',
          dataIndex: 'name',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.name.localeCompare(b.name),
          width: '40%',
        },
        {
          title: 'Township Count',
          dataIndex: 'townshipcount',
          sorter: (a, b) => a.townshipcount - b.townshipcount,
          width: '40%',
        },
        {
          title: 'Action',
          dataIndex:'action',
          render : (text,record,index) =>(
                    <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>{
                                handleShowUpdate(e);
                                setStateRegionUpdate(record.name);
                                setIdData(record.id);
                                }} id="update"><i className="bi bi-pencil-fill"></i>
                            </button>

                            {   record.townshipcount > 0 ?

                                <button type="button" className="btn btn-primary btn-sm" disabled>
                                    <i className="bi bi-trash-fill"></i>
                                </button>

                                :

                                <button type="button" className="btn btn-primary btn-sm"
                                onClick={(e)=>{
                                    handleShowDelete(e);
                                    setIdData(record.id);
                                    }} id="delete"><i className="bi bi-trash-fill"></i>
                                </button>

                            }
                    </div>
          )
        }
      ];

      async function _updateStateRegion(e){


        e.preventDefault();

        if(!stateRegionUpdate){

            setIsInvalidUpdate(true);

            setTimeout(()=>setIsInvalidUpdate(false),3000);

        }else{

            let data = {

                "name" : stateRegionUpdate,
                "updated_by" : localStorage.getItem("usernameLogin")

            }

            await axios.put(Uri.rootUri + `master/region/${idData}/`,data, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                // console.log(response);
                caches.delete("region");

                let cacheName = {

                    "name" : "region",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function(response){

                    // console.log(response);


                })
                .catch(function(error){


                    console.log(error);

                });

                _loadDataAll("region");

                Helper.alert("Success", "success", "Success update data", 3000);

                setShowUpdate(false);

                _loadData(currentPage, pageSize);
            })
            .catch(function(error){

                setShowUpdate(false);



            })
        }
    }

      async function _loadData(page, pageSize){

        setCurrentPage(page);

        setPageSize(pageSize);

        let arrCache = await caches.keys();// get cache

        let arr = [];

            await axios.get(Uri.rootUri + `master/region/?page=${page}&page_size=${pageSize}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){


                // console.log(response);

                let dataResponseRegion = response.data;

                let dataResponseTownship ;

                setTotalData(dataResponseRegion.count);

                    if(!arrCache.includes("township")){

                        // console.log("cache township not available");

                        await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                        .then(function(response){

                            dataResponseTownship = response.data;

                        })
                        .catch(function(error){



                            // console.log(error)

                        });



                        dataResponseRegion.results.forEach(item=>{

                            let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

                            let dataobj = {

                                "id" : item.id,
                                "townshipcount" : townshipCount.length ? townshipCount.length : 0,
                                "name" : item.name

                            }

                            arr.push(dataobj);

                        });


                        setDataSource(arr);

                        Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

                    }else{


                        let resultData = arrCache.filter( x => x == "township"); //get where cache name

                        resultData.map(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/township`);

                        // console.log("cache township error");

                            if(!cacheResponse || cacheResponse == undefined){

                                await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                .then(function(response){

                                    dataResponseTownship = response.data;

                                })
                                .catch(function(error){



                                    // console.log(error)

                                });

                                // console.log(dataResponseTownship);

                                dataResponseRegion.results.forEach(item=>{

                                    let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

                                    let dataobj = {

                                        "id" : item.id,
                                        "townshipcount" : townshipCount.length ? townshipCount.length : 0,
                                        "name" : item.name

                                    }

                                    arr.push(dataobj);

                                });


                                setDataSource(arr);

                                Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

                            }else{

                                let dataResponse = await cacheResponse.json();

                                // console.log(dataResponse);

                                // console.log("cache township available");

                                if(!dataResponse || dataResponse.length == 0){

                                    // console.log("cache township arr lenght 0");

                                    await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                    .then(function(response){

                                        dataResponseTownship = response.data;

                                    })
                                    .catch(function(error){



                                        // console.log(error)

                                    });


                                    dataResponseRegion.results.forEach(item=>{

                                        let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

                                        let dataobj = {

                                            "id" : item.id,
                                            "townshipcount" : townshipCount.length ? townshipCount.length : 0,
                                            "name" : item.name

                                        }

                                        arr.push(dataobj);

                                    });


                                    setDataSource(arr);

                                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

                                }else{

                                    // console.log("cache sub incident available");

                                    dataResponseRegion.results.map(item=>{

                                        let townshipCount = dataResponse.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

                                        let data = {
                                            "id" : item.id,
                                            "name" : item.name,
                                            "townshipcount" : townshipCount.length ? townshipCount.length : 0,
                                        }

                                        arr.push(data);

                                    });


                                    setDataSource(arr);



                                }

                            }

                        })


                    }

                })
                .catch(function(error){

                    console.log(error);

                });

        // let arrCache = await caches.keys();// get cache

        // let arr = [];

        // if(!arrCache.includes("region") || !arrCache.includes("township")){

        //     await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(async function(response){


        //         console.log(response);

        //         let dataResponse = response.data;
        //         let dataResponseTownship ;

        //         await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             dataResponseTownship = response.data;



        //         })
        //         .catch(function(error){



        //             // console.log(error)

        //         });



        //         dataResponse.forEach(item=>{

        //             let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

        //             let dataobj = {

        //                 "id" : item.id,
        //                 "townshipcount" : townshipCount.length ? townshipCount.length : 0,
        //                 "name" : item.name

        //             }

        //             arr.push(dataobj);

        //         });


        //         setDataSource(arr);

        //         setDataSource(arr);

        //         Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);

        //         Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

        //     })
        //     .catch(function(error){

        //         // console.log(error);



        //     });

        // }else{// if name cache available

        //     let resultData = arrCache.filter( x => x == "region"); //get where cache name

        //     resultData.forEach(async cachename => {

        //       let cacheStorage = await caches.open(cachename);

        //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/region`);

        //       // console.log(dataResponse);

        //       if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

        //             await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(async function(response){


        //                 console.log(response);

        //                 let dataResponse = response.data;
        //                 let dataResponseTownship ;

        //                 await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(function(response){

        //                     dataResponseTownship = response.data;



        //                 })
        //                 .catch(function(error){



        //                     // console.log(error)

        //                 });



        //                 dataResponse.forEach(item=>{

        //                     let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

        //                     let dataobj = {

        //                         "id" : item.id,
        //                         "townshipcount" : townshipCount.length ? townshipCount.length : 0,
        //                         "name" : item.name

        //                     }

        //                     arr.push(dataobj);

        //                 });


        //                 setDataSource(arr);

        //                 setDataSource(arr);

        //                 Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);

        //                 Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

        //             })
        //             .catch(function(error){

        //                 // console.log(error);



        //             });

        //       }else{ //if data in cache not empty


        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(async function(response){


        //                 console.log(response);

        //                 let dataResponse = response.data;
        //                 let dataResponseTownship ;

        //                 await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(function(response){

        //                     dataResponseTownship = response.data;



        //                 })
        //                 .catch(function(error){



        //                     // console.log(error)

        //                 });



        //                 dataResponse.forEach(item=>{

        //                     let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

        //                     let dataobj = {

        //                         "id" : item.id,
        //                         "townshipcount" : townshipCount.length ? townshipCount.length : 0,
        //                         "name" : item.name

        //                     }

        //                     arr.push(dataobj);

        //                 });


        //                 setDataSource(arr);

        //                 setDataSource(arr);

        //                 Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);

        //                 Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

        //             })
        //             .catch(function(error){

        //                 // console.log(error);



        //             });

        //         }else{

        //             //region not empty

        //             let resultData = arrCache.filter( x => x == "township"); //get where cache name

        //             resultData.forEach(async cachename => {

        //               let cacheStorage = await caches.open(cachename);

        //               let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/township`);

        //               let dataTownship = await cacheResponse.json();

        //               if(!dataTownship || dataTownship.length == 0){


        //                 await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                 .then(async function(response){


        //                     console.log(response);

        //                     let dataResponse = response.data;
        //                     let dataResponseTownship ;

        //                     await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                     .then(function(response){

        //                         dataResponseTownship = response.data;



        //                     })
        //                     .catch(function(error){



        //                         // console.log(error)

        //                     });



        //                     dataResponse.forEach(item=>{

        //                         let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

        //                         let dataobj = {

        //                             "id" : item.id,
        //                             "townshipcount" : townshipCount.length ? townshipCount.length : 0,
        //                             "name" : item.name

        //                         }

        //                         arr.push(dataobj);

        //                     });


        //                     setDataSource(arr);

        //                     setDataSource(arr);

        //                     Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);

        //                     Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

        //                 })
        //                 .catch(function(error){

        //                     // console.log(error);



        //                 });

        //               }else{

        //                   dataResponse.forEach(item=>{

        //                       let townshipCount = dataTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

        //                       let dataobj = {

        //                           "id" : item.id,
        //                           "townshipcount" : townshipCount.length ? townshipCount.length : 0,
        //                           "name" : item.name

        //                       }

        //                       arr.push(dataobj);

        //                   });


        //                   setDataSource(arr);

        //                   setDataSource(arr);
        //               }


        //             });





        //         }


        //       }


        //     });


        // }

    }
      async function onChange(page, pageSize){

        setCurrentPage(page);

        setPageSize(pageSize);

        let arrCache = await caches.keys();// get cache

        let arr = [];

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=20&';

        await axios.get(Uri.rootUri + `master/region/?${currentPageOnChange}${currentPageSizeOnChange}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(async function(response){


            // console.log(response);

            let dataResponseRegion = response.data;

            let dataResponseTownship ;

            setTotalData(dataResponseRegion.count);

                if(!arrCache.includes("township")){

                    // console.log("cache township not available");

                    await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                    .then(function(response){

                        dataResponseTownship = response.data;

                    })
                    .catch(function(error){



                        // console.log(error)

                    });



                    dataResponseRegion.results.forEach(item=>{

                        let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

                        let dataobj = {

                            "id" : item.id,
                            "townshipcount" : townshipCount.length ? townshipCount.length : 0,
                            "name" : item.name

                        }

                        arr.push(dataobj);

                    });


                    setDataSource(arr);

                    Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

                }else{


                    let resultData = arrCache.filter( x => x == "township"); //get where cache name

                    resultData.map(async cachename => {

                    let cacheStorage = await caches.open(cachename);

                    let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/township`);

                    // console.log("cache township error");

                        if(!cacheResponse || cacheResponse == undefined){

                            await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                            .then(function(response){

                                dataResponseTownship = response.data;

                            })
                            .catch(function(error){



                                // console.log(error)

                            });

                            // console.log(dataResponseTownship);

                            dataResponseRegion.results.forEach(item=>{

                                let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

                                let dataobj = {

                                    "id" : item.id,
                                    "townshipcount" : townshipCount.length ? townshipCount.length : 0,
                                    "name" : item.name

                                }

                                arr.push(dataobj);

                            });


                            setDataSource(arr);

                            Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

                        }else{

                            let dataResponse = await cacheResponse.json();

                            // console.log(dataResponse);

                            // console.log("cache township available");

                            if(!dataResponse || dataResponse.length == 0){

                                // console.log("cache township []");

                                await axios.get(Uri.rootUri + `master/township/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                .then(function(response){

                                    dataResponseTownship = response.data;

                                })
                                .catch(function(error){



                                    // console.log(error)

                                });


                                dataResponseRegion.results.forEach(item=>{

                                    let townshipCount = dataResponseTownship.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

                                    let dataobj = {

                                        "id" : item.id,
                                        "townshipcount" : townshipCount.length ? townshipCount.length : 0,
                                        "name" : item.name

                                    }

                                    arr.push(dataobj);

                                });


                                setDataSource(arr);

                                Helper.addDataIntoCache("township", Helper.getHostCurrentUrl() + `/township`, dataResponseTownship);//set data to cache

                            }else{

                                // console.log("cache sub incident available");

                                dataResponseRegion.results.map(item=>{

                                    let townshipCount = dataResponse.filter( x => Helper.hiddenChar(x.region_id) == Helper.hiddenChar(item.id));

                                    let data = {
                                        "id" : item.id,
                                        "name" : item.name,
                                        "townshipcount" : townshipCount.length ? townshipCount.length : 0,
                                    }

                                    arr.push(data);

                                });


                                setDataSource(arr);



                            }

                        }

                    })


                }

            })
            .catch(function(error){

                console.log(error);

            });

    }

    async function _loadDataAll(cachenameparam){

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
        .then(async function(response){

             //get version data if there is an update
            let dataResponse = response.data;

            //get data cache where cache name
            let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

            if(!arrCache.includes("region")){// if empty cache name in browser

                // console.log("cache in browser empty");

                await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){

                    let dataResponse = response.data;

                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);


                })
                .catch(function(error){

                    console.log(error);



                });

                if(resultObjCache.length == 0){// if data cache in database empty

                  let cacheName = {

                    "name" : cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function(response){

                    //   console.log(response);


                  })
                  .catch(function(error){


                      console.log(error);

                  });

                }else{

                    //   console.log("set version");

                      // if data cache in database not empty
                      Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                }

              }else{

                let resultData = arrCache.filter( x => x == "region");

                //Operation Caching Validation

                resultData.forEach(async cachename => {

                  let cacheStorage = await caches.open(cachename);

                  let regionResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/region');
                  //get version in cache for check update data
                  let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                  if(!cacheResponse || cacheResponse == undefined || !regionResponse || regionResponse == undefined){// check data valid or no valid

                    // console.log("set version into cache");

                    await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                    .then(function(response){

                        let dataResponse = response.data;

                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);


                    })
                    .catch(function(error){

                        console.log(error);



                    });

                    if(resultObjCache.length == 0){// if data cache in database empty

                      let cacheName = {

                        "name" : cachenameparam,
                        "created_by": localStorage.getItem("usernameLogin"),
                        "updated_by": localStorage.getItem("usernameLogin")

                      }

                      await axios.post(Uri.rootUri + `cache/`, cacheName)
                      .then(function(response){

                        //   console.log(response);


                      })
                      .catch(function(error){


                          console.log(error);

                      });

                    }else{

                        //   console.log("set version");

                          // if data cache in database not empty
                          Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                    }


                  }else{

                    let dataResponseVersion = await cacheResponse.json();

                    let dataResponseRegion = await regionResponse.json();

                    if(!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseRegion || dataResponseRegion.length == 0){/*check data into cache empty or not empty*/

                        await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                        .then(function(response){

                            let dataResponse = response.data;

                            Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);


                        })
                        .catch(function(error){

                            console.log(error);



                        });

                        if(resultObjCache.length == 0){// if data cache in database empty

                            let cacheName = {

                                "name" : cachenameparam,
                                "created_by": localStorage.getItem("usernameLogin"),
                                "updated_by": localStorage.getItem("usernameLogin")

                            }

                            await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function(response){

                                // console.log(response);


                            })
                            .catch(function(error){


                                console.log(error);

                            });

                        }else{

                            // console.log("set version");

                            // if data cache in database not empty
                            Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                        }

                    }else{

                        // dataResponseVersion and dataResponseRegion there must be !

                        if(resultObjCache.length == 0){// if data cache in database empty

                            await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                            .then(function(response){

                                let dataResponse = response.data;

                                Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);


                            })
                            .catch(function(error){

                                console.log(error);



                            });

                            let cacheName = {

                                "name" : cachenameparam,
                                "created_by": localStorage.getItem("usernameLogin"),
                                "updated_by": localStorage.getItem("usernameLogin")

                            }

                            await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function(response){

                                // console.log(response);


                            })
                            .catch(function(error){


                                console.log(error);

                            });

                        }else{

                              //check version  cache local browser you and database api , resultObjCache there must be !
                              let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                              if(resultVersion){

                                    // console.log("Update cache new version ");

                                    await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                    .then(function(response){

                                        let dataResponse = response.data;


                                        Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` , dataResponse);


                                    })
                                    .catch(function(error){

                                        console.log(error);



                                    });

                                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                              }else{

                                    // console.log("data incident and version available in cache");

                                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region`, dataResponseRegion);

                                    Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                              }

                        }


                    }

                  }

                });

              }


        })
        .catch(function(error){

            console.log(error);

        });


        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("region")){

        //     await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(function(response){

        //         let dataResponse= response.data;

        //         Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` ,dataResponse);//set cache

        //     })
        //     .catch(function(error){

        //         console.log(error);

        //     });

        // }else{

        //     let resultDataIncident = arrCache.filter( x => x == "region"); //get where cache name

        //     resultDataIncident.map(async cachename => {

        //     let cacheStorage = await caches.open(cachename);

        //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/region`);

        //     if(!cacheResponse || cacheResponse == undefined){

        //         await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(function(response){

        //             let dataResponse= response.data;

        //             Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` ,dataResponse);//set cache

        //         })
        //         .catch(function(error){

        //             console.log(error);

        //         });

        //     }else{

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/region/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 let dataResponse= response.data;

        //                 Helper.addDataIntoCache("region", Helper.getHostCurrentUrl() + `/region` ,dataResponse);//set cache

        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             });
        //         }

        //     }

        //     });

        // }

    }

      useEffect(()=>{
        const effect = async () => {

        //ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadData(1, 100);
        await _loadDataAll("region");
        
        setLoading(false);
      }
      effect();
      },[]);

    return(
                <Spin size="large" spinning={loading}>
                    <div className="stateRegion">
                        <div className="row">
                            <div className="col-lg-4">
                                <h4>Add New State/Region</h4>
                                    <div className="mb-3">
                                        <label htmlFor="stateregion" className='m-1 d-flex align-items-start'>State / Region: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>
                                        {isInvalid && !stateRegion ?

                                            <div id="stateregion" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                Please insert State / Region.
                                            </div>
                                            :
                                            <></>
                                        }
                                        <input type="text" name="stateregion" className="form-control" id="stateregion" onChange={_onChangeStateRegion} value={stateRegion && stateRegion} required />
                                        </div>
                                <button type="submit" className="btn btn-primary" style={{ width:"100%" }} onClick={_saveData}><i className="bi bi-plus-circle-fill"></i> Add to Master Data</button>
                            </div>
                            <div className="col-lg-8">
                                <h4>State / Region Data</h4>
                                    <Table
                                        columns={columns}
                                        dataSource={dataSource}
                                        pagination={false}
                                        scroll={{ x:'max-content'}}
                                    />
                                      <div className="text-center mt-3">
                                        <PaginationComponent
                                            currentPage = {currentPage}
                                            setCurrentPage = {setCurrentPage}
                                            pageSize = {pageSize}
                                            setPageSize = {setPageSize}
                                            onChange = {onChange}
                                            showSizeChanger = {false}
                                            total = {totalData ? totalData : 0}
                                            defaultPageSize = {20}
                                        />
                                    </div>
                            </div>

                            <>
                                <Modal show={showUpdate} onHide={handleCloseUpdate} id={`update`}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Data</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="mb-3">
                                            <label htmlFor="stateregion">State / Region</label>
                                            {isInvalidUpdate && !stateRegionUpdate ?

                                                <div id="stateregion" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                    Please insert State / Region
                                                </div>
                                                :
                                                <></>
                                            }
                                            <input type="text" className="form-control" name="party5others" id="party5others"onChange={_onChangeStateRegionUpdate} value={stateRegionUpdate  ? stateRegionUpdate : "" }/>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseUpdate}>
                                        Close
                                    </Button>
                                    <Button type="submit" variant="primary" onClick={_updateStateRegion}>
                                        Save
                                    </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirmation</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p className='m-0'>Delete this data ?</p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseDelete}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={(e)=>_deleteStateRegion(e,idData)}>
                                        Ok
                                    </Button>
                                    </Modal.Footer>
                                </Modal>
                            </>
                        </div>
                    </div>
                </Spin>
    )

}