import React, { useRef, useEffect, useCallback } from "react";
import { useGoogleMap } from "@react-google-maps/api";
import { Switch } from "antd";

const MapControl = (props) => {
  const map = useGoogleMap();
  const ref = useRef();

  const onMapGridToggle = (checked, e) => {
    e.stopPropagation();
    props.onToggle();
  };

  useEffect(() => {
    if (map && ref) {
      map.controls[window.google.maps.ControlPosition[props.position]].push(
        ref.current
      );
    }
  }, [map, ref]);

  return (
    <div ref={ref} >
      <Switch loading={props.loading} style={{ top: '-50px', left: '200px', }} onChange={onMapGridToggle} checkedChildren="Grid ON" unCheckedChildren="Grid OFF" defaultChecked />
    </div>
  );
};

export default MapControl;
