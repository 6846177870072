import React,{useState,useEffect} from 'react'
import { GoogleMap, LoadScript,Marker, Autocomplete, Data, useGoogleMap } from '@react-google-maps/api';
import { useMediaQuery } from 'react-responsive'
import { Table, Tooltip, Select, Input, Spin, Switch} from 'antd';
import { InfoCircleFilled, InfoCircleOutlined} from '@ant-design/icons';
import PaginationComponent from './utils/PaginationComponent';
import { Modal, Button } from "react-bootstrap";
import axios from 'axios';
import Uri from '../Uri';
import { Link, useParams, useHistory } from 'react-router-dom';
import star from '../images/poi-icon/star.png';
import house from '../images/poi-icon/house.png';
import map from '../images/poi-icon/map-pin.png';
import Helper from './helpers/Helper';
import { useAxios } from '../App';
import { useMsal } from '@azure/msal-react';

export default function PointOfInterest(props) {
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();

    const authToken = localStorage.getItem("token");

    const { pointOfIncidentId, companyId } = useParams(); //get id poi and company

    let history = useHistory();

    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    const laptopOrDesktop = useMediaQuery({ query: '(min-width: 992px)' });

    //User Information

    let [username, setUsername] = useState("");
    let [emailAddress, setEmailAddress] = useState("");
    let [authId, setAuthId] = useState("");
    let [company, setCompany] = useState("");
    let [userId, setUserId] = useState("");
    let [permissions, setPermissions] = useState([]);
    let [approveIncident, setApproveIncident] = useState("1");
    let [customerManager, setCustomerManager] = useState("2");
    let [editIncident, setEditIncident] = useState("3");
    let [poiSelfService, setPoiSelfService] = useState("4");
    let [readOnly, setReadOnly] = useState("5");
    let [superUser, setSuperUser] = useState("6");

    let [idCompany, setIdCompany] = useState("");
    let [incidentTypeSelection, setIncidentTypeSelection] = useState([]);
    let [companyName, setCompanyName] = useState("");
    let [tableau, setTableau] = useState("");
    let [subscriptionType, setSubscriptionType] = useState("");
    let [elSubscriptionType, setElSubscriptionType] = useState([]);
    let [elTypeOfIncident, setElTypeOfIncident] = useState([]);
    let [subscriptionDate, setSubscriptionDate] = useState("");
    let [endOfContract, setEndOfContract] = useState("");
    let [incidentDateRange, setIncidentDateRange] = useState("");
    let [elIncidentDateRange, setElIncidentDateRange] = useState([]);
    let [maximumPoi, setMaximumPoi] = useState();


    let [ currentPage, setCurrentPage] = useState();
    let [ pageSize, setPageSize ] = useState();
    let [ totalData, setTotalData] = useState();

    let [ currentPageCompany, setCurrentPageCompany] = useState();
    let [ pageSizeCompany, setPageSizeCompany ] = useState();
    let [ totalDataCompany, setTotalDataCompany] = useState();

    let [quotaPoi, setQuotaPoi] = useState();
    let [poiId, setPoiId] = useState();
    let [latitude,setLatitude] = useState();
    let [longitude,setLongitude] = useState();
    let [poiName, setPoiName] = useState("");
    let [poiCategory, setPoiCategory] = useState("");
    let [freeField1, setFreeField1] = useState("");
    let [freeField2, setFreeField2] = useState("");
    let [poiShape, setPoiShape] = useState("");
    let [elPoiShape, setElPoiShape] = useState([]);
    let [poiColor, setPoiColor] = useState("");
    let [elPoiColor, setElPoiColor] = useState([]);
    let [selectedElement,setSelectedElement] = useState();
    let [autoComplete,setAutoComplete] = useState();
    let [formatAddress, setFormatAddress] = useState("");
    let [titleAddress, setTitleAddress] = useState("");
    let [search, setSearch] = useState("");
    let [arrPoiData, setArrPoiData] = useState([]);
    let [isInvalid, setIsInvalid] = useState();
    let [zoom, setZoom] = useState();
    let [loading, setLoading] = useState(true);
    let [companyIdPoi, setCompanyIdPoi] = useState("");
    const [showDelete, setShowDelete] = useState();

    function handleCloseDelete(e){

        setShowDelete(false);

    };
    function handleShowDelete(e){

        setShowDelete(true);

    }

    const text = <div className='text-center '>create yourself the relevant categories, i.e. office, residence, factory, etc</div>

    const { Option } = Select;

    const containerStyle = {
        width: '100%',
        height: '500px'
    };

    const center = {
        lat: latitude ? Number(latitude) : 	21.954510,
        lng: longitude ? Number(longitude) : 96.093292
    }

    const onLoadAutoComplete = autocomplete => {

        // console.log('autoComplete: ', autocomplete);

        setAutoComplete(autocomplete);
    }

    function onPlaceChanged (e,props) {
        if(autoComplete !== null) {

            let objAutoComplete = autoComplete.getPlace();

            setSelectedElement(true);

            setFormatAddress(objAutoComplete.formatted_address);

            setTitleAddress(objAutoComplete.name);

            setPoiName(objAutoComplete.formatted_address);

            setLatitude(objAutoComplete.geometry.location.lat());

            setLongitude(objAutoComplete.geometry.location.lng());

            if(props){ //when on page add new company

                props.setPoiName(objAutoComplete.formatted_address);

                props.setLatitude(objAutoComplete.geometry.location.lat());

                props.setLongitude(objAutoComplete.geometry.location.lng());

            }

            setZoom(16);

            // console.log(objAutoComplete);
        } else {
          console.log('Autocomplete is not loaded yet!')
        }
    }
    function onDrag(e){

        setLatitude(e.latLng.lat());

        setLongitude(e.latLng.lng());

        // console.log(e.latLng.lat(),e.latLng.lng());
    }
    const onLoadMarker = marker => {
        // console.log('marker: ', marker)
    }
    function _latitudePoi(e){

        e.preventDefault();

        let dataLatitude = e.target.value

        if(dataLatitude){

            setLatitude(dataLatitude)

        }else{

            setLatitude()

        }
    }
    function _longitudePoi(e){

        e.preventDefault();

        let dataLongitude = e.target.value

        if(dataLongitude){

            setLongitude(dataLongitude)

        }else{

            setLongitude()

        }
    }
    function _namePoi(e,props){

        e.preventDefault();

        let namePoi = e.target.value;

        if(namePoi){

            // console.log(props);

            setPoiName(namePoi);

            if(props.setPoiName){

                props.setPoiName(namePoi);//if on page add new company

            }

        }else{

            setPoiName("");

            if(props.setPoiName){

                props.setPoiName("");//if on page add new company

            }

        }
    }

    function _categoryPoi(e, props){

        e.preventDefault();

        let categoryPoi = e.target.value;

        if(categoryPoi){

            setPoiCategory(categoryPoi);

            if(props.setPoiCategory){

                props.setPoiCategory(categoryPoi);//if on page add new company

            }

        }else{

            setPoiCategory("");

            if(props.setPoiCategory){

                props.setPoiCategory("");//if on page add new company

            }

        }
    }
    function _freefield1Poi(e, props){

        e.preventDefault();

        let freefield1Poi = e.target.value;

        if(freefield1Poi){

            setFreeField1(freefield1Poi);

            if(props.setFreeField1){

                props.setFreeField1(freefield1Poi);

            }

        }else{

            setFreeField1("");

            if(props.setFreeField1){

                props.setFreeField1("");

            }

        }
    }
    function _freefield2Poi(e, props){

        e.preventDefault();

        let freefield2Poi = e.target.value;

        if(freefield2Poi){

            setFreeField2(freefield2Poi);

            if(props.setFreeField2){

                props.setFreeField2(freefield2Poi);

            }

        }else{

            setFreeField2("");

            if(props.setFreeField2){

                props.setFreeField2("");

            }

        }
    }

    function _shapePoi(e, props){

        let shapePoi = e;

        if(shapePoi){

            setPoiShape(shapePoi);

            if(props.setPoiShape){

                props.setPoiShape(shapePoi);

            }

        }else{

            setPoiShape("");

            if(props.setPoiShape){

                props.setPoiShape("");

            }

        }

    }

    function _colorPoi(e, props){
    //    console.log(e);
        let colorPoi = e;

        if(colorPoi){

            setPoiColor(colorPoi);

            if(props.setPoiColor){

                props.setPoiColor(colorPoi);

            }

        }else{

            setPoiColor("");

            if(props.setPoiColor){

                props.setPoiColor("");

            }

        }

    }

    async function _saveData(e,props){

        e.preventDefault();

        const isLatitude = isFinite(latitude) && Math.abs(latitude) <= 90; //validasi latitude
        const isLongitude = isFinite(longitude) && Math.abs(longitude) <= 180; //validasi longitude

        if(!latitude || !longitude || !poiName || !poiCategory || !poiShape || !poiColor){

            setIsInvalid(true);

            setLoading(false);

            setTimeout(()=>setIsInvalid(false),3000);

        }else{

            if(!isLatitude || !isLongitude){

                setLoading(false);

                setIsInvalid(true);

                setTimeout(()=>setIsInvalid(false),3000);

                Helper.alert("Failed", "error", "Please fill in latitude and longitude correctly", 3000);

                // console.log("latitude and longitude false");

            }else{

                let data ;

                if(props.company){ // if add Poi on company page

                    if(props.companyId){

                        if(quotaPoi > 0){ //if remaining POI still available

                            data  = {
                                "company_id" : Helper.hiddenChar(props.companyId),
                                "name" : poiName,
                                "category" : poiCategory,
                                "free1" : freeField1,
                                "free2" : freeField2,
                                "latitude" : String(latitude),
                                "longitude": String(longitude),
                                "icon_shape" : poiShape,
                                "icon_color" : poiColor,
                                "updated_by" : localStorage.getItem("usernameLogin"),
                                "created_by" : localStorage.getItem("usernameLogin")
                            };

                            await axios.post(Uri.rootUri + `poi/`,data,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                            .then(function(response){

                                // console.log(response);


                                setLatitude();

                                setLongitude();

                                setPoiCategory("");

                                setFreeField1("");

                                setFreeField2("");

                                setPoiShape("");

                                setPoiName("");

                                setPoiColor("");

                                _loadData(props, currentPageCompany, pageSizeCompany);

                                Helper.alert("Success", "success", "Success save data", 3000);

                                setIsInvalid(false);



                            })
                            .catch(function(error){

                                console.log(error);

                            });

                        }else{


                            Helper.alert("Failed", "error", "You reach maximum number of point of interest! ", 3000);


                        }

                    }

                }else{

                    // console.log(quotaPoi);

                    if(quotaPoi > 0){ //if remaining POI still available

                        data  = {
                            "company_id" : Helper.hiddenChar(company),
                            "name" : poiName,
                            "category" : poiCategory,
                            "free1" : freeField1,
                            "free2" : freeField2,
                            "latitude" : String(latitude),
                            "longitude": String(longitude),
                            "icon_shape" : poiShape,
                            "icon_color" : poiColor,
                            "updated_by" : localStorage.getItem("usernameLogin"),
                            "created_by" : localStorage.getItem("usernameLogin")
                        };

                        // console.log(data);

                        await axios.post(Uri.rootUri + `poi/`, data,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                        .then(function(response){

                            // console.log(response);


                            setLatitude();

                            setLongitude();

                            setPoiCategory("");

                            setFreeField1("");

                            setFreeField2("");

                            setPoiShape("");

                            setPoiName("");

                            setPoiColor("");

                            _loadData(props, currentPage, pageSize);

                            Helper.alert("Success","success","Success save data", 3000);

                            setIsInvalid(false);



                        })
                        .catch(function(error){

                            console.log(error);


                        });

                    }else{

                        Helper.alert("Failed", "error", "You reach maximum number of point of interest! ", 3000);

                    }

                }
                // console.log(data);


            }
        }

    }

    async function _updateData(e,props){


        e.preventDefault();



        const isLatitude = isFinite(latitude) && Math.abs(latitude) <= 90; //validasi latitude
        const isLongitude = isFinite(longitude) && Math.abs(longitude) <= 180; //validasi longitude

        if(!latitude || !longitude || !poiName || !poiCategory || !poiShape || !poiColor){

            setIsInvalid(true);

            setLoading(false);

            setTimeout(()=>setIsInvalid(false),3000);

        }else{


            if(!isLatitude || !isLongitude){

                setIsInvalid(true);

                setLoading(false);

                setTimeout(()=>setIsInvalid(false),3000);

                Helper.alert("Failed", "error", "Please fill in latitude and longitude correctly", 3000);

                // console.log("latitude and longitude false");

            }else{

                let data  = {
                    "company_id" : Helper.hiddenChar(companyIdPoi) ,
                    "name" : poiName,
                    "category" : poiCategory,
                    "free1" : freeField1,
                    "free2" : freeField2,
                    "latitude" : String(latitude),
                    "longitude": String(longitude),
                    "icon_shape" : poiShape,
                    "icon_color" : poiColor,
                    "updated_by" : localStorage.getItem("usernameLogin")
                };

                await axios.put(Uri.rootUri + `poi/${poiId}/`, data,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){

                    // console.log(response);


                    setLatitude();

                    setLongitude();

                    setPoiCategory("");

                    setFreeField1("");

                    setFreeField2("");

                    setPoiShape("");

                    setPoiName("");

                    setPoiColor("");

                    _loadData(props, currentPage, pageSize);

                    Helper.alert("Success", "success", "Success update data", 3000);

                    setIsInvalid(false);



                    setTimeout(()=>{

                        window.close();

                    },2000);

                    window.onunload = Helper.refreshParent();


                })
                .catch(function(error){



                });
            }

        }

    }

    const columns = [
        {
          title: 'POI Name',
          dataIndex: 'name',
          sorter: (a, b) => a.name.localeCompare(b.name),
        //   width: '15%',
        },
        {
          title: 'POI Category',
          dataIndex: 'category',
          sorter: (a, b) => a.category.localeCompare(b.category),
        //   width: '15%',
        },
        {
          title: 'Sub Category 1',
          dataIndex: 'free1',
          sorter: (a, b) => a.free1.localeCompare(b.free1),
        //   width: '15%',
        },
        {
          title: 'Sub Category 2',
          dataIndex: 'free2',
          sorter: (a, b) => a.free2.localeCompare(b.free2),
        //   width: '15%',
        },
        {
            title: 'POI Shape',
            dataIndex: 'icon_shape',
            // width: '15%',
            render : (text,record,index) => (
                <div>
                    {text == "star" &&
                        <img src={star} alt="star" className='img-fluid' style={{ width:20, height:20 }} />
                    }
                    {text == "house" &&
                        <img src={house} alt="house" className='img-fluid' style={{ width:20, height:20 }} />
                    }
                    {text == "pin" &&
                         <img src={map} alt="map" className='img-fluid' style={{ width:20, height:20 }} />
                    }
                </div>
            )
        },
        {
            title: 'POI Color',
            dataIndex: 'icon_color',
            // width: '15%',
            render : (text,record,index) => {

                return(

                    <div style={{ width:20, height:20, margin:5, backgroundColor:`${text}` }}></div>

                )
            }
        },
        {
          title: 'Latitude',
          dataIndex: 'latitude',
        //   width: '15%',
          render : (text,record,index) => {

            let result;

            let arrText = text.split('.');

            result = arrText[0] + '.' + arrText[1].substr(0,6);

            return result

          }
        },
        {
          title: 'Longitude',
          dataIndex: 'longitude',
        //   width:'15%',
          render : (text,record,index) => {

            let result;

            let arrText = text.split('.');

            result = arrText[0] + '.' + arrText[1].substr(0,6);

            return result

          }
        },
        {
          title: 'Action',
          dataIndex:'action',
          render : (text,record,index) =>(
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>window.open(`${window.location.origin}/Index/ViewPointOfInterest/${record.id}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")}>
                                    <i className="bi bi-pencil-fill"></i>
                                {/* <Link onClick={(e)=>window.open(`http://localhost:3000/Index/ViewPointOfInterest/${record.id}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} className='text-white'>
                                    <i className="bi bi-pencil-fill"></i>
                                </Link> */}
                            </button>

                            <button type="button" className="btn btn-primary btn-sm"
                            onClick={(e)=>{
                                handleShowDelete(e);
                                setPoiId(record.id);
                                }} id="delete"><i className="bi bi-trash-fill"></i>
                            </button>
                        </div>
                    </div>
          )
        }
      ];


    function _searchForm(e){

        if(e.target.value){

            setSearch(e.target.value);

        }else{

            setSearch("");

        }

    }

    async function _onSearch(e,props){

        e.preventDefault();

        let searchText = search ? `search=${search}&` : "";

        if(props.company){

            let companyId = props.companyId ? `company_id=${props.companyId}&` : ``;

            await axios.get(Uri.rootUri + `poi/?page=1&${companyId}${searchText}page_size=50`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(function(response){

                let dataResponse = response.data;

                setCurrentPageCompany(1);

                setTotalDataCompany(dataResponse.count);

                setArrPoiData(dataResponse.results);
                // console.log(response);

            })
            .catch(function(error){

                console.log(error);

            });

        }else{

            await axios.get(Uri.rootUri + `poi/?page=1&company_id=${localStorage.getItem("company_id")}&${searchText}page_size=50`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(function(response){

                let dataResponse = response.data;

                setCurrentPage(1);

                setTotalData(dataResponse.count);

                setArrPoiData(dataResponse.results);

                // console.log(response);

            })
            .catch(function(error){

                console.log(error);

            });
        }

    }

    async function _loadShape(e){

        const childrenShape = [

            <Option key='star '>
                <img src={star} alt="star" className='img-fluid' style={{ width:20, height:20 }} />
            </Option>,
            <Option key='house'>
                <img src={house} alt="house" className='img-fluid' style={{ width:20, height:20 }} />
            </Option>,
            <Option key='pin'>
                <img src={map} alt="map" className='img-fluid' style={{ width:20, height:20 }} />
            </Option>,

        ];

        setElPoiShape(childrenShape);

    }

    async function _loadColor(e){

        const childrenColor = [

            <Option key='#000000'>
                <div style={{ width:20, height:20, margin:5, backgroundColor:'#000000' }}></div>
            </Option>,
            <Option key='#fc0303'>
                <div style={{ width:20, height:20, margin:5, backgroundColor:'#fc0303' }}></div>
            </Option>,
            <Option key='#0d00ff'>
                <div style={{ width:20, height:20, margin:5, backgroundColor:'#0d00ff' }}></div>
            </Option>,
            <Option key='#ff9900'>
                <div style={{ width:20, height:20, margin:5, backgroundColor:'#ff9900' }}></div>
            </Option>,

        ]

        setElPoiColor(childrenColor)

    }

    async function _loadData(props, page, pageSize){

        // console.log(props);

            if(props.company){

                if(props.companyId){

                    setCurrentPageCompany(page);

                    setPageSizeCompany(pageSize);

                    await axios.get(Uri.rootUri + `poi/?page=${page}&company_id=${props.companyId}&page_size=${pageSize}`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                    .then(function(response){

                        let dataResponse = response.data;

                        // console.log(response);

                        setTotalDataCompany(dataResponse.count);

                        setArrPoiData(dataResponse.results);



                    })
                    .catch(function(error){

                        console.log(error);

                    });

                    await axios.get(Uri.rootUri + `company/${props.companyId}/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                    .then(function(response){

                        let dataResponse = response.data;

                        // console.log(dataResponse.quotapoi);

                        setQuotaPoi(dataResponse.quotapoi);

                    })
                    .catch(function(error){

                        console.log(error);

                    });

                }

            }else{ // if page on POI

            if(pointOfIncidentId){

                await axios.get(Uri.rootUri + `poi/${pointOfIncidentId}/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){

                    // console.log(response);

                    let dataResponse = response.data;

                    setCompanyIdPoi(dataResponse.company_id);

                    setPoiId(dataResponse.id);

                    setPoiName(dataResponse.name);

                    setPoiCategory(dataResponse.category);

                    setFreeField1(dataResponse.free1);

                    setFreeField2(dataResponse.free2);

                    setLatitude(dataResponse.latitude);

                    setLongitude(dataResponse.longitude);

                    setPoiShape(dataResponse.icon_shape);

                    setPoiColor(dataResponse.icon_color);

                    // setArrPoiData(dataResponse);


                })
                .catch(function(error){

                    console.log(error);

                });



            }else{

                setPageSize(pageSize);

                setCurrentPage(page);

                await axios.get(Uri.rootUri + `poi/?company_id=${localStorage.getItem("company_id")}&page=${page}&page_size=${pageSize}`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){

                    let dataResponse = response.data;

                    // console.log(response);

                    setTotalData(dataResponse.count)

                    setArrPoiData(dataResponse.results);


                })
                .catch(function(error){

                    console.log(error);

                });

                await axios.get(Uri.rootUri + `company/${localStorage.getItem("company_id")}/`,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){

                    // console.log(response);

                    let dataResponse = response.data;

                    setIdCompany(dataResponse.id);

                    setCompanyName(dataResponse.companyname);

                    setQuotaPoi(dataResponse.quotapoi);

                })
                .catch(function(error){

                    console.log(error);

                });

            }

        }


    }

    async function _deleteData(e, id, props){



        if(id){

            let data = {

                "deleted_by" : localStorage.getItem("usernameLogin")

            }

            if(props.company){

                await axios.put(Uri.rootUri + `poi/${id}/delete/`, data,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){

                    handleCloseDelete();

                    Helper.alert("Success","success","Success delete data", 3000);

                    _loadData(props, currentPageCompany, pageSizeCompany);



                })
                .catch(function(error){

                    console.log(error);

                });

            }else{


                await axios.put(Uri.rootUri + `poi/${id}/delete/`, data ,  { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){

                    handleCloseDelete();

                    Helper.alert("Success","success","Success delete data", 3000);

                    _loadData(props, currentPage, pageSize);

                })
                .catch(function(error){

                    console.log(error);

                });

            }

        }

    }

    function onClikMap(e,props){

        // console.log(e);

        setLatitude(e.latLng.lat());

        setLongitude(e.latLng.lng());

        if(props.setLatitude && props.setLongitude){

            props.setLatitude(e.latLng.lat());

            props.setLongitude(e.latLng.lng());
        }

    }

    function _cancel(e){

        window.close();

    }

    const ButtonAction = (props) =>{

        if(props.company){ //if on page add new company

            if(props.buttonAddPoi){// if on page edit company
                return(
                    <div className='mb-3 text-end'>
                        <button type='submit' className='btn btn-primary' onClick={(e)=>_saveData(e,props)}>Add POI</button>
                    </div>
                )

            }else{

                return(
                    <></>
                )

            }

        }else{ // if on page Poi

            if(props.pointOfIncidentId){
                return(
                        <div className='mb-3 text-center'>
                            <button type='submit' className='btn btn-secondary mx-1' onClick={_cancel}>Cancel</button>
                            <button type='submit' className='btn btn-primary mx-1' onClick={(e)=>_updateData(e,props)}>Save</button>
                        </div>
                )
            }else{
                return(
                        <div className='mb-3 text-end'>
                            <button type='submit' className='btn btn-primary' onClick={(e)=>_saveData(e,props)}>Add POI</button>
                        </div>
                )
            }
        }

    }

    async function _loadUserInformation(props){

        const userInformation = await Helper.getUserLogin();

        if(userInformation){

          setUserId(userInformation.user_id);

          setEmailAddress(userInformation.email_address);

          setAuthId(userInformation.auth_id);

          setCompany(userInformation.company_id);

          setUsername(userInformation.username);

          let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));

          setPermissions(strPermissions);

          if(!props.company){ // if open only on page POI , but open on page Company, validation there is company page

            if(!strPermissions.includes(poiSelfService)){// if not Permission Type Edit Incident

                history.push("Unauthorize");

                // window.location.replace(`${window.location.origin}/Index/Unauthorize`);

                // window.location.replace("http://localhost:3000/Index/Unauthorize");

            }

          }

        }

      }

      async function onChange(page, pageSize){

        // console.log(page,pageSize); //page = new previous  page and new next page

        setPageSize(pageSize);

        setCurrentPage(page);

        let searchText = search ? `search=${search}&` : "";

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=50&';

        await axios.get(Uri.rootUri + `poi/?company_id=${localStorage.getItem("company_id")}&${currentPageOnChange}${searchText}${currentPageSizeOnChange}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(function(response){

            let dataResponse = response.data;

            setTotalData(dataResponse.count);

            setArrPoiData(dataResponse.results);

        })
        .catch(function(error){

            console.log(error);

        });

    }
      async function onChangeCompany(page, pageSize){

        // console.log(page,pageSize); //page = new previous  page and new next page

        setPageSizeCompany(pageSize);

        setCurrentPageCompany(page);

        let searchText = search ? `search=${search}&` : "";

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=50&';

        // console.log(companyId);

        await axios.get(Uri.rootUri + `poi/?company_id=${companyId}&${currentPageOnChange}${searchText}${currentPageSizeOnChange}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(function(response){

            let dataResponse = response.data;

            setTotalDataCompany(dataResponse.count);

            setArrPoiData(dataResponse.results);

        })
        .catch(function(error){

            console.log(error);

        });

    }

    useEffect(() => {
      const effect = async () => {

        // ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadUserInformation(props);

        await _loadData(props, 1, 50);
        await _loadShape();
        await _loadColor();
        
        setLoading(false);
      }
      effect();
    }, []);

    return (
                            <Spin size='large' spinning={loading}>
                                <div className='pointOfInterest'>
                                    {/* row 1 */}
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <h6 className='text-center'>Capture here your Point of Interest (POIs) for display in your security dashboard </h6>
                                        </div>
                                    </div>
                                    {/* end row 1 */}
                                    
                                    {/* row 2 */}
                                    <div className="row mb-3" style={{ background: "#f5f5f5" }} >
                                        <div className="col-lg-1" style={{ maxWidth: '24px' }} >
                                            <InfoCircleOutlined />
                                        </div>

                                        <div className="col-lg-11" >
                                            <span className='text-center'>NB: Vanguard data, including POI lists, are refreshed every hour. There is therefore a time lag between the moment you create/edit a POI and the moment those changes appear on "Incident near my POIs" dashboard. </span>
                                        </div>
                                    </div>
                                    {/* end row 3 */}

                                    {/* row 3 */}
                                    <div className="row mb-3">

                                        <div className="col-lg-7 mb-4">
                                            <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["places"]}>
                                                <GoogleMap
                                                    mapContainerStyle={ laptopOrDesktop ? containerStyle : {width:'100%',height:'500px'}}
                                                    center={center}
                                                    zoom={zoom ? zoom : 6}
                                                    mapTypeId = 'hybrid'
                                                    onClick={(e)=>onClikMap(e,props)}

                                                    >
                                                        {/* {console.log(arrPoiData)} */}
                                                        <Marker
                                                            onLoad={onLoadMarker}
                                                            position={center}
                                                            draggable={true}
                                                            onDrag={onDrag}
                                                            onClick={()=>{
                                                                setSelectedElement(true);
                                                            }}
                                                        />
                                                       { arrPoiData.map(markerPosition =>(
                                                            <Marker
                                                                onLoad={onLoadMarker}
                                                                position={{
                                                                    lat : Number(markerPosition.latitude),
                                                                    lng : Number(markerPosition.longitude)
                                                                }}
                                                                draggable={false}
                                                                icon={`https://maps.google.com/mapfiles/ms/icons/blue-dot.png`}
                                                            />
                                                        ))
                                                        }
                                                        <Autocomplete
                                                            onLoad={onLoadAutoComplete}
                                                            onPlaceChanged={(e)=>onPlaceChanged(e,props)}
                                                        >
                                                            <div style={{
                                                                    position:`relative`,
                                                                    display:`inline-block`,
                                                                    marginTop: 90,
                                                                    marginLeft: 10,
                                                                    marginRight: 10,
                                                                    background:`#fff`,
                                                                    width: 350
                                                                    // padding : 10
                                                                    }}>
                                                                <div className=' rounded' style={{
                                                                    width: `100%`,
                                                                    padding: 5,
                                                                    background:`#fff`

                                                                }}>
                                                                    <h6 className='text-white bg-primary py-1 px-2 rounded'>Autocomplete Search</h6>

                                                                    <input
                                                                    type="text"
                                                                    placeholder="Search in google map"
                                                                    className='form-control form-control-sm'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Autocomplete>
                                                    </GoogleMap>
                                            </LoadScript>
                                        </div>


                                        <div className="col-lg-5">

                                            <div className='row g-1 needs-validation'>

                                                <div className={`${isInvalid ? 'mb-2': 'mb-4'} d-flex justify-content-between align-items-center`}>

                                                <label for="poiname" className="form-label d-flex align-items-start m-0">POI Name: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>
                                                    <div style={{ width:'70%' }}>
                                                        {isInvalid && !poiName ?

                                                            <div id="poiname" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                                Please insert poiname.
                                                            </div>
                                                        :
                                                        <></>
                                                        }
                                                        <Input type="text"  id="poiname" name='poiname' onChange={(e)=>_namePoi(e,props)} value={ poiName ? poiName : ""} aria-describedby="poiname"/>
                                                    </div>

                                                </div>

                                                <div className={`mb-4 d-flex justify-content-between align-items-center`}>

                                                    <label for="poicategory" className="form-label d-flex align-items-start text-nowrap">POI Category:  <Tooltip className="text-center" placement="top" title={text}> <InfoCircleFilled /></Tooltip><i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i>
                                                    </label>

                                                    <div style={{ width:'70%' }}>
                                                    {isInvalid && !poiCategory ?

                                                        <div id="poicategory" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                            Please insert poicategory.
                                                        </div>
                                                    :
                                                    <></>
                                                    }
                                                        <Input type="text" id="poicategory" onChange={(e)=>_categoryPoi(e,props)} value={ poiCategory ? poiCategory : ""} />

                                                    </div>

                                                </div>

                                                <div className='mb-4 d-flex justify-content-between align-items-center'>
                                                     {/*previous name was Free Field 1*/}
                                                    <label for="freefield1" className="form-label d-flex align-items-center">Sub Category 1: <Tooltip className="text-center" placement="top" title={text}><InfoCircleFilled /></Tooltip></label>

                                                    <div style={{ width:'70%' }}>
                                                        <Input type="text" id="freefield1" onChange={(e)=>_freefield1Poi(e,props)} value={ freeField1 ? freeField1 : "" }/>
                                                    </div>
                                                </div>

                                                <div className='mb-4 d-flex justify-content-between align-items-center'>
                                                    {/*previous name was Free Field 2*/}
                                                    <label for="freefield2" className="form-label d-flex align-items-center">Sub Category 2: <Tooltip className="text-center" placement="top" title={text}><InfoCircleFilled /></Tooltip></label>

                                                    <div style={{ width:'70%' }}>
                                                        <Input type="text" id="freefield2" onChange={(e)=>_freefield2Poi(e,props)} value={ freeField2 ? freeField2 : ""}/>
                                                    </div>
                                                </div>

                                                <div className={`${isInvalid ? 'mb-2' : 'mb-4'} d-flex justify-content-between align-items-center`}>

                                                    <label for="latitude" className="form-label d-flex align-items-start">Latitude: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>

                                                    <div style={{ width:'70%' }}>

                                                        {isInvalid && !latitude ?
                                                            <div id="latitude"  className="text-danger mb-1" style={{ fontSize:14 }}>
                                                                Please insert latitude correctly.
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        <Input type="text" id="latitude"  onChange={(e)=>_latitudePoi(e,props)} value={ latitude ? latitude : ""} />
                                                    </div>

                                                </div>

                                                <div className={`${isInvalid ? 'mb-2' : 'mb-4'} d-flex justify-content-between align-items-center`}>

                                                    <label for="longitude" className="form-label d-flex align-items-start">Longitude: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>

                                                    <div style={{ width:'70%' }}>

                                                        {isInvalid && !longitude ?

                                                            <div id="longitude" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                                Please insert longitude correctly.
                                                            </div>
                                                            :
                                                            <></>
                                                        }
                                                        <Input type="text" id="longitude" onChange={(e)=>_longitudePoi(e,props)} value={ longitude ? longitude : ""} />

                                                    </div>

                                                </div>

                                                <div className={`${isInvalid ? 'mb-2' : 'mb-4'} d-flex justify-content-between align-items-center`}>

                                                    <label for="poiname" className="form-label d-flex align-items-start">POI Shape: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>

                                                    <div style={{ width:'70%' }} >
                                                        { isInvalid && !poiShape ?

                                                        <div id="poishape" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                             Please choose a shape.
                                                         </div>
                                                         :
                                                         <></>
                                                        }

                                                        <Select size={"middle"} onChange={(e)=>_shapePoi(e,props)} style={{ width:'100%' }} value={poiShape && poiShape}>
                                                            {elPoiShape}
                                                        </Select>
                                                    </div>

                                                </div>

                                                <div className={`${isInvalid ? 'mb-2' : 'mb-4'} d-flex justify-content-between align-items-center`}>

                                                    <label for="poicolor" className="form-label d-flex align-items-start">POI Color: <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>

                                                    <div style={{ width:'70%' }} >
                                                        { isInvalid && !poiColor ?

                                                        <div id="poicolor" className="text-danger mb-1" style={{ fontSize:14 }}>
                                                             Please choose a color.
                                                         </div>
                                                         :
                                                         <></>
                                                        }

                                                        <Select size={"middle"} onChange={(e)=>_colorPoi(e,props)} style={{ width:'100%' }} value={poiColor && poiColor}>
                                                            {elPoiColor}
                                                        </Select>
                                                    </div>

                                                </div>

                                                <ButtonAction
                                                    pointOfIncidentId = {pointOfIncidentId}
                                                    buttonAddPoi = {props.buttonAddPoi}
                                                    company = {props.company}
                                                    companyId = {props.companyId}
                                                />

                                            </div>
                                        </div>


                                    </div>
                                    {/* end row 3 */}

                                    {pointOfIncidentId ?

                                        <></>
                                    :
                                    <>
                                        {/* row 4 */}
                                        <div className="row mb-3 align-items-center">
                                            <div className="col-lg-3 offset-lg-1">

                                                { props.company && props.companyId ?

                                                    <>
                                                        <p className='m-0'>Remaining POI Left : {props.quotaPoi ? props.quotaPoi : "0"}</p>
                                                    </>
                                                :
                                                    <>
                                                        <p className='m-0'>Remaining POI Left : {quotaPoi ? quotaPoi : "0"}</p>
                                                    </>

                                                }


                                            </div>
                                            <div className="col-lg-3">
                                                <div className='d-flex align-items-center ml-5 mb-3'>
                                                    <div className="d-inline-block mx-2" >
                                                        <label htmlFor="search" className="col-form-label">Search: </label>
                                                    </div>
                                                    <div class="input-group" style={{ width:250 }}>
                                                        <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={_searchForm}/>
                                                        <button className="btn btn-primary" type="submit" id="button-addon2" onClick={(e)=>_onSearch(e,props)}><i class="bi bi-search"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 offset-lg-1">
                                            </div>
                                        </div>
                                        {/* end row 4 */}
                                        {/* row 5 */}
                                        <div className="row justify-content-center mb-3">
                                            <div className="col-lg-10">
                                                <Table
                                                    columns={columns}
                                                    dataSource={arrPoiData}
                                                    pagination={false}
                                                    size={'small'}
                                                    scroll={{x:'max-content'}}
                                                />
                                                <div className='text-center mt-3'>

                                                { props.company ?

                                                    <PaginationComponent
                                                        currentPage = {currentPageCompany}
                                                        setCurrentPage = {setCurrentPageCompany}
                                                        pageSize = {pageSizeCompany}
                                                        setPageSize = {setPageSizeCompany}
                                                        onChange = {onChangeCompany}
                                                        showSizeChanger = {false}
                                                        total = {totalDataCompany ? totalDataCompany : 0}
                                                        defaultPageSize = {50}
                                                    />

                                                    :

                                                    <PaginationComponent
                                                        currentPage = {currentPage}
                                                        setCurrentPage = {setCurrentPage}
                                                        pageSize = {pageSize}
                                                        setPageSize = {setPageSize}
                                                        onChange = {onChange}
                                                        showSizeChanger = {false}
                                                        total = {totalData ? totalData : 0}
                                                        defaultPageSize = {50}
                                                    />
                                                }

                                                </div>
                                            </div>
                                        </div>
                                        {/* end row 5 */}
                                    </>
                                }
                                    <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirmation</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <p className='m-0'>Delete this Point Of Interest ?</p>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseDelete}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={(e)=>_deleteData(e,poiId,props)}>
                                            Ok
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </Spin>

    )
}
