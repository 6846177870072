import React,{useState, useEffect} from 'react';
import { Table, Spin } from 'antd';
import { Modal, Button  } from 'react-bootstrap';
import axios from 'axios';
import Uri from '../../Uri';
import Helper from '../helpers/Helper';
import PaginationComponent from '../utils/PaginationComponent';
import { useAxios } from '../../App';
import { useMsal } from '@azure/msal-react';

export default function TypeOfIncident(props) {
    const axios = useAxios();
    const { instance: msalInstance } = useMsal();
    

    const authToken = localStorage.getItem("token");

    let [typeOfIncident, setTypeOfIncident] = useState("");
    let [typeOfIncidentUpdate, setTypeOfIncidentUpdate] = useState("");
    let [isInvalid, setIsInvalid] = useState();
    let [isInvalidUpdate, setIsInvalidUpdate] = useState();
    let [dataSource, setDataSource] = useState([]);
    let [idData, setIdData] = useState();
    let [loading, setLoading] = useState(true);

    let [ currentPage, setCurrentPage] = useState();
    let [ pageSize, setPageSize ] = useState();
    let [ totalData, setTotalData] = useState();

    const [showUpdate, setShowUpdate] = useState();
    const [showDelete, setShowDelete] = useState();

    function handleCloseDelete(e){

        setShowDelete(false);

    };
    function handleShowDelete(e){

        setShowDelete(true);

    }

    function handleCloseUpdate(e){

        setShowUpdate(false);

        setTypeOfIncident("");

    };
    function handleShowUpdate(e){

        setShowUpdate(true);

    }


    const columns = [
        {
            title: 'Incident Category',
            dataIndex: 'name',
            // key: 'name',
            defaultSortOrder: 'ascend',
            sorter : (a,b)=> a.name.localeCompare(b.name) ,
            // responsive: ['sm','md','lg','xs'],
        },
        {
            title: 'Sub-Type Of Incident Count',
            dataIndex: 'subtypeofincidentcount',
            key: 'subtypeofincidentcount',
            sorter : (a,b)=> a.subtypeofincidentcount - b.subtypeofincidentcount ,
            responsive: ['sm','md','lg','xs'],
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            responsive: ['sm','md','lg','xs'],
            render : (text,record,index) => {
                // console.log(record);
              return(
                      <>
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>{
                                handleShowUpdate(e);
                                setTypeOfIncidentUpdate(record.name);
                                setIdData(record.id);
                                }} id="update"><i className="bi bi-pencil-fill"></i>
                            </button>

                            {   record.subtypeofincidentcount > 0 ?

                                <button type="button" className="btn btn-primary btn-sm" disabled>
                                    <i className="bi bi-trash-fill"></i>
                                </button>

                                :

                                <button type="button" className="btn btn-primary btn-sm"
                                onClick={(e)=>{
                                    handleShowDelete(e);
                                    setIdData(record.id);
                                    }} id="delete"><i className="bi bi-trash-fill"></i>
                                </button>

                            }
                        </div>
                      </>
                    )
              }
        }
    ];

    function _onChangeTypeOfIncident(e){

        if(e.target.value){

            setTypeOfIncident(e.target.value);

        }else{

            setTypeOfIncident("");

        }

    }

    function _onChangeTypeOfIncidentUpdate(e){

        if(e.target.value){

            setTypeOfIncidentUpdate(e.target.value);

        }else{

            setTypeOfIncidentUpdate("");

        }

    }

    async function _saveData(e){

        e.preventDefault();



        if(!typeOfIncident){

            setIsInvalid(true);



            setTimeout(()=>setIsInvalid(false),3000);


        }else{

            let data = {
                "name" : typeOfIncident,
                "created_by" : localStorage.getItem("usernameLogin") ,
                "updated_by" : localStorage.getItem("usernameLogin")
            }

            await axios.post(Uri.rootUri + `master/incident/`, data, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                caches.delete("incident");// delete cache for update

                let cacheName = {

                    "name" : "incident",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function(response){

                    // console.log(response);


                })
                .catch(function(error){


                    console.log(error);

                });

                _loadDataAll("incident");

                setTypeOfIncident("");

                Helper.alert("Success", "success", "Success save data", 3000);

                _loadData(currentPage, pageSize);

            })
            .catch(function(error){

                // console.log(error);s

                setTypeOfIncident("");

                let objError = error.toJSON();

                if(objError.status == 400){

                    Helper.alert("Failed", "error", "This incident category already exist", 3000);

                }



            });

        }

    }
    async function _deleteTypeOfIncident(e,id){

        e.preventDefault();



        if(id){

            // console.log(id);
            let data = {

                "deleted_by" : localStorage.getItem("usernameLogin")

            }
            await axios.put(Uri.rootUri + `master/incident/${id}/delete/`, data, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                // console.log(response);
                caches.delete("incident");// delete cache for update

                let cacheName = {

                    "name" : "incident",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function(response){

                    // console.log(response);


                })
                .catch(function(error){


                    console.log(error);

                });

                _loadDataAll("incident");

                Helper.alert("Success", "success", "Success delete data", 3000);

                setShowDelete(false);

                _loadData(currentPage, pageSize);


            })
            .catch(function(error){

                console.log(error);
                setShowDelete(false);
                let errorMessage = error.response?.data?.res;
                if(error.response?.status == 400 && errorMessage == "Cannot delete, used in an incident"){
                    errorMessage = Helper.errorMsgDataValidation("Incident Category");
                    Helper.alertHtml("Failed", "error", errorMessage, 3000);
                }

            })



        }

    }

    async function _updateTypeOfIncident(e){



        e.preventDefault();

        if(!typeOfIncidentUpdate){

            setIsInvalidUpdate(true);



            setTimeout(()=>setIsInvalidUpdate(false),3000);

        }else{

            let data = {

                "name" : typeOfIncidentUpdate,
                "updated_by" : localStorage.getItem("usernameLogin")

            }

            await axios.put(Uri.rootUri + `master/incident/${idData}/`, data, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                // console.log(response);
                caches.delete("incident");// delete cache for update


                let cacheName = {

                    "name" : "incident",
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                }

                await axios.post(Uri.rootUri + `cache/`, cacheName)
                .then(function(response){

                    // console.log(response);


                })
                .catch(function(error){


                    console.log(error);

                });

                _loadDataAll("incident");

                Helper.alert("Success", "success", "Success update data", 3000);

                setShowUpdate(false);

                _loadData(currentPage, pageSize);
            })
            .catch(function(response){

                setShowUpdate(false);



            })


            // console.log(data);


        }


    }

    async function onChange(page, pageSize){

        let arr = [];

        let arrCache = await caches.keys();// get cache

        setCurrentPage(page);

        setPageSize(pageSize);

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=20&';

        await axios.get(Uri.rootUri + `master/incident/?${currentPageOnChange}${currentPageSizeOnChange}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                // console.log(response);

                let dataResponseIncident = response.data;

                let dataResponseSubIncident ;

                setTotalData(dataResponseIncident.count);

                // console.log(response);

                if(!arrCache.includes("subincident")){ // if not cache sub incident in browser

                    // console.log("cache sub incident undefined");

                    await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                    .then(function(response){

                        dataResponseSubIncident = response.data;

                        // console.log(dataResponseSubIncident);

                    })
                    .catch(function(error){

                        console.log(error);

                    })


                    dataResponseIncident.results.map(item=>{

                        let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

                        // console.log(item);

                        // console.log(countSubTypeIncident.length);

                        let data = {
                            "id" : item.id,
                            "name" : item.name,
                            "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
                        }

                        arr.push(data);

                    });

                    // console.log(Helper.getHostCurrentUrl());

                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponseIncident);

                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , []);

                    setDataSource(arr);

                }else{ // if not empty cache sub incident

                    let resultDataIncident = arrCache.filter( x => x == "subincident"); //get where cache name

                    resultDataIncident.map(async cachename => {

                    let cacheStorage = await caches.open(cachename);

                    let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/subincident`);

                        if(!cacheResponse || cacheResponse == undefined){

                            await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                            .then(function(response){

                                dataResponseSubIncident = response.data;

                                // console.log(dataResponseSubIncident);

                            })
                            .catch(function(error){

                                console.log(error);

                            })


                            dataResponseIncident.results.map(item=>{

                                let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

                                // console.log(item);

                                // console.log(countSubTypeIncident.length);

                                let data = {
                                    "id" : item.id,
                                    "name" : item.name,
                                    "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
                                }

                                arr.push(data);

                            });

                            // console.log(Helper.getHostCurrentUrl());

                            Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponseIncident);

                            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

                            setDataSource(arr);

                        }else{

                            let dataResponse = await cacheResponse.json();

                            if(!dataResponse || dataResponse.length == 0){

                                // console.log("cache data sub incident not available");

                                await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                .then(function(response){

                                    dataResponseSubIncident = response.data;

                                    // console.log(dataResponseSubIncident);

                                })
                                .catch(function(error){

                                    console.log(error);

                                })


                                dataResponseIncident.results.map(item=>{

                                    let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

                                    // console.log(item);

                                    // console.log(countSubTypeIncident.length);

                                    let data = {
                                        "id" : item.id,
                                        "name" : item.name,
                                        "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
                                    }

                                    arr.push(data);

                                });

                                // console.log(Helper.getHostCurrentUrl());

                                Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponseIncident);

                                Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

                                setDataSource(arr);

                            }else{

                                // console.log("cache sub incident available");

                                dataResponseIncident.results.map(item=>{

                                    let countSubTypeIncident = dataResponse.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

                                    // console.log(item);

                                    // console.log(countSubTypeIncident.length);

                                    let data = {
                                        "id" : item.id,
                                        "name" : item.name,
                                        "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
                                    }

                                    arr.push(data);

                                });


                                setDataSource(arr);



                            }


                        }

                    });

                }

            })
            .catch(function(error){

                console.log(error);



            });

    }

    async function  _loadData(page, pageSize){

        let arr = [];

        let arrCache = await caches.keys();// get cache

        setCurrentPage(page);

        setPageSize(pageSize);

        await axios.get(Uri.rootUri + `master/incident/?page=${page}&page_size=${pageSize}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            .then(async function(response){

                // console.log(response);

                let dataResponseIncident = response.data;

                let dataResponseSubIncident ;

                setTotalData(dataResponseIncident.count);

                    await axios.get(Uri.rootUri + `cache/`)
                    .then(async function(response){
                    // console.log(response);

                    let dataResponse = response.data;

                    //get data cache where cache name
                    let resultObjCache = dataResponse.filter(item => item.name === "subincident");

                    if(!arrCache.includes("subincident")){ // if not cache sub incident in browser

                        // console.log("cache sub incident undefined");

                        await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                        .then(function(response){

                            dataResponseSubIncident = response.data;

                            // console.log(dataResponseSubIncident);

                        })
                        .catch(function(error){

                            console.log(error);

                        })


                        dataResponseIncident.results.map(item=>{

                            let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

                            // console.log(item);

                            // console.log(countSubTypeIncident.length);

                            let data = {
                                "id" : item.id,
                                "name" : item.name,
                                "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
                            }

                            arr.push(data);

                        });

                        if(resultObjCache.length == 0){// if data cache in database empty

                            let cacheName = {

                                "name" : "subincident",
                                "created_by": localStorage.getItem("usernameLogin"),
                                "updated_by": localStorage.getItem("usernameLogin")

                            }

                            await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function(response){

                                // console.log(response);


                            })
                            .catch(function(error){


                                console.log(error);

                            });

                            }else{

                                // console.log("set version");

                                // if data cache in database not empty
                                Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                            }

                            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);
                        // console.log(Helper.getHostCurrentUrl());

                            setDataSource(arr);

                    }else{ // if not empty cache sub incident

                        let resultDataIncident = arrCache.filter( x => x == "subincident"); //get where cache name

                        resultDataIncident.map(async cachename => {

                        let cacheStorage = await caches.open(cachename);

                        let cacheResponseSubIncident = await cacheStorage.match(Helper.getHostCurrentUrl() + `/subincident`);

                        let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                            if(!cacheResponse || cacheResponse == undefined || !cacheResponseSubIncident || cacheResponseSubIncident == undefined){

                                await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                .then(function(response){

                                    dataResponseSubIncident = response.data;

                                    // console.log(dataResponseSubIncident);

                                })
                                .catch(function(error){

                                    console.log(error);

                                })


                                dataResponseIncident.results.map(item=>{

                                    let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

                                    // console.log(item);

                                    // console.log(countSubTypeIncident.length);

                                    let data = {
                                        "id" : item.id,
                                        "name" : item.name,
                                        "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
                                    }

                                    arr.push(data);

                                });

                                if(resultObjCache.length == 0){// if data cache in database empty

                                    let cacheName = {

                                        "name" : "subincident",
                                        "created_by": localStorage.getItem("usernameLogin"),
                                        "updated_by": localStorage.getItem("usernameLogin")

                                    }

                                    await axios.post(Uri.rootUri + `cache/`, cacheName)
                                    .then(function(response){

                                        // console.log(response);


                                    })
                                    .catch(function(error){


                                        console.log(error);

                                    });

                                    }else{

                                        // console.log("set version");

                                        // if data cache in database not empty
                                        Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                                }


                                // console.log(Helper.getHostCurrentUrl());

                                Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

                                setDataSource(arr);

                            }else{

                                let dataResponseVersion = await cacheResponse.json();

                                let dataResponseSubIncident = await cacheResponseSubIncident.json();

                                if(!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseSubIncident || dataResponseSubIncident.length == 0){

                                    // console.log("cache data sub incident not available");

                                    await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                    .then(function(response){

                                        dataResponseSubIncident = response.data;

                                        // console.log(dataResponseSubIncident);

                                    })
                                    .catch(function(error){

                                        console.log(error);

                                    })


                                    dataResponseIncident.results.map(item=>{

                                        let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

                                        // console.log(item);

                                        // console.log(countSubTypeIncident.length);

                                        let data = {
                                            "id" : item.id,
                                            "name" : item.name,
                                            "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
                                        }

                                        arr.push(data);

                                    });

                                    if(resultObjCache.length == 0){// if data cache in database empty

                                        let cacheName = {

                                            "name" : "subincident",
                                            "created_by": localStorage.getItem("usernameLogin"),
                                            "updated_by": localStorage.getItem("usernameLogin")

                                        }

                                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function(response){

                                            // console.log(response);


                                        })
                                        .catch(function(error){


                                            console.log(error);

                                        });

                                        }else{

                                            // console.log("set version");

                                            // if data cache in database not empty
                                            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                                        }

                                    // console.log(Helper.getHostCurrentUrl());

                                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

                                    setDataSource(arr);

                                }else{

                                    if(resultObjCache.length == 0){// if data cache in database empty

                                        await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                        .then(function(response){

                                            // console.log(response);

                                            let dataResponse = response.data;

                                            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponse);


                                        })
                                        .catch(function(error){

                                            console.log(error);

                                        });

                                        let cacheName = {

                                            "name" : "subincident",
                                            "created_by": localStorage.getItem("usernameLogin"),
                                            "updated_by": localStorage.getItem("usernameLogin")

                                        }

                                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                                        .then(function(response){

                                            // console.log(response);

                                        })
                                        .catch(function(error){


                                            console.log(error);

                                        });

                                    }else{

                                        //check version  cache local browser you and database api , resultObjCache there must be !
                                        let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                                        if(resultVersion){

                                                // console.log("Update cache new version ");

                                                await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                                .then(function(response){

                                                    // console.log(response);

                                                    let dataResponse = response.data;

                                                    Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponse);


                                                })
                                                .catch(function(error){

                                                    console.log(error);

                                                });

                                                Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                                        }else{

                                            dataResponseIncident.results.map(item=>{

                                                let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

                                                // console.log(item);

                                                // console.log(countSubTypeIncident.length);

                                                let data = {
                                                    "id" : item.id,
                                                    "name" : item.name,
                                                    "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
                                                }

                                                arr.push(data);

                                            });


                                            setDataSource(arr);

                                            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident`, dataResponseSubIncident);

                                            Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                                        }


                                    }


                                }


                            }

                        });

                    }
                })
                .catch(function(error){

                    console.log(error);
                });

            })
            .catch(function(error){

                console.log(error);



            });

        // let arr = [];

        // let arrCache = await caches.keys();// get cache

        // setCurrentPage(page);

        // setPageSize(pageSize);

        // await axios.get(Uri.rootUri + `master/incident/?page=${page}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(async function(response){

        //         // console.log(response);

        //         let dataResponseIncident = response.data;

        //         let dataResponseSubIncident ;

        //         setTotalData(dataResponseIncident.count);

        //         // console.log(response);

        //         if(!arrCache.includes("subincident")){ // if not cache sub incident in browser

        //             console.log("cache sub incident undefined");

        //             await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(function(response){

        //                 dataResponseSubIncident = response.data;

        //                 // console.log(dataResponseSubIncident);

        //             })
        //             .catch(function(error){

        //                 console.log(error);

        //             })


        //             dataResponseIncident.results.map(item=>{

        //                 let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

        //                 // console.log(item);

        //                 // console.log(countSubTypeIncident.length);

        //                 let data = {
        //                     "id" : item.id,
        //                     "name" : item.name,
        //                     "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
        //                 }

        //                 arr.push(data);

        //             });

        //             // console.log(Helper.getHostCurrentUrl());

        //             Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

        //             setDataSource(arr);

        //         }else{ // if not empty cache sub incident

        //             let resultDataIncident = arrCache.filter( x => x == "subincident"); //get where cache name

        //             resultDataIncident.map(async cachename => {

        //             let cacheStorage = await caches.open(cachename);

        //             let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/subincident`);

        //                 if(!cacheResponse || cacheResponse == undefined){

        //                     await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                     .then(function(response){

        //                         dataResponseSubIncident = response.data;

        //                         // console.log(dataResponseSubIncident);

        //                     })
        //                     .catch(function(error){

        //                         console.log(error);

        //                     })


        //                     dataResponseIncident.results.map(item=>{

        //                         let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

        //                         // console.log(item);

        //                         // console.log(countSubTypeIncident.length);

        //                         let data = {
        //                             "id" : item.id,
        //                             "name" : item.name,
        //                             "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
        //                         }

        //                         arr.push(data);

        //                     });

        //                     // console.log(Helper.getHostCurrentUrl());

        //                     Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

        //                     setDataSource(arr);

        //                 }else{

        //                     let dataResponse = await cacheResponse.json();

        //                     if(!dataResponse || dataResponse.length == 0){

        //                         console.log("cache data sub incident not available");

        //                         await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //                         .then(function(response){

        //                             dataResponseSubIncident = response.data;

        //                             // console.log(dataResponseSubIncident);

        //                         })
        //                         .catch(function(error){

        //                             console.log(error);

        //                         })


        //                         dataResponseIncident.results.map(item=>{

        //                             let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

        //                             // console.log(item);

        //                             // console.log(countSubTypeIncident.length);

        //                             let data = {
        //                                 "id" : item.id,
        //                                 "name" : item.name,
        //                                 "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
        //                             }

        //                             arr.push(data);

        //                         });

        //                         // console.log(Helper.getHostCurrentUrl());

        //                         Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

        //                         setDataSource(arr);

        //                     }else{

        //                         // console.log("cache sub incident available");

        //                         dataResponseIncident.results.map(item=>{

        //                             let countSubTypeIncident = dataResponse.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

        //                             // console.log(item);

        //                             // console.log(countSubTypeIncident.length);

        //                             let data = {
        //                                 "id" : item.id,
        //                                 "name" : item.name,
        //                                 "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
        //                             }

        //                             arr.push(data);

        //                         });


        //                         setDataSource(arr);



        //                     }


        //                 }

        //             });

        //         }

        //     })
        //     .catch(function(error){

        //         console.log(error);



        //     });

            // let arr = [];

            // let arrCache = await caches.keys();// get cache

            // if(!arrCache.includes("incident") || !arrCache.includes("subincident")){

            //     await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            //     .then(async function(response){


            //         let dataResponseIncident = response.data;
            //         let dataResponseSubIncident ;

            //         // console.log(response);

            //         await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            //         .then(function(response){

            //             dataResponseSubIncident = response.data;

            //             // console.log(dataResponseSubIncident);

            //         })
            //         .catch(function(error){

            //             console.log(error);

            //         })


            //         dataResponseIncident.map(item=>{

            //             let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

            //             // console.log(item);

            //             // console.log(countSubTypeIncident.length);

            //             let data = {
            //                 "id" : item.id,
            //                 "name" : item.name,
            //                 "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
            //             }

            //             arr.push(data);

            //         });

            //         // console.log(Helper.getHostCurrentUrl());

            //         Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponseIncident);

            //         Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

            //         setDataSource(arr);


            //     })
            //     .catch(function(error){

            //         console.log(error);



            //     });

            // }else{

            //     let resultDataIncident = arrCache.filter( x => x == "incident"); //get where cache name

            //     resultDataIncident.map(async cachename => {


            //       let cacheStorage = await caches.open(cachename);

            //       let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/incident`);

            //       if(!cacheResponse || cacheResponse == undefined){

            //         await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            //         .then(async function(response){


            //             let dataResponseIncident = response.data;
            //             let dataResponseSubIncident ;

            //             // console.log(response);

            //             await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            //             .then(function(response){

            //                 dataResponseSubIncident = response.data;

            //                 // console.log(dataResponseSubIncident);

            //             })
            //             .catch(function(error){

            //                 console.log(error);

            //             })


            //             dataResponseIncident.map(item=>{

            //                 let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

            //                 // console.log(item);

            //                 // console.log(countSubTypeIncident.length);

            //                 let data = {
            //                     "id" : item.id,
            //                     "name" : item.name,
            //                     "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
            //                 }

            //                 arr.push(data);

            //             });

            //             // console.log(Helper.getHostCurrentUrl());

            //             Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponseIncident);

            //             Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

            //             setDataSource(arr);


            //         })
            //         .catch(function(error){

            //             console.log(error);



            //         });

            //       }else{

            //         let dataResponse = await cacheResponse.json();

            //         if(!dataResponse || dataResponse.length == 0){

            //             await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            //             .then(async function(response){


            //                 let dataResponseIncident = response.data;
            //                 let dataResponseSubIncident ;

            //                 // console.log(response);

            //                 await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            //                 .then(function(response){

            //                     dataResponseSubIncident = response.data;

            //                     // console.log(dataResponseSubIncident);

            //                 })
            //                 .catch(function(error){

            //                     console.log(error);

            //                 })


            //                 dataResponseIncident.map(item=>{

            //                     let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

            //                     // console.log(item);

            //                     // console.log(countSubTypeIncident.length);

            //                     let data = {
            //                         "id" : item.id,
            //                         "name" : item.name,
            //                         "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
            //                     }

            //                     arr.push(data);

            //                 });

            //                 // console.log(Helper.getHostCurrentUrl());

            //                 Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponseIncident);

            //                 Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

            //                 setDataSource(arr);


            //             })
            //             .catch(function(error){

            //                 console.log(error);



            //             });

            //         }else{


            //             let dataResponseSubIncident ;

            //             // console.log(response);

            //             await axios.get(Uri.rootUri + `master/subincident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
            //             .then(function(response){

            //                 dataResponseSubIncident = response.data;

            //                 // console.log(dataResponseSubIncident);

            //             })
            //             .catch(function(error){

            //                 console.log(error);

            //             })


            //             dataResponse.map(item=>{

            //                 let countSubTypeIncident = dataResponseSubIncident.filter(x => Helper.hiddenChar(x.incident_id) == Helper.hiddenChar(item.id));

            //                 // console.log(item);

            //                 // console.log(countSubTypeIncident.length);

            //                 let data = {
            //                     "id" : item.id,
            //                     "name" : item.name,
            //                     "subtypeofincidentcount": countSubTypeIncident.length ? countSubTypeIncident.length : 0,
            //                 }

            //                 arr.push(data);

            //             });

            //             // console.log(Helper.getHostCurrentUrl());

            //             Helper.addDataIntoCache("subincident", Helper.getHostCurrentUrl() + `/subincident` , dataResponseSubIncident);

            //             setDataSource(arr);


            //         }

            //     }

            // });



            // }

    }

    async function _loadDataAll(cachenameparam){

        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
        .then(async function(response){

             //get version data if there is an update
            let dataResponse = response.data;

            //get data cache where cache name
            let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

            if(!arrCache.includes("incident")){// if empty cache name in browser

                // console.log("cache in browser empty");

                await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){

                    let dataResponse = response.data;

                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponse);


                })
                .catch(function(error){

                    console.log(error);



                });

                if(resultObjCache.length == 0){// if data cache in database empty

                  let cacheName = {

                    "name" : cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")

                  }

                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function(response){

                    //   console.log(response);


                  })
                  .catch(function(error){


                      console.log(error);

                  });

                }else{

                    //   console.log("set version");

                      // if data cache in database not empty
                      Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                }

              }else{

                let resultData = arrCache.filter( x => x == "incident");

                //Operation Caching Validation

                resultData.forEach(async cachename => {

                  let cacheStorage = await caches.open(cachename);

                  let incidentResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/incident');
                  //get version in cache for check update data
                  let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url

                  if(!cacheResponse || cacheResponse == undefined || !incidentResponse || incidentResponse == undefined){// check data valid or no valid

                    // console.log("set version into cache");

                    await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                    .then(function(response){

                        let dataResponse = response.data;

                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponse);


                    })
                    .catch(function(error){

                        console.log(error);



                    });

                    if(resultObjCache.length == 0){// if data cache in database empty

                      let cacheName = {

                        "name" : cachenameparam,
                        "created_by": localStorage.getItem("usernameLogin"),
                        "updated_by": localStorage.getItem("usernameLogin")

                      }

                      await axios.post(Uri.rootUri + `cache/`, cacheName)
                      .then(function(response){

                        //   console.log(response);


                      })
                      .catch(function(error){


                          console.log(error);

                      });

                    }else{

                        //   console.log("set version");

                          // if data cache in database not empty
                          Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                    }


                  }else{

                    let dataResponseVersion = await cacheResponse.json();

                    let dataResponseIncident = await incidentResponse.json();

                    if(!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseIncident || dataResponseIncident.length == 0){/*check data into cache empty or not empty*/

                        await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                        .then(function(response){

                            let dataResponse = response.data;

                            Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponse);


                        })
                        .catch(function(error){

                            console.log(error);



                        });

                        if(resultObjCache.length == 0){// if data cache in database empty

                            let cacheName = {

                                "name" : cachenameparam,
                                "created_by": localStorage.getItem("usernameLogin"),
                                "updated_by": localStorage.getItem("usernameLogin")

                            }

                            await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function(response){

                                // console.log(response);


                            })
                            .catch(function(error){


                                console.log(error);

                            });

                        }else{

                            // console.log("set version");

                            // if data cache in database not empty
                            Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                        }

                    }else{

                        // dataResponseVersion and dataResponseIncident there must be !

                        if(resultObjCache.length == 0){// if data cache in database empty

                            await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                            .then(function(response){

                                let dataResponse = response.data;

                                Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponse);


                            })
                            .catch(function(error){

                                console.log(error);



                            });

                            let cacheName = {

                                "name" : cachenameparam,
                                "created_by": localStorage.getItem("usernameLogin"),
                                "updated_by": localStorage.getItem("usernameLogin")

                            }

                            await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function(response){

                                // console.log(response);


                            })
                            .catch(function(error){


                                console.log(error);

                            });

                        }else{

                              //check version  cache local browser you and database api , resultObjCache there must be !
                              let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;

                              if(resultVersion){

                                    // console.log("Update cache new version ");

                                    await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                    .then(function(response){

                                        let dataResponse = response.data;

                                        Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` , dataResponse);


                                    })
                                    .catch(function(error){

                                        console.log(error);



                                    });

                                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                              }else{

                                    // console.log("data incident and version available in cache");

                                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident`, dataResponseIncident);

                                    Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache

                              }

                        }


                    }

                  }

                });

              }


        })
        .catch(function(error){

            console.log(error);

        });


        // let arrCache = await caches.keys();// get cache

        // if(!arrCache.includes("incident")){

        //     await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //     .then(async function(response){

        //         let dataResponse = response.data;

        //         Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` ,dataResponse);//set cache


        //     })
        //     .catch(function(error){

        //         console.log(error);


        //     });

        // }else{

        //     let resultDataIncident = arrCache.filter( x => x == "incident"); //get where cache name

        //     resultDataIncident.map(async cachename => {

        //     let cacheStorage = await caches.open(cachename);

        //     let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + `/incident`);

        //     if(!cacheResponse || cacheResponse == undefined){

        //         await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //         .then(async function(response){

        //             let dataResponse = response.data;

        //             Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` ,dataResponse);//set cache


        //         })
        //         .catch(function(error){

        //             console.log(error);


        //         });

        //     }else{

        //         let dataResponse = await cacheResponse.json();

        //         if(!dataResponse || dataResponse.length == 0){

        //             await axios.get(Uri.rootUri + `master/incident/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        //             .then(async function(response){

        //                 let dataResponse = response.data;

        //                 Helper.addDataIntoCache("incident", Helper.getHostCurrentUrl() + `/incident` ,dataResponse);//set cache


        //             })
        //             .catch(function(error){

        //                 console.log(error);


        //             });
        //         }

        //     }

        //     });

        // }

    }

    useEffect(()=>{
      const effect = async () => {

        //ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadData(1, 100);
        await _loadDataAll("incident");
        
        setLoading(false);
      }
      effect();
    },[]);

  return(

    <Spin size='large' spinning={loading}>
    <div className="typeofincident">
        <div className="row">
            <div className="col-lg-4">
                <h4>Add New Incident Category:</h4>
                    <div className="mb-3">
                        <label htmlFor="typeofincident" className='m-1 d-flex align-items-start'>Incident Category <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>
                        {isInvalid && !typeOfIncident ?

                            <div id="typeofincident" className="text-danger mb-1" style={{ fontSize:14 }}>
                                Please insert Incident Category.
                            </div>
                            :
                            <></>
                        }
                        <input type="text" className="form-control" name="typeofincident" id="typeofincident" onChange={_onChangeTypeOfIncident} value={typeOfIncident && typeOfIncident}/>
                    </div>
                <button type="submit" className="btn btn-primary" style={{ width:"100%" }} onClick={_saveData} ><i className="bi bi-plus-circle-fill"></i> Add to Master Data</button>
            </div>
            <div className="col-lg-8">
                <h4>Incident Category Data</h4>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            scroll={{x:'max-content'}}
                            pagination={false}
                        />
                         <div className="text-center mt-3">
                            <PaginationComponent
                            currentPage = {currentPage}
                            setCurrentPage = {setCurrentPage}
                            pageSize = {pageSize}
                            setPageSize = {setPageSize}
                            onChange = {onChange}
                            showSizeChanger = {false}
                            total = {totalData ? totalData : 0}
                            defaultPageSize = {20}
                            />
                        </div>
            </div>
        </div>


            <>

                <Modal show={showUpdate} onHide={handleCloseUpdate} id={`update`}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-3'>
                        <label htmlFor='type ' className='m-1 d-flex align-items-start'>Incident Category <i className="bi bi-asterisk text-danger" style={{ fontSize:8 }}></i></label>
                        {isInvalidUpdate && !typeOfIncidentUpdate ?

                            <div id="type" className="text-danger mb-1" style={{ fontSize:14 }}>
                                Please select Incident Category.
                            </div>
                                :
                            <></>
                         }
                         <input type="text" className="form-control" onChange={_onChangeTypeOfIncidentUpdate} value={typeOfIncidentUpdate ? typeOfIncidentUpdate : "" }/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdate}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={_updateTypeOfIncident}>
                        Save
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                         <p className='m-0'>Delete this incident category ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e)=>_deleteTypeOfIncident(e,idData)}>
                        Ok
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
    </div>
    </Spin>
  )

}
