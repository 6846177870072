import React,{useEffect,useState}from 'react'
import { useHistory } from 'react-router-dom';
import '../../node_modules/antd/dist/antd.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../src/App.css'
import { Spin } from 'antd';
import Helper from './helpers/Helper';
import Loading from './utils/Loading';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { useAxios } from '../App';

export default function Login(props) {
    const axios = useAxios();

    let history = useHistory();

    let [loading, setLoading] = useState(true);
    let [isInvalid, setIsInvalid] = useState();
    let [found, setFound] = useState();
    let [tokenNotFound, setTokenNotFound] = useState();
    let [notFound, setNotFound] = useState();
    let [visible, setVisible] = useState(true);

    const { instance: msalInstance, inProgress } = useMsal();

    async function _loadAuthToken(){

        await Helper.tokenValidation(axios); //token validation
        
        if(localStorage.getItem("verified") == 1){
    
            setVisible(false);

            // history.goBack();

            history.push('Index/embed/LandingPage');
            // history.push('Index');
    
        }else{
    
            setVisible(false);
            if (localStorage.getItem("authError") == 1) {
              setTokenNotFound(true);
              setTimeout(()=> setTokenNotFound(false), 10000);
              localStorage.setItem("authError", 0);
            }

            // if(props.location.state){
            //     _checkValidation(props);

            // }

    
        }
    
      }

    const handleLoginPopup = async () => {
        try {
            await msalInstance
              .loginPopup({
                  ...loginRequest,
                  // redirectUri: '/Index',
            });
            window.location.replace('/Index');
        } catch (error) {
            console.log(error)
        }
    };

    const handleLoginSilent = async () => {
      try {
          await msalInstance
              .ssoSilent({
                  ...loginRequest,
                  redirectUri: '/Index',
              });
          window.location.replace('/Index');
      } catch (error) {
          console.log(error)
      }
  };

    const handleLoginRedirect = async () => {
      try {
          await msalInstance.loginRedirect({
            ...loginRequest,
            // redirectUri: '/auth-redirect',
          }).catch((error) => console.log(error));
      } catch (error) {
          console.log(error)
      }
    };
    
    useEffect(() => {

        console.log("Running App");
        console.log("exera development v1");

        _loadAuthToken();

        setLoading(false);
        setIsInvalid(false);
       
    }, []);
    return (
        <>
        { visible ?

                <Loading/>

            :
            
        <>  
        <div className='landing-page'>
            <Spin size='large' spinning={loading}>
                {/* <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className='text-center'>
                            <img src={landing} alt="img" className='img-fluid' style={{ height:"500px", }}/>
                        </div>
                    </div>
                </div> */}
                <div className='loginPage'>
                    <div className="container" >

                        {/* row  */}
                        {/* <div className="row mb-5 justify-content-center">
                                <div className="col-lg-8">
                                    <div className='bg-img text-center'>
                                        <img src={landing} alt="img" className='img-fluid'/>
                                    </div>
                                </div>
                            </div> */}
                        {/* end row */}

                        {/* row */}
                        <div className="row d-flex align-items-end" style={{ minHeight:"100vh" }}>
                            <div className="col-lg-4 offset-lg-4" style={{ marginBottom: "7rem" }} >
                                {/* <label htmlFor="email" className='text-white mb-2'><strong>Email:</strong> </label> */}
                                {isInvalid ?
                                    <div id="permissionselection" className="text-danger mb-1" style={{ fontSize:14 }}>
                                        Please insert a email with '@' character.
                                    </div>
                                :
                                    <></>
                                }
                                <div className="input-group mb-3">
                                    {/* <input type="email" className="form-control form-login" placeholder="Email..." name='email' id='email' value={email ? email : ""} onChange={_email} onPressEnter={_signIn}/>
                                    <button type="submit" className="btn btn-primary" onClick={_signIn}>Sign In</button> */}
                                    <button type="submit" className="btn btn-light" style={{ width: '100%' }} onClick={handleLoginRedirect}><b>Sign In</b></button>
                                </div>
                                {
                                    found &&
                                    <div className="alert alert-warning" role="alert">
                                        A login link will be sent to you mailbox. Open your mailbox.
                                    </div>
                                }
                                { notFound &&
                                    <div className="alert alert-warning" role="alert">
                                        Email not found.
                                    </div>
                                }
                                { tokenNotFound &&
                                    <div className="alert alert-warning" role="alert">
                                        Access denied, please login or contact your administrator.
                                    </div>
                                }
                            </div>
                        </div>
                        {/* end row */}
                    </div>
                </div>
            </Spin>
        </div>
        </>
        }
    </>
    )
}
