import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import Uri from '../Uri';
import Helper from './helpers/Helper';
import { useAxios } from '../App';
import { useMsal } from '@azure/msal-react';
import jwtDecode from 'jwt-decode';
import { useParams } from 'react-router-dom';
import $ from 'jquery';

export default function TableauEmbedded({ setBackground }) {
  const axios = useAxios();
  const authToken = localStorage.getItem("token");
  const { instance: msalInstance } = useMsal();

  const { embedPathname } = useParams();

  const [iframeHeight, setIframeHeight] = useState('400px'); // Set initial height
  const [iframeWidth, setIframeWidth] = useState('1366px'); // Set initial wdith

  const [loading, setLoading] = useState(true);
  const [isVizSizeKnown, setIsVizSizeKnown] = useState(false);
  const [embedToken, setEmbedToken] = useState('');

  const updateIframeHeight = useCallback(() => {
    // Update iframe height based on window.innerHeight
    setIframeHeight(`${window.innerHeight - 58}px`);
  }, []);

  const updateIframeWidth = useCallback(() => {
    // Update iframe width based on window.innerHeight
    let width = window.innerWidth;
    if (width < 1280) width = 1280;
    if (width > 1920) width = 1920;
    setIframeWidth(`${width}px`);
  }, []);

  useEffect(() => {
    // Attach event listener to window resize event
    window.addEventListener('resize', updateIframeHeight);
    window.addEventListener('resize', updateIframeWidth);

    // Initial update
    updateIframeHeight();
    updateIframeWidth();

    // setBackground('#f5f5f5'); // Change background color of MainDashboard to gray
    $("#page-content-wrapper").toggleClass("embed");

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', updateIframeHeight);
      window.removeEventListener('resize', updateIframeWidth);
      $("#page-content-wrapper").toggleClass("embed");
    };
  }, []); // Empty dependency array to run effect only once on mount

  // Check for token, if it's not present then request a new one
  useEffect(() => {
    const getEmbedToken = async () => {
      await Helper.authorizationLogin(axios, msalInstance);

      // Check token in localStorage
      const localEmbedToken = localStorage.getItem('embedToken');
      if (localEmbedToken) {
        const decodedToken = jwtDecode(localEmbedToken, { header: false });
        const now = new Date();

        if (decodedToken.exp && ((decodedToken.exp * 1000) > now.getTime())) { // Check token expiration time, embedded token must be valid for only 10 minutes
          setEmbedToken(localEmbedToken);
          setLoading(false);
          return;
        }
      }

      // There's no active token, request a new one
      try {
        const response = await axios.get(Uri.rootUri + `user/auth/${authToken}/embed/`, { headers: { "Authorization": `Bearer ${authToken}` } });
        setLoading(false);

        if (response) {
          localStorage.setItem('embedToken', response.data.token);
          setEmbedToken(response.data.token);
          setLoading(false);
          return;
        } else {
          console.log('No response');
          Helper.alert("Failed", "error", "Cannot establish connection to Tableau, please try again later", 3000);
        }
      } catch (error) {
        console.log(error);
        Helper.alert("Failed", "error", "Cannot establish connection to Tableau, please try again later", 3000);
      }
    }
    getEmbedToken();
  }, []);

  useEffect(() => {
    if (!!!loading) {
      let viz = document.getElementById('tableau-viz');

      viz?.addEventListener("firstvizsizeknown", handleFirstVizSizeKnown);
      viz?.addEventListener("tabswitched", handleVizTabSwitch);
    }
  }, [loading]);


  async function handleFirstVizSizeKnown(e) {
    console.log(e);
    setIsVizSizeKnown(true);
  }

  async function handleVizTabSwitch(e) {
    // console.log(e);
  }

  return (
    <React.Fragment>
      {loading ?
        <React.Fragment /> :
        <Spin spinning={!!!isVizSizeKnown} >
          <div class='tableauPlaceholder' style={{ width: '100%', height: '100%', margin: '0 auto' }}>
            <tableau-viz
              id='tableau-viz'
              src={embedPathname !== 'AnimatedVisualization' ? `${Uri.embedUri}${embedPathname}` : `${Uri.animatedVizUri}${embedPathname}`}
              width={iframeWidth}
              height={iframeHeight}
              hide-tabs
              toolbar='top'
              token={embedToken}
            />
          </div>
        </Spin>
      }
    </React.Fragment>
  );
}