import React, { useEffect,useState } from 'react';
import { Table , Spin} from 'antd';
import { Link, useHistory  } from 'react-router-dom';
import Uri from '../Uri';
import {Modal,Button} from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import Helper from './helpers/Helper';
import PaginationComponent from './utils/PaginationComponent';
import { useAxios } from '../App';
import { useMsal } from '@azure/msal-react';

export default function Company() {
        const axios = useAxios();
        const { instance: msalInstance } = useMsal();
        
        const authToken = localStorage.getItem("token");

        let history = useHistory();

        //User Information
        let [username, setUsername] = useState("");
        let [emailAddress, setEmailAddress] = useState("");
        let [authId, setAuthId] = useState("");
        let [companyId, setCompanyId] = useState("");
        let [userId, setUserId] = useState("");
        let [permissions, setPermissions] = useState([]);
        let [approveIncident, setApproveIncident] = useState("1");
        let [customerManager, setCustomerManager] = useState("2");
        let [editIncident, setEditIncident] = useState("3");
        let [poiSelfService, setPoiSelfService] = useState("4");
        let [readOnly, setReadOnly] = useState("5");
        let [superUser, setSuperUser] = useState("6");

        let [ company,setCompany ] = useState([]);
        let [ subscriptionTypeData, setSubscriptionTypeData] = useState([]);
        let [ idData, setIdData] = useState();
        let [ loading, setLoading] = useState();
        let [ search, setSearch] = useState("");

        let [ currentPage, setCurrentPage] = useState();
        let [ pageSize, setPageSize ] = useState();
        let [ totalData, setTotalData] = useState();

        const [showDelete, setShowDelete] = useState();

        function handleCloseDelete(e){
    
            setShowDelete(false);
    
        };
        function handleShowDelete(e){
    
            setShowDelete(true);
    
        }

        const columns = [
            {
                title: 'Company Name',
                dataIndex: 'companyname',
                key: 'companyname',
                sorter : (a,b)=> a.companyname.localeCompare(b.companyname) ,
                responsive: ['sm','md','lg','xs'],
            },
            {
                title: 'Date of Subscription',
                dataIndex: 'subscriptiondate',
                key: 'subscriptiondate',
                sorter : (a,b)=> a.subscriptiondate.localeCompare(b.subscriptiondate),
                responsive: ['sm','md','lg','xs'],
                render :(text,record,index)=>{

                    return moment(text).format("DD/MM/YYYY");

                }
            },
            {
                title: 'End of Contract',
                dataIndex: 'endofcontract',
                key: 'endofcontract',
                sorter : (a,b)=> a.endofcontract.localeCompare(b.endofcontract),
                responsive: ['sm','md','lg','xs'],
                render :(text,record,index)=>{

                    return moment(text).format("DD/MM/YYYY");

                }
            },
            {
                title: 'Subscription Type',
                dataIndex: 'subscriptiontype',
                key: 'subscriptiontype',
                sorter : (a,b)=> a.subscriptiontype.localeCompare(b.subscriptiontype),
                responsive: ['sm','md','lg','xs'],
                render : (text,record,index)=>{
                    return subscriptionTypeData.map(x=>{

                        if(String(x.id) == String(text)){

                            return x.name

                        }

                    });
                }
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                responsive: ['sm','md','lg','xs'],
                render : (text,record,index) => {
                    // console.log(record);
                    return(
                            <>
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>window.open(`${window.location.origin}/Index/ViewCompany/${record.id}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} > 
                                        <i className="bi bi-pencil-fill"></i>

                                        {/* <Link onClick={(e)=>window.open(`http://localhost:3000/Index/ViewCompany/${record.id}`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} className='text-white'>
                                            <i className="bi bi-pencil-fill"></i>
                                        </Link> */}
                                    </button>
                                    <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>{
                                                handleShowDelete(e);
                                                setIdData(record.id);
                                                }} id="delete">
                                            <i className="bi bi-trash-fill"></i>
                                    </button>
                                </div>
                            </>
                        )
                    }
            },
        ];

    function _searchForm(e){

        if(e.target.value){

            setSearch(e.target.value);

        }else{

            setSearch("");

        }

    }

    async function onChange(page, pageSize){

        // console.log(page,pageSize,currentPage); //page = new previous  page and new next page
  
        setPageSize(pageSize);
  
        setCurrentPage(page);
  
        let searchText = search ? `search=${search}&` : "";

        let currentPageOnChange = page ? `page=${page}&` : 'page=1&';

        let currentPageSizeOnChange = pageSize ? `page_size=${pageSize}&` : 'page_size=100&';    
  
        await axios.get(Uri.rootUri + `company/?${currentPageOnChange}${searchText}${currentPageSizeOnChange}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(function(response){

            let dataResponse = response.data;

            setTotalData(dataResponse.count);

            setCompany(dataResponse.results);

        })
        .catch(function(error){

            console.log(error);

        });

    }

    async function _onSearch(e){

        e.preventDefault();

        let searchText = search ? `search=${search}&` : "";

        await axios.get(Uri.rootUri + `company/?page=1&${searchText}page_size=100`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(function(response){

            let dataResponse = response.data;

            setTotalData(dataResponse.count);

            setCompany(dataResponse.results);
            

        })
        .catch(function(error){

            console.log(error);

        })

    }

    async function _loadData(page, pageSize){

        setCurrentPage(page);

        setPageSize(pageSize);

        await axios.get(Uri.rootUri + `company/?page=${page}&page_size=${pageSize}`, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(function(response){

            let dataResponse = response.data;

            // console.log(response);


            setTotalData(dataResponse.count);

            setCompany(dataResponse.results);

        })
        .catch(function(error){

            console.log(error);

        });

    }
    
    async function _loadSubscriptionTypeData(e){

        let dataObj = [
            {
                "id" : "1",
                "name" : "Platinum"
            },
            {
                "id" : "2",
                "name" : "Gold"
            },
            {
                "id" : "3",
                "name" : "Silver"
            },
            {
                "id" : "4",
                "name" : "Bronze"
            },

        ]

        setSubscriptionTypeData(dataObj);

    }

    async function _deleteData(e,id){

        let data={

            "deleted_by" : localStorage.getItem("usernameLogin")

        }

        await axios.put(Uri.rootUri + `company/${id}/delete/`, data, { headers: {"Authorization" : `Bearer ${authToken}`} })
        .then(async function(response){

            // console.log(response)
            caches.delete("company");

            
            //API for update cache
            let cacheName = {

                "name" : "company",
                "created_by": localStorage.getItem("usernameLogin"),
                "updated_by": localStorage.getItem("usernameLogin")

            }
    
            await axios.post(Uri.rootUri + `cache/`, cacheName)
            .then(function(response){

                // console.log(response);

            })
            .catch(function(error){

                console.log(error);

            });

            _loadDataAll("company");
            
            Helper.alert("Success", "success", "Success delete data", 3000);
            
            _loadData(currentPage, pageSize)
            
            handleCloseDelete();
        })
        .catch(function(error){

            handleCloseDelete();

            // console.log(error);

        })

    }

    async function _loadUserInformation(){

        const userInformation = await Helper.getUserLogin();
    
        if(userInformation){
    
          setUserId(userInformation.user_id);
    
          setEmailAddress(userInformation.email_address);
    
          setAuthId(userInformation.auth_id);
    
          setCompanyId(userInformation.company_id);
    
          setUsername(userInformation.username);
    
          let strPermissions = Array.from(Helper.hiddenChar(userInformation.permissions));
    
          setPermissions(strPermissions);
    
          if(!strPermissions.includes(customerManager)){// if not Permission Type Edit Incident
    
                // history.push("Unauthorize");

                window.location.replace(`${window.location.origin}/Index/Unauthorize`);
    
                // window.location.replace("http://localhost:3000/Index/Unauthorize");
    
            }
    
        }
    
      }

    async function _loadDataAll(cachenameparam){
        
        let arrCache = await caches.keys();// get cache

        await axios.get(Uri.rootUri + `cache/`)
        .then(async function(response){

             //get version data if there is an update
            let dataResponse = response.data;

            //get data cache where cache name
            let resultObjCache = dataResponse.filter(item => item.name === cachenameparam);

            if(!arrCache.includes("company")){// if empty cache name in browser

                // console.log("cache in browser empty");
      
                await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                .then(function(response){
      
                  // console.log(response);
      
                  let dataResponse = response.data;
      
                  Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache
      
                })
                .catch(function(error){
      
                  console.log(error);
      
                });
      
                if(resultObjCache.length == 0){// if data cache in database empty
      
                  let cacheName = {
      
                    "name" : cachenameparam,
                    "created_by": localStorage.getItem("usernameLogin"),
                    "updated_by": localStorage.getItem("usernameLogin")
      
                  }
      
                  await axios.post(Uri.rootUri + `cache/`, cacheName)
                  .then(function(response){
      
                    //   console.log(response);
    
      
                  })
                  .catch(function(error){
      
      
                      console.log(error);
      
                  });
      
                }else{
      
                    //   console.log("set version");
      
                      // if data cache in database not empty
                      Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache
      
                }
      
              }else{

                let resultData = arrCache.filter( x => x == "company"); 

                //Operation Caching Validation
      
                resultData.forEach(async cachename => {
      
                  let cacheStorage = await caches.open(cachename);
      
                  let companyResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/company');
                  //get version in cache for check update data
                  let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/version'); // get data cache where url
      
                  if(!cacheResponse || cacheResponse == undefined || !companyResponse || companyResponse == undefined){// check data valid or no valid
      
                        await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                        .then(function(response){
            
                        // console.log(response);
            
                        let dataResponse = response.data;
            
                        Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache
            
                        })
                        .catch(function(error){
            
                        console.log(error);
            
                        });
            
                        if(resultObjCache.length == 0){// if data cache in database empty
            
                        let cacheName = {
            
                            "name" : cachenameparam,
                            "created_by": localStorage.getItem("usernameLogin"),
                            "updated_by": localStorage.getItem("usernameLogin")
            
                        }
            
                        await axios.post(Uri.rootUri + `cache/`, cacheName)
                        .then(function(response){
            
                            // console.log(response);
            
            
                        })
                        .catch(function(error){
            
            
                            console.log(error);
            
                        });
            
                      }else{
            
                            // if data cache in database not empty
                            Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache
            
                      }
      
                  }else{
      
                    let dataResponseVersion = await cacheResponse.json();
      
                    let dataResponseCompany = await companyResponse.json();
      
                    if(!dataResponseVersion || dataResponseVersion.length == 0 || !dataResponseCompany || dataResponseCompany.length == 0){/*check data into cache empty or not empty*/
      
                            await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                            .then(function(response){
                
                            // console.log(response);
                
                                let dataResponse = response.data;
                
                                Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache
                
                            })
                            .catch(function(error){
                
                                console.log(error);
                
                            });
                
                            if(resultObjCache.length == 0){// if data cache in database empty
                
                                let cacheName = {
                    
                                    "name" : cachenameparam,
                                    "created_by": localStorage.getItem("usernameLogin"),
                                    "updated_by": localStorage.getItem("usernameLogin")
                    
                                }
                
                                await axios.post(Uri.rootUri + `cache/`, cacheName)
                                .then(function(response){
                    
                                    // console.log(response);
                    
                                })
                                .catch(function(error){
                    
                    
                                    console.log(error);
                    
                                });
                
                            }else{
                
                                // if data cache in database not empty
                                Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache
                
                            }
      
                    }else{
      
                        // dataResponseVersion and dataResponseCompany there must be !
      
                        if(resultObjCache.length == 0){// if data cache in database empty
      
                            await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                            .then(function(response){
                
                            // console.log(response);
                
                                let dataResponse = response.data;
                
                                Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache
                
                            })
                            .catch(function(error){
                
                                console.log(error);
                
                            });
      
                            let cacheName = {
                    
                                "name" : cachenameparam,
                                "created_by": localStorage.getItem("usernameLogin"),
                                "updated_by": localStorage.getItem("usernameLogin")
                
                            }
            
                            await axios.post(Uri.rootUri + `cache/`, cacheName)
                            .then(function(response){
                
                                // console.log(response);
                
                            })
                            .catch(function(error){
                
                
                                console.log(error);
                
                            });
              
                        }else{
      
                              //check version  cache local browser you and database api , resultObjCache there must be !
                              let resultVersion = resultObjCache[0].version !== dataResponseVersion[0].version ? true : false;
      
                              if(resultVersion){
      
                                //   console.log("Update cache new version ");
      
                                  await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
                                    .then(function(response){
                        
                                    // console.log(response);
                        
                                        let dataResponse = response.data;
                        
                                        Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache
                        
                                    })
                                    .catch(function(error){
                        
                                        console.log(error);
                        
                                    });

                                    Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache
      
                              }else{
      
                                  Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company`, dataResponseCompany);
      
                                  Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/version` , resultObjCache);//set cache
      
                              }
              
                        }
      
      
                    } 
      
                  }
      
                });

              }


        })
        .catch(function(error){

            console.log(error);

        });

    // let arrCache = await caches.keys();// get cache

    // if(!arrCache.includes("company")){

    //     await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //     .then(function(response){

    //         // console.log(response);

    //         let dataResponse = response.data;

    //         Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache

    //     })
    //     .catch(function(error){

    //         console.log(error);

    //     });

    // }else{

    //     let resultData = arrCache.filter( x => x == "company"); 

    //     resultData.forEach(async cachename => {

    //         let cacheStorage = await caches.open(cachename);

    //         let cacheResponse = await cacheStorage.match(Helper.getHostCurrentUrl() + '/company');

    //         if(!cacheResponse || cacheResponse == undefined){// if data in cache empty

    //         await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //         .then(function(response){

    //             // console.log(response);

    //             let dataResponse = response.data;

    //             Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache

    //         })
    //         .catch(function(error){

    //             console.log(error);

    //         });

    //         }else{// if data in cache not empty

    //         let dataResponse = await cacheResponse.json();

    //         if(!dataResponse || dataResponse.length == 0){

    //             await axios.get(Uri.rootUri + `company/`, { headers: {"Authorization" : `Bearer ${authToken}`} })
    //             .then(function(response){
    
    //             // console.log(response);
    
    //             let dataResponse = response.data;
    
    //             Helper.addDataIntoCache("company", Helper.getHostCurrentUrl() + `/company` ,dataResponse);//set cache
    
    //             })
    //             .catch(function(error){
    
    //             console.log(error);
    
    //             });

    //         }

    //         }

    //     });

    // }


    }
  

    
    useEffect(()=>{
      const effect = async () => {
        // ajax call pending set loading
        // axios.setLoadingInterceptors(setLoading);
        await Helper.authorizationLogin(axios, msalInstance);

        await _loadUserInformation();

        await _loadData(1, 100);
        await _loadDataAll("company");
        await _loadSubscriptionTypeData();

        setLoading(false);
      }
      effect();
    },[]);

  return(
    <Spin size='large' spinning={loading}>
        <div className="company">
            <div className="row">
                <div className="col-lg-4 col-sm-12 col-xs-12">
                    <div className="mb-3">
                        <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>window.open(`${window.location.origin}/Index/AddNewCompany`,'_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} >
                            Add New Company
                        </button>
                        
                        {/* <button type="button" className="btn btn-primary btn-sm">
                            <Link onClick={(e)=>window.open(`http://localhost:3000/Index/AddNewCompany`, '_blank', "resizable=yes, scrollbars=yes, titlebar=yes, width=1300, height=700, top=10, left=10")} className='text-white text-decoration-none'><i className="bi bi-plus-circle-fill"></i> Add New Company</Link>
                        </button> */}
                    </div>
                </div>
                <div className="col-lg-8 col-sm-12 col-xs-12">
                    <div className='d-flex align-items-center ml-5 mb-3'>
                        <div className="d-inline-block mx-2" >
                            <label htmlFor="search" className="col-form-label">Search: </label>
                        </div>
                        <div class="input-group" style={{ width:250 }}>
                            <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={_searchForm}/>
                            <button class="btn btn-primary" type="submit" id="button-addon2" onClick={_onSearch}><i class="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                        <Table 
                        columns={columns}
                        dataSource={company}
                        pagination={false}
                        size={"small"}
                        // scroll={{ x:1500}}
                        scroll={{ x:'max-content'}}
                        />
                        <div className='text-center mt-3'>
                        <PaginationComponent
                            currentPage = {currentPage}
                            setCurrentPage = {setCurrentPage}
                            pageSize = {pageSize}
                            setPageSize = {setPageSize}
                            onChange = {onChange}
                            showSizeChanger = {false}
                            total = {totalData ? totalData : 0}
                            defaultPageSize = {100}
                        />
                    </div>
                </div>
            </div>
                <Modal show={showDelete} onHide={handleCloseDelete} id={`delete`}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='m-0'>Delete this company ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e)=>_deleteData(e,idData)}>
                        Ok
                    </Button>
                    </Modal.Footer>
                </Modal>
        </div>
    </Spin>
  );
}
