import React, { useEffect } from 'react'
import unauthorize from '../images/unauthorize.svg';

export default function Unauthorize() {

  return (
    <>  
        <div className='d-flex align-items-center justify-content-center' style={{ height:'400px' }}>
            <div>
                <img src={unauthorize} className='img-fluid' style={{ width:'300px', height : '300px' }}/>
                <h6>Access denied, please contact your administrator.</h6>
            </div>
        </div>
      
    </>
  )
}
